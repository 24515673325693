import React from "react";
import { useState } from "react";
import coatOfArm from "../assets/coatOfArm.svg";
import closeIcon from "../assets/x-circle-black.svg";
import styles from "./news.modal.module.css";

const NewsModal = () => {
	const [modalVisible, setModalVisible] = useState(true);

	// checking if the user has visited the page and has seen the news modal //
	// useEffect(() => {
	// 	if (!localStorage.getItem("modalShown")) {
	// 	setModalVisible(true);
	// 	localStorage.setItem("modalShown", "true");
	// 	}
	// }, []);

	// const downloadImage = () => {
	// 	const link = document.createElement("a");
	// 	link.href = ESIRSJTBTIN;
	// 	link.download = "ESIRS JTB TIN";
	// 	document.body.appendChild(link);
	// 	link.click();
	// 	document.body.removeChild(link);
	// };

	return (
		<div
			className={`${styles.modalOverlay} ${
				modalVisible ? styles.visible : styles.invisible
			}`}
			onClick={() => setModalVisible(false)}
		>
			<div
				className={styles.modalContent}
				onClick={(e) => e.stopPropagation()}
			>
				{/* <h2 className={styles.clickMe}>Click Me </h2>
				{/* <h2 className={styles.text__announcement_header}>
					Generation of TIN for all MDAs' staff
				</h2> */}
				<button
					className={styles.close__btn}
					onClick={() => setModalVisible(false)}
				>
					<img src={closeIcon} alt="modal close button icon" />
				</button>
				<img
					className={styles.logo}
					src={coatOfArm}
					alt="coat of arm of Nigeria"
				/>
				<p className={styles.text__announcement_description}>
					Tax Identification Number (TIN) is now compulsory for ESBN
					registration. Kindly obtain it before proceeding.
				</p>
				<div className={styles.group__btns}>
					<a
						href="https://tin.jtb.gov.ng/TinIndividualRequestExternal"
						target="_blank"
						rel="noreferrer"
					>
						Get TIN for Individuals
					</a>
					<a
						href="https://tin.jtb.gov.ng/TinRequestExternal"
						target="_blank"
						rel="noreferrer"
					>
						Get TIN for Organizations
					</a>
				</div>
				{/* <button
					className={styles.modal__action_btn}
					onClick={downloadImage}
				>
					Download worksheet
				</button> */}
			</div>
		</div>
	);
};

export default NewsModal;
