import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import Modal from "../../components/Modal/regular";
import downloadIcon from "../../assets/whiteDownloadIcon.svg";
import goBackIcon from "../../assets/blackArrowLeft.svg";
import styles from "../ReceiptPymtRef/style.module.css";
import styles1 from "./style.module.css";
import styles3 from "../../Identity-management-individual/style.module.css";
import { formatAmount } from "../../utils";

const DACertificate = () => {
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	const history = useHistory();
	const [ESBN, setESBN] = useState("");
	const [year, setYear] = useState("");
	const [loadingBreakdown, setLoadingBreakdown] = useState(false);
	const [paymentBreakdown, setPaymentBreakdown] = useState([]);
	const [certificate, setCertificate] = useState("");
	// const [isSendingCertificate, setIsSendingCertificate] = useState(false);
	const [loadingCertificate, setLoadingCertificate] = useState(false);
	const [certModalShowing, setCertModalShowing] = useState(false);
	const [certDetails, setCertDetails] = useState(null);
	const [sendingForApproval, setSendingForApproval] = useState(false);
	const [checked, setChecked] = useState(false);
	const [waiverReason, setWaiverReason] = useState("");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const headers = [
		["Revenue", "Revenue"],
		["Year", "AssessmentYear"],
		["Total Tax Due (NGN)", "TotalTaxDue", formatAmount],
		["Tax Paid (NGN)", "Amount", formatAmount],
		["Assessment Code", "AssessmentCode"],
		["Receipt Number", "EReceiptNo"],
		["Issue Date", "createdAt"],
		["Payment Date", "PaymentDate"]
	];

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const getPaymentBreakdown =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault();
			setLoadingBreakdown(true);
			setCertificate("");
			try {
				const res = await axios
					.get(`/getcertesbn/${ESBN}/${year}`)
					.then((res) => (res.data || {}).data);

				setPaymentBreakdown(res);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message ||
							error.response?.data.err.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setLoadingBreakdown(false);
			}
		};

	const getCertificate = async () => {
		setLoadingCertificate(true);
		setCertificate("");
		try {
			const res = await axios.post(`/getesbncert`, {
				ESBN,
				startYear: year,
				WaiveFlg: checked ? "Y" : "N",
				WaiveReason: waiverReason
			});
			if (res.data.status === 1 || res.data.status === 2) {
				throw new Error(res.data.msg);
			}

			setCertificate(res.data.html);
			setCertDetails(res.data.data);
			setCertModalShowing(true);
		} catch (error) {
			if (error.response) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message ||
						error.response?.data.err.message
				}));
			} else if (error.request) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.request
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		} finally {
			setLoadingCertificate(false);
		}
	};

	useEffect(() => {
		if (!certModalShowing) setCertDetails(null);
	}, [certModalShowing]);

	// const sendCertificate = async (e) => {
	// 	e.preventDefault();
	// 	setLoading(true);
	// 	setData("");
	// 	try {
	// 		const data = await axios.post(
	// 			`/txn/invoice/receipt?InvoiceNumber=${values.assessmentCode}&RevenueCode=${values.paymentReference}`,
	// 			{
	// 				headers: {
	// 					Authorization: `Bearer ${accessToken}`
	// 				}
	// 			}
	// 		);
	// 		setLoading(false);
	// 		if (data.data.status === 1 || data.data.status === 2) {
	// 			throw new Error(data.data.msg);
	// 		}

	// 		setAlert((alert) => ({
	// 			...alert,
	// 			showing: true,
	// 			type: "success",
	// 			message:
	// 				"Certificate successfully sent to the Taxpayers email address."
	// 		}));
	// 	} catch (error) {
	// 		setLoading(false);
	// 		if (error.response) {
	// 			console.log("response", error.response.data.message);
	// 			setAlert((alert) => ({
	// 				...alert,
	// 				showing: true,
	// 				type: "error",
	// 				message:
	// 					error.response.data?.msg ||
	// 					error.response.data?.errors?.[0]?.message ||
	// 					error.response.data?.errors?.details[0]?.message ||
	// 					error.response?.data?.message
	// 			}));
	// 		} else if (error.request) {
	// 			console.log("request", error.request);
	// 			setAlert((alert) => ({
	// 				...alert,
	// 				showing: true,
	// 				type: "error",
	// 				message: error.request.msg
	// 			}));
	// 		} else {
	// 			console.log("Error", error.message);
	// 			setAlert((alert) => ({
	// 				...alert,
	// 				showing: true,
	// 				type: "error",
	// 				message: error.message
	// 			}));
	// 		}
	// 	}
	// };

	const requestForApproval = async () => {
		setSendingForApproval(true);
		try {
			const res = await axios.post(`/autocert4approval`, {
				inputter: userInfo.email,
				...certDetails
			});

			setCertModalShowing(false);
			setAlert((alert) => ({
				...alert,
				showing: true,
				type: "success",
				message: res.data.msg
			}));
		} catch (error) {
			if (error.response) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message ||
						error.response?.data.err.message
				}));
			} else if (error.request) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.request
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		} finally {
			setSendingForApproval(false);
		}
	};

	const printCertificate = (e) => {
		e?.preventDefault();

		const w = window.open();
		w.document.write(certificate);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		w.onafterprint = () => {
			w.close();
		};
	};

	return (
		<div className={styles.receipt__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{certModalShowing && (
				<Modal toggle={setCertModalShowing} showing={certModalShowing}>
					<>
						<div className={styles1.cert__btns}>
							<button
								className={`secondary__btn ${styles1.download__btn}`}
								onClick={() => printCertificate()}
							>
								<img
									src={downloadIcon}
									alt="download icon"
									style={{ marginRight: "8px" }}
								/>
								Print
							</button>
							{certDetails.Approval === "N" && (
								<button
									className={`primary__btn ${styles1.request__btn}`}
									onClick={() => requestForApproval()}
								>
									{sendingForApproval
										? "Sending..."
										: "Request for Approval"}
								</button>
							)}
						</div>
						<div
							dangerouslySetInnerHTML={{
								__html: certificate
							}}
						></div>
					</>
				</Modal>
			)}
			<button
				className={styles1.back__btn}
				onClick={() => history.goBack()}
			>
				<img
					src={goBackIcon}
					alt="back arrow icon"
					style={{ marginRight: "10px" }}
				/>
				Go Back
			</button>
			<form
				className={styles3.columned__form}
				style={{ marginBottom: "40px" }}
				onSubmit={(e) => getPaymentBreakdown()(e)}
			>
				<div className={styles3.double__inputs}>
					<div>
						<label className={styles3.input__label} htmlFor="ESBN">
							ESBN <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="ESBN"
							name="ESBN"
							className={styles3.select__input}
							value={ESBN}
							onChange={(e) => setESBN(e.target.value)}
							placeholder="eg. 54826648633"
							required
						/>
					</div>
					<div>
						<label className={styles3.input__label} htmlFor="year">
							Year <span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="year"
							value={year}
							onChange={(e) => setYear(e.target.value)}
							required
						>
							<option value="">Select</option>
							<option>2010</option>
							{new Array(new Date().getFullYear() - 2010)
								.fill(0)
								.map((_, i) => (
									<option key={i}>{2010 + i + 1}</option>
								))}
						</select>
					</div>
				</div>
				<button
					type="submit"
					className={["secondary__btn", styles1.breakdown__btn].join(
						" "
					)}
					disabled={!ESBN || !year}
				>
					Get Breakdown
				</button>
			</form>
			{loadingBreakdown && <p>Loading...</p>}
			{paymentBreakdown?.length > 0 && !loadingBreakdown && (
				<>
					<div className={styles1.table__wrapper}>
						<Table
							headers={headers}
							data={paymentBreakdown}
							total={paymentBreakdown?.length}
							full
							showingTotal={false}
						/>
					</div>
					<div className={styles1.waiver__div}>
						<label className={styles1.checkbox}>
							<input
								type="checkbox"
								checked={checked}
								onChange={(e) => setChecked(e.target.checked)}
							/>
							Waive Unpaid Tax(es)
						</label>
						{checked && (
							<div>
								<label
									className={styles3.input__label}
									htmlFor="waiverReason"
								>
									Waiver Reason
								</label>
								<input
									id="waiverReason"
									name="waiverReason"
									className={styles3.select__input}
									value={waiverReason}
									onChange={(e) =>
										setWaiverReason(e.target.value)
									}
									placeholder="Enter Waiver Reason"
								/>
							</div>
						)}
					</div>
					<button
						className={["primary__btn", styles1.submit__btn].join(
							" "
						)}
						disabled={!ESBN || !year || (checked && !waiverReason)}
						onClick={() => getCertificate()}
					>
						{loadingCertificate
							? "Generating Certificate..."
							: "Generate Certificate"}
					</button>
				</>
			)}
		</div>
	);
};

export default DACertificate;
