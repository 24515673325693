/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router";
import Multiselect from "multiselect-react-dropdown";

import Spinner from "../../components/spinner";
import Alert from "../../components/Alert";
import styles from "../../Identity-management-individual/style.module.css";
import style from "../user/style.module.css";

const CreateRole = () => {
	const { state: roleEdit = {} } = useLocation();

	const [values, setValues] = useState({
		name: roleEdit?.Name || "",
		level: roleEdit?.Level || 0
	});
	const [claims, setClaims] = useState([]);
	const [selectedClaims, setSelectedClaims] = useState(
		(roleEdit?.Claims || []).map((cl) => ({ name: cl.Name, id: cl.id }))
	);
	const [removedClaims, setRemovedClaims] = useState([]);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const accessToken = localStorage.access_token;

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	async function getClaims() {
		setLoading(true);

		try {
			const request = await axios.get(`/auth/claim`, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			});
			setLoading(false);

			setClaims(
				request?.data?.data?.claims?.map((cl) => ({
					name: cl.Name,
					id: cl.id
				}))
			);
		} catch (error) {
			setLoading(false);
			if (error.response) {
				console.log("response", error.response.data.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.message
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		}
	}

	useEffect(() => {
		getClaims();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (e) => {
		const Name = e.target.name || e.target.id;
		const value = e.target.value;

		setValues({
			...values,
			[Name]: value
		});
	};

	const submit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			const obj = { ...values };
			if (roleEdit) {
				obj.id = roleEdit.id;
			}

			const savedRole = await axios
				.post(`/auth/role`, obj, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			if ((selectedClaims || []).length) {
				await Promise.all(
					selectedClaims.map((claim) =>
						axios.put(`/auth/role/${savedRole.id}/${claim.id}`)
					)
				);
			}

			setLoading(false);

			// setValues({
			// 	Name: "",
			// 	Route: "",
			// 	Method: ""
			// });

			// setSelectedClaims([]);

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: "Role Created!"
			});
		} catch (error) {
			setLoading(false);
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors?.[0] ||
						error.response.data?.message
				});
			} else {
				console.log("Error", error.message);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	return (
		<main className={style.wrapper}>
			<form className={styles.columned__form}>
				{alert.showing && (
					<Alert text={alert.message} type={alert.type} />
				)}
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="name">
							Name <span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="text"
							onChange={handleChange}
							id="name"
							name="name"
							placeholder=""
							required
							value={values.name}
						/>
					</div>
					<div>
						<label className={styles.input__label} htmlFor="level">
							Level <span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="number"
							onChange={handleChange}
							id="level"
							name="level"
							placeholder=""
							required
							value={values.level}
						/>
					</div>
				</div>
				<p className={styles.input__label}>Select Claims</p>
				<Multiselect
					options={claims}
					selectedValues={selectedClaims}
					onSelect={(_, claim) => {
						setSelectedClaims(selectedClaims.concat(claim));
					}}
					onRemove={(_, claim) => {
						const claims = selectedClaims.slice();
						const index = claims.findIndex(
							(cl) => cl.id === claim.id
						);
						claims.splice(index, 1);
						setSelectedClaims(claims);
						setRemovedClaims(claim);
					}}
					displayValue="name"
					closeOnSelect={false}
				/>
				<footer
					className={styles.form__footer}
					style={{ marginTop: "36px" }}
				>
					<button
						disabled={loading || !values.name}
						type="button"
						onClick={submit}
						className={[
							"primary__btn",
							styles.primary__button,
							loading ? styles.loading__button : ""
						].join(" ")}
					>
						Save
						{loading && <Spinner />}
					</button>
				</footer>
			</form>
		</main>
	);
};

export default CreateRole;
