import style from "./style.module.css";
import Subscription from "../../Home/subscription/index";
import Footer from "../footer";
// import newsImg from "../../assets/newsImg.png"
// import authorImg from "../../assets/authorImg.png";
// import calendarImg from "../../assets/calendarImg.png"
import { Link } from "react-router-dom";

const taxNews = [
	{
		headline: "Enugu State Government Guide to all payers",
		newsContent:
			"The Enugu State Government has onboarded to a new electronic collection scheme in her bid to streamline her collection processes and make payment easy for all. The new platform provides multiple channels that make it easier for payers to make payment with ease, and in a timely manner.",
		// image: newsImg,
		authorsName: "Authors Name",
		// authorImg: authorImg,
		// calendarImg: calendarImg,
		author: "Authors Name",
		date: "11/05/2024",
		link: "Read more..."
	},
	{
		headline: "Enugu State Government Guide to all payers",
		newsContent:
			"The Enugu State Government has onboarded to a new electronic collection scheme in her bid to streamline her collection processes and make payment easy for all. The new platform provides multiple channels that make it easier for payers to make payment with ease, and in a timely manner.",
		// image: newsImg,
		authorsName: "Authors Name",
		// authorImg: authorImg,
		// calendarImg: calendarImg,
		date: "11/05/2024",

		link: "Read more..."
	},
	{
		headline: "Enugu State Government Guide to all payers",
		newsContent:
			"The Enugu State Government has onboarded to a new electronic collection scheme in her bid to streamline her collection processes and make payment easy for all. The new platform provides multiple channels that make it easier for payers to make payment with ease, and in a timely manner.",
		// image: newsImg,
		authorsName: "Authors Name",
		// authorImg: authorImg,
		// calendarImg: calendarImg,
		date: "11/05/2024",

		link: "Read more..."
	}
];

const NewsPage = () => {
	return (
		<main>
			<section className={style.heroSection}>
				<div>
					<h1>News </h1>
					<h3 className={style.desktop}>
						Discover the most recent updates on tax-related <br />{" "}
						events unfolding in Enugu State.{" "}
					</h3>
				</div>
			</section>
			<section className={style.enuguTaxNewsHere}>
				<h1 className={style.desktopsNewsHeading}>
					Stay informed about the latest <br /> tax developments and
					events in Enu
					<span className={style.underlineGreen}>gu State</span>
				</h1>
				<h1 className={style.mobileNewsHeading}>
					Stay informed about the <br /> latest tax developments and
					<br />
					events in Enugu{" "}
					<span className={style.underlineGreen}>State</span>
				</h1>
				<div className={style.newsCards}>
					{taxNews.map((news, index) => (
						<div key={index}>
							<div className={style.newsCard}>
								<img src={news.image} alt="newsImage" />
								<div className={style.authDate}>
									<div className={style.auth}>
										<img
											src={news.authorImg}
											alt="newsImg"
										/>
										<h6>{news.authorsName}</h6>
									</div>
									<div className={style.vertical}></div>
									<div className={style.date}>
										<img
											src={news.calendarImg}
											alt="calendarImg"
										/>
										<h6>{news.date}</h6>
									</div>
								</div>
								<h2 className={style.headline}>
									{news.headline}
								</h2>
								<p className={style.newsContent}>
									{news.newsContent}
								</p>
								<div className={style.link}>
									<Link to="#">{news.link}</Link>
								</div>
							</div>
						</div>
					))}
				</div>
			</section>
			<Subscription />
			<Footer />
		</main>
	);
};

export default NewsPage;
