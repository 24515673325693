import { useState, useEffect, createRef, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Table from "../../../components/Table4";
import Alert from "../../../components/Alert";
import { formatAmount } from "../../../utils";
import Modal from "../../../components/Modal/regular";
import GoBackButton from "../../../components/goBackButton";
import authViewStyle from "../../PAYE/Authorisation/style.module.css";
import styles from "./style.module.css";
import styles1 from "../../MDAs/style.module.css";
import styles2 from "../../../Direct-assessment/Create-direct-assessment/style.module.css";
import ArrowDownDownloadBtn from "../../../assets/ArrowDownDownloadBtn.svg";
import XcircleRed from "../../../assets/x-circle-red.svg";
import closeBtn from "../../../assets/x-circle-black.svg";

const CorporateView = () => {
	let userId;
	const { state: locationState } = useLocation();
	const [loading, setLoading] = useState(false);
	const [finished, setFinished] = useState("0");
	const [data, setData] = useState([]);
	const [receiptData, setReceiptData] = useState(null);
	const [isCancelModal, setIsCancelModal] = useState(false);
	const [cancelConfirmation, setCancelConfirmation] = useState(false);
	const [cancelReason, setCancelReason] = useState("");
	const [isDeleteModal, setIsDeleteModal] = useState(false);
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const [deleteReason, setDeleteReason] = useState("");
	const [billShow, setBillShow] = useState(false);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const filterRef = createRef();
	const userIdRef = useRef(userId);

	useEffect(() => {
		if (
			JSON.parse(localStorage.getItem("user_info")).account_type ===
			"corporate"
		) {
			userIdRef.current = JSON.parse(
				localStorage.getItem("user_info")
			).anssid;
		} else {
			userIdRef.current = locationState.CoyESBN;
		}
		getBillDetails()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finished === "1"]);

	const getBillDetails =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);

			if (!filterRef.current?.classList.contains(styles1.no__display)) {
				filterRef.current?.classList.toggle(styles1.no__display);
			}

			try {
				await axios
					.get(
						`/dareports/tmspayedisplaybilldetails/${locationState.BillNumber}`
					)
					.then((res) => {
						const billdataArray = res.data.data || [];
						setData(billdataArray);
					});
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.message ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			} finally {
				setFinished("1");
				setLoading(false);
			}
		};

	const fetchReceipt = async () => {
		try {
			const res = await axios
				.get(`/gettmspaye/${locationState.BillNumber}/1`)
				.then((res) => res.data || {});
			setReceiptData(res);
		} catch (err) {
			console.error(err);
		} finally {
			setFinished("1");
		}
	};

	const fetchPDF = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.get(
				`/gettmspaye/${locationState.BillNumber}/2`,
				{
					responseType: "arraybuffer"
				}
			);

			const blob = new Blob([response.data], { type: "application/pdf" });

			// For Internet Explorer
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blob, "downloaded-file.pdf");
			} else {
				// For other browsers
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.download = "PAYE-Bill.pdf";
				document.body.appendChild(link);
				link.click();

				// Clean up
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			}
		} catch (error) {
			console.error("Error downloading PDF:", error);
		}
	};

	const sendEmail = async () => {
		try {
			const res = await axios.get(
				`/gettmspaye/${locationState.BillNumber}/3`
			);
			const responseData = res.data || {};

			if (res.status === 200) {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: responseData.msg
				});
			}

			setReceiptData(responseData.email);
		} catch (err) {
			console.error(err);
		} finally {
			setFinished("3");
		}
	};

	useEffect(() => {
		fetchReceipt();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finished === "1"]);

	const printReceipt = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(receiptData);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		w.onafterprint = () => {
			w.close();
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		};
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const cancel = async () => {
		setLoading(true);
		try {
			const { msg } = await axios
				.put("processpaye", {
					billno: locationState.BillNumber,
					approve: "1", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval
					reason4Reject: cancelReason,
					recId: locationState.id
				})
				.then((res) => res.data);
			setCancelReason("");
			setIsCancelModal(false);

			if (data.status === 200) {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: "Bill has successfully been Cancelled"
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: msg
				});
			}
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: error.response.data?.msg
				});
			}
		} finally {
			setLoading(false);
		}
		setCancelReason("");
		setIsCancelModal(false);
	};

	const deleteRecord = async () => {
		try {
			const { msg } = await axios
				.put("processpaye", {
					billno: locationState.BillNumber,
					approve: "6", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval
					reason4Reject: deleteReason,
					recId: locationState.id
				})
				.then((res) => res.data);
			setDeleteReason("");
			if (data.status === 200) {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: "Bill has successfully been deleted"
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: msg
				});
			}
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: error.response.data?.msg
				});
			}
		}
	};

	const headers = [
		["Staff Name ", "StaffName"],
		["Annual Income ", "AnnualPay", formatAmount],
		["Monthly Income ", "MthPay", formatAmount],
		["Total Relief", "TotRelief", formatAmount],
		["Taxable Income", "TaxableIncome", formatAmount],
		["Annual Tax Due ", "AnnualTaxDue", formatAmount],
		["Monthly Tax Due", "MthTaxDue", formatAmount],
		["Development Levy", "DevLevy", formatAmount]
	];

	return (
		<main className={[styles2.wrapper, styles.main].join(" ")}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<GoBackButton />
			<div className={styles.downloadSec}>
				<h2 className={styles.companyDetailsHeader}>
					corporat view Company Details
				</h2>
				<div
					className={styles.voucher}
					onClick={() => setBillShow(true)}
				>
					<h4>View Bill </h4>
				</div>
			</div>
			<div className={styles.employeeColumn}>
				<div className={styles.employeeColumn1}>
					<div className={styles.employeeColumn1Contents}>
						<div className={styles.detailsKey}>Company Name:</div>
						<div className={styles.detailsValue}>
							{locationState.CoyName}
						</div>
					</div>
					<div className={styles.employeeColumn1Contents}>
						<div className={styles.detailsKey}>ESBN: </div>
						<div className={styles.detailsValue}>
							{locationState.CoyESBN}
						</div>
					</div>
					<div className={styles.employeeColumn1Contents}>
						<div className={styles.detailsKey}>
							PAYE Bill Number:
						</div>
						<div className={styles.detailsValue}>
							{locationState.BillNumber}
						</div>
					</div>
					<div className={styles.employeeColumn1Contents}>
						<div className={styles.detailsKey}>
							Amount for PAYE Type:
						</div>
						{locationState.PayeType === "Yearly" ? (
							<div className={styles.detailsValue}>
								&#8358;{" "}
								{formatAmount(locationState.totAmtCharged)}
							</div>
						) : (
							""
						)}
						{locationState.PayeType === "Monthly" ? (
							<div className={styles.detailsValue}>
								&#8358;{" "}
								{formatAmount(locationState.MonthlyTypeAmtBill)}
							</div>
						) : (
							""
						)}

						{locationState.PayeType === "Period" ? (
							<div className={styles.detailsValue}>
								&#8358;{" "}
								{formatAmount(locationState.PeriodTypeAmtBill)}
							</div>
						) : (
							""
						)}
					</div>
				</div>
				<div className={styles.employeeColumn2}>
					<div>
						<div className={styles.employeeColumn1Contents}>
							<div className={styles.detailsKey}>
								Assessment Type
							</div>
							<div className={styles.detailsValue}>
								{locationState.PayeType}
							</div>
						</div>
						<div className={styles.employeeColumn1Contents}>
							{locationState.PayeType === "Monthly" ? (
								<div className={styles.employeeDetail}>
									<div className={styles.detailsKey}>
										Schedule Period:
									</div>
									<div className={styles.detailsValue}>
										{locationState.fiscalMth}&nbsp;
										{locationState.fiscalYear}
									</div>
								</div>
							) : (
								""
							)}
							{locationState.PayeType === "Yearly" ? (
								<div className={styles.employeeDetail}>
									<div className={styles.detailsKey}>
										Schedule Period:
									</div>
									<div className={styles.detailsValue}>
										{locationState.fiscalYear}
									</div>
								</div>
							) : (
								""
							)}
							{locationState.PayeType === "Period" ? (
								<div className={styles.employeeDetail}>
									<div className={styles.detailsKey}>
										Schedule Period:
									</div>
									<div className={styles.detailsValue}>
										{locationState.PayePeriodStartDate}/
										{locationState.PayePeriodEndDate}
									</div>
								</div>
							) : (
								""
							)}
						</div>
						<div className={styles.employeeColumn1Contents}>
							<div className={styles.detailsKey}>
								Process Status:{" "}
							</div>
							<div
								className={
									styles[`status-${locationState.ApproveFlg}`]
								}
							>
								{locationState.ProcessStatus}
							</div>
						</div>
						<div className={styles.employeeColumn1Contents}>
							{locationState.PayeType === "Yearly" ? (
								<>
									<div className={styles.detailsKey}>
										Monthly Due:
									</div>
									<div className={styles.detailsValue}>
										&#8358;{" "}
										{formatAmount(
											locationState.totMthAmtBilled
										)}
									</div>
								</>
							) : (
								""
							)}
							{locationState.PayeType === "Period" ? (
								<>
									<div className={styles.detailsKey}>
										Monthly Due:
									</div>
									<div className={styles.detailsValue}>
										&#8358;{" "}
										{formatAmount(
											locationState.totMthAmtBilled
										)}
									</div>
								</>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			</div>

			<h2 className={styles.companyDetailsHeader}> Employee Details </h2>
			<div className={styles1.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						onPageChanged={getBillDetails}
					/>
				)}
			</div>
			{cancelConfirmation && (
				<div className={styles.cancelMiniModal}>
					<p>
						Are you sure you want to {}
						<strong style={{ color: "#d73e3e" }}>Cancel</strong>
						{""} this PAYE <br />
						Assessment with &nbsp;
						<strong>{locationState.CoyESBN}</strong> ESBN?
					</p>
					<div className={styles.miniModalBtns}>
						<button
							style={{ backgroundColor: "#ffff", color: "#000" }}
							onClick={() => setCancelConfirmation(false)}
						>
							No
						</button>
						<button
							style={{ backgroundColor: "#d73e3e" }}
							onClick={() => {
								setIsCancelModal(true);
								setCancelConfirmation(false);
							}}
						>
							Yes
						</button>
					</div>
				</div>
			)}
			{locationState.ApproveFlg === "0" && (
				<div className={styles.approveRevoke_btn}>
					<button
						className={["secondary__btn", styles.revoke__btn].join(
							" "
						)}
						onClick={() => setCancelConfirmation(true)}
					>
						Cancel
					</button>
				</div>
			)}

			{deleteConfirmation && (
				<div className={styles.cancelMiniModal}>
					<p>
						Are you sure you want to &nbsp;
						<strong style={{ color: "#d73e3e" }}>
							Delete
						</strong>{" "}
						&nbsp;this PAYE <br />
						Assessment with &nbsp;
						<strong>{locationState.CoyESBN}</strong> ESBN?
					</p>
					<div className={styles.miniModalBtns}>
						<button
							style={{ backgroundColor: "#ffff", color: "#000" }}
							onClick={() => setDeleteConfirmation(false)}
						>
							No
						</button>
						<button
							style={{ backgroundColor: "#d73e3e" }}
							onClick={() => {
								setIsDeleteModal(true);
								setDeleteConfirmation(false);
							}}
						>
							Yes
						</button>
					</div>
				</div>
			)}

			{locationState.ApproveFlg === "1" && (
				<div className={styles.approveRevoke_btn}>
					<button
						className={["secondary__btn", styles.revoke__btn].join(
							" "
						)}
						onClick={() => setDeleteConfirmation(true)}
					>
						Delete
					</button>
				</div>
			)}

			{isCancelModal && (
				<Modal toggle={setIsCancelModal} showing={isCancelModal}>
					<div className={authViewStyle.approvalSuccess__badge}>
						<img
							src={XcircleRed}
							alt="close button icon"
							style={{ width: "40px", height: "40px" }}
						/>
						<span>
							<h3>Cancel </h3>
						</span>
					</div>
					<div className={authViewStyle.content}>
						<p>
							You are about to cancel{" "}
							<q>{locationState.CoyName}</q> PAYE Filing, an email
							would <br /> be sent to notify them accordingly.
						</p>
						<label htmlFor="reasonForCancel">
							Reason For Cancellation
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							id="reasonForCancel"
							placeholder="write the reason for declining this PAYE Assessment"
							type="text"
							value={cancelReason}
							onChange={(e) => setCancelReason(e.target.value)}
						/>
						<div className={authViewStyle.continueBtn}>
							<button
								className={[
									"primary__btn",
									authViewStyle.contBtn
								].join(" ")}
								onClick={() => {
									cancel();
								}}
							>
								{loading ? "Continue..." : "Continue"}
							</button>
						</div>
					</div>
				</Modal>
			)}

			{isDeleteModal && (
				<Modal toggle={setIsDeleteModal} showing={isDeleteModal}>
					<div className={authViewStyle.approvalSuccess__badge}>
						<img
							src={XcircleRed}
							alt="close button icon"
							style={{ width: "40px", height: "40px" }}
						/>
						<span>
							<h3>Delete</h3>
						</span>
					</div>
					<div className={authViewStyle.content}>
						<p>
							You are about to delete{" "}
							<q>{locationState.CoyName}</q>
							PAYE Filing, an email would <br /> be sent to notify
							them accordingly.
						</p>
						<label htmlFor="deleteReason">
							Reason For Deleting
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							id="deleteReason"
							placeholder="write the reason for deleting this PAYE Assessment"
							type="text"
							value={deleteReason}
							onChange={(e) => setDeleteReason(e.target.value)}
						/>
						<div className={authViewStyle.continueBtn}>
							<button
								className={[
									"primary__btn",
									authViewStyle.contBtn
								].join(" ")}
								onClick={() => {
									deleteRecord();
									setIsDeleteModal(false);
								}}
							>
								Continue
							</button>
						</div>
					</div>
				</Modal>
			)}
			{billShow && (
				<Modal toggle={setIsCancelModal} showing={isCancelModal}>
					<div className={styles.closeBillbtn}>
						<img
							src={closeBtn}
							alt="close Button"
							onClick={() => setBillShow(false)}
						/>
					</div>
					{receiptData && (
						<>
							<div
								dangerouslySetInnerHTML={{
									__html: receiptData
								}}
								className={styles.doc__area}
								style={{
									marginTop: "24px"
								}}
							></div>
							{locationState.ApproveFlg === "3" && (
								<div className={styles.actionBtns}>
									<button
										type="button"
										onClick={() => sendEmail()}
									>
										Send by Mail
									</button>
									<button
										type="button"
										onClick={(e) => {
											printReceipt(e);
										}}
										style={{
											backgroundColor: "#ffff",
											color: "#34aa63"
										}}
									>
										Print
									</button>
									<button
										type="button"
										onClick={(e) => {
											fetchPDF(e);
										}}
									>
										Download PAYE Schedule
										<img
											src={ArrowDownDownloadBtn}
											alt="Arrow Down Icon"
											style={{ marginLeft: "5px" }}
										/>
									</button>
								</div>
							)}
						</>
					)}
				</Modal>
			)}
		</main>
	);
};

export default CorporateView;
