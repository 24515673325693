/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import axios from "axios";
import Alert from "../../../components/Alert";
import styles1 from "./style.module.css";
import styles from "../../Receipt/style.module.css";
import styles3 from "../../../Identity-management-individual/style.module.css";
import Table from "../../../components/Table5";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import styles2 from "../../../Direct-assessment/Create-direct-assessment/style.module.css";
import { formatAmount } from "../../../utils";
import { useHistory, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import backArrow from "../../../assets/arrowLeft.svg";
import PAYEEmodule from "../../../Dashboard/PAYE/Paye-Record/style.module.css";
const ConsolidatedView = () => {
	const [ownersesbn, setOwnersesbn] = useState("");
	const [ownername, setownersname] = useState("");
	const [residentialaddress, setResidentialaddress] = useState("");
	const [PhoneNumber, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [btnCalcClick, setBtnCalcClick] = useState(false);
	const [arrBtnClick, setArrBtnClick] = useState([]);
	const [subscription, setSubcription] = useState("monthly");
	const [billtype, setbilltype] = useState("");
	const [mdaKode, setMdaKode] = useState("");
	const [mdaId, setMdaId] = useState("");
	const [revId, setRevId] = useState("");
	const [mda, setMDA] = useState("");
	const [billCategory, setBillCategory] = useState("");
	const [description, setDescription] = useState("");
	const [revenue, setRevenue] = useState("");
	const [discount, setDiscount] = useState(0);
	const [resDiscount, setResDiscount] = useState("");
	const [granddiscount, setGrandDiscount] = useState(0);
	const [taxoffice, setTaxOffice] = useState("");
	const [taxOfficeId, setTaxOfficeId] = useState("");
	let [tableId, setTableId] = useState("");
	let [editId, setEditId] = useState("");
	let [editFlg, setEditFlg] = useState(false);
	const [pid, setPID] = useState("");
	const [searchNumType, setSearchNumType] = useState("ESBN");
	const [selectYear, setSelectYear] = useState("");
	const [selectMonth, setSelectedMonth] = useState("");
	const [asssessduedate, setAssessDueDate] = useState("");
	const [assessyear, setAssessYear] = useState("");
	const [billCategories, setBillCategories] = useState([]);
	const [descriptions, setDescriptions] = useState([]);
	const [mdas, setMDAs] = useState([]);
	const [revenues, setRevenues] = useState([]);
	const [taxoffices, setTaxOffices] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [tmpData, setTmpData] = useState([]);
	const [amount, setAmount] = useState("");
	const [outstanding, setOutStanding] = useState("");
	let [fem, setFem] = useState([]);
	const [OutstandingDesciption, setOutStandingDescription] = useState("");
	let [total, setTotal] = useState(0);
	let [grandTotal, setGrandTotal] = useState(0);
	let [grandOutstanding, setGrandOutstanding] = useState(0);
	const [date, setDate] = useState(new Date());
	const [loading, setLoading] = useState(false);
	const [isGenerate, setIsGenerate] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const location = useLocation();
	const history = useHistory();
	const historyState = history?.location?.state;
	const [invoiceData, setInvoiceData] = useState(null);
	const [gettingInvoice, setIsGettingInvoice] = useState(false);
	const userInfo = JSON.parse(localStorage.getItem("user_info"));

	useEffect(() => {
		const getESBN = async () => {
			const res = await axios
				.get(`users/anssid?anssid=${ownersesbn}`)
				.then((res) => (res.data || {}).data);
			// setAccountType(res.accountType);
			if (res?.accounttype === "individual") {
				setownersname(`${res.firstname} ${res.surname}`);
				setResidentialaddress(res.residentialaddress);
				setPhoneNumber(res.phonenumber);
				// setSurname(res.surname);
			} else {
				setownersname(res?.name);
				setResidentialaddress(res.stateOfficeAddress);
				setPhoneNumber(res.phoneNumber);
			}
			setResidentialaddress(res.residentialaddress);
			setEmail(res.email);
			setAssessDueDate("2024-12-31");
		};
		if (ownersesbn?.length >= 10) getESBN();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownersesbn]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);
		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const printInvoice = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(invoiceData);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		w.onafterprint = () => {
			w.close();
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		};
	};

	async function fetchDetails() {
		setIsGettingInvoice(false);
		setLoading(true);

		try {
			let info;
			let infodat;
			const accessToken = localStorage.getItem("access_token");
			infodat = await axios
				.get(`/mdaconsoldn/${location.state.BillNumber}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => res.data || {});

			setInvoiceData(infodat.html);
			info = infodat.data;

			if (info) {
				const getbills = info.ConsolidatedBillMainDetails.map(
					(bill) => {
						let typeBill;
						if (bill.billtype === "3") {
							typeBill = "Registration";
						}
						if (bill.billtype === "2") {
							typeBill = "Renewal";
						}
						if (bill.billtype === "1") {
							typeBill = "Fixed";
						}
						if (bill.billtype === "4") {
							typeBill = "ManualEntry";
						}

						return {
							mda: bill.MDAName,
							billtype: typeBill,
							billCategory: bill.ProfileCategory,
							description: bill.RevenueDescription,
							revenue: bill.RevenueDescription,
							amount: bill.Amount,
							outstanding: bill.OutstandingAmount,
							OutstandingDesciption: bill.OutStandingReason,
							mdaId: bill.MDA,
							revId: bill.RevenueCode,
							tableId: bill.id
						};
					}
				);

				setSearchNumType(info.ESBN_PID_Flg === "1" ? "ESBN" : "PID");
				setSubcription(
					info.BillFrequency === "1" ? "monthly" : "yearly"
				);
				setownersname(info.Name);
				setOwnersesbn(info.ESBN_PID);
				setSelectYear(info.FiscalYear);
				setSelectedMonth(info.FiscalMonth);
				setResidentialaddress(info.Address);
				info.BillFrequency === "1"
					? setDate(new Date(info.DueDate))
					: setAssessDueDate(new Date(info.DueDate));
				setPhoneNumber(info.PhoneNo);
				setTaxOfficeId(info.TaxOffice);
				setEmail(info.Email);

				setTableData(getbills);
			}
			// billfrequency: subscription === "monthly" ? "1" : "0",
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setAssessDueDate(assessyear !== "" ? `${assessyear}-12-31` : "");
	}, [assessyear]);

	const formatDate = () => {
		const input = asssessduedate;
		const inputDate = new Date(input);

		const year = inputDate.getFullYear();
		const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
		const day = inputDate.getDate().toString().padStart(2, "0");

		const formattedDate = `${year}-${month}-${day}`;

		return formattedDate;
	};

	const headers = [
		["MDA", "mda"],
		[`Type of Bill`, "billtype"],
		["Category of Bill", "billCategory"],
		["Description of Bill", "description"],
		["Revenue", "revenue"],
		["Amount", "amount"],
		["Outstanding", "outstanding"],
		["OutstandingDescription  ", "OutstandingDesciption"]
	];

	return (
		<div className={[styles.main].join(" ")}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<button
				className={`${PAYEEmodule.review_header_btn} ${PAYEEmodule.customBackButton}`}
				onClick={() =>
					history.push("/demand-notice/consoldemandnotice")
				}
			>
				<img
					src={backArrow}
					alt="backArrow"
					className={PAYEEmodule.backArrow}
				/>
				<span className={PAYEEmodule.backText}>Back</span>
			</button>
			<form
				className={styles3.columned__form}
				style={{ marginTop: "20px" }}
			>
				{/* //////first///// */}
				<div className={styles3.double__inputs}>
					<div className={styles2.radio__button_div}>
						<span className={styles1.input__label}>
							Generate by
						</span>
						<div>
							<div>
								<input
									id="generateByPID"
									type="radio"
									value="PID"
									checked={searchNumType === "PID"}
									onChange={(e) =>
										setSearchNumType(e.target.value)
									}
									disabled
								/>
								<label
									className={styles3.input__label}
									htmlFor="generateByPID"
								>
									PID
								</label>
							</div>
							<div>
								<input
									id="generateByESBN"
									type="radio"
									value="ESBN"
									checked={searchNumType === "ESBN"}
									onChange={(e) =>
										setSearchNumType(e.target.value)
									}
									disabled
								/>
								<label
									className={styles3.input__label}
									htmlFor="generateByESBN"
								>
									ESBN
								</label>
							</div>
						</div>
					</div>

					<div className={styles2.radio__button_div}>
						<span className={styles1.input__label}>
							Scheduled for
						</span>
						<div>
							<div>
								<input
									id="monthlySchedule"
									type="radio"
									value="monthly"
									checked={subscription === "monthly"}
									onChange={(e) =>
										setSubcription(e.target.value)
									}
									disabled
								/>
								<label
									className={styles3.input__label}
									htmlFor="monthlySchedule"
									disabled
								>
									Monthly
								</label>
							</div>
							<div>
								<input
									id="yearlySchedule"
									type="radio"
									value="yearly"
									checked={subscription === "yearly"}
									onChange={(e) =>
										setSubcription(e.target.value)
									}
									disabled
								/>
								<label
									className={styles3.input__label}
									htmlFor="yearlySchedule"
								>
									Yearly
								</label>
							</div>
						</div>
					</div>
				</div>

				{/* //////first//////// */}
				<div className={styles3.double__inputs}>
					{searchNumType === "ESBN" ? (
						<div>
							<label
								className={styles3.input__label}
								htmlFor="esbn"
							>
								ESBN
							</label>
							<input
								id="esbn"
								type="text"
								value={ownersesbn}
								onChange={(e) => setOwnersesbn(e.target.value)}
								placeholder="Enter ESBN Number"
								disabled
							/>
						</div>
					) : (
						<div>
							<label
								className={styles3.input__label}
								htmlFor="PID"
							>
								PID
							</label>
							<input
								id="PID"
								type="text"
								value={pid}
								onChange={(e) => setPID(e.target.value)}
								placeholder="Enter PID Number"
								disabled
							/>
						</div>
					)}

					<div>
						<label
							className={styles1.input__label}
							htmlFor="selectYear"
						>
							Year Schedule
						</label>
						<select
							id="selectYear"
							value={selectYear}
							onChange={(e) => setSelectYear(e.target.value)}
							// disabled={historyState?.view}
							disabled
						>
							<option value="">Select</option>
							<option>2013</option>
							{new Array(new Date().getFullYear() - 2013)
								.fill(0)
								.map((_, i) => (
									<option key={i}>{2013 + i + 1}</option>
								))}
						</select>
					</div>
				</div>
				{/* ////////second/////////// */}
				<div className={`${styles3.double__inputs}`}>
					<div>
						<label
							className={styles3.input__label}
							htmlFor="ownername"
						>
							NAME <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="ownername"
							type="text"
							value={ownername}
							onChange={(e) => setownersname(e.target.value)}
							placeholder="Enter NAME"
							disabled
						/>
					</div>
					<div>
						<label htmlFor="selectMonth">Monthly Schedule</label>
						<select
							id="selectMonth"
							value={selectMonth}
							onChange={(e) => {
								setSelectedMonth(e.target.value);
							}}
							disabled
						>
							<option value="" disabled>
								Select
							</option>
							{[...Array(12)].map((_, index) => (
								<option key={index} value={index}>
									{new Intl.DateTimeFormat("en", {
										month: "long"
									}).format(new Date(2000, index, 1))}
								</option>
							))}
						</select>
					</div>
				</div>
				{/* /////third/////// */}
				<div className={styles3.double__inputs}>
					<div>
						<label
							style={{ marginBottom: "15px" }}
							className={styles3.input__label}
							htmlFor="residentialaddress"
						>
							Address
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="residentialaddress"
							name="residentialaddress"
							style={{ marginTop: "0" }}
							className={styles3.select__input}
							value={residentialaddress}
							onChange={(e) =>
								setResidentialaddress(e.target.value)
							}
							placeholder="Enter your  Address"
							required
							disabled
						/>
					</div>
					{subscription === "monthly" ? (
						<div>
							<label
								className={styles1.input__label}
								htmlFor="montlyduedate"
							>
								Due Date
							</label>
							<div>
								<DatePicker
									id="monthlyduedate"
									selected={date}
									onChange={(date) => setDate(date)}
									disabled
								/>
							</div>
						</div>
					) : (
						<div>
							<label
								className={styles1.input__label}
								htmlFor="yearlyduedate"
							>
								Due Date
							</label>
							<input
								name="asssessduedate"
								type="date"
								id="yearlyduedate"
								value={
									asssessduedate
										? formatDate()
										: asssessduedate
								}
								onChange={(e) =>
									setAssessDueDate(e.target.value)
								}
								disabled
								required
							/>
						</div>
					)}
				</div>
				{/* ////////////fouth//////////// */}
				<div className={styles3.double__inputs}>
					<div>
						<label
							style={{ marginBottom: "15px" }}
							className={styles3.input__label}
							htmlFor="PhoneNumber"
						>
							Phone Number <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="PhoneNumber"
							type="text"
							name="PhoneNumber"
							style={{ marginTop: "0" }}
							onChange={(e) => setPhoneNumber(e.target.value)}
							value={PhoneNumber}
							placeholder="Enter your Phone Number"
							disabled
						/>
					</div>

					<div>
						<label
							className={styles.input__label}
							htmlFor="taxoffice"
						>
							Tax Office
						</label>
						<select
							id="taxoffice"
							name="taxoffice"
							onChange={(e) => {
								setTaxOfficeId(
									e.target.selectedOptions[0].getAttribute(
										"taxcode"
									)
								);
								setTaxOffice(e.target.value);
							}}
							value={taxoffice}
							required
							disabled
						>
							<option value="">--Select Tax--</option>
							{taxoffices.map((tax, index) => (
								<option
									value={tax.Description}
									taxcode={tax.Code}
									index={index}
								>
									{tax.Description}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className={styles3.double__inputs}>
					{/* //////////fifth/////////////// */}
					<div>
						<label
							style={{ marginBottom: "15px" }}
							className={styles3.input__label}
							htmlFor="email"
						>
							Email <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="email"
							type="text"
							name="email"
							style={{ marginTop: "0" }}
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							placeholder="Enter your email address"
							required
							disabled
						/>
					</div>
				</div>
				<hr className={styles3.divider} />
				{/* //////////////////////Dropdowns//////////////////////////// */}
				<div className={styles3.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="mda">
							MDA
						</label>
						<select
							id="mda"
							name="mda"
							value={mda}
							required
							disabled
						>
							<option value="">--Select MDA--</option>
							{mdas.map((mda, index) => (
								<option
									value={mda.Coyname}
									mdacode={mda.CoyId}
									index={index}
								>
									{mda.Coyname}
								</option>
							))}
						</select>
					</div>
					<div>
						<label htmlFor="billtype">
							Type of Bill
							<span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="billtype"
							name="billtype"
							value={billtype}
							onChange={(e) => {
								console.log("MDA Bill -> ", mdaKode);
								setbilltype(e.target.value);
							}}
							required
							disabled
						>
							<option>--Select BillType--</option>
							<option value={"RenewalFee"}>Renewal</option>
							<option value={"RegistrationFee"}>
								Registration
							</option>
							<option value={"FixedFee"}>Fixed Amount</option>
							<option value={"ManualEntry"}>Manual Entry </option>
						</select>
					</div>
				</div>
				{/* /////// second row//////// */}
				<div className={styles3.double__inputs}>
					<div>
						<label htmlFor="billCategory">
							Category of Bill
							<span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="billCategory"
							className={styles3.input__long}
							name="billCategory"
							onChange={(e) => {
								setBillCategory(e.target.value);
								console.log("category  -> ", billCategory);
								const catkode =
									e.target.selectedOptions[0].getAttribute(
										"catcode"
									);
								console.log("catkode - category -> ", catkode);
							}}
							value={billCategory}
							required
							disabled
						>
							<option>--Select Category--</option>
							{billCategories.map((category, index) => (
								<option
									value={category.Description}
									catcode={category.Code}
									index={index}
								>
									{category.Description}
								</option>
							))}
						</select>
					</div>
					<div>
						<label htmlFor="description">
							Description Bill
							<span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="description"
							className={styles3.input__long}
							name="description"
							value={description}
							onChange={(e) => {
								setDescription(e.target.value);
								console.log("billtype - bill -> ", billtype);
								if (billtype === "RenewalFee") {
									setAmount(
										e.target.selectedOptions[0].getAttribute(
											"renewal"
										)
									);
								} else if (
									e.target.selectedOptions[0].getAttribute(
										"renewal"
									) <= 0 ||
									e.target.selectedOptions[0].getAttribute(
										"renewal"
									) === ""
								) {
									setAlert({
										showing: false,
										type: "error",
										message:
											"Bill Amount choosen is not configured."
									});
								}

								if (billtype === "fixed") {
									setAmount(
										e.target.selectedOptions[0].getAttribute(
											"fixed"
										)
									);
								} else if (
									e.target.selectedOptions[0].getAttribute(
										"fixed"
									) <= 0 ||
									e.target.selectedOptions[0].getAttribute(
										"fixed"
									) === ""
								) {
									setAlert({
										showing: false,
										type: "error",
										message:
											"Bill Amount choosen is not configured."
									});
								}
								if (billtype === "reg") {
									setAmount(
										e.target.selectedOptions[0].getAttribute(
											"reg"
										)
									);
								} else if (
									e.target.selectedOptions[0].getAttribute(
										"reg"
									) <= 0 ||
									e.target.selectedOptions[0].getAttribute(
										"reg"
									) === ""
								) {
									setAlert({
										showing: false,
										type: "error",
										message:
											"Bill Amount choosen is not configured."
									});
								}
							}}
							required
							disabled
						>
							<option>--Select Description --</option>
							{descriptions.map((descrip, index) => (
								<option
									value={descrip.Description}
									descode={descrip.Code}
									renewal={descrip.RenewalFee}
									reg={descrip.RegistrationFee}
									fixed={descrip.FixedFee}
									index={index}
								>
									{descrip.Description}
								</option>
							))}
						</select>
					</div>
				</div>
				{/* ////////////third row/////////// */}
				<div className={styles3.double__inputs}>
					<div>
						<label htmlFor="revenue">
							Revenue
							<span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="revenue"
							name={revenue}
							onChange={(e) => {
								setRevId(
									e.target.selectedOptions[0].getAttribute(
										"revcode"
									)
								);
								setRevenue(e.target.value);
							}}
							value={revenue}
							required
							disabled
						>
							<option>--Select Revenue--</option>
							{revenues.map((rev, index) => (
								<option
									value={rev.Description}
									revcode={rev.Code}
									index={index}
								>
									{rev.Description}
								</option>
							))}
						</select>
					</div>
					<div>
						<label
							style={{ marginBottom: "15px" }}
							className={styles3.input__label}
							htmlFor="amount"
						>
							Amount
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="amount"
							name="amount"
							type="number"
							style={{ marginTop: "0" }}
							value={amount}
							onChange={(e) => setAmount(e.target.value)}
							placeholder=""
							required
							disabled
						/>
					</div>
				</div>
				<div className={styles3.double__inputs}>
					<div>
						<label
							style={{ marginBottom: "15px" }}
							className={styles3.input__label}
							htmlFor="outstanding"
						>
							Outstanding
						</label>
						<input
							id="outstanding"
							type="text"
							name="outstanding"
							style={{ marginTop: "0" }}
							onChange={(e) => {
								setOutStanding(e.target.value);
							}}
							value={outstanding}
							placeholder="Enter Outstanding Bill "
							disabled
						/>
					</div>
					<div>
						<label
							style={{ marginBottom: "15px" }}
							className={styles3.input__label}
							htmlFor="OutstandingDesciption"
						>
							Outstanding Desciption{" "}
						</label>
						<input
							id="OutstandingDesciption"
							type="text"
							name="OutstandingDesciption"
							style={{ marginTop: "0" }}
							onChange={(e) =>
								setOutStandingDescription(e.target.value)
							}
							value={OutstandingDesciption}
							placeholder="Enter Outstanding Description"
							disabled
						/>
					</div>
				</div>
				<div className={styles1.add_btn}>
					{/* <button
						className={["primary__btn", styles1.add_btn].join(" ")}
						onClick={(e) => {
							e.preventDefault();
							console.log("clickme!!!");
							tableId += 1;
							fem =
								// ...fem,
								{
									mdaId,
									mda,
									billtype,
									billCategory,
									description,
									revenue,
									revId,
									amount,
									outstanding,
									OutstandingDesciption,
									tableId: uuidv4()
								};

							console.log("fem  --> ", fem);
							tmpData.push(fem);

							if (editFlg === true) {
								const locData = tmpData.findIndex(
									(itm) => itm.tableId === editId
								);
								console.log("edit - locdata -> ", locData);
								console.log("editId - locdata -> ", editId);
								if (locData !== -1) {
									tableData[locData].mdaId = mdaId;
									tableData[locData].mda = mda;
									tableData[locData].billtype = billtype;
									tableData[locData].billCategory =
										billCategory;
									tableData[locData].description =
										description;
									tableData[locData].revenue = revenue;
									tableData[locData].revId = revId;
									tableData[locData].amount = amount;
									tableData[locData].outstanding =
										outstanding;
									tableData[locData].OutstandingDesciption =
										OutstandingDesciption;

									setTableData(tableData);
								}

								total = total + Number(amount);
								grandTotal = grandTotal + Number(amount);
								grandOutstanding =
									grandOutstanding + Number(outstanding);
								setTotal(total);
								setGrandOutstanding(grandOutstanding);
								setGrandTotal(grandTotal);
								setEditFlg(false);
								setEditId("");
							} else {
								total = total + Number(amount);
								grandTotal = grandTotal + Number(amount);
								grandOutstanding =
									grandOutstanding + Number(outstanding);
								setTotal(total);
								setGrandOutstanding(grandOutstanding);
								setGrandTotal(grandTotal);
								setTableData([...tableData, fem]);
							}
							console.log("tabledata -> ", tableData);
						}}
					>
						{editFlg === true ? "Edit Bill" : "Add Bill"}
					</button> */}
				</div>
				<hr className={styles3.divider} />
				<div
					className={styles1.adjust}
					style={{
						margin: "0px"
					}}
				>
					<div className={styles1.table__div}>
						<Table
							headers={headers}
							data={tableData}
							full
							onEdit={(data) => {
								const { tableId } = data;
								console.log("row data onEdit ->", data.tableId);

								// Find the index of the element with the specified tableId
								const indexToEdit = tableData.findIndex(
									(item) => item.tableId === tableData.tableId
								);

								tableData[indexToEdit].mdaId = mdaId;
								tableData[indexToEdit].mda = mda;
								tableData[indexToEdit].billtype = billtype;
								tableData[indexToEdit].billCategory =
									billCategory;
								tableData[indexToEdit].description =
									description;
								tableData[indexToEdit].revenue = revenue;
								tableData[indexToEdit].revId = revId;
								tableData[indexToEdit].amount = amount;
								tableData[indexToEdit].outstanding =
									outstanding;
								tableData[indexToEdit].OutstandingDesciption =
									OutstandingDesciption;

								total = total - Number(data.amount);
								grandTotal = grandTotal - Number(data.amount);
								grandOutstanding =
									grandOutstanding - Number(data.outstanding);

								setTotal(total);
								setGrandOutstanding(grandOutstanding);
								setGrandTotal(grandTotal);
							}}
							onDelete={(data) => {
								console.log(
									"row data onDelete ->",
									tableData.tableId
								);

								const { tableId } = data;

								// Find the index of the element with the specified tableId
								const indexToDelete = tableData.findIndex(
									(item) => item.tableId === tableId
								);

								if (indexToDelete !== -1) {
									// Use splice to remove the element at the found index
									const updatedData = [...tableData];
									updatedData.splice(indexToDelete, 1);

									total =
										total -
										Number(tableData[indexToDelete].amount);
									grandTotal =
										grandTotal -
										Number(tableData[indexToDelete].amount);
									grandOutstanding =
										grandOutstanding -
										Number(
											tableData[indexToDelete].outstanding
										);

									setTotal(total);
									setGrandOutstanding(grandOutstanding);
									setGrandTotal(grandTotal);

									// Update the state with the modified array
									setTableData(updatedData);
								}
							}}
						/>
					</div>
				</div>
				<div className={styles1.undertable}>
					<div>
						<div>
							<label
								style={{ marginBottom: "15px" }}
								className={styles3.input__label}
								htmlFor="discount"
							>
								Discount
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								id="discount"
								type="text"
								name="discount"
								style={{ marginTop: "0" }}
								onChange={(e) => {
									setDiscount(e.target.value);
								}}
								value={discount}
								disabled
							/>
						</div>
						<div>
							<label
								style={{ marginBottom: "15px" }}
								className={styles3.input__label}
								htmlFor="resDiscount"
							>
								Reasons for Discount
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								id="resDiscount"
								type="text"
								name="resDiscount"
								style={{ marginTop: "0" }}
								onChange={(e) => {
									setResDiscount(e.target.value);
								}}
								value={resDiscount}
								disabled
							/>
						</div>
						{/* <button
							id="btnCalc"
							className={[styles1.calc_btn, "primary__btn"].join(
								" "
							)}
							onClick={(e) => {
								let grdsum = 0;
								let grdout = 0;
								let grdTotal = 0;
								e.preventDefault();
								setBtnCalcClick(true);
								console.log("arrBtnClick -> ", arrBtnClick);
								console.log("tabledata -> ", tableData);
								const gBill = tableData.map((bill) => {
									console.log(
										`startig=ng -> ${bill.amount}  |  amount -> ${bill.amount} `
									);
									grdTotal =
										Number(grdTotal) + Number(bill.amount);
									grdsum =
										Number(grdsum) +
										Number(bill.amount) +
										Number(bill.outstanding);
									grdout =
										Number(grdout) +
										Number(bill.outstanding || 0);
									return {
										grdsum,
										grdout,
										grdTotal
									};
								});
								setTotal(grdTotal);
								setGrandOutstanding(grdout);
								setGrandDiscount(discount);
								setGrandTotal(
									Number(grdTotal) +
										Number(grdout) -
										Number(discount)
								);
								console.log(
									`setTotal -> ${grdTotal}  |  setOutStand -> ${grandOutstanding} | setDiscount -> ${discount} | setGrandTotal -> ${grandTotal}`
								);
								console.log(
									`grdsum -> ${grdsum}  |  grdTotal -> ${grdTotal} | grdout -> ${grdout} | setGrandTotal -> ${grdTotal}`
								);
							}}
						>
							Calculate
						</button> */}
					</div>
					<div className={styles1.determinants}>
						<h3>
							Total Current Bill :{" "}
							<b>&#8358;{formatAmount(total)}</b>{" "}
						</h3>
						<h3>
							Total Outstanding Bill:
							<b>
								&#8358;
								{formatAmount(grandOutstanding)}
							</b>{" "}
						</h3>
						<h3>
							Grand Discount :
							<b>
								&#8358;
								{formatAmount(granddiscount)}
							</b>
						</h3>
						<h3>
							Total Due:{" "}
							<b>
								&#8358;
								{formatAmount(grandTotal)}
							</b>
						</h3>
					</div>
				</div>
				{/* <button
					type="submit"
					className={["primary__btn", styles.submit__btn].join(" ")}
				>
					{isGenerate
						? "Generating Generate Consolidated Demand Notice..."
						: "Generate Consolidated Demand Notice"}
				</button> */}
			</form>
			{invoiceData &&
				!gettingInvoice &&
				Boolean(
					userInfo?.roles?.[0]?.Claims.find(
						(claim) => claim.Route === "/mdaconsoldn"
					)
				) && (
					<>
						<div className={styles.notice__btns}>
							<button
								onClick={(e) => printInvoice(e)}
								className={styles1.print__btn}
							>
								Print
							</button>
						</div>
						<div
							dangerouslySetInnerHTML={{ __html: invoiceData }}
							className={styles.doc__area}
						></div>
					</>
				)}
			{gettingInvoice && (
				<p style={{ textAlign: "center" }}>Loading Invoice...</p>
			)}
		</div>
	);
};

export default ConsolidatedView;
