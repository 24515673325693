import React from "react";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import Alert from "../components/Alert";
import style from "./style.module.css";
import styles1 from "../Identity-management-individual/style.module.css";
import constants from "../constants";
import BankData from "../banks.json";

axios.defaults.base1URL = constants.BASE1_URL;
const EditMaker = () => {
	const token = localStorage.getItem("access_token");
	const history = useHistory();
	const [esbn, setESBN] = useState("");
	const [customerName, setCusName] = useState("");
	const [branchName, setBank] = useState("");
	const [billNumber, setBillNumber] = useState("");
	const [paymentMethod, setPayMth] = useState("");
	const [totalAmountPaid, setTAmount] = useState("");
	const [address, setAddress] = useState("");
	const [paymentDate, setPaymentDate] = useState("");
	const [depositorsPhone, setDepositorsPhone] = useState("");
	const [depositorsRcNumber, setDepositorsRcNumber] = useState("");
	const [depositSlipNumber, setDepositSlipNumber] = useState("");
	const [paymentRefNumber, setPaymentRefNumber] = useState("");
	const [receiptNumber, setReceiptNumber] = useState("");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const [getrev, setRevhead] = useState([]);
	const [getmdas, setMdahead] = useState([]);
	const [revenue, setRevenue] = useState("");
	const [mda, setMdas] = useState("");

	const { id } = useParams();

	const [loading, setLoading] = useState(false);

	const [setAllData] = useState();

	useEffect(() => {
		const getESBN = async () => {
			const res = await axios
				.get(`users/anssid?anssid=${esbn}`)
				.then((res) => (res.data || {}).data);

			if (res.accounttype === "individual") {
				setCusName(`${res.firstname} ${res.surname}`);
			} else {
				setCusName(res.name);
			}
		};
		if (esbn?.length >= 10) getESBN();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [esbn]);

	const getManualRecon = async () => {
		setLoading(true);
		try {
			const response = await axios.get(
				`${constants.BASE1_URL}/manualRecon/getAManualReconRecord/${id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);

			const data = response.data;

			setLoading(false);

			if (response.status === 200) {
				const {
					esbn,
					customerName,
					branchName,
					billNumber,
					paymentMethod,
					totalAmountPaid,
					address,
					revenue,
					paymentDate,
					depositorsPhone,
					depositorsRcNumber,
					depositSlipNumber,
					paymentRefNumber,
					receiptNumber,
					mda
				} = data.data;

				setESBN(esbn);
				setCusName(customerName);
				setBank(branchName);
				setBillNumber(billNumber);
				setPayMth(paymentMethod);
				setTAmount(totalAmountPaid);
				setAddress(address);
				setRevenue(revenue);
				setPaymentDate(paymentDate);
				setDepositorsPhone(depositorsPhone);
				setDepositorsRcNumber(depositorsRcNumber);
				setDepositSlipNumber(depositSlipNumber);
				setPaymentRefNumber(paymentRefNumber);
				setReceiptNumber(receiptNumber);
				setMdas(mda);
				setAllData(data.data);
			}
		} catch (error) {
			setLoading(false);
			// Handle the error here, such as displaying a message to the user
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message:
					error.response.data?.msg ||
					error.response.data?.errors?.[0] ||
					error.response.data?.errors?.[0]?.message ||
					error.response.data?.errors?.details[0]?.message ||
					error.response.message
			});
		}
	};

	useEffect(() => {
		getManualRecon();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const RevenueHead = async () => {
		const res = await axios
			.get(`/settings/mdalinkedrevenue/${mda}`)
			.then((res) => (res.data || {}).data);
		setRevhead(res);
	};

	useEffect(() => {
		RevenueHead();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mda]);

	const Mdas = async () => {
		const res = await axios
			.get(`/settings/allmdas`)
			.then((res) => (res.data || {}).data);
		setMdahead(res);
	};

	useEffect(() => {
		Mdas();
	}, []);

	const updateData = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const res = await axios.put(
				`${constants.BASE1_URL}/manualRecon/manualRecon/updateManualReconRecord/${id}`,
				{
					esbn,
					customerName,
					mda,
					paymentDate,
					paymentMethod,
					paymentRefNumber,
					branchName,
					depositorsPhone,
					depositorsRcNumber,
					totalAmountPaid,
					revenue,
					depositSlipNumber,
					receiptNumber,
					billNumber
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`
					}
				}
			);

			history.push("/Maker-checker/inputter");

			setAlert({
				...alert,
				type: "success",
				showing: true,
				message: res.data.message
			});
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		} finally {
			setLoading(false);
		}
	};

	// React.useEffect(() => {
	// 	updateData()
	// }, [])
	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form className={[styles1.columned__form, styles1.main].join(" ")}>
				<div className={styles1.double__inputs}>
					<div>
						<label className={styles1.input__label} htmlFor="esbn">
							ESBN <span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="esbn"
							type="text"
							id="esbn"
							value={esbn}
							onChange={(e) => setESBN(e.target.value)}
							placeholder="eg. 01"
							required
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="customerName"
						>
							Customer
						</label>
						<input
							name="customerName"
							type="text"
							id="customerName"
							value={customerName}
							onChange={(e) => setCusName(e.target.value)}
							required
						/>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="billNumber"
						>
							Bill Number <span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="billNumber"
							type="text"
							id="billNumber"
							value={billNumber}
							onChange={(e) => setBillNumber(e.target.value)}
							placeholder="eg. 01"
							required
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="bank"
							id="bank"
						>
							Bank<span style={{ color: "red" }}>*</span>
						</label>
						<select
							value={branchName}
							onChange={(e) => setBank(e.target.value)}
						>
							<option value={""}>--Select Bank--</option>
							{BankData &&
								BankData.map((bank) => (
									<option value={bank.name}>
										{bank.name}
									</option>
								))}
						</select>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="address"
						>
							Address <span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="address"
							type="text"
							id="address"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							placeholder="eg. New Haven "
						/>
					</div>
				</div>
				<hr />
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="depositorsPhone"
						>
							Depositor’s Phone No.{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="depositorsPhone"
							type="text"
							id="depositorsPhone"
							value={depositorsPhone}
							onChange={(e) => setDepositorsPhone(e.target.value)}
							placeholder="Enter Phone Number"
							required
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="depositSlipNumber"
						>
							Deposit Slip No.
						</label>
						<input
							name="depositSlipNumber"
							type="text"
							id="depositSlipNumber"
							value={depositSlipNumber}
							onChange={(e) =>
								setDepositSlipNumber(e.target.value)
							}
							required
						/>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="depositorsRcNumber"
						>
							Depositor’s RC-No{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="depositorsRcNumber"
							type="text"
							id="depositorsRcNumber"
							value={depositorsRcNumber}
							onChange={(e) =>
								setDepositorsRcNumber(e.target.value)
							}
							placeholder="eg. 01"
							required
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="paymentRefNumber"
						>
							Payment Ref No.
						</label>
						<input
							name="paymentRefNumber"
							type="text"
							id="paymentRefNumber"
							value={paymentRefNumber}
							onChange={(e) =>
								setPaymentRefNumber(e.target.value)
							}
							required
						/>
					</div>
				</div>
				<hr />
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="totalAmountPaid"
						>
							Total Amount Paid{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="totalAmountPaid"
							type="text"
							id="totalAmountPaid"
							value={totalAmountPaid}
							onChange={(e) => setTAmount(e.target.value)}
							placeholder="eg. 01"
							required
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="receiptNumber"
						>
							Receipt No
						</label>
						<input
							name="receiptNumber"
							type="text"
							id="receiptNumber"
							value={receiptNumber}
							onChange={(e) => setReceiptNumber(e.target.value)}
							required
						/>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="paymentMethod"
						>
							Payment Method{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="paymentMethod"
							type="text"
							id="paymentMethod"
							value={paymentMethod}
							onChange={(e) => setPayMth(e.target.value)}
							placeholder="eg. 01"
							required
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="PaymentDate"
						>
							Payment Date
						</label>
						<input
							name="PaymentDate"
							type="date"
							id="PaymentDate"
							value={paymentDate}
							onChange={(e) => setPaymentDate(e.target.value)}
							required
						/>
					</div>
				</div>

				<div className={styles1.double__inputs}>
					<div>
						<label className={styles1.input__label} htmlFor="mda">
							MDAS <span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="mda"
							className={styles1.input__medium}
							name="mda"
							onChange={(e) => {
								setMdas(e.target.value);
							}}
							value={mda}
							required
						>
							<option value={""}>
								{getmdas ? "--Select MDA --" : "Loading..."}
							</option>
							{getmdas &&
								getmdas.map((getMdas) => (
									<option value={getMdas.Code}>
										{getMdas.Description}
									</option>
								))}
						</select>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="revenue"
						>
							Revenue <span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="revenue"
							className={styles1.input__medium}
							name="revenue"
							onChange={(e) => {
								setRevenue(e.target.value);
							}}
							value={revenue}
							required
						>
							<option value={""}>
								{getrev
									? "--Select Revenue head--"
									: "Loading..."}
							</option>
							{getrev &&
								getrev.map((getRev) => (
									<option value={getRev.Code}>
										{getRev.Description}
									</option>
								))}
						</select>
					</div>
				</div>

				<div className={style.setbtn}>
					<div>
						<button onClick={updateData} className={[style.btn]}>
							{loading}
							update
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default EditMaker;
