import style from "./style.module.css";
import FlexRight from "../FlexRight-template";
import taxTypeImg from "../../assets/Home/taxTypeImg.svg";
import FlexLeft from "../FlexLeft-template";
import capitalGainImg from "../../assets/Home/capitalGainImg.svg";
import stampDutyImg from "../../assets/Home/stampDutyImg.svg";
import businessPremisesImg from "../../assets/Home/businessPremisesImg.svg";
import purchaseImage from "../../assets/Home/purchaseImage.svg";
import withholdingImg from "../../assets/Home/withholdingImg.svg";
import Subscription from "../../Home/subscription/index";
import Footer from "../footer";
import NavBar from "../nav-bar";
import { useState, useRef } from "react";
import useIntersectionObserver from "../IntersectionObserver";
import observerStyle from "../IntersectionObserver/style.module.css";

const PIT = [
	{
		number: 1,
		heading: "Pay-As-You-Earn (PAYE) i.e. taxes from employment",
		content1:
			"PAYE which is an acronym for Pay As You Earn. Only staff of Organizations pays PAYE and it is so called because taxes are deducted at source, to be remitted by the organization to the IGR account of the government",
		link: "Read More"
	},
	{
		number: 2,
		heading: "Taxes from Self-Employed persons (Direct Assessment)",
		content1:
			"Direct Assessment which is made on self employed persons, professionals, contractors, traders, landlords etc. and are expected to file a tax return on income earned in the preceding year using tax form A.",
		link: "Read More"
	}
];

const TaxType = () => {
	useIntersectionObserver();
	const [showRate, setShowRate] = useState(false);
	const tableRef = useRef(null);

	return (
		<main>
			<NavBar />
			<section className={style.heroSection}>
				<h1>Tax Types</h1>
				<h3>
					Defines the various types of tax collected by the <br />
					different taxation bodies.
				</h3>
			</section>

			<section className={style.personalTaxIncome}>
				<img
					src={taxTypeImg}
					alt="taxTypeImg"
					className={`${observerStyle.animateHomePage} ${observerStyle.slideInRight}`}
				/>
				<div
					className={`${style.personalTaxIncomeLeft} ${observerStyle.animateHomePage} ${observerStyle.slideInLeft}`}
				>
					{!showRate ? (
						<div className={style.paragraphs}>
							<h2>
								<span className={style.underlineGreen}>
									Personal
								</span>
								&nbsp;Income Tax
							</h2>
							<p>
								According to the Personal Income Tax act as
								amended in 2011, Tax is imposed on individuals
								who either work as direct employees of an
								organization or carry out their businesses in a
								business name or partnership.
							</p>
							<h4>
								<strong>These taxes are collectible by:</strong>
							</h4>
							<p>
								<strong>Federal Government (FIRS):</strong> From
								the highly mobile federal workers such as the
								Police, members of the Military, etc.
							</p>
							<p>
								<strong>State Governments:</strong> From all
								their qualified residents, even from civilians
								who worked with Police and the military as long
								as they are resident in the state.
							</p>
							<button onClick={() => setShowRate(true)}>
								See Rate
							</button>
						</div>
					) : (
						<div className={style.paragraphs} ref={tableRef}>
							<h2>
								<span className={style.underlineGreen}>
									Personal
								</span>
								&nbsp;Income Tax Rates
							</h2>
							<p>
								The table below shows a summary of the taxable
								income tax bands and applicable rates of tax on
								an annual basis.
							</p>
							<table className={style.table}>
								<thread>
									<tr>
										<th>Annual income (#)</th>
										<th>PIT (%)</th>
									</tr>

									<tr>
										<th>First 300,000</th>
										<th className={style.pitValue}>7</th>
									</tr>

									<tr>
										<th>Next 300,000</th>
										<th>11</th>
									</tr>

									<tr>
										<th>Next 500,000</th>
										<th>15</th>
									</tr>

									<tr>
										<th>Next 500,000</th>
										<th>19</th>
									</tr>

									<tr>
										<th>Next 1,600,000</th>
										<th>21</th>
									</tr>

									<tr>
										<th>Above 3,200,000</th>
										<th>24</th>
									</tr>
								</thread>
							</table>
							<p>
								It must be that employees who earn not more than
								the national minimum wage (currently NGN 30,000)
								are no longer liable to tax or deduction of
								monthly PAYE."
							</p>
							<button onClick={() => setShowRate(false)}>
								Go Back
							</button>
						</div>
					)}
				</div>
			</section>
			<section className={style.PitContainer}>
				<h2 className={style.desktop}>
					<span className={`${style.underlineGreen}`}>Two</span> types
					of Personal Income Tax (PIT)
				</h2>
				<h2 className={`${style.mobile}`}>
					Two types of <br />
					<span className={style.underlineGreen}> Personal</span>
					&nbsp;Income Tax (PIT)
				</h2>
				<div className={style.pitCards}>
					{PIT.map((item, index) => (
						<div key={index} className={style.pitCard}>
							<h2>{item.number}</h2>
							<h3>{item.heading}</h3>
							<p>{item.content1}</p>
							<p>{item.content2}</p>
						</div>
					))}
				</div>
			</section>

			<FlexLeft
				heading={
					<div>
						<span className={style.underlineGreen}>Capital</span>
						&nbsp;Gains Tax (Individuals Only)
					</div>
				}
				content1="This is tax payable by individuals on the profit made from the sale of their assets such as shares, and other properties."
				image={capitalGainImg}
			/>
			<FlexRight
				headingIn={
					<div>
						<span className={style.underlineGreen}>Stamp</span>
						&nbsp;Duties
					</div>
				}
				content1="
				Tax imposed on legal instruments or document executed by people. Some of these documents includes change of ownership of houses, land etc"
				image={stampDutyImg}
			/>
			<FlexLeft
				heading={
					<div>
						<span className={style.underlineGreen}>Business</span>
						&nbsp;Premises Levy
					</div>
				}
				content1="It is a tax on property used for the production of income including rental houses, office buildings, factories etc and it is paid first at registration and renewal on annual basis."
				image={businessPremisesImg}
			/>
			<FlexRight
				headingIn={
					<div>
						<span className={style.underlineGreen}>Withhol</span>
						ding Taxes
					</div>
				}
				content1="It is also known as advance tax where the specified sum is deducted at source by the organization in contract with another party in respect of the amount payable to the to the contract party for services rendered or investments. For example, there is a withholding tax on rent which is pegged at 10% on rent paid to be remitted to the state IRS according to the PIT act Cap P8 LFN 2011 as amended."
				image={withholdingImg}
			/>

			<FlexLeft
				heading={
					<div>
						<span className={style.underlineGreen}> Purchas</span>es
						Tax:
					</div>
				}
				content1="This is tax of 5% of all sales and services payable by hotels, guest houses supermarkets etc. and 10% on print electronic media advertisement fees."
				image={purchaseImage}
			/>
			<Subscription />
			<Footer />
		</main>
	);
};

export default TaxType;
