import style from "./style.module.css";
import Subscription from "../subscription";
import Footer from "../footer";
import NavBar from "../nav-bar";

const consolidatedSteps = [
	{
		id: 1,
		steps: "Step",
		content:
			"From the Homepage, Click on 'Pay Tax with' and Select REMITA PAY (Only for now)"
	},
	{
		id: 2,
		steps: "Step",
		content:
			"Insert the Bill number and wait for all the details of the bills to be displayed"
	},
	{
		id: 3,
		steps: "Step",
		content: "Click on Pay now."
	}
];

const consolidatedStepsPhase2 = [
	{
		id: 4,
		steps: "Step",
		content: "Insert the preferred amount and click Pay now"
	},
	{
		id: 5,
		steps: "Step",
		content:
			"From the Payment option displayed, 'Select the preferred such as Bank Transfer e.tc.' and follow the lead as usual."
	}
];

const ConsolidatedSteps = () => {
	return (
		<main>
			<NavBar />
			<div className={style.heroSection}>
				<h1 className={style.desktop}>
					Steps to Pay your Single or <br /> Consolidated Bills
				</h1>
				<h1 className={style.mobile}>
					Steps to Pay your Single or <br /> Consolidated Bills
				</h1>
			</div>
			<div className={style.consolidatedSteps}>
				{consolidatedSteps.map((phase1, index) => (
					<div key={index} className={style.phase1}>
						<h4>
							{phase1.steps} <span>{phase1.id}</span>
						</h4>
						<p>{phase1.content}</p>
					</div>
				))}
			</div>

			<div className={style.consolidatedStepsCtn}>
				<h3 className={style.desktop}>
					Where the amount to be paid is less than the bill <br />
					amount, then proceed further as below:
				</h3>
				<h3 className={style.mobile}>
					Where the amount to be paid is less than the bill amount,
					then proceed further as below:
				</h3>
				<div className={style.consolidatedSteps2}>
					{consolidatedStepsPhase2.map((phase2, index) => (
						<div key={index} className={style.phase1}>
							<h4>
								{phase2.steps} <span>{phase2.id}</span>
							</h4>
							<p>{phase2.content}</p>
						</div>
					))}
				</div>
			</div>
			<Subscription />
			<Footer />
		</main>
	);
};

export default ConsolidatedSteps;
