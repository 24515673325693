import { useState } from "react";
import { Card } from "../../Dashboard/Settings/id.details";
import styles1 from "../../Identity-management-individual/style.module.css";

const DirectAssessmentSettings = () => {
	//Assessment Code
	const [SettingId, setSettingId] = useState("");
	const [Description, setAssessmentCodeDescription] = useState("");
	const [IdTagShortname, setIDTagShortName] = useState("");
	const [srlDigitNo, setSrlDigitNumber] = useState("");
	const [IdPrefix, setIDPrefix] = useState("");
	const [IdSuffix, setIDSuffix] = useState("");
	const [IdStartSrlNo, setIDStartSrlNumber] = useState("");
	const [IdEndSrlNo, setIDEndSrlNumber] = useState("");
	// const [Deactivated, setDeactivated] = useState(false);

	//Charges in Tranches Setup
	const [TranchId, setTranchId] = useState("");
	const [TranchDesc, setTranchDesc] = useState("");
	const [Tranch, setTranch] = useState("");
	const [TranchRate, setTranchRate] = useState("");
	const [TranchNo, setTranchNo] = useState("");
	const [TranchFlg, setTranchFlg] = useState("");

	//Income Source
	const [incomeSourceCode, setIncomeSourceCode] = useState("");
	const [incomeSourceDesc, setIncomeSourceDesc] = useState("");

	//Sector
	const [sectorCode, setSectorCode] = useState("");
	const [sectorDesc, setSectorDesc] = useState("");

	//Category
	const [categoryCode, setCategoryCode] = useState("");
	const [categoryDesc, setCategoryDesc] = useState("");

	//Assess Revenue
	const [assessRevenueCode, setAssessRevenueCode] = useState("");
	const [assessRevenueDesc, setAssessRevenueDesc] = useState("");

	//Assess Record
	const [assessRecordCode, setAssessRecordCode] = useState("");
	const [assessRecordDesc, setAssessRecordDesc] = useState("");

	//Assess Rating
	const [assessRatingCode, setAssessRatingCode] = useState("");
	const [assessRatingDesc, setAssessRatingDesc] = useState("");

	//Assess Mode
	const [assessModeCode, setAssessModeCode] = useState("");
	const [assessModeDesc, setAssessModeDesc] = useState("");

	//Assess Method
	const [assessMethodCode, setAssessMethodCode] = useState("");
	const [assessMethodDesc, setAssessMethodDesc] = useState("");

	//payment Status
	const [paymentStatusCode, setPaymentStatusCode] = useState("");
	const [paymentStatusDesc, setPaymentStatusDesc] = useState("");

	//Process Status
	const [processStatusCode, setProcessStatusCode] = useState("");
	const [processStatusDesc, setProcessStatusDesc] = useState("");

	//Tax Office
	const [taxOfficeCode, setTaxOfficeCode] = useState("");
	const [taxOfficeDesc, setTaxOfficeDesc] = useState("");

	return (
		<main>
			<Card
				cardName="Assessment Code"
				codeValue={SettingId}
				onCodeChange={setSettingId}
				descriptionValue={Description}
				onDescriptionChange={setAssessmentCodeDescription}
				state={{
					IdTagShortname,
					srlDigitNo,
					IdPrefix,
					IdStartSrlNo,
					IdEndSrlNo
				}}
				urlSubstring={"assessassessmentcode"}
				altChildren={
					<>
						<hr className={styles1.divider} />
						<div>
							<label
								className={styles1.input__label}
								htmlFor="srlDigitNumber"
							>
								Serial Digit Number{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								name="srlDigitNumber"
								type="text"
								id="srlDigitNumber"
								value={srlDigitNo}
								onChange={(e) =>
									setSrlDigitNumber(e.target.value)
								}
								placeholder="eg. 01"
								required
							/>
						</div>
						<hr className={styles1.divider} />
						<div>
							<label
								className={styles1.input__label}
								htmlFor="IDSuffix"
							>
								ID Suffix{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								name="IDSuffix"
								type="text"
								id="IDSuffix"
								value={IdSuffix}
								onChange={(e) => setIDSuffix(e.target.value)}
								placeholder="eg. 01"
								required
							/>
						</div>
						<hr className={styles1.divider} />
						<div>
							<label
								className={styles1.input__label}
								htmlFor="IDEndSrlNumber"
							>
								ID End Serial Number{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								name="IDEndSrlNumber"
								type="text"
								id="IDEndSrlNumber"
								value={IdEndSrlNo}
								onChange={(e) =>
									setIDEndSrlNumber(e.target.value)
								}
								placeholder="eg. 01"
								required
							/>
						</div>
					</>
				}
			>
				<hr className={styles1.divider} />
				<div>
					<label
						className={styles1.input__label}
						htmlFor="IDTagshortName"
					>
						ID Tag Short Name{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						name="IDTagshortName"
						type="text"
						id="IDTagshortName"
						value={IdTagShortname}
						onChange={(e) => setIDTagShortName(e.target.value)}
						placeholder="eg. 01"
						required
					/>
				</div>
				<hr className={styles1.divider} />
				<div>
					<label className={styles1.input__label} htmlFor="IDPrefix">
						ID Prefix <span style={{ color: "red" }}>*</span>
					</label>
					<input
						name="IDPrefix"
						type="text"
						id="IDPrefix"
						value={IdPrefix}
						onChange={(e) => setIDPrefix(e.target.value)}
						placeholder="eg. 01"
						required
					/>
				</div>
				<hr className={styles1.divider} />
				<div>
					<label
						className={styles1.input__label}
						htmlFor="IDStartSrlNumber"
					>
						ID Start Serial Number{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						name="IDStartSrlNumber"
						type="text"
						id="IDStartSrlNumber"
						value={IdStartSrlNo}
						onChange={(e) => setIDStartSrlNumber(e.target.value)}
						placeholder="eg. 01"
						required
					/>
				</div>
			</Card>
			<Card
				cardName="Charge Tranches Setup"
				codeValue={TranchId}
				onCodeChange={setTranchId}
				descriptionValue={TranchDesc}
				onDescriptionChange={setTranchDesc}
				state={{
					Tranch,
					TranchRate,
					TranchNo,
					TranchFlg
				}}
				urlSubstring="assesstranch"
				altChildren={
					<>
						<hr className={styles1.divider} />
						<div>
							<label
								className={styles1.input__label}
								htmlFor="TranchRate"
							>
								Tranch Rate
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								name="TranchRate"
								type="text"
								id="TranchRate"
								value={TranchRate}
								onChange={(e) => setTranchRate(e.target.value)}
								placeholder="eg. 01"
								required
							/>
						</div>
						<hr className={styles1.divider} />
						<div>
							<label
								className={styles1.input__label}
								htmlFor="TranchFlg"
							>
								Tranch Flag
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								name="TranchFlg"
								type="text"
								id="TranchFlg"
								value={TranchFlg}
								onChange={(e) => setTranchFlg(e.target.value)}
								placeholder="eg. 01"
								required
							/>
						</div>
					</>
				}
			>
				<hr className={styles1.divider} />
				<div>
					<label className={styles1.input__label} htmlFor="Tranch">
						Tranch
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						name="Tranch"
						type="text"
						id="Tranch"
						value={Tranch}
						onChange={(e) => setTranch(e.target.value)}
						placeholder="eg. 01"
						required
					/>
				</div>
				<hr className={styles1.divider} />
				<div>
					<label className={styles1.input__label} htmlFor="TranchNo">
						Tranch Number
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						name="TranchNo"
						type="text"
						id="TranchNo"
						value={TranchNo}
						onChange={(e) => setTranchNo(e.target.value)}
						placeholder="eg. 01"
						required
					/>
				</div>
			</Card>
			<Card
				cardName="Income Source"
				codeValue={incomeSourceCode}
				onCodeChange={setIncomeSourceCode}
				descriptionValue={incomeSourceDesc}
				onDescriptionChange={setIncomeSourceDesc}
				urlSubstring="assessincomesource"
			/>
			<Card
				cardName="Sector"
				codeValue={sectorCode}
				onCodeChange={setSectorCode}
				descriptionValue={sectorDesc}
				onDescriptionChange={setSectorDesc}
				urlSubstring="assesssector"
			/>
			<Card
				cardName="Category"
				codeValue={categoryCode}
				onCodeChange={setCategoryCode}
				descriptionValue={categoryDesc}
				onDescriptionChange={setCategoryDesc}
				urlSubstring="assesscategory"
			/>
			<Card
				cardName="Assess Revenue"
				codeValue={assessRevenueCode}
				onCodeChange={setAssessRevenueCode}
				descriptionValue={assessRevenueDesc}
				onDescriptionChange={setAssessRevenueDesc}
				urlSubstring="assessrevenue"
			/>
			<Card
				cardName="Assess Record"
				codeValue={assessRecordCode}
				onCodeChange={setAssessRecordCode}
				descriptionValue={assessRecordDesc}
				onDescriptionChange={setAssessRecordDesc}
				urlSubstring="assessrecord"
			/>
			<Card
				cardName="Assess Rating"
				codeValue={assessRatingCode}
				onCodeChange={setAssessRatingCode}
				descriptionValue={assessRatingDesc}
				onDescriptionChange={setAssessRatingDesc}
				urlSubstring="assessrating"
			/>
			<Card
				cardName="Assess Mode"
				codeValue={assessModeCode}
				onCodeChange={setAssessModeCode}
				descriptionValue={assessModeDesc}
				onDescriptionChange={setAssessModeDesc}
				urlSubstring="assessmode"
			/>
			<Card
				cardName="Assess Method"
				codeValue={assessMethodCode}
				onCodeChange={setAssessMethodCode}
				descriptionValue={assessMethodDesc}
				onDescriptionChange={setAssessMethodDesc}
				urlSubstring="assessmethod"
			/>
			<Card
				cardName="Payment status"
				codeValue={paymentStatusCode}
				onCodeChange={setPaymentStatusCode}
				descriptionValue={paymentStatusDesc}
				onDescriptionChange={setPaymentStatusDesc}
				urlSubstring="assesspaymentstatus"
			/>
			<Card
				cardName="Process Status"
				codeValue={processStatusCode}
				onCodeChange={setProcessStatusCode}
				descriptionValue={processStatusDesc}
				onDescriptionChange={setProcessStatusDesc}
				urlSubstring="assessprocessstatus"
			/>
			<Card
				cardName="Tax Office"
				codeValue={taxOfficeCode}
				onCodeChange={setTaxOfficeCode}
				descriptionValue={taxOfficeDesc}
				onDescriptionChange={setTaxOfficeDesc}
				urlSubstring="assesstaxoffice"
			/>
		</main>
	);
};

export default DirectAssessmentSettings;
