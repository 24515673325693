import style from "./style.module.css";
import Subscription from "../../Home/subscription/index";
import Footer from "../footer";
import NavBar from "../nav-bar";
import { useEffect } from "react";

const stateGovernment = [
	{
		id: "1.",
		text: "Personal Income Tax in respect of individuals resident in the State (a)Pay-As-You-Earn(PAYE) (b)Direct Taxation(Self-Assessment)"
	},
	{
		id: "2.",
		text: "Witholding Tax (Individuals Only)"
	},
	{
		id: "3.",
		text: "Capital Gains Tax (Individuals Only)"
	},
	{
		id: "4.",
		text: "Stamp Duties on instrument executed by individuals"
	},
	{
		id: "5.",
		text: "Poola Betting, Lotteries Gaming and Casino Taxes"
	},
	{
		id: "6.",
		text: "Road Taxes"
	},
	{
		id: "7.",
		text: "Business premises registration fee in respect of urban and rural areas which includes registration fees and per annum for the renewals as fixed by each state"
	},
	{
		id: "8.",
		text: "Development Levy (individuals only) not more than 100 per annum on all taxable individuals"
	},
	{
		id: "9.",
		text: "Naming of street registration fees in the State Capital"
	},
	{
		id: "10.",
		text: "Right of Occupancy fees on lands owned by the State Government in urban areas of the State"
	},
	{
		id: "11.",
		text: "Market Taxes and Levies where State finance is involved"
	},
	{
		id: "12.",
		text: "Land use Charge, where applicable"
	},
	{
		id: "13.",
		text: "Entertainment Tax, where applicable"
	},
	{
		id: "14.",
		text: "Environmental (Ecological) fee or levy"
	},
	{
		id: "15.",
		text: "Mining, milling and quarrying fee, where applicable"
	},
	{
		id: "16.",
		text: "Hotel, Restaurant or Event Centre Consumption Tax, where applicable"
	},
	{
		id: "17.",
		text: "Animal Trade Tax, where applicable"
	},
	{
		id: "18.",
		text: "Produce Sales Tax, where applicable"
	},
	{
		id: "19.",
		text: "Slaughter or Abattoir fees, where applicable"
	},
	{
		id: "20.",
		text: "Infrastructure Maintenance Charge or levy, where applicable"
	},
	{
		id: "21.",
		text: "Fire Service Charge"
	},
	{
		id: "22.",
		text: "Property Tax, where applicable"
	},
	{
		id: "23.",
		text: "Economic Development Levy, where applicable"
	},
	{
		id: "24.",
		text: "Social Services Contribution Levy, where applicable"
	},
	{
		id: "25.",
		text: "Signage and Mobile Advertisment, jointly collected by the State and Local Government"
	}
];

const localGovernment = [
	{
		id: "1.",
		text: "Shops and Kiosks rates"
	},
	{
		id: "2.",
		text: "Tenement rates"
	},
	{
		id: "3.",
		text: "On and Off Liquor License fees"
	},
	{
		id: "4.",
		text: "Slaughter slab fees"
	},
	{
		id: "5.",
		text: "Marriage, birth and death registration fees"
	},
	{
		id: "6.",
		text: "Naming of street registration fees, excluding any street in the State Capital"
	},
	{
		id: "7.",
		text: "Right of Occupancy fees on lands in the rural areas, excluding those collectible by the Federal  and State Governments."
	},
	{
		id: "8.",
		text: "Markets taxes and levies excluding any market where State finance is involved"
	},
	{
		id: "9.",
		text: "Motor park levies"
	},
	{
		id: "10.",
		text: "Domestic animal license fees"
	},
	{
		id: "11.",
		text: "Bicycle, truck canoe wheelbarrow and cart fees, other than a mechanically propelled truck"
	},
	{
		id: "12.",
		text: "Cattle tax payable by cattle farmers only"
	},
	{
		id: "13.",
		text: "Merriment and road closure"
	},
	{
		id: "14.",
		text: "Radio and television license fees (other than radio and television transmitter)"
	},
	{
		id: "15.",
		text: "Vehicle radio license"
	},
	{
		id: "16.",
		text: "Wrong parking charges"
	},
	{
		id: "17.",
		text: "Public convenience, sewage and refuse disposal fees."
	},
	{
		id: "18.",
		text: "Customary burial grounds permit fees"
	},
	{
		id: "19.",
		text: "Religious places establishment permit fees."
	},
	{
		id: "20.",
		text: "Signboard and Advertisement permit fees."
	},
	{
		id: "21.",
		text: "Wharf Landing Charge, where applicable"
	}
];

const federalGovernment = [
	{
		id: "1.",
		text: "Companies Income Tax"
	},
	{
		id: "2.",
		text: "Witholding Tax on companies and residents of the Federal Capital Territory and non-resident individuals"
	},
	{
		id: "3.",
		text: "Petroleum Profit Tax"
	},
	{
		id: "4.",
		text: "Value Added Tax"
	},
	{
		id: "5.",
		text: "Education Tax"
	},
	{
		id: "6.",
		text: "Capital Gains Tax on the residents of the Federal Capital Territory, bodies corporate and non-resident individuals"
	},
	{
		id: "7.",
		text: "Stamp Duties on bodies corporate and residents of the Federal Capital Territory"
	},
	{
		id: "8.",
		text: "Personal Income Tax in respect of: (a)Members of the Armed Forces of the Federation (b)Members of the Nigerian Police Force (c)Residents of the Federal Capital Territory (d)Stahh of the Ministry of Foreign Affairs and non-resident individuals"
	},
	{
		id: "9.",
		text: "National Information Technology Development Levy"
	}
];

const TaxCollection = () => {
	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<main>
			<NavBar />
			<section className={style.heroSection}>
				<h2>Tax Collection </h2>
				<p className={style.desktop}>
					Tax collection involves the enforcement of tax policies and
					laws <br /> established by a government institution to
					generate and <br />
					consolidate revenue for governmental use.
				</p>
				<p className={style.mobile}>
					Tax collection involves the enforcement of tax <br />{" "}
					policies and laws established by a government <br />{" "}
					institution to generate and consolidate revenue for
					governmental use.
				</p>
			</section>
			<section className={style.governmentCards}>
				<h2 className={style.desktop}>
					Tax Collection has been classified below <br /> under the 3
					ma<span className={style.underlineGreen}>in tax col</span>
					lection bodies:
				</h2>
				<h2 className={style.mobile}>
					Tax Collection has been classified <br /> below under the 3
					main tax <br />{" "}
					<span className={style.underlineGreen}>collect</span>ion
					bodies:
				</h2>
				<div className={style.localState}>
					<div className={style.left}>
						<h3>Taxes Collected by State Government</h3>
						<div className={style.governmentCard}>
							{stateGovernment.map((state, index) => (
								<div key={index} className={style.state}>
									<p>{state.id}</p>
									<p className={style.text}>{state.text}</p>
								</div>
							))}
						</div>
					</div>
					<div className={style.right}>
						<h3>
							Taxes and Levies to be Collected by Local Government
						</h3>
						<div className={style.governmentCard}>
							{localGovernment.map((local, index) => (
								<div key={index} className={style.state}>
									<p>{local.id}</p>
									<p className={style.text}>{local.text}</p>
								</div>
							))}
						</div>
					</div>
				</div>

				<div className={style.leftBottom}>
					<h3>Taxes Collected by Federal Government</h3>
					<div className={style.governmentCard}>
						{federalGovernment.map((federal, index) => (
							<div key={index} className={style.state}>
								<p>{federal.id}</p>
								<p className={style.text}>{federal.text}</p>
							</div>
						))}
					</div>
				</div>
			</section>

			<Subscription />
			<Footer />
		</main>
	);
};

export default TaxCollection;
