import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import styles from "./style.module.css";
import style from "../style.module.css";
import Table from "../../../components/Table";
import style1 from "../../../Identity-management-individual/style.module.css";
import arrowRightIcon from "../../../assets/arrowRightIcon.svg";
import arrowLeftIcon from "../../../assets/arrowLeftIcon.svg";
import Spinner from "../../../components/spinner";
import trashIcon from "../../../assets/Filing/trashIcon.svg";
import Alert from "../../../components/Alert";

export const StepFour = ({
	updateTab = () => {},
	updateFilingData = () => {},
	filingData = null
}) => {
	const [loading, setLoading] = useState(false);
	const [reciept, setReciept] = useState(
		filingData?.supportingDocs?.reciept ?? null
	);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const recieptRef = useRef(null);

	const [recieptData, setRecieptData] = useState({
		receiptNo: filingData?.recieptData?.receiptNo ?? "",
		data: filingData?.recieptData?.data ?? []
	});

	const headers = [
		["Reciept Number", "ReceiptNo"],
		["Ammount (₦)", "Amount"],
		["Payment Date", "PaymentDate"]
	];

	const deleteItem = (item) => {
		if (recieptData?.data) {
			const filteredData = recieptData?.data?.filter(
				(reciept) => reciept?.id !== item
			);
			setRecieptData((prev) => ({
				...prev,
				data: filteredData
			}));
		}
	};

	const fetchRecieptDetails = async () => {
		setLoading(true);

		try {
			const accessToken = localStorage.getItem("access_token");
			let data = await axios
				.get(`gettmspayref/${recieptData?.receiptNo}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => res.data || { data: {} });

			if (!data || !data?.data) {
				// handle errors
				return null;
			}

			setLoading(false);
			setRecieptData((prev) => ({
				...prev,
				data: [...prev.data, data.data]
			}));

			updateFilingData({
				...filingData,
				recieptData: {
					receiptNo: recieptData.receiptNo,
					data: [...(filingData?.recieptData?.data || []), data.data]
				}
			});

			setAlert({
				showing: true,
				type: "success",
				message:
					data?.description?.description.toUpperCase() ??
					"Bill Number fetched successful!"
			});
		} catch (err) {
			setAlert({
				showing: true,
				type: "error",
				message:
					err?.response?.data?.message ??
					"Bill Number fetch Unsuccessful!"
			});
		} finally {
			// setLoading(false);
		}
	};

	useEffect(() => {
		if (reciept) {
			updateFilingData({
				...filingData,
				supportingDocs: {
					...(filingData?.supportingDocs || []),
					reciept: reciept
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reciept]);

	return (
		<>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles.wrapper}>
				<p>Step 4: Upload Receipt</p>

				<div className={`bg-warning ${styles.notice__left}`}>
					<p>
						<strong>Instructions</strong>
					</p>
					<ul>
						<li>Kindly upload receipt document</li>
					</ul>
				</div>
				<br />
				<div className={`row ${styles.uploads}`}>
					<div className={`col-md-6`}>
						<p>
							<strong>Receipt (Max: 1mb)</strong>
						</p>
						<span className={style.flex__left}>
							<input
								type="file"
								id="reciept"
								accept="image/png, image/jpeg, image/webp"
								ref={recieptRef}
								onChange={(e) => setReciept(e.target.files[0])}
								style={{ display: "none" }}
							/>

							<label
								htmlFor="reciept"
								className={`btn btn-success ${styles.downloads__button}`}
							>
								<p>Choose File</p>
							</label>
							<span className={styles.ml_1}>
								<p>{reciept?.name}</p>
							</span>
							{reciept?.name && (
								<span className={styles.ml_1}>
									<img
										src={trashIcon}
										alt="delete"
										className={styles.delete_icon}
										onClick={() => {
											setReciept(null);
											recieptRef.current.value = null;
										}}
									/>
								</span>
							)}
						</span>
					</div>
				</div>
				<br />
				<br />
				<br />
				<div className={`bg-warning ${styles.notice__center}`}>
					<p>
						Enter Reciept Number for each remittance made in{" "}
						{new Date().getFullYear() - 1} (Payee and direct
						assessments bills numbers only)
					</p>
				</div>
				<br />
				<br />
				<div className={style.flex__left}>
					<input
						type="text"
						onChange={(e) =>
							setRecieptData((prev) => ({
								...prev,
								receiptNo: e.target.value
							}))
						}
						id="billNumber"
						placeholder="Reciept Number"
						required
						value={recieptData.receiptNo}
					/>
					<button
						className={`btn btn-success ${styles.downloads__button} ${styles.ml_1}`}
						onClick={() => fetchRecieptDetails()}
					>
						<p>{loading ? "Adding..." : "Add"}</p>
					</button>
				</div>
				<br />

				<Table
					headers={headers}
					data={recieptData?.data || []}
					full
					pageSize={20}
					total={recieptData?.data?.length}
					onDelete={(record) => deleteItem(record.id)}
				/>
				<br />
				<br />

				<footer className={style1.form__footer}>
					<button
						disabled={loading}
						type="submit"
						className={`btn text-success ${styles.border__button__green}`}
						onClick={() => updateTab(3)}
					>
						<img src={arrowLeftIcon} alt="download" />
						Go Back
					</button>
					<button
						type="submit"
						className={[
							"primary__btn",
							style1.primary__button,
							loading ? style1.loading__button : ""
						].join(" ")}
						onClick={() => updateTab(5)}
					>
						Next
						<img src={arrowRightIcon} alt="download" />
						{loading && <Spinner />}
					</button>
				</footer>
			</div>
		</>
	);
};
