import { useState, useEffect, createRef, useMemo } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";

import styles from "./style.module.css";
import Alert from "../../components/Alert";

const FilterComponent = ({ filterText, onFilter }) => (
	<>
		<div>
			<div>
				<input
					type="text"
					onChange={onFilter}
					placeholder="Code"
					value={filterText}
				/>
			</div>
		</div>
	</>
);

const MonthlySalaryRange = () => {
	const [values, setValues] = useState({
		Code: "",
		StartSalaryRange: "",
		EndSalaryRange: ""
	});
	const [sendingStatus, setSendingStatus] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const codeInputRef = createRef();

	const [editingStatus, setEditingStatus] = useState(false);
	const [loading, setLoadingStatus] = useState(false);
	const [resourceId, setResourceId] = useState(null);
	const [previousRecords, setPreviousRecords] = useState(null);

	const [filterText, setFilterText] = useState("");

	const filteredPreviousRecords = (previousRecords || []).filter((record) =>
		record.Code.toLowerCase().includes(filterText.toLowerCase())
	);

	const subHeaderComponentMemo = useMemo(() => {
		return (
			<FilterComponent
				onFilter={(e) => setFilterText(e.target.value)}
				filterText={filterText}
			/>
		);
	}, [filterText]);

	const columns = [
		{
			selector: "Code",
			name: "Code",
			sortable: true
		},
		{
			selector: "StartSalaryRange",
			name: "Start Salary Range",
			sortable: true
		},
		{
			selector: "EndSalaryRange",
			name: "End Salary Range",
			sortable: true
		},
		{
			name: "Action",
			sortable: false,
			cell: (record) => (
				<div
					className={styles.each__record}
					style={{ flexDirection: "column", height: "unset" }}
				>
					<button
						type="button"
						className={styles.deactive__btn}
						onClick={() => {
							setEditingStatus(true);
							setResourceId(record.id);
							setValues(record);
							codeInputRef.current.focus();
						}}
						disabled={editingStatus}
					>
						Edit
					</button>
					<button
						type="button"
						className={styles.delete__btn}
						onClick={() => deactivate(record.id)}
					>
						{record.Deactivated ? "Activate" : "Deactivate"}
					</button>
				</div>
			)
		}
	];

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	useEffect(() => {
		getPreviousRecords();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;

		setValues({
			...values,
			[name]: value
		});
	};

	const getPreviousRecords = async () => {
		setLoadingStatus(true);
		try {
			const request = await axios.get(`/settings/monthlysalaryrange`);

			setLoadingStatus(false);
			setPreviousRecords(request.data.data);
		} catch (error) {
			setLoadingStatus(false);
			if (error.response) {
				console.log("response", error.response.data.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.message
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		}
	};

	const deactivate = async (id) => {
		try {
			const { data } = await axios.put(
				`/settings/monthlysalaryrange/${id}`,
				{ deactivate: true }
			);

			if (!data.data) {
				return setAlert({
					...alert,
					showing: true,
					type: "error",
					message: "Something bad happened"
				});
			}

			const index = previousRecords.findIndex((pr) => pr.id === id);
			const record = data.data;

			if (index < 0) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: `Record not found`
				});
			}

			const updatedPrevious = previousRecords.slice();
			updatedPrevious[index] = record;
			setPreviousRecords(updatedPrevious);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: `Record ${
					record.Deactivated ? "Deactivated" : "Activated"
				}`
			});
		} catch (error) {
			if (error.response) {
				console.log("response", error.response.data.msg);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.msg
				});
			} else {
				console.log("Error", error.message);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const submit = async (e, id) => {
		if (e) e.preventDefault();

		const accessToken = localStorage.getItem("access_token");

		setSendingStatus(true);

		try {
			if (editingStatus) {
				const { data } = await axios.put(
					`/settings/monthlysalaryrange/${id}`,
					{ ...values }
				);

				if (!data.data) {
					return setAlert({
						...alert,
						showing: true,
						type: "error",
						message: "Edit failed. Please try again."
					});
				}

				const index = previousRecords.findIndex((pr) => pr.id === id);

				if (index < 0) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: `Record not found`
					});
					setSendingStatus(false);
					setEditingStatus(false);
					setValues({
						Code: "",
						StartSalaryRange: "",
						EndSalaryRange: ""
					});
					return;
				}

				const updatedPrevious = previousRecords.slice();
				updatedPrevious.splice(index, 1, data.data);
				setPreviousRecords(updatedPrevious);
				setSendingStatus(false);
				setEditingStatus(false);

				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: `Record Edited`
				});

				setValues({
					Code: "",
					StartSalaryRange: "",
					EndSalaryRange: ""
				});
			} else {
				await axios.post(
					`/settings/monthlysalaryrange`,
					{ ...values },
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				);
				setSendingStatus(false);
				setValues({
					Code: "",
					StartSalaryRange: "",
					EndSalaryRange: ""
				});
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: "Saved!"
				});
			}
		} catch (error) {
			setSendingStatus(false);
			if (error.response) {
				console.log("response", error.response.data.message);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.message
				});
			} else {
				console.log("Error", error.message);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	return (
		<div className={styles.settings__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form
				onSubmit={(e) => submit(e, resourceId)}
				className={styles.anssid__form}
			>
				<div className={styles.inputs__div}>
					<div className={styles.single__input__div}>
						<label htmlFor="Code">
							Code <span>*</span>
						</label>
						<input
							type="text"
							id="Code"
							name="Code"
							value={values.Code}
							onChange={handleChange}
							placeholder="Code"
							required
							min={0}
							ref={codeInputRef}
						/>
					</div>
				</div>
				<div className={[styles.inputs__div, styles.last].join(" ")}>
					<div>
						<label htmlFor="StartSalaryRange">
							Start Salary Range <span>*</span>
						</label>
						<input
							type="number"
							id="StartSalaryRange"
							name="StartSalaryRange"
							value={values.StartSalaryRange}
							onChange={handleChange}
							placeholder="010100"
							required
							min={0}
						/>
					</div>
					<div>
						<label htmlFor="EndSalaryRange">
							End Salary Range <span>*</span>
						</label>
						<input
							type="number"
							id="EndSalaryRange"
							name="EndSalaryRange"
							value={values.EndSalaryRange}
							onChange={handleChange}
							placeholder="010100"
							required
							min={0}
						/>
					</div>
				</div>
				<button
					type="submit"
					className="primary__btn"
					disabled={
						sendingStatus ||
						!values.Code ||
						!values.StartSalaryRange ||
						!values.EndSalaryRange
					}
				>
					{sendingStatus ? "Saving..." : "Save"}
				</button>
				{loading && (
					<p
						style={{
							paddingBottom: "30px",
							textAlign: "center"
						}}
					>
						Loading...
					</p>
				)}
				{!loading && previousRecords && (
					<div className={styles.records}>
						<p>
							<b>
								{previousRecords.length} previous{" "}
								{previousRecords.length === 1
									? "entry"
									: "entries"}
							</b>
						</p>
						<table className={styles.table}>
							{loading && (
								<tbody>
									<tr>
										<td>
											<p style={{ textAlign: "center" }}>
												Loading...
											</p>
										</td>
									</tr>
								</tbody>
							)}
							{!previousRecords?.length && !loading ? (
								<tbody>
									<tr>
										<td>
											<p style={{ textAlign: "center" }}>
												No Existing Records.
											</p>
										</td>
									</tr>
								</tbody>
							) : (
								<tbody>
									<DataTable
										title="Salary Ranges"
										columns={columns}
										data={filteredPreviousRecords}
										progressPending={loading}
										pagination
										paginationTotalRows={
											filteredPreviousRecords.length
										}
										paginationPerPage={10}
										subHeader
										subHeaderComponent={
											subHeaderComponentMemo
										}
									/>
								</tbody>
							)}
						</table>
					</div>
				)}
			</form>
		</div>
	);
};

export default MonthlySalaryRange;
