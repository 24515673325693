import React, { useEffect, useState, createRef } from "react";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import Alert from "../../components/Alert";
import AddIcon from "../../assets/UserManagement/add.svg";
import styles from "./style.module.css";
import filterStyles from "../MDAs/style.module.css";

const User = () => {
	const history = useHistory();

	const filterRef = createRef();

	const [users, setUsers] = useState(null);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	async function fetchUsers(e) {
		e?.preventDefault();

		setLoading(true);

		if (!filterRef.current.classList.contains(filterStyles.no__display)) {
			filterRef.current.classList.toggle(filterStyles.no__display);
		}

		let query = "";
		if (name) {
			query += `name=${name}&`;
		}
		if (email) {
			query += `email=${email}`;
		}
		if (phone) {
			query += `phone=${phone.replace(/^(0|((\+)*234))/, "234")}`;
		}

		try {
			const request = await axios.get(
				`/usermanagement/getusers?${query}`
			);
			setLoading(false);

			setUsers(request.data.data);
		} catch (error) {
			setLoading(false);
			if (error.response) {
				console.log("response", error.response.data.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.message
				}));
			} else {
				console.log("Errors", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		}
	}

	useEffect(() => {
		fetchUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const deactivate = async (id, status, index) => {
		try {
			await axios.put(
				`/admin/usermanagement/${
					status === true ? "deactivateaccount" : "activateaccount"
				}/${id}`
			);

			users[index].isActive = !status;

			setUsers([...users]);

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: `User ${status === true ? "Deactivated" : "activated"}`
			});
		} catch (error) {
			if (error.response) {
				console.log("response", error.response.data.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.message
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		}
	};

	const clearFilters = () => {
		setName("");
		setEmail("");
		setPhone("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		fetchUsers();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	return (
		<main className={styles.wrapper}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<header className={styles.header}>
				<div className={styles.top}>
					<p className={styles.heading__text}>Users</p>
					<div className={filterStyles.filter}>
						<button
							onClick={() => {
								filterRef.current.classList.toggle(
									filterStyles.no__display
								);
							}}
							className={filterStyles.filter__button}
						>
							Filter
						</button>
						<div
							ref={filterRef}
							className={[
								filterStyles.filter__div,
								filterStyles.no__display
							].join(" ")}
						>
							<div className={filterStyles.filter__header}>
								<h6>Filter</h6>
								<button
									aria-labelledby="close filter button"
									onClick={() => {
										filterRef.current.classList.toggle(
											filterStyles.no__display
										);
									}}
								>
									X
								</button>
							</div>
							<button
								className={filterStyles.clear__filter_fields}
								onClick={clearFilters}
								disabled={!name && !email && !phone}
							>
								Clear Filter fields
							</button>
							<p>By Name</p>
							<form>
								<div>
									<label htmlFor="name">Name</label>
									<input
										name="name"
										id="name"
										type="text"
										value={name}
										onChange={(e) =>
											setName(e.target.value)
										}
										placeholder="Eg. Onyinye ogbodo"
									/>
								</div>
							</form>
							<p>By Email</p>
							<form>
								<div>
									<label htmlFor="email">Email</label>
									<input
										name="email"
										id="email"
										type="email"
										value={email}
										onChange={(e) =>
											setEmail(e.target.value)
										}
										placeholder="Eg. onyinye.ogbodo@gmail.com"
									/>
								</div>
							</form>
							<p>By Phone Number</p>
							<form>
								<div>
									<label htmlFor="phone">Phone Number</label>
									<input
										name="phone"
										id="phone"
										type="number"
										value={phone}
										onChange={(e) =>
											setPhone(e.target.value)
										}
										placeholder="08123456789"
									/>
								</div>
							</form>
							<button
								className={[
									filterStyles.submit__button,
									"primary__btn"
								].join(" ")}
								onClick={() => fetchUsers()}
							>
								Search
							</button>
						</div>
					</div>
					<span className={styles.drop__arrow}></span>
				</div>
				<div className={styles.bottom}>
					<p className={styles.user__count}>
						Total Users: {users?.length}{" "}
					</p>
					<NavLink
						to={{
							pathname: "/create-user",
							state: { ssActive: "2" }
						}}
						className={styles.nav__link}
					>
						<img src={AddIcon} alt="add icon" /> Create User
					</NavLink>
				</div>
			</header>
			<section>
				<table className={styles.table}>
					{loading && (
						<tbody>
							<tr>
								<td>
									<p style={{ textAlign: "center" }}>
										Loading...
									</p>
								</td>
							</tr>
						</tbody>
					)}
					{!users?.length && !loading ? (
						<tbody>
							<tr>
								<td>
									<p style={{ textAlign: "center" }}>
										No Users.
									</p>
								</td>
							</tr>
						</tbody>
					) : (
						<tbody>
							{!loading &&
								users?.map((user, index) => (
									<tr key={index}>
										<td>
											<p>
												{user.firstname} {user.surname}
											</p>
											<p>{user.email}</p>
										</td>
										<td>
											<p>Role</p>
											<p>{user.role}</p>
										</td>
										<td>
											<p>Last Login</p>
											<p>
												{user.lastLogin
													? new Date(
															user.lastLogin
													  ).toLocaleString()
													: "N/A"}
											</p>
										</td>
										<td>
											<button
												className={styles.deactive__btn}
												onClick={() =>
													deactivate(
														user.id,
														user.isActive,
														index
													)
												}
											>
												{user.isActive
													? "Deactivate"
													: "Activate"}
											</button>
											<button
												className={styles.edit__btn}
												onClick={() => {
													history.push(
														"/create-user",
														{
															user,
															edit: true
														}
													);
												}}
											>
												Edit
											</button>
										</td>
									</tr>
								))}
						</tbody>
					)}
				</table>
			</section>
		</main>
	);
};

export default User;
