import { useState, useEffect, createRef, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Table from "../../../components/Table4";
import Alert from "../../../components/Alert";
import Modal from "../../../components/Modal/regular";
import GoBackButton from "../../../components/goBackButton";
import { formatAmount } from "../../../utils";
import styles from "./style.module.css";
import styles1 from "../../MDAs/style.module.css";
import recordView from "../Paye-Record/style.module.css";
import XcircleRed from "../../../assets/x-circle-red.svg";

const AuthView = () => {
	const { state: locationState } = useLocation();
	const [declineConfirmation, setDeclineConfirmation] = useState(false);
	const [revokeConfirmation, setRevokeConfirmation] = useState(false);
	const [approveConfirmation, setApproveConfirmation] = useState(false);
	const [isDeclineModal, setIsDeclineModal] = useState(false);
	const [isRevokeModal, setIsRevokeModal] = useState(false);
	const [declineReason, setDeclineReason] = useState("");
	const [loading, setLoading] = useState(false);
	const [approving, setApproving] = useState(false);
	const [finished, setFinished] = useState("0");
	const [total, setTotal] = useState(0);
	const [data, setData] = useState([]);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const filterRef = createRef();
	const userIdRef = useRef();

	const getAssessmentRecords =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();
			setLoading(true);
			if (!filterRef.current?.classList.contains(styles1.no__display)) {
				filterRef.current?.classList.toggle(styles1.no__display);
			}
			try {
				let { data, total } = await axios
					.get(
						`/dareports/tmspayedisplaybilldetails/${locationState.BillNumber}`
					)
					.then((res) => res.data || { data: {} });

				if (!data) {
					// handle errors
					return null;
				}

				setData(data);
				setTotal(total);
			} catch (err) {
				console.error(err);
			} finally {
				setFinished("1");
			}
		};

	useEffect(() => {
		if (
			JSON.parse(localStorage.getItem("user_info")).account_type ===
			"corporate"
		) {
			userIdRef.current = JSON.parse(
				localStorage.getItem("user_info")
			).anssid;
		} else {
			userIdRef.current = locationState.CoyESBN;
		}
		getAssessmentRecords()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finished === "1"]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);
		return () => clearTimeout(delay);
		// eslint-disable-next-line
	}, [!!alert.showing]);

	const approve = async () => {
		setApproving(true);
		try {
			const { msg } = await axios
				.put("processpaye", {
					billno: locationState.BillNumber,
					approve: "3", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval
					reason4Reject: "test",
					recId: locationState.id
				})
				.then((res) => res.data);

			if (data.status === 200) {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: "Bill has successfully been Approved"
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: msg
				});
			}
			setApproveConfirmation(false);
			getAssessmentRecords()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: error.response.data?.msg
				});
			}
			setApproveConfirmation(false);
		} finally {
			setApproving(false);
		}
	};

	const Decline = async () => {
		setApproving(true);
		try {
			const { msg } = await axios
				.put("processpaye", {
					billno: locationState.BillNumber,
					approve: "2", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval
					reason4Reject: declineReason,
					recId: locationState.id
				})
				.then((res) => res.data);

			setAlert({
				...alert,
				showing: true,
				type: "error",
				message: "Bill has been successfully revoked"
			});

			setAlert({
				...alert,
				showing: true,
				type: "error",
				message: msg
			});
			setIsDeclineModal(false);
			setDeclineReason("");
			getAssessmentRecords()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		} finally {
			setApproving(true);
		}
		setDeclineReason("");
	};

	const Revoke = async () => {
		setLoading(true);
		try {
			const { msg } = await axios
				.put("processpaye", {
					billno: locationState.BillNumber,
					approve: "4", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval
					reason4Reject: declineReason,
					recId: locationState.id
				})
				.then((res) => res.data);

			setAlert({
				...alert,
				showing: true,
				type: "error",
				message: "Bill has been successfully revoked"
			});
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message: msg
			});
			setDeclineReason("");
			setIsRevokeModal(false);
			getAssessmentRecords()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
			setDeclineReason("");
		} finally {
			setLoading(false);
		}
	};

	const headers = [
		["Staff Name ", "StaffName"],
		["Total Annual Income Per Annum ", "AnnualPay", formatAmount],
		["Total Income Per Month ", "MthPay", formatAmount],
		["CRA N200,000.00 + 20% of 3 ", "CRA", formatAmount],
		["Pension Contribution Per Annum 8% of 3  ", "Pension", formatAmount],
		[
			"NHIS Contribution Per Annum 2.5% of 3 or less  ",
			"NHIS",
			formatAmount
		],
		["NHF Contribution Per Annum 2.5% of 3 or less ", "NHF", formatAmount],
		["Life Assurance Contribution Per Annum ", "Life", formatAmount],
		["Total Relief Sum of 5 to 9 ", "TotRelief", formatAmount],
		["Taxable Income 3 - 10 ", "TaxableIncome", formatAmount],
		["Tax Due Per Annum ", "AnnualTaxDue", formatAmount],
		["Tax Due Per Month ", "MthTaxDue", formatAmount],
		["Development Levy N500.00 per Annum ", "DevLevy", formatAmount]
	];

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<GoBackButton />
			<h2 className={styles.companyDetailsHeader}>Company Details </h2>
			<div className={recordView.employeeDetails}>
				<div>
					<div className={recordView.employeeDetail}>
						<h4 className={recordView.detailsKey}>Company Name:</h4>
						<h4 className={recordView.detailsValue}>
							{locationState.CoyName}
						</h4>
					</div>
					<div className={recordView.employeeDetail}>
						<h4 className={recordView.detailsKey}>ESBN: </h4>
						<h4 className={recordView.detailsValue}>
							{locationState.CoyESBN}
						</h4>
					</div>
					<div className={recordView.employeeDetail}>
						<h4 className={recordView.detailsKey}>
							PAYE Bill Number:
						</h4>
						<h4 className={recordView.detailsValue}>
							{locationState.BillNumber}
						</h4>
					</div>
					<div className={recordView.employeeDetail}>
						<h4 className={recordView.detailsKey}>
							Amount for PAYE Type:
						</h4>
						{locationState.PayeType === "Yearly" ? (
							<h4 className={styles.detailsValue}>
								&#8358;{" "}
								{formatAmount(locationState.totAmtCharged)}
							</h4>
						) : (
							""
						)}
						{locationState.PayeType === "Monthly" ? (
							<h4 className={styles.detailsValue}>
								&#8358;{" "}
								{formatAmount(locationState.MonthlyTypeAmtBill)}
							</h4>
						) : (
							""
						)}

						{locationState.PayeType === "Period" ? (
							<h4 className={styles.detailsValue}>
								&#8358;{" "}
								{formatAmount(locationState.PeriodTypeAmtBill)}
							</h4>
						) : (
							""
						)}
					</div>
				</div>
				<div>
					<div className={recordView.employeeDetail}>
						<h4 className={recordView.detailsKey}>
							Assessment Type:
						</h4>
						<h4 className={recordView.detailsValue}>
							{locationState.PayeType}
						</h4>
					</div>
					{locationState.PayeType === "Monthly" ? (
						<div className={recordView.employeeDetail}>
							<h4 className={recordView.detailsKey}>
								Schedule Period:
							</h4>
							<h4 className={recordView.detailsValue}>
								{locationState.fiscalMth}&nbsp;
								{locationState.fiscalYear}
							</h4>
						</div>
					) : (
						""
					)}
					{locationState.PayeType === "Yearly" ? (
						<div className={recordView.employeeDetail}>
							<h4 className={recordView.detailsKey}>
								Schedule Period:
							</h4>
							<h4 className={recordView.detailsValue}>
								{locationState.fiscalYear}
							</h4>
						</div>
					) : (
						""
					)}
					{locationState.PayeType === "Period" ? (
						<div className={recordView.employeeDetail}>
							<h4 className={recordView.detailsKey}>
								Schedule Period:{" "}
							</h4>
							<h4 className={recordView.detailsValue}>
								{locationState.PayePeriodStartDate}/
								{locationState.PayePeriodEndDate}
							</h4>
						</div>
					) : (
						""
					)}
					<div className={recordView.employeeDetail}>
						<h4 className={recordView.detailsKey}>
							Process Status:{" "}
						</h4>
						<h4
							className={
								recordView[`status-${locationState.ApproveFlg}`]
							}
						>
							{locationState.ProcessStatus}
						</h4>
					</div>
					<div className={recordView.employeeDetail}>
						{locationState.PayeType === "Yearly" ? (
							<>
								<h4 className={recordView.detailsKey}>
									Monthly Due:
								</h4>
								<h4 className={recordView.detailsValue}>
									&#8358;{" "}
									{formatAmount(
										locationState.totMthAmtBilled
									)}
								</h4>
							</>
						) : (
							""
						)}
						{locationState.PayeType === "Period" ? (
							<>
								<h4 className={recordView.detailsKey}>
									Monthly Due:
								</h4>
								<h4 className={recordView.detailsValue}>
									&#8358;{" "}
									{formatAmount(
										locationState.totMthAmtBilled
									)}
								</h4>
							</>
						) : (
							""
						)}
					</div>
					<div className={recordView.employeeDetail}>
						{locationState.PayeType === "Yearly" ? (
							<>
								<h4 className={recordView.detailsKey}>
									Monthly Due:
								</h4>
								<h4 className={recordView.detailsValue}>
									&#8358;{" "}
									{formatAmount(
										locationState.totMthAmtBilled
									)}
								</h4>
							</>
						) : (
							""
						)}
						{locationState.PayeType === "Period" ? (
							<>
								<h4 className={recordView.detailsKey}>
									Monthly Due:
								</h4>
								<h4 className={recordView.detailsValue}>
									&#8358;{" "}
									{formatAmount(
										locationState.totMthAmtBilled
									)}
								</h4>
							</>
						) : (
							""
						)}
					</div>
				</div>
				<div></div>
			</div>
			<h2 className={recordView.companyDetailsHeader}>
				Employee Details{" "}
			</h2>
			<div className={styles1.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getAssessmentRecords}
					/>
				)}
			</div>
			{locationState.ApproveFlg === "5" && (
				<div className={styles.approveRevoke_btn}>
					<button
						className={["secondary__btn", styles.revoke__btn].join(
							" "
						)}
						onClick={() => {
							setDeclineConfirmation(true);
							setApproveConfirmation(false);
						}}
					>
						Decline
					</button>
					<button
						className={["primary__btn", styles.approve__btn].join(
							" "
						)}
						onClick={() => {
							setApproveConfirmation(true);
							setDeclineConfirmation(false);
						}}
					>
						Approve
					</button>
				</div>
			)}

			{approveConfirmation && (
				<div className={styles.declineMiniModal}>
					<p>
						Are you sure you want to{" "}
						<strong style={{ color: "#34AA63" }}>Approve</strong>{" "}
						this PAYE <br /> Filing with &nbsp;
						<strong>{locationState.CoyESBN}</strong> ESBN?
					</p>
					<div className={styles.miniModalBtns}>
						<button
							style={{ backgroundColor: "#ffff", color: "#000" }}
							onClick={() => setApproveConfirmation(false)}
						>
							No
						</button>
						<button
							style={{ backgroundColor: "#d73e3e" }}
							onClick={() => approve()}
						>
							Yes
						</button>
					</div>
				</div>
			)}

			{locationState.ApproveFlg === "3" && (
				<div className={styles.approveRevoke_btn}>
					<button
						className={["secondary__btn", styles.revoke__btn].join(
							" "
						)}
						onClick={() => {
							setRevokeConfirmation(true);
						}}
					>
						Revoke
					</button>
				</div>
			)}

			{declineConfirmation && (
				<div className={styles.declineMiniModal}>
					<p>
						Are you sure you want to{" "}
						<strong style={{ color: "#d73e3e" }}>Decline</strong>{" "}
						this PAYE <br />
						Filing with &nbsp;
						<strong>{locationState.CoyESBN}</strong> ESBN?
					</p>
					<div className={styles.miniModalBtns}>
						<button
							style={{ backgroundColor: "#ffff", color: "#000" }}
							onClick={() => setDeclineConfirmation(false)}
						>
							No
						</button>
						<button
							style={{ backgroundColor: "#d73e3e" }}
							onClick={() => {
								setIsDeclineModal(true);
								setDeclineConfirmation(false);
							}}
						>
							Yes
						</button>
					</div>
				</div>
			)}
			{revokeConfirmation && (
				<div className={styles.revokeMiniModal}>
					<p>
						Are you sure you want to{" "}
						<strong style={{ color: "#d73e3e" }}>Revoke</strong>
						&nbsp;this PAYE <br />
						Filing with&nbsp;
						<strong>{locationState.CoyESBN}</strong> ESBN?
					</p>
					<div className={styles.miniModalBtns}>
						<button
							style={{
								backgroundColor: "#ffff",
								color: "#000"
							}}
							onClick={() => setRevokeConfirmation(false)}
						>
							No
						</button>
						<button
							style={{ backgroundColor: "#d73e3e" }}
							onClick={() => {
								setIsRevokeModal(true);
								setRevokeConfirmation(false);
							}}
						>
							Yes
						</button>
					</div>
				</div>
			)}
			{isDeclineModal && (
				<Modal toggle={setIsDeclineModal} showing={isDeclineModal}>
					<div className={styles.approvalSuccess__badge}>
						<img
							src={XcircleRed}
							alt="close button icon"
							style={{ width: "40px", height: "40px" }}
						/>
						<span>
							<h3>Declined </h3>
						</span>
					</div>
					<div className={styles.content}>
						<p>
							You are about to decline{" "}
							<q>{locationState.CoyName}</q> PAYE Filing, an email
							would <br /> be sent to notify them accordingly.
						</p>
						<label htmlFor="reasonForDecline">
							Reason For Declining{" "}
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							id="reasonForDecline"
							placeholder="write the reason for rejecting this PAYE Assessment"
							type="text"
							value={declineReason}
							onChange={(e) => setDeclineReason(e.target.value)}
						/>
						<div className={styles.continueBtn}>
							<button
								className={[
									"primary__btn",
									styles.contBtn
								].join(" ")}
								onClick={() => {
									Decline();
								}}
							>
								{approving ? "Continue..." : "Continue"}
							</button>
						</div>
					</div>
				</Modal>
			)}
			{isRevokeModal && (
				<Modal toggle={setIsRevokeModal} showing={isRevokeModal}>
					<div className={styles.approvalSuccess__badge}>
						<img
							src={XcircleRed}
							alt="close button icon"
							style={{ width: "40px", height: "40px" }}
						/>
						<span>
							<h3>Revoked</h3>
						</span>
					</div>
					<div className={styles.content}>
						<p>
							You are about to revoke &nbsp;{" "}
							<q>{locationState.CoyName}</q>
							PAYE Filing, an email would <br /> be sent to notify
							them accordingly.
						</p>
						<label htmlFor="reasonForRevoking">
							Reason For Revoking{" "}
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							id="reasonForRevoking"
							placeholder="write the reason for revoking this PAYE Assessment"
							type="text"
							value={declineReason}
							onChange={(e) => setDeclineReason(e.target.value)}
						/>
						<div className={styles.continueBtn}>
							<button
								className={[
									"primary__btn",
									styles.contBtn
								].join(" ")}
								onClick={() => {
									Revoke();
								}}
							>
								{loading ? "Continue..." : "Continue"}
							</button>
						</div>
					</div>
				</Modal>
			)}
		</section>
	);
};

export default AuthView;
