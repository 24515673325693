import React, { useState } from "react";
import { useHistory } from "react-router";
import styles from "../../MDAs/style.module.css";
import RegularModal from "../../../components/Modal/regular";

export const ApprovalModal = ({
	showing = false,
	closeModal = () => {},
	rejectionAction,
	approvalState = "denied",
	enterprise = ""
}) => {
	const [reason, setReason] = useState("");

	const history = useHistory();
	return (
		<RegularModal showing={showing} toggle={(val) => closeModal(val)}>
			{approvalState === "approved" && (
				<p className={styles.revoke__warning}>
					You have successfully approved {enterprise} annual returns
					filing, an email would be sent to notify them accordingly.
				</p>
			)}

			{approvalState === "denied" && (
				<p className={styles.revoke__warning}>
					You have denied {enterprise} annual returns filing, an email
					would be sent to notify them accordingly.
				</p>
			)}

			{approvalState === "denied" && (
				<>
					<div>
						<br />
						<label className={styles.input__label} htmlFor="reason">
							Address
						</label>
						<input
							type="text"
							onChange={(e) => setReason(e.target.value)}
							id="reason"
							placeholder=""
							//  disabled
							value={reason}
						/>
					</div>
				</>
			)}

			<div className={styles.revoke__btns}>
				<button
					onClick={() => {
						approvalState === "denied"
							? rejectionAction(reason)
							: closeModal(!showing);
						history.goBack();
					}}
					className="primary__btn"
					// disabled={}
				>
					Continue
				</button>
			</div>
		</RegularModal>
	);
};
