import React, { useState, useEffect } from "react";
import style from "./style.module.css";
import { useHistory, useLocation } from "react-router-dom";
import anssidStyles from "../../StateTIN/style.module.css";
import downloadIcon from "../../../assets/downloadIcon.svg";
import PAYEAssessmentFormat from "../../../assets/PAYE_format_Template.xlsx";
import * as XLSX from "xlsx";
import Table from "../../../components/Table";
import successCheck from "../../../assets/Certificate/approvedCheck.svg";
import statusNotCheck from "../../../assets/x-circle-red.svg";
import axios from "axios";
import Alert from "../../../components/Alert";
import styles1 from "../../MDAs/style.module.css";
import Modal from "../../../components/Modal/regular";
import closeBlackCircleBtn from "../../../assets/x-circle-black.svg";
import { formatAmount } from "../../../utils";
import GoBackButton from "../../../components/goBackButton";

const PAYEAssessment = () => {
	const history = useHistory();
	const location = useLocation();
	const inputterInfo = JSON.parse(localStorage.getItem("user_info"));
	const {
		corpESBN,
		typeOfAssessment,
		selectedYear,
		selectedMonth,
		periodicStartDate,
		periodicEndDate,
		retrieveESBNdetails,
		mode
	} = location.state || {};

	const [excelData, setExcelData] = useState([]);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const [loading, setLoading] = useState(false);
	const [payload, setPayload] = useState(null);
	const [
		successPAYEassessmentModalShowing,
		setSuccessPAYEassessmentModalShowing
	] = useState(false);
	const [HTMLGeneratedBill, setHTMLGeneratedBill] = useState(false);
	const [htmlContent, setHtmlContent] = useState(null);
	const [invalidESBN, setInvalidESBN] = useState(false);
	const [generatingBill, setGeneratingBill] = useState(false);
	const [billGenerated, setBillGenerated] = useState(false);
	const [excelDoc, setExcelDoc] = useState("");

	const checkESBNStatus = async (esbn) => {
		try {
			if (esbn === "N/A") {
				return { status: "Unknown" };
			}

			const response = await axios.get(`/users/anssid?anssid=${esbn}`);

			if (
				response.data &&
				response.data.data &&
				response.data.data.anssid
			) {
				const data = response.data.data;

				const hasNameDetails =
					data.firstname && data.middlename && data.surname;

				if (!hasNameDetails) {
					setInvalidESBN(true);
					return {
						status: "NameNotFound",
						fullName: "Name not available"
					};
				}

				const { firstname = "", middlename = "", surname = "" } = data;
				const fullName = `${firstname} ${middlename} ${surname}`.trim();
				return {
					status: "Valid",
					fullName
				};
			}

			setInvalidESBN(true);
			return {
				status: "WrongESBN",
				fullName: "N/A"
			};
		} catch (error) {
			console.error("Error fetching ESBN data:", error);
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message:
					"There is an invalid ESBN in the file uploaded or a network issue."
			});

			setInvalidESBN(true);
			return {
				status: "WrongESBN",
				fullName: "N/A"
			};
		}
	};

	const renderStatus = (status) => {
		if (status === "Valid") {
			return (
				<div className={style.statusCheck}>
					<img src={successCheck} alt="Success Check Icon" />
					<p>ValidESBN</p>
				</div>
			);
		} else if (status === "WrongESBN") {
			return (
				<div className={style.statusCheck}>
					<img src={statusNotCheck} alt="Wrong Check Icon" />
					<p>WrongESBN</p>
				</div>
			);
		} else if (status === "NameNotFound") {
			return (
				<div className={style.statusCheck}>
					<p>Name Not Available</p>
				</div>
			);
		} else {
			return (
				<div className={style.statusCheck}>
					<p>Unknown</p>
				</div>
			);
		}
	};

	const downloadFormat = () => {
		const element = document.createElement("a");
		element.href = PAYEAssessmentFormat;
		element.download = "PAYE format";

		element.click();
	};

	const handleFileDisplay = (e) => {
		const file = e.target.files[0];

		if (file) {
			setExcelDoc(file.name);
			const reader = new FileReader();
			reader.onload = async (e) => {
				const fileContent = e.target.result;

				// Parse the Excel file
				const workbook = XLSX.read(fileContent, { type: "array" });
				const firstSheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[firstSheetName];

				// Convert sheet to JSON
				const jsonData = XLSX.utils.sheet_to_json(worksheet, {
					header: 1
				});

				// Transform data to match table structure
				const { transformDataForApi, transformDataForTable } =
					await transformExcelDataToTableFormat(jsonData);

				let hasMissingESBN = transformDataForTable.some(
					(row) => row["ESBN"] === "N/A"
				);
				if (hasMissingESBN) {
					setInvalidESBN(true);
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							"There is an empty or invalid ESBN. Please correct it."
					});
				} else {
					setInvalidESBN(false);
					setAlert({ ...alert, showing: false });
				}
				setExcelData(transformDataForTable);

				window.scroll({
					top: document.body.scrollHeight,
					left: 0,
					behavior: "smooth"
				});
				setPayload(preparePayload(transformDataForApi));
			};

			reader.readAsArrayBuffer(file);
		}
	};

	const transformExcelDataToTableFormat = async (data) => {
		setLoading(true);
		window.scroll({
			top: document.body.scrollHeight,
			left: 0,
			behavior: "smooth"
		});
		if (data.length === 0) return [];

		const headerRowIndex = 7;
		const headers = data[headerRowIndex];

		const rows = data.slice(headerRowIndex + 1);

		// Transform data for the table
		const transformExcelToTable = await Promise.all(
			rows.map(async (row) => {
				const rowObject = {};
				headers.forEach((header, index) => {
					if (header === "ESBN") {
						rowObject[header] = row[index] || "N/A";
					} else if (header === "NAME *") {
						rowObject[header] = row[index] || "N/A";
					} else {
						rowObject[header] = row[index] || 0;
					}
				});
				const esbn = rowObject["ESBN"];

				const { status, fullName } = await checkESBNStatus(esbn);

				if (status === "Valid") {
					rowObject["NAME *"] = fullName;
				}
				rowObject["STATUS"] = renderStatus(status);
				return rowObject;
			})
		);

		// Transform data for the API
		const transformDataForApi = transformExcelToTable.map((rowObject) => {
			return {
				SN: rowObject["SN"],
				EMPLOYEE_TIN: rowObject["ESBN"],
				NAME: rowObject["NAME *"],
				TOTALINCOME:
					parseFloat(
						rowObject["ANNUAL TOTAL INCOME * (Eg 20,000.56)"]
					) || 0,
				PENSION: parseFloat(rowObject["PENSION"]) || 0,
				NHIS: parseFloat(rowObject["NHIS"]) || 0,
				NHF: parseFloat(rowObject["NHF"]) || 0,
				LIFE: parseFloat(rowObject["LIFE ASSURANCE"]) || 0
			};
		});
		setLoading(false);
		return {
			transformDataForTable: transformExcelToTable,
			transformDataForApi
		};
	};

	const preparePayload = (transformedData) => ({
		coyname: retrieveESBNdetails.name,
		esbn: retrieveESBNdetails.anssid || corpESBN,
		paytype: typeOfAssessment,
		year: selectedYear,
		mth: selectedMonth,
		payperiodstartdate: periodicStartDate ? periodicStartDate : null,
		payperiodenddate: periodicEndDate ? periodicEndDate : null,
		approve: "0",
		mode: mode,
		inputter: inputterInfo.email,
		coystaff: transformedData
	});

	const sendEmployeeDetails = async (payload) => {
		setGeneratingBill(true);
		try {
			const response = await axios
				.post("/tmspaye", payload)
				.then((res) => res.data || {});

			setSuccessPAYEassessmentModalShowing(true);
			setGeneratingBill(false);

			return setHtmlContent(response.html);
		} catch (error) {
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message:
					error.response.data?.msg ||
					error.response.data?.message ||
					error.response.data?.errors?.[0] ||
					error.response.data?.errors?.[0]?.message ||
					error.response.data?.errors?.details[0]?.message ||
					error.response.message
			});
			setSuccessPAYEassessmentModalShowing(false);
		}
		setGeneratingBill(false);
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const tableHeaders = [
		["SN", "SN"],
		["NAME", "NAME *"],
		["ESBN", "ESBN"],
		[
			"ANNUAL TOTAL INCOME",
			"ANNUAL TOTAL INCOME * (Eg 20,000.56)",
			formatAmount
		],
		["PENSION", "PENSION ", formatAmount],
		["NHF", "NHF", formatAmount],
		["NHIS", "NHIS", formatAmount],
		["LIFE ASSURANCE", "LIFE ASSURANCE", formatAmount],
		["STATUS", "STATUS"]
	];
	return (
		<main>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<GoBackButton />
			<div className={style.Dpage}>
				<h3 className={style.assessmentType}>
					File PAYE <q>{typeOfAssessment}</q>
				</h3>
				<hr />
				<section className={style.instruction1}>
					<ul>
						<li>
							This form allows you download the Schedule Excel/CSV
							Template.
						</li>
						<li>
							Fill in the details of your employee enrollments and
							upload the Excel/CSV to start the process of your
							Annual returns.
						</li>
						<li>
							Note that a maximum of 2,000 records are allowed per
							Excel/CSV batch.
						</li>
						<li>
							Make sure your internet connection is fast and
							reliable to avoid disruption.
						</li>
						<li>
							REMEMBER TO FILL ALL COLUMNS THAT ARE COMPULSORY OR
							THE UPLOADS FAILS.
						</li>
						<li>
							Email{" "}
							<a href="mailto:info@irs.en.gov.ng">
								info@irs.en.gov.ng
							</a>{" "}
							if you have an issue.
						</li>
					</ul>
				</section>
				<section className={style.esbnDetals}>
					<div className={style.columns}>
						<div>
							<h4>
								Company Name{" "}
								<span style={{ color: "red" }}>*</span>
							</h4>
							<p>{retrieveESBNdetails.name}</p>
						</div>
						<div>
							<h4>Contact Email Address </h4>
							<p>{retrieveESBNdetails.email} </p>
						</div>
						<div>
							<h4>Type of filing </h4>
							<p>{typeOfAssessment} </p>
						</div>
						{typeOfAssessment === "periodic" && (
							<div>
								<h4>End Date</h4>
								<p>{periodicEndDate}</p>
							</div>
						)}
					</div>
					<div className={style.columns}>
						<div>
							<h4>
								ESBN <span style={{ color: "red" }}>*</span>
							</h4>
							<p>{corpESBN}</p>
						</div>
						<div>
							<h4>Contact Phone </h4>
							<p>{retrieveESBNdetails.phoneNumber}</p>
						</div>
						<div>
							<h4>Sector </h4>
							<p>{retrieveESBNdetails.economicSector}</p>
						</div>
					</div>
					<div className={style.columns}>
						<div>
							<h4>
								ADDRESS <span style={{ color: "red" }}>*</span>
							</h4>
							<p>{retrieveESBNdetails.headOfficeAddress}</p>
						</div>

						<div>
							<h4>Filing Year</h4>
							<p>{selectedYear}</p>
						</div>

						{typeOfAssessment === "Monthly" && (
							<div>
								<h4>Month of Filling</h4>
								<p>{selectedMonth}</p>
							</div>
						)}

						{typeOfAssessment === "Period" && (
							<div>
								<h4>Start Date </h4>
								<p>
									{periodicStartDate}/{periodicEndDate}
								</p>
							</div>
						)}
					</div>
				</section>
				<section className={style.downloadUploadSection}>
					<div>
						<h3>Download excel template to SCHEDULE PAYE</h3>
						<button
							className={`primary__btn ${anssidStyles.create__btn}`}
							onClick={() => downloadFormat()}
						>
							<img src={downloadIcon} alt="download Icon" />
							<span style={{ marginLeft: "12px" }}>
								Download Template
							</span>
						</button>
					</div>
					<div className={style.uploadArea}>
						<h3>
							Upload Excel Sheet (Use Template Provided){" "}
							<span style={{ color: "red" }}>*</span>
						</h3>
						<button
							className={`primary__btn ${anssidStyles.create__btn}`}
							onClick={(e) => {
								e.preventDefault();
								document.getElementById("excelData").click();
							}}
						>
							Choose File
						</button>
						<input
							id="excelData"
							type="file"
							accept=".xlsx, .xls"
							onChange={handleFileDisplay}
							style={{ display: "none" }}
						/>
						{excelDoc && <span>{excelDoc}</span>}
					</div>
				</section>
				<section className={style.instruction2}>
					<ul>
						<li>
							please kindly request all your Employee to obtain
							their esbn from{" "}
							<a
								href="https://irs.en.gov.ng/signup"
								target="_blank"
								rel="noopener noreferrer"
								style={{ textTransform: "lowercase" }}
							>
								https://irs.en.gov.ng/signup
							</a>
						</li>
						<li>
							Basic transport, rent are compulsory to fill in the
							template.
						</li>
						<li>
							Note that supporting documents would be required if
							annual nhis, nhf & pension have values
						</li>
					</ul>
				</section>

				{successPAYEassessmentModalShowing && (
					<Modal
						toggle={setSuccessPAYEassessmentModalShowing}
						showing={successPAYEassessmentModalShowing}
					>
						<div className={style.approvalSuccess__badge}>
							<div className={style.successSection}>
								<img
									src={successCheck}
									alt="circle with a tick check inside"
								/>
								<h3>SUCCESS!</h3>
							</div>
							<button
								onClick={() =>
									setSuccessPAYEassessmentModalShowing(false)
								}
								style={{
									background: "none",
									border: "none",
									padding: 0,
									cursor: "pointer"
								}}
								aria-label="Close"
							>
								<img
									src={closeBlackCircleBtn}
									alt="Close button Icon"
								/>
							</button>
						</div>
						<div className={style.modalContents}>
							{typeOfAssessment === "Monthly" && (
								<p className={style.success_message}>
									You have successfully Generated a bill
									<br /> for PAYE Assessment for <br />
									<q>{retrieveESBNdetails.name}</q>
									<br />
									for the month of &nbsp;{selectedMonth}.
								</p>
							)}
							{typeOfAssessment === "Period" && (
								<p className={style.success_message}>
									You have successfully Generated a bill
									<br /> for PAYE Assessment for <br />
									<q>{retrieveESBNdetails.name}</q>
									<br />
									for the period of {periodicStartDate} to
									&nbsp;{periodicEndDate}.
								</p>
							)}
							{typeOfAssessment === "Yearly" && (
								<p className={style.success_message}>
									You have successfully Generated a bill
									<br /> for PAYE Assessment for <br />
									<q>{retrieveESBNdetails.name}</q>
									<br />
									for the year {selectedYear}.
								</p>
							)}
							<div className={style.modalBtn}>
								<button
									className={`primary__btn ${style.continue__btn}`}
									onClick={() => {
										setSuccessPAYEassessmentModalShowing(
											false
										);
										setHTMLGeneratedBill(true);
										setBillGenerated(true);
									}}
								>
									View Generated Bill
								</button>
							</div>
						</div>
					</Modal>
				)}

				{HTMLGeneratedBill && (
					<>
						<div></div>
						<Modal
							toggle={setHTMLGeneratedBill}
							showing={HTMLGeneratedBill}
						>
							<div className={style.HTMLGeneratedBill}>
								<button
									onClick={() => {
										setHTMLGeneratedBill(false);
									}}
									style={{
										background: "none",
										border: "none",
										padding: 0,
										cursor: "pointer"
									}}
									aria-label="Close"
								>
									<img
										src={closeBlackCircleBtn}
										alt="Close button Icon"
									/>
								</button>
							</div>

							<div
								dangerouslySetInnerHTML={{
									__html: htmlContent
								}}
								style={{
									padding: "20px"
								}}
							></div>
						</Modal>
					</>
				)}
			</div>{" "}
			{loading ? (
				<div
					style={{
						fontSize: "24px",
						display: "flex",
						justifyContent: "center",
						marginTop: "20px"
					}}
				>
					Processing...
				</div>
			) : (
				<div>
					{excelData.length > 0 && (
						<>
							<p className={style.employeeReviewHeader}>
								Employee entries preview
							</p>
							<div className={styles1.table__div}>
								<Table
									headers={tableHeaders}
									data={excelData}
									pageSize={20}
									full
								/>
							</div>
							<div className={style.employeeDataBtn}>
								<button
									className={` ${style.exitBtn}`}
									value="download"
									onClick={() =>
										history.push("/PAYE-Assessment")
									}
								>
									Exit
								</button>
								<button
									className={`primary__btn ${anssidStyles.create__btn}`}
									onClick={() => {
										sendEmployeeDetails(payload);
									}}
									disabled={
										invalidESBN ||
										generatingBill ||
										billGenerated
									}
									style={{
										color: billGenerated ? "#34AA63" : "",
										fontWeight: "bolder"
									}}
								>
									{billGenerated
										? "Generated"
										: generatingBill
											? "Generating..."
											: "Generate Bill"}
								</button>
							</div>
						</>
					)}
				</div>
			)}
		</main>
	);
};

export default PAYEAssessment;
