import axios from "axios";
import { useCallback, useEffect, useState } from "react"

export const useFetchIncome = ({
    route
}) => {
    const [data, setData] = useState(null);

    const handleGETRequestHandler = useCallback(async (route) => {
        const res = await axios.get(route)
            .then((res) => (res.data || {}).data);

        setData(res)
    }, [])

    useEffect(() => {
        if (route?.length) {
            handleGETRequestHandler(route);
        } else {
            return
        }
    }, [route, handleGETRequestHandler])

    return [{ data, loading: data === null }]
}

export const useAddHNI = ({
    route
}) => {
    const [data, setData] = useState(null);
    const [hookData, setHookData] = useState({
        route: '',
        payload: null
    });

    const handlePOSTRequestHandler = useCallback(async () => {
        const res = await axios
            .post(hookData?.route, hookData?.payload)
            .then((res) => (res.data || {}).data);

        setData(res)
    }, [hookData])

    useEffect(() => {
        if (hookData?.payload) {
            handlePOSTRequestHandler(hookData?.route);
        }
    }, [route, hookData, handlePOSTRequestHandler])

    return [{ data, hookData, setHookData }]
}

export const useSetupPOSTRequests = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState({
        state: null,
        message: ''
    });
    const [hookData, setHookData] = useState({
        route: '',
        method: '',
        payload: null,
        service: ''
    });

    const handlePUTRequestHandler = useCallback(async () => {
        setLoading(true)
        try {
            const res = await axios
                .put(hookData?.route, hookData?.payload)
                .then((res) => (res.data || {}).data);
            setData(res);
            setStatus({
                state: 'success',
                message: res?.msg ?? res?.data?.msg ?? "Successful!"
            })
        } catch (error) {
            setStatus({
                state: 'error',
                message: error.response.data?.msg ||
                    error.response.data?.errors?.[0] ||
                    error.response.data?.errors?.[0]?.message ||
                    error.response.data?.errors?.details[0]?.message ||
                    error.response.message ||
                    error.response.data.message
            })
        } finally {
            setLoading(false)
        }
    }, [hookData])

    const handlePOSTRequestHandler = useCallback(async () => {
        setLoading(true)
        try {
            const res = await axios
                .post(hookData?.route, hookData?.payload)
                .then((res) => (res.data || {}).data);
            setData(res);
            setStatus({
                state: 'success',
                message: res?.msg ?? res?.data?.msg ?? "Successful!"
            })
        } catch (error) {
            setStatus({
                state: 'error',
                message: error.response.data?.msg ||
                    error.response.data?.errors?.[0] ||
                    error.response.data?.errors?.[0]?.message ||
                    error.response.data?.errors?.details[0]?.message ||
                    error.response.message ||
                    error.response.data.message
            })
        } finally {
            setLoading(false)
        }
    }, [hookData])

    useEffect(() => {
        if (hookData?.payload && hookData?.route) {
            if (hookData?.method === 'PUT') {
                handlePUTRequestHandler(hookData?.route);
            } else {
                handlePOSTRequestHandler(hookData?.route)
            }
        }
    }, [
        handlePUTRequestHandler,
        handlePOSTRequestHandler,
        hookData
    ])

    return { data, loading, service: hookData?.service, status, setHookData }
}


export const useSetupGETRequests = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(null);
    const [hookData, setHookData] = useState({
        route: '',
        service: ''
    });
    const [status, setStatus] = useState({
        state: null,
        message: ''
    });

    const handleGETRequestHandler = useCallback(async () => {
        setLoading(true)
        try {
            const res = await axios
                .get(hookData?.route)
                .then((res) => (res.data || {}).data)
            setStatus({
                state: null,
                message: ''
            })
            setData(res)
        } catch (error) {
            setStatus({
                state: 'error',
                message: error.response.data?.msg ||
                    error.response.data?.errors?.[0] ||
                    error.response.data?.errors?.[0]?.message ||
                    error.response.data?.errors?.details[0]?.message ||
                    error.response.message ||
                    error.response.data.message
            })
        } finally {
            setLoading(false)
        }

    }, [hookData])


    useEffect(() => {
        if (hookData?.route) {
            handleGETRequestHandler();
        }
    }, [handleGETRequestHandler, hookData])

    return { data, loading, status, service: hookData?.service, setHookData }

}

export const getDate = (separator = '/') => {
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${year}${separator}${month}${separator}${day}`;
    return currentDate; // "2022/6/17"
}