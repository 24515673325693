import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import Spinner from '../../components/spinner';
import styles from '../../Identity-management-individual/style.module.css';
import axios from 'axios';

const CreateJTB = () => {
  const [values, setValues] = useState({
    jtbTin: "",
    tccNo: "",
    taxPeriod: "",
    turnOver: 0,
    accessibleProfit: 0,
    totalProfit: 0,
    taxPayable: 0,
    taxPaid: 0,
    taxAuthority: "",
    taxOffice: ""
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  function handleChange(e) {
    const name = e.target.id || e.target.name;
    const value = e.target.value;

    setValues({
      ...values,
      [name]: value
    });
  }

  const submitJTBData = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const accessToken = localStorage.getItem("access_token");
      await axios.post(`/admin/jtb`, {
        ...values
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(res => res.data);

    } catch (err) {
      console.error(err);
      setError("MDA could not be created. Please verify your details and try again.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <form className={styles.columned__form} onSubmit={submitJTBData}>
      {error && <div className="error">{error}</div>}
      <div className={styles.double__inputs}>
        <div>
          <label className={styles.input__label} htmlFor="jtbTin">JTB TIN *</label>
          <input
            type="text"
            onChange={handleChange}
            id="jtbTin"
            placeholder=""
            required
            value={values.jtbTin} />
        </div>
        <div>
          <label className={styles.input__label} htmlFor="tccNo">TCC no *</label>
          <input
            type="text"
            onChange={handleChange}
            id="tccNo"
            placeholder=""
            required
            value={values.tccNo} />
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.double__inputs}>
        <div>
          <label className={styles.input__label} htmlFor="taxPeriod">Tax Period *</label>
          <DatePicker
            selected={values.taxPeriod}
            dateFormat="dd/MM/yyyy"
            id="taxPeriod"
            name="taxPeriod"
            className={styles.input__short}
            required
            onChange={(date) => {
              setValues({
                ...values,
                taxPeriod: date
              });
            }} />
        </div>
        <div>
          <label className={styles.input__label} htmlFor="turnOver">Turnover *</label>
          <input
            type="number"
            onChange={handleChange}
            id="turnOver"
            placeholder=""
            required
            min={0}
            value={values.turnOver} />
        </div>
      </div>
      <div className={styles.double__inputs}>
        <div>
          <label className={styles.input__label} htmlFor="accessibleProfit">Accessible Profit *</label>
          <input
            type="number"
            onChange={handleChange}
            id="accessibleProfit"
            placeholder=""
            required
            min={0}
            value={values.accessibleProfit} />
        </div>
        <div>
          <label className={styles.input__label} htmlFor="totalProfit">Total Profit *</label>
          <input
            type="number"
            onChange={handleChange}
            id="totalProfit"
            placeholder=""
            required
            min={0}
            value={values.totalProfit} />
        </div>
      </div>
      <div className={styles.double__inputs}>
        <div>
          <label className={styles.input__label} htmlFor="taxPayable">Tax Payable *</label>
          <input
            type="number"
            onChange={handleChange}
            id="taxPayable"
            placeholder=""
            required
            min={0}
            value={values.taxPayable} />
        </div>
        <div>
          <label className={styles.input__label} htmlFor="taxPaid">Tax Paid *</label>
          <input
            type="number"
            onChange={handleChange}
            id="taxPaid"
            placeholder=""
            required
            min={0}
            value={values.taxPaid} />
        </div>
      </div>
      <div className={styles.double__inputs}>
        <div>
          <label className={styles.input__label} htmlFor="taxAuthority">Tax Authority *</label>
          <input
            type="text"
            onChange={handleChange}
            id="taxAuthority"
            placeholder=""
            required
            value={values.taxAuthority} />
        </div>
        <div>
          <label className={styles.input__label} htmlFor="taxOffice">Tax Office *</label>
          <input
            type="text"
            onChange={handleChange}
            id="taxOffice"
            placeholder=""
            required
            value={values.taxOffice} />
        </div>
      </div>
      <footer className={styles.form__footer}>
        <button
          disabled={loading}
          type="submit"
          className={[styles.primary__button, loading ? styles.loading__button : ""].join(" ")}>
          Save
        {loading && (<Spinner />)}
        </button>
      </footer>
    </form>
  )
};

export default CreateJTB;
