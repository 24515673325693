import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styles from "./style.module.css";
import downloadIcon from "../../assets/downloadFilingIcon.svg";
import Table from "../../components/Table";
import { ApprovalModal } from "./modals/approvalModal";
import RegularModal from "../../components/Modal/regular";
import { H1Document } from "./modals/h1Document";
import Alert from "../../components/Alert";

const headers = [
	["Employee Name", "fullName"],
	["Months in Service", "monthsInService"],
	["Employee ESBN", "eSBN"],
	["Total Gross Salary", "annualGrossSalary"],
	["Total Reliefs (₦)", "annualCalcTotalRelief"],
	["Annual Tax Due (₦)", "annualCalcTaxDue"]
];

const remitanceTableHeaders = [
	["Receipt no.", "receiptNo"],
	["Bill Number", "billNumber"],
	["Issued To", "issuedTo"],
	["Amount (₦)", "amount"],
	["Payment Date", "paymentDate"],
	["Revenue Source", "revenueSource"]
];

export const ViewFiling = () => {
	const [showModal, setShowModal] = useState({
		modalState: false,
		approvalState: ""
	});
	const [H1File, setH1File] = useState([]);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const [downloadH1ModalStatus, setDownloadH1ModalStatus] = useState({
		downloading: false,
		showing: false,
		data: null
	});

	const totalTaxDue =
		downloadH1ModalStatus?.data?.data?.EmployeeDetails?.reduce(
			(accumulator, currentValue) =>
				accumulator + Number(currentValue.annualCalcTaxDue),
			0
		);

	const totalAmountPaid = 0;

	const { state } = useLocation();
	const { coy, corporateCompanyID } = state;

	const approveAnnualReturns = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			let data = await axios
				.put(`company/annual-returns/${coy}/approve`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => res.data || { data: {} });

			if (!data || !data?.data) {
				return null;
			}
			if (data) {
				setShowModal({
					modalState: true,
					approvalState: "approved"
				});
			}
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response?.data?.message ?? "Approval Unsuccessful!"
			});
		}
	};

	const rejectAnnualReturns = async (rejectionReason) => {
		try {
			const accessToken = localStorage.getItem("access_token");
			let data = await axios
				.put(
					`company/annual-returns/${coy}/reject`,
					{
						reason: rejectionReason
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data || { data: {} });

			if (!data || !data?.data) {
				return null;
			}
			if (data) {
				setAlert({
					showing: true,
					type: "success",
					message: data.message
				});
				setShowModal({
					...showModal,
					modalState: false
				});
			}
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response?.data?.message ?? "Rejection Unsuccessful!"
			});
		}
	};

	const getH1 = async (coy, corporateCompanyID) => {
		try {
			const accessToken = localStorage.getItem("access_token");
			let data = await axios
				.get(
					`corporate/company/annual-returns/${coy}/${corporateCompanyID}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data || { data: {} });

			if (!data || !data?.data) {
				return null;
			}
			if (data) {
				setDownloadH1ModalStatus((h1Modal) => ({
					...h1Modal,
					data: data
				}));
			}
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response?.data?.message ??
					"H1 Data Fetching Unsuccessful!"
			});
		}
	};

	const printH1File = () => {
		if (H1File) {
			const w = window.open();
			if (w.document) {
				w.document.write(H1File);
				w.document.close();
				w.onload = () => {
					w.focus();
					w.print();
				};

				w.onafterprint = () => {
					w.close();
				};
			} else {
				return;
			}
		} else {
			return;
		}
	};

	useEffect(() => {
		if (coy && corporateCompanyID) {
			getH1(coy, corporateCompanyID);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<>
			<div className={styles.view__filing__wrapper}>
				{alert.showing && (
					<Alert text={alert.message} type={alert.type} />
				)}
				{downloadH1ModalStatus.showing && (
					<RegularModal showing={downloadH1ModalStatus.showing}>
						<H1Document
							data={downloadH1ModalStatus?.data}
							getFile={(file) => setH1File(file)}
						/>
						<div className={styles.action__btns}>
							<button
								className={`btn btn-danger`}
								onClick={() =>
									setDownloadH1ModalStatus((h1Modal) => ({
										...h1Modal,
										showing: false,
										data: {}
									}))
								}
							>
								<p>Close Modal</p>
							</button>
							<button
								className={`btn btn-success ${styles.download__btn}`}
								onClick={() => printH1File()}
							>
								<p>Download H1 Details</p>
								<img src={downloadIcon} alt="download" />
							</button>
						</div>
					</RegularModal>
				)}
				{showModal.modalState && (
					<ApprovalModal
						showing={showModal.modalState}
						closeModal={(value) =>
							setShowModal({
								modalState: value,
								approvalState: ""
							})
						}
						rejectionAction={(reason) =>
							rejectAnnualReturns(reason)
						}
						approvalState={showModal.approvalState}
					/>
				)}
				<div className={styles.view__filing__header}>
					<p className={styles.header__title}>
						Details for:{" "}
						{
							downloadH1ModalStatus?.data?.data?.CompanyDetails
								?.name
						}
					</p>
					<button
						className={`btn btn-success ${styles.download__btn}`}
						onClick={() =>
							setDownloadH1ModalStatus((h1Modal) => ({
								...h1Modal,
								showing: true
							}))
						}
					>
						<img src={downloadIcon} alt="download" />
						<p>Download H1 Details</p>
					</button>
				</div>
				<hr />

				<div>
					<div className={`bg-warning ${styles.complaint}`}>
						<p>
							TODAY'S DATE IS {new Date().toLocaleDateString()}.{" "}
							{downloadH1ModalStatus?.data?.data?.CompanyDetails?.name.toUpperCase()}{" "}
							IS COMPLAINT:
						</p>
						<p>
							<strong>
								BELOW ARE THE DETAILS OF RELEVANT DOCUMENTS
							</strong>
						</p>
					</div>
				</div>

				<div className={`row ${styles.h1__deets}`}>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Company Name{" "}
								<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>
							{
								downloadH1ModalStatus?.data?.data
									?.CompanyDetails?.name
							}
						</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								ESBN<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>{downloadH1ModalStatus?.data?.data?.esbn}</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Address<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>
							{
								downloadH1ModalStatus?.data?.data
									?.CompanyDetails?.state_Office_Address
							}
						</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Contact Email Address
								<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>
							{
								downloadH1ModalStatus?.data?.data
									?.CompanyDetails?.email_Address
							}
						</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Contact Phone
								<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>
							{
								downloadH1ModalStatus?.data?.data
									?.CompanyDetails?.phone_Number
							}
						</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Sector<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>
							{
								downloadH1ModalStatus?.data?.data
									?.CompanyDetails?.business_Category
							}
						</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Filing Year
								<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>
							{
								downloadH1ModalStatus?.data?.data?.signature
									?.dateFiled
							}
						</p>
					</div>
				</div>

				<hr />

				{/* <div className={styles.view__filing__total}>
					<p>Total Gross Salary: ₦1,000,000.00</p>
					<p>Total Gross Salary: ₦1,000,000.00</p>
					<p>Total Gross Salary: ₦1,000,000.00</p>
					<p>Total Gross Salary: ₦1,000,000.00</p>
				</div> */}

				{/* <button
					className={`btn btn-success ${styles.download__btn} ${styles.employee__button}`}
					onClick={() => { }}
				>
					<img src={downloadIcon} alt="download" />
					<p>Download Employee Details</p>
				</button> */}
				<div className="table-responsive">
					<br />
					<h5 className={styles.entries__title}>
						Employee entries preview
					</h5>

					<Table
						headers={headers}
						data={
							downloadH1ModalStatus?.data?.data
								?.EmployeeDetails || []
						}
						full
						pageSize={20}
						total={
							downloadH1ModalStatus?.data?.data?.EmployeeDetails
								?.length
						}
					/>
				</div>

				<br />

				<div className={`row ${styles.entries__deets}`}>
					<div className={`col-md-3`}>
						<img
							className={styles.entries__title__signature}
							src={
								downloadH1ModalStatus?.data?.data?.signature
									?.signature
							}
							alt="Signature"
						/>
						<p>Signature</p>
					</div>
					<div className={`col-md-3`}>
						<p>
							<strong>
								{
									downloadH1ModalStatus?.data?.data?.signature
										?.name
								}
							</strong>
						</p>
						<p>Name</p>
					</div>
					<div className={`col-md-3`}>
						<p>
							<strong>
								{
									downloadH1ModalStatus?.data?.data?.signature
										?.designation
								}
							</strong>
						</p>
						<p>Designation</p>
					</div>
					<div className={`col-md-3`}>
						<p>
							<strong>
								{
									downloadH1ModalStatus?.data?.data?.signature
										?.dateFiled
								}
							</strong>
						</p>
						<p>Date Filed</p>
					</div>
				</div>
				<br />
				<br />
				<br />
				{downloadH1ModalStatus?.data?.data?.billNumbers?.length && (
					<>
						<h5 className={styles.entries__title}>
							Bill Number for each remittance made in{" "}
							{
								downloadH1ModalStatus?.data?.data?.signature
									?.dateFiled
							}
						</h5>
						<br />

						<Table
							headers={remitanceTableHeaders}
							data={
								downloadH1ModalStatus?.data?.data?.billNumbers
							}
							full
							pageSize={20}
							total={
								downloadH1ModalStatus?.data?.data?.billNumbers
									?.length
							}
						/>
						<br />
						<hr />
						<br />
					</>
				)}

				<h5 className={styles.entries__title}>VARIANCE</h5>
				<br />

				<div className={`row ${styles.variants__deets}`}>
					<div className={`col-md-4`}>
						<p>
							<strong>Total Payment</strong>
						</p>
						<p>₦{totalAmountPaid}</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>Total Tax Due</strong>
						</p>
						<p>₦{totalTaxDue}</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>Variance</strong>
						</p>
						<p>₦{totalTaxDue - totalAmountPaid}</p>
					</div>
				</div>
				<br />
				<hr />

				<br />
				<h5 className={styles.entries__title}>
					SUPPORTING DOCUMENTS ATTACHED
				</h5>

				<div className={`bg-warning ${styles.py__2}`}>
					<small>
						Click on the file name to view or download document
					</small>
				</div>
				<br />
				<div className={`row ${styles.variants__deets}`}>
					{downloadH1ModalStatus?.data?.data?.docs?.slice(0, 2) &&
						downloadH1ModalStatus?.data?.data?.docs
							?.slice(0, 2)
							?.map((doc) => (
								<Fragment key={doc?.id}>
									<div className={`col-md-5`}>
										<p>
											<strong>
												Payment Receipt:{" "}
												{doc?.description} (Max: 1mb){" "}
												<span style={{ color: "red" }}>
													*
												</span>
											</strong>
										</p>
										<a href={doc?.data} download>
											File.pdf
										</a>
									</div>
								</Fragment>
							))}
				</div>

				<div className={`row ${styles.variants__deets}`}>
					{downloadH1ModalStatus?.data?.data?.docs?.slice(2, 4) &&
						downloadH1ModalStatus?.data?.data?.docs
							?.slice(2, 4)
							?.map((doc) => (
								<Fragment key={doc?.id}>
									<div className={`col-md-5`}>
										<p>
											<strong>
												Payment Receipt:{" "}
												{doc?.description} (Max: 1mb){" "}
												<span style={{ color: "red" }}>
													*
												</span>
											</strong>
										</p>
										<a href={doc?.data} download>
											File.pdf
										</a>
									</div>
								</Fragment>
							))}
				</div>
				{downloadH1ModalStatus?.data?.data?.docs?.length === 5 && (
					<div className={`row ${styles.variants__deets}`}>
						{downloadH1ModalStatus?.data?.data?.docs?.slice(4, 5) &&
							downloadH1ModalStatus?.data?.data?.docs
								?.slice(4, 5)
								?.map((doc) => (
									<Fragment key={doc?.id}>
										<div className={`col-md-5`}>
											<p>
												<strong>
													Payment Receipt:{" "}
													{doc?.description} (Max:
													1mb){" "}
													<span
														style={{ color: "red" }}
													>
														*
													</span>
												</strong>
											</p>
											<a href={doc?.data} download>
												File.pdf
											</a>
										</div>
									</Fragment>
								))}
					</div>
				)}

				<br />
				<br />
				<br />

				<div className={styles.action__button}>
					<button
						className={`btn btn-danger`}
						onClick={() =>
							setShowModal({
								modalState: true,
								approvalState: "denied"
							})
						}
					>
						<p>Reject</p>
					</button>
					<button
						type="button"
						data-toggle="modal"
						data-target="#myModal"
						className={`btn btn-success ${styles.download__btn}`}
						onClick={() => approveAnnualReturns()}
					>
						<p>Approve</p>
					</button>
				</div>
				<br />
				<br />
			</div>
		</>
	);
};
