import style from "./style.module.css";
import faqArrowDown from "../../assets/Home/faqArrowDown.svg";
import faqArrowUp from "../../assets/Home/faqArrowUp.svg";
import { useState } from "react";
// import { Link } from "react-router-dom";
import arrowRightBlack from "../../assets/Home/arrowRight.svg";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom";
// import newsImg from "../../assets/newsImg.png"
// import authorImg from "../../assets/authorImg.png";
// import calendarImg from "../../assets/calendarImg.png"

const frequentQuestions = [
	{
		Question: "What is the full meaning of ESBN?",
		Answer: "ESBN means Enugu State Social Benefit Number.",
		partition: " "
	},
	{
		Question: "How do I sign up to get my ESBN? ",
		Answer: {
			optionFlow: "There are two options available:",
			optionTitle1: "i)	Self-Service Option",
			optionStep1: (
				<>
					Log in to the URL address &nbsp;
					<Link
						to="https://irs.en.gov.ng/home"
						className={style.faqLink}
					>
						https://irs.en.gov.ng/home
					</Link>
				</>
			),
			optionStep2: "Scroll down to click on “Register now” to Signup",
			optionStep3:
				"Obtain OTP to verify with either your phone number or e-mail",
			optionStep4: "Then log in to complete the registration process",
			optionStep5: "ESBN is then sent to you as SMS and email.",
			optionStep6: "",
			option2Title: "ii)	Tax Office Registration Option",
			option2Step1:
				"Visit any of the tax offices in the state closest to you and ESBN will be issued to you via the system instantly"
		}
	},
	{
		Question: "How do I retrieve my ESBN if forgotten? ",
		Answer: "To get your ESBN, click on Retrieve ESBN, search with your Name, Email and Phone number, the system will produce for you your ESBN. ",
		partition: " "
	},
	{
		Question: "Can one make payment without ESBN?",
		Answer: "Yes, but this will soon be stopped as all payments should be made with the ESBN to ensure proper tracking and the preparation of consolidated position where necessary.",
		partition: " "
	},
	{
		Question: "Can the Old ESBN be used for payment?",
		Answer: "Yes, there is no segregation in the numbers as all existing ones have been uploaded in the system while only new ones are issued for those who are yet to have the unique number.",
		partition: " "
	},
	{
		Question: "How do I pay for Tax and other government levies?",
		Answer: {
			optionTitle1: "Method 1: Self-service :",
			optionStep1: (
				<>
					<Link to="https://irs.en.gov.ng/home" target="_blank">
						https://irs.en.gov.ng/home
					</Link>
				</>
			),
			optionStep2:
				"Click 'Pay with' and choose your preferred payment gateway (e.g., Remita, Flutterwave, Interswitch QTB, e-Transact, or UP).			",
			optionStep3: " Enter the Bill Number as seen on your invoice.",
			optionStep4:
				" Click the 'Pay now' button or specify the amount you intend to pay, then click 'Pay now'.	",
			optionStep5:
				" Follow the instructions to select your preferred method of payment (e.g., Cards, Bank Transfer, USSD, Bank branch, etc.). Complete the payment process.",
			optionStep6:
				"Click the 'I92ve made the payment' button if it is available. Note that some payment gateways might not have this option.",
			option2Title: "Method 2: Payment at Bank",
			option2Step1: "Go to your bank and present the bill to the teller.",
			option2Step2:
				"Ensure the teller enters the bill number and the amount you wish to pay.",
			option2Step3: "Obtain your payment receipt from the teller."
		},
		partition: " "
	},
	{
		Question: "What is the full meaning of PAYE?",
		Answer: "PAYE stands for Pay As You Earn.",
		partition: " "
	},
	{
		Question: "Which people should be obliged to pay PAYE?",
		Answer: "Only staff of Organizations pays PAYE and it is so called because taxes are deducted at source, to be remitted by the organization to the IGR account of the government .",
		partition: " "
	}
];

// const taxNews = [
// 	{
// 		headline: "Enugu State Government Guide to all payers",
// 		newsContent:
// 			"The Enugu State Government has onboarded to a new electronic collection scheme in her bid to streamline her collection processes and make payment easy for all. The new platform provides multiple channels that make it easier for payers to make payment with ease, and in a timely manner.",
// 		image: newsImg,
// 		authorsName: "Authors Name",
// 		authorImg: authorImg,
// 		calendarImg: calendarImg,
// 		author: "Authors Name",
// 		date: "11/05/2024",
// 		link: "Read more..."
// 	},
// 	{
// 		headline: "Enugu State Government Guide to all payers",
// 		newsContent:
// 			"The Enugu State Government has onboarded to a new electronic collection scheme in her bid to streamline her collection processes and make payment easy for all. The new platform provides multiple channels that make it easier for payers to make payment with ease, and in a timely manner.",
// 		image: newsImg,
// 		authorsName: "Authors Name",
// 		authorImg: authorImg,
// 		calendarImg: calendarImg,
// 		date: "11/05/2024",

// 		link: "Read more..."
// 	},
// 	{
// 		headline: "Enugu State Government Guide to all payers",
// 		newsContent:
// 			"The Enugu State Government has onboarded to a new electronic collection scheme in her bid to streamline her collection processes and make payment easy for all. The new platform provides multiple channels that make it easier for payers to make payment with ease, and in a timely manner.",
// 		image: newsImg,
// 		authorsName: "Authors Name",
// 		authorImg: authorImg,
// 		calendarImg: calendarImg,
// 		date: "11/05/2024",

// 		link: "Read more..."
// 	}
// ];

const Faq = () => {
	const history = useHistory();
	const [selected, setSelected] = useState(null);
	const toggle = (i) => {
		if (selected === i) {
			return setSelected(null);
		}

		setSelected(i);
	};

	return (
		<main>
			<section className={style.accordion}>
				<h1 className={style.heading}>FAQ</h1>
				{frequentQuestions.map((item, i) => (
					<div className={style.item} key={i}>
						<div className={style.title} onClick={() => toggle(i)}>
							<h2> {item.Question}</h2>
							{selected === i ? (
								<img src={faqArrowUp} alt="faqArrowUp" />
							) : (
								<img src={faqArrowDown} alt="faqArrowDown" />
							)}
						</div>
						<div
							className={`${style.content} ${
								selected === i ? style.contentShow : ""
							}`}
						>
							{typeof item.Answer === "object" ? (
								<div>
									<p>{item.Answer.step1}</p>
									<p>{item.Answer.step2}</p>
									<p>{item.Answer.step3}</p>
									<p>{item.Answer.step4}</p>
									<p>{item.Answer.step5}</p>
									<p>{item.Answer.optionFlow}</p>
									<p className={style.optionTitle}>
										{item.Answer.optionTitle1}
									</p>
									<p className={style.optionSteps}>
										1.
										<strong>
											Visit the Enugu State IRS Homepage:
										</strong>
										{item.Answer.optionStep1}
									</p>
									<p className={style.optionSteps}>
										2.{" "}
										<strong>Select Payment Gateway:</strong>
										{item.Answer.optionStep2}
									</p>
									<p className={style.optionSteps}>
										3.<strong>Enter Bill Details:</strong>
										{item.Answer.optionStep3}
									</p>
									<p className={style.optionSteps}>
										4.
										<strong>
											Specify Payment Amount:{" "}
										</strong>
										{item.Answer.optionStep4}
									</p>
									<p className={style.optionSteps}>
										5.{" "}
										<strong>Complete the Payment: </strong>
										{item.Answer.optionStep5}
									</p>
									<p className={style.optionSteps}>
										6.
										<strong>
											Confirm Payment (if available):{" "}
										</strong>
										{item.Answer.optionStep6}
									</p>
									<p className={style.optionTitle}>
										{item.Answer.option2Title}
									</p>
									<p className={style.optionSteps}>
										1.<strong>Visit Your Bank: </strong>
										{item.Answer.option2Step1}
									</p>
									<p className={style.optionSteps}>
										2.{" "}
										<strong>
											Provide Payment Details:{" "}
										</strong>
										{item.Answer.option2Step2}
									</p>
									<p className={style.optionSteps}>
										3.<strong> Obtain Receipt: </strong>{" "}
										{item.Answer.option2Step3}
									</p>
								</div>
							) : (
								<p>{item.Answer}</p>
							)}
						</div>
						{i !== frequentQuestions.length - 1 && (
							<div className={style.partition}></div>
						)}
					</div>
				))}
				<div className={style.faqBtnWrapper}>
					<button
						className={style.faqBtn}
						onClick={() =>
							history.push("/frequently-asked-questions")
						}
					>
						See all
						<img src={arrowRightBlack} alt="arrowRightBlack" />
					</button>
				</div>
			</section>
			{/* <section className={style.enuguTaxNewsHere}>
				<h1 className={style.desktopsNewsHeading}>
					Stay informed about the latest <br /> tax developments and
					events in Enu<span className={style.underlineGreen}>gu State</span>
				</h1>
				<h1 className={style.mobileNewsHeading}>
					Stay informed about the <br /> latest tax developments and
					<br />
					events in Enugu <span className={style.underlineGreen}>State</span>
				</h1>
				<div className={style.newsCards}>
					{taxNews.map((news, index) => (
						<div key={index}>
							<div className={style.newsCard}>
								<img src={news.image} alt="newsImage" />
								<div className={style.authDate}>
									<div className={style.auth}>
										<img src={news.authorImg} alt="newsImg" />
										<h6>{news.authorsName}</h6>
									</div>
									<div className={style.vertical}></div>
									<div className={style.date}>
										<img src={news.calendarImg} alt="calendarImg" />
										<h6>{news.date}</h6>
									</div>
								</div>
								<h2 className={style.headline}>
									{news.headline}
								</h2>
								<p className={style.newsContent}>
									{news.newsContent}
								</p>
								<div className={style.link}>
									<Link to="#">{news.link}</Link>
								</div>
							</div>
						</div>
					))}
				</div>
				<div className={style.newsBtn}>
					<button onClick={() => history.push("/latest-news")}>
						Goto news page
						<img src={ArrowRight} alt="ArrowRight" />
					</button>
				</div>
			</section> */}
		</main>
	);
};

export default Faq;
