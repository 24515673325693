import Table from "../../components/Table";
import styles from "../MDAs/style.module.css";
import { useEffect, useState, createRef } from "react";
import axios from "axios";
import { exportExcel, formatAmount, remapObj } from "../../utils";

const Analytics = () => {
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);
	const [toXcel, setToXcel] = useState(false);
	const [minTotal, setMinTotal] = useState("");
	const [maxTotal, setMaxTotal] = useState("");
	const [startdate, setStartDate] = useState("");
	const [enddate, setEndDate] = useState("");
	const [sortOrder, setSortOrder] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [data, setData] = useState([]);

	const filterRef = createRef();

	const headers = [
		["PAYE (NGN)", "paye", formatAmount],
		["Direct Assessment (NGN)", "directAssessment", formatAmount],
		["Road Taxes (NGN)", "roadTaxes", formatAmount],
		["Other Taxes (NGN)", "otherTaxes", formatAmount],
		["Total (NGN)", "total", formatAmount],
		["MDA Revenues (NGN)", "mdaRevenues", formatAmount],
		["Grand Total (NGN)", "grandTotal", formatAmount]
	];

	let prit=0
	// const getAnalysis = ({ currentPage = 1 } = {}) => async () => {
	const getAnalysis = async () => {
	console.log('falz....')
			setLoading(true);
			const currentPage= 1
			let query = `page=${currentPage}&pageSize=20&`;

			if (minTotal) {
				query += `minTotal= ${minTotal}&`;
			}

			if (maxTotal) {
				query += `maxTotal=${maxTotal}&`;
			}

			if (startdate) {
				query += `startdate=${startdate}&`;
			}

			if (enddate) {
				query += `enddate=${enddate}`;
			}

			try {
				const accessToken = localStorage.getItem("access_token");
				const info = await axios
					.get(`/admin/analytics?${query}`, {
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					})
					.then((res) => (res.data || {}).data);
				console.log('res.data --> ',info)
				if (info) {
					const d = [];
					for (let i in info){						
						if (info[i]) {
							d[i] = info[i];
						} 
					}
					setData(d);
		console.log('setData --> ', d)
		console.log('prit --> ', prit)
		console.log('Xcel  --> ', toXcel)
					// if (toXcel) {
					if (prit === 1) {
						exportExcel(
							remapObj(d, headers),
							headers,
							`JTB Report - ${new Date().toLocaleString()}.xlsx`
						);
					}
				}
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		};

	const clearFilters = () => {
		setMinTotal("");
		setMaxTotal("");
		setSortOrder("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		// getAnalysis()();
		getAnalysis();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getAnalysis();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.MDA__page}>
			<div className={styles.actions__div}>
				<button
					className={["primary__btn", styles.download__button].join(
						" "
					)}
					onClick={(e) => {
						setToXcel(true)
						console.log('clickme....');
						prit =1;
						getAnalysis();
						// getAnalysis(true);
					}}
				>
					Download Report
				</button>
				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={!minTotal && !maxTotal && !sortOrder}
						>
							Clear Filter fields
						</button>
						<p>By Date</p>
						<form >
							<div>
								<label htmlFor="startdate">Start Date</label>
								<input
									name={startdate}
									id="startdate"
									type="date"
									value={startdate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="enddate">End Date</label>
								<input
									name={enddate}
									id="enddate"
									type="date"
									value={enddate}
									onChange={(e) =>
										setEndDate(e.target.value)
									}
								/>
							</div>
						</form>
						<p>By Amount</p>
						<form>
							<div>
								<label htmlFor="min_total">Min Total</label>
								<input
									name="min_total"
									id="min_total"
									type="number"
									value={minTotal}
									onChange={(e) => {
										setMinTotal(e.target.value);
									}}
									placeholder="100,000"
								/>
							</div>
							<div>
								<label htmlFor="max_total">Max Total</label>
								<input
									name="max_total"
									id="max_total"
									type="number"
									value={maxTotal}
									onChange={(e) => {
										setMaxTotal(e.target.value);
									}}
									placeholder="2,000,000"
								/>
							</div>
						</form>
						<p>Sort Taxpayer Name Alphabetically</p>
						<form className={styles.radio__form}>
							<div>
								<input
									name={sortOrder}
									id="a_to_z"
									type="radio"
									value="a-to-z"
									onChange={(e) =>
										setSortOrder(e.target.value)
									}
								/>
								<label htmlFor="a_to_z">A to Z</label>
							</div>
							<div>
								<input
									name={sortOrder}
									id="z_to_a"
									type="radio"
									value="z-to-a"
									onChange={(e) =>
										setSortOrder(e.target.value)
									}
								/>
								<label htmlFor="z_to_a">Z to A</label>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							type="submit"
							onClick={getAnalysis}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<div className={styles.table__div}>
				<Table headers={headers} data={data} />
			</div>
		</div>
	);
};

export default Analytics;
