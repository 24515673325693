/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import axios from "axios";
import Alert from "../components/Alert";
import { useHistory } from "react-router";
// import Modal from "../components/Modal";
import InterswitchForPayment from "../assets/Home/interswitchForPayment.svg";
import { RevenueHeads } from "./components/revenueHeads";
import { Mdas } from "./components/mdas";
import constants from "../constants";

const coylogo = process.env.REACT_APP_COYNAME;

let imgpath = "../assets/Home/" + process.env.REACT_APP_COYLOGO;

axios.defaults.testURL = constants.BASE_URL;

const InterSwitcheb = () => {
	const [BillNumber, setBillNumber] = useState("");
	const [billNumberDetails, setBillNumberDetails] = useState(null);
	const [ownersesbn, setownersesbn] = useState("");
	const [amount, setAmount] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [revenue, setRevenue] = useState("");
	const [mda, setMdas] = useState("");
	const [getrev, setRevhead] = useState([]);
	const [revenueDeets, setRevenueDeets] = useState({
		description: "",
		code: ""
	});
	const [ESBNDetails, setESBNDetails] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showESBN, toggleShowESBN] = useState("esbn");
	const [accountType, setAccountType] = useState("");
	const [showAlert, setShowAlert] = useState(true);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [isButtonEnabled, setIsButtonEnabled] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const history = useHistory();

	// femi - exclude PAYE and WITHOLDING TAX FROM BILL VALIDATION
	const [isBillNoOk, setIsBillNoOk] = useState(false);
	const [isPaymentOnPAYE_WHT, setIsPaymentOnPAYE_WHT] = useState(false);
	const PAYE_WHT = [
		"20008001/12010004",
		"20008001/12010005",
		"20008001/12010006",
		"20008001/12010007",
		"20008001/12010020",
		"20008001/12010010",
		"20008001/12010011",
		"20008001/12010012",
		"20008001/12010013",
		"20008001/12010014",
		"20008001/12010015",
		"20008001/12010028",
		"20008001/12010029",
		"47001002/12010028",
		"48001001/12010010"
	];

	useEffect(() => {
		checkIfPAYEorWHTInList();
	}, []);

	const checkIfPAYEorWHTInList = (revCode) => {
		// console.log('revCode => ', revCode)
		const found = PAYE_WHT.includes(revCode);
		setIsPaymentOnPAYE_WHT(found);
		// console.log('revCode[found] => ', found)
	};
	// femi - exclude PAYE and WITHOLDING TAX FROM BILL VALIDATION

	const RevenueHead = async () => {
		const res = await axios
			.get(`/settings/mdalinkedrevenue/${mda}`)
			.then((res) => (res.data || {}).data);
		setRevhead(res);
	};

	useEffect(() => {
		RevenueHead();
	}, [mda]);

	//Alert message when the page is loaded
	const CustomAlert = ({ message, onClose, isButtonEnabled }) => {
		return (
			<div className={styles.backdrop}>
				<div className={styles.custom__alert}>
					<p>{message}</p>
					<button onClick={onClose} className={styles.btn}>
						OK
					</button>
				</div>
			</div>
		);
	};

	let charges;

	// Check if the transaction amount is less than 100
	if (amount < 6000) {
		charges = amount * 100 + 100 * 100;
	} else {
		// Calculate charges as 2% of the transaction amount
		charges = amount * 100 * 1.02;
	}

	const handleCloseAlert = () => {
		setShowAlert(false);
	};

	function randomReference() {
		var length = 10;
		var chars =
			"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
		var result = "";
		for (var i = length; i > 0; --i)
			result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	}

	function paymentCallback(response) {
		const requestData = {
			paymentgateway: "INTERSWITCH",
			paymentoption: showESBN,
			paygatetranid: "Default_Payable_MX112693",
			billnumber: BillNumber,
			billername: ownersesbn ? ownersesbn : name,
			billerphoneno: phone ? phone : ESBNDetails?.phone,
			billeremail: email ? email : ESBNDetails?.email,
			amount: amount,
			mda: mda,
			revenue: revenue,
			payload: [
				{
					txn_ref: randomReference(),
					amount: charges,
					// amount: charges,
					cust_id: email ? email : ESBNDetails?.email,
					currency: 566
				}
			]
		};

		// API endpoint
		const apiEndpoint = `${constants.BASE_URL}/taxpaid`;

		// Use Axios to send the POST request
		axios
			.post(apiEndpoint, requestData, {
				headers: {
					"Content-Type": "application/json"
					// Add any additional headers as needed
				}
			})
			.then((response) => {
				console.log("Data sent successfully");
				// Handle success if needed
			})
			.catch((error) => {
				console.error("Failed to send data to the API:", error);
				// Handle errors if needed
			});
	}

	const callWebhook = async () => {
		const apiEndpoint = `https://alpha.norlics.ng/api/txn/invoice/webhook`;
		await axios
			.get(apiEndpoint)
			.then((res) => {
				console.log({ res });
				// history.push('/home')
			})
			.catch((error) => {
				console.log({ error });
			});
	};

	useEffect(() => {
		const getESBN = async () => {
			const res = await axios
				.get(`users/anssid?anssid=${ownersesbn}`)
				.then((res) => (res.data || {}).data);
			if (res.accounttype === "individual") {
				setName(`${res.firstname} ${res.surname}`);
			} else {
				setName(res.name);
			}

			// setName(`${res.firstname} ${res.surname}`);
			setESBNDetails(res);
		};
		if (ownersesbn?.length >= 10) getESBN();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownersesbn]);

	useEffect(() => {
		const getBillAmount = async () => {
			await axios
				.get(`/billingno/${BillNumber}`)
				.then((res) => {
					setIsBillNoOk(true);
					if (res?.data?.data[0]?.GrandTotal) {
						setAmount(res.data.data[0].GrandTotal);
						console.log(res);
					}
				})
				.catch((error) => {
					setIsBillNoOk(false);
					if (error.response) {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message:
								error.response.data?.errors?.[0] ||
								error.response.data?.msg
						});
					}
				});
		};
		if (BillNumber?.length >= 18) getBillAmount();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [BillNumber]);

	useEffect(() => {
		const interswitchScript = document.createElement("script");
		interswitchScript.src = `https://newwebpay.interswitchng.com/inline-checkout.js`;
		interswitchScript.async = true;
		document.body.appendChild(interswitchScript);
	}, []);

	// const generatePaymentRequest = (charges, email, ESBNDetails) => {
	// 	return {
	// 	  merchant_code: "MX112693",
	// 	  pay_item_id: "Default_Payable_MX112693",
	// 	  txn_ref: randomReference(),
	// 	  amount: charges,
	// 	  cust_id: email ? email : (ESBNDetails?.email || ""),
	// 	  currency: 566,
	// 	  site_redirect_url: "https://irs.en.gov.ng/interswitchpage",
	// 	  onComplete: callWebhook,
	// 	  mode: "LIVE"
	// 	};
	//   };
	//sample payment request
	const samplePaymentRequest = {
		merchant_code: "MX112693",
		pay_item_id: "Default_Payable_MX112693",
		txn_ref: randomReference(),
		amount: charges,
		cust_id: email ? email : ESBNDetails?.email,
		currency: 566,
		site_redirect_url: "https://irs.en.gov.ng/interswitchpage",
		onComplete: callWebhook,
		mode: "LIVE"
	};

	const handleButton = async () => {
		try {
			await paymentCallback();
		} catch (error) {
			// Handle errors from Function 1
			console.error("Error in Function 1:", error);
		}
		// Execute Function 2
		await samplePaymentRequest;
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const handleButtonClick = () => {
		// Display the confirmation modal
		setShowConfirmation(true);
	};

	const handleConfirm = () => {
		// Proceed with the action
		console.log("User confirmed! Proceeding with the action...");
		// Add your logic here, such as making an API call, redirecting, etc.

		// Close the confirmation modal
		setShowConfirmation(false);
	};

	const handleCancel = () => {
		// The user canceled the action
		console.log("User canceled the action.");

		// Close the confirmation modal
		setShowConfirmation(false);
	};

	return (
		<>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}

			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<button
				className={`secondary__btn ${styles.home__btn}`}
				onClick={() => history.push("/home")}
			>
				go home
			</button>

			<center>
				{showAlert && (
					<CustomAlert
						message=" Dear Customer, on this platform, payment attracts 2% of bill amount or minimum of N100"
						onClose={handleCloseAlert}
					/>
				)}
			</center>
			<main className={styles.main}>
				<div>
					<center>
						<div className={[styles.interswitch__header].join("")}>
							<img
								src={InterswitchForPayment}
								alt="interswitch payment"
							/>
						</div>
					</center>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-start",
							padding: "0px 30px"
						}}
					>
						<div className={styles.esbn__selector}>
							<input
								type="radio"
								id="esbn"
								name="esbn"
								value="esbn"
								onChange={(e) => toggleShowESBN(e.target.value)}
								checked={showESBN === "esbn"}
							/>
							<label htmlFor="esbn">Pay with ESBN</label>
						</div>

						<div className={styles.esbn__selector}>
							<input
								type="radio"
								id="noEsbn"
								name="noEsbn"
								value="noEsbn"
								onChange={(e) => toggleShowESBN(e.target.value)}
								checked={showESBN === "noEsbn"}
							/>
							<label htmlFor="noEsbn">Pay without ESBN</label>
						</div>
					</div>

					<hr className={styles.divider} />
					{showESBN === "esbn" && (
						<>
							<div className={styles.wrapper}>
								<div
									className={styles.payments__double__inputs}
								>
									<div>
										<label
											className={styles.input__label}
											htmlFor="ownersesbn"
										>
											ESBN{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											onChange={(e) =>
												setownersesbn(e.target.value)
											}
											id="ownersesbn"
											placeholder="enter your ESBN"
											required
											value={ownersesbn}
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="BillNumber"
										>
											Bill Number{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											onChange={(e) =>
												setBillNumber(e.target.value)
											}
											id="BillNumber"
											placeholder="Enter bill number"
											required
											value={BillNumber}
										/>
									</div>
								</div>
								<hr className={styles.divider} />

								<div
									className={styles.payments__double__inputs}
								>
									<div>
										<label
											className={styles.input__label}
											htmlFor="name"
										>
											Name{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={name}
											onChange={(e) =>
												setName(e.target.value)
											}
											id="amount"
											placeholder="Enter Name"
											required
											disabled
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="amount"
										>
											Amount{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={amount}
											onChange={(e) =>
												setAmount(e.target.value)
											}
											id="amount"
											placeholder="Enter amount"
											required
										/>
									</div>
								</div>

								<br />
								<div
									className={styles.payments__double__inputs}
								>
									<Mdas
										setValue={(val) => setMdas(val)}
										value={mda}
									/>
									<div>
										<label
											className={styles.input__label}
											htmlFor="mda"
										>
											Revenue{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<select
											id="mda"
											className={styles.input__medium}
											name="mda"
											onChange={(e) => {
												checkIfPAYEorWHTInList(
													e.target.value
												);
												setRevenue(e.target.value);
											}}
											value={revenue}
											required
										>
											<option>
												{getrev
													? "--Select Revenue head--"
													: "Loading..."}
											</option>
											{getrev &&
												getrev.map((getRev) => (
													<option value={getRev.Code}>
														{getRev.Description}
													</option>
												))}
										</select>
									</div>
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										margin: "23px 30px 0px 0px"
									}}
								>
									{ownersesbn &&
										// BillNumber &&
										(isBillNoOk || isPaymentOnPAYE_WHT) &&
										name &&
										amount && (
											<button
												onClick={() =>
													window?.webpayCheckout(
														samplePaymentRequest
													)
												}
												className={[styles.btn]}
											>
												Pay now
											</button>
										)}
								</div>
							</div>
						</>
					)}
					{showESBN === "noEsbn" && (
						<>
							<div className={styles.wrapper}>
								<div
									className={styles.payments__double__inputs}
								>
									<div>
										<label
											className={styles.input__label}
											htmlFor="name"
										>
											Name{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={name}
											onChange={(e) =>
												setName(e.target.value)
											}
											id="name"
											placeholder="Enter Name"
											required
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="amount"
										>
											Amount{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={amount}
											onChange={(e) =>
												setAmount(e.target.value)
											}
											id="amount"
											placeholder="Enter amount"
											required
										/>
									</div>
								</div>
								<br />

								<div
									className={styles.payments__double__inputs}
								>
									<div>
										<label
											className={styles.input__label}
											htmlFor="email"
										>
											Email{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={email}
											onChange={(e) =>
												setEmail(e.target.value)
											}
											id="email"
											placeholder="Enter Email"
											required
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="phone"
										>
											Phone Number{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={phone}
											onChange={(e) =>
												setPhone(e.target.value)
											}
											id="phone"
											placeholder="Enter phone number"
											required
										/>
									</div>
								</div>

								<br />
								<div
									className={[
										styles.payments__double__inputs
									].join(" ")}
								>
									<Mdas
										setValue={(val) => setMdas(val)}
										value={mda}
									/>
									<div>
										<label
											className={styles.input__label}
											htmlFor="mda"
										>
											Revenue{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<select
											id="mda"
											className={styles.input__medium}
											name="mda"
											onChange={(e) => {
												setRevenue(e.target.value);
											}}
											value={revenue}
											required
										>
											<option>
												{getrev
													? "--Select Revenue head--"
													: "Loading..."}
											</option>
											{getrev &&
												getrev.map((getRev) => (
													<option value={getRev.Code}>
														{getRev.Description}
													</option>
												))}
										</select>
									</div>
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										margin: "23px 30px 0px 0px"
									}}
								>
									{name &&
										amount &&
										email &&
										phone &&
										revenue && (
											<button
												className={[styles.btn]}
												onClick={() =>
													window?.webpayCheckout(
														samplePaymentRequest
													)
												}
											>
												Pay now
											</button>
										)}
								</div>
							</div>
						</>
					)}
				</div>
			</main>
		</>
	);
};

export default InterSwitcheb;
