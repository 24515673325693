import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "../style.module.css";

export const RevenueHeads = ({ setValue = () => {}, value }) => {
	const [revenueheads, setrevenueheads] = useState([]);

	const getRevenueHeads = async () => {
		const res = await axios
			.get(`users/mdarevenue`)
			.then((res) => (res.data || {}).data);
		setrevenueheads(res);
	};

	useEffect(() => {
		getRevenueHeads();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<label className={styles.input__label} htmlFor="revenue">
				Revenue Head <span style={{ color: "red" }}>*</span>
			</label>
			<select
				id="revenue"
				className={styles.input__medium}
				name="revenue"
				onChange={(e) => {
					setValue(e.target.value);
				}}
				value={value}
				required
			>
				<option>
					{revenueheads.length
						? "--Select Revenue head--"
						: "Loading..."}
				</option>
				{revenueheads.length &&
					revenueheads?.map((revenueHead, index) => (
						<React.Fragment key={revenueHead?.id + "key" + index}>
							<option
								value={`${revenueHead?.Description}/${revenueHead?.Code}`}
							>
								{revenueHead?.Description}
							</option>
						</React.Fragment>
					))}
			</select>
		</div>
	);
};
