import { useEffect, useState } from "react";
import axios from "axios";
import Alert from "../../components/Alert";
import styles from "../user/style.module.css";


const Group = () => {
	const [deactivatedAgents, setDeactivatedAgents] = useState(null);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	
	const accessToken = localStorage.getItem("access_token");

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	async function fetchDeactivatedAgents() {
		setLoading(true);

		try {
			const request = await axios.get(`/admin/usermanagement/getusersbyrole/anssid-agent`, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			});
			setLoading(false);

			const users = (request.data?.data?.Users || []).filter(user => !user.isActive);
			setDeactivatedAgents(users);
		} catch (error) {
			setLoading(false);
			if (error.response) {
				console.log("response", error.response.data.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.message
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		}
	}

	useEffect(() => {
		fetchDeactivatedAgents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const activate = async (id) => {
		try {
			await axios.put(
				`/admin/usermanagement/activateaccount/${id}`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);

			fetchDeactivatedAgents();

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: "Agent activated!"
			});
		} catch (error) {
			if (error.response) {
				console.log("response", error.response.data.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.message
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		}
	};

	return (
		<main className={styles.wrapper}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<header className={styles.header}>
				<p className={styles.heading__text}>Deactivated Agents</p>
				<span className={styles.drop__arrow}></span>
			</header>
			<section>
				<div className={styles.header}>
					<p className={styles.heading__text}>
						{deactivatedAgents?.length}{" "}
						{loading
							? ""
							: deactivatedAgents?.length === 1
							? "Deactivated Agent"
							: "Deactivated Agents"}
					</p>
				</div>
				<table className={styles.table}>
					{loading && (
						<tbody>
							<tr>
								<td>
									<p style={{ textAlign: "center" }}>
										Loading...
									</p>
								</td>
							</tr>
						</tbody>
					)}
					{!deactivatedAgents?.length && !loading ? (
						<tbody>
							<tr>
								<td>
									<p style={{ textAlign: "center" }}>
										No Deactivated Agents.
									</p>
								</td>
							</tr>
						</tbody>
					) : (
						<tbody>
							{deactivatedAgents?.map((agent) => (
								<tr>
									<td>
										<p>
											{agent.firstname} {agent.surname}
										</p>
										<p>{agent.email}</p>
									</td>
									<td>
										<button
											className={styles.deactive__btn}
											onClick={() => activate(agent.id)}
										>
											Reactivate
										</button>
										<button className={styles.delete__btn}>
											Delete
										</button>
									</td>
								</tr>
							))}
						</tbody>
					)}
				</table>
			</section>
		</main>
	);
};

export default Group;
