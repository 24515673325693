import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import Table from "../../components/Table";
import Alert from "../../components/Alert";
import arrowLeft from "../../assets/arrowLeft.svg";
import styles from "./corporate.profiling.module.css";
import styles1 from "../../Identity-management-individual/style.module.css";

const CorporateProfiling = () => {
	const inputter = JSON.parse(localStorage.getItem("user_info")).email;
	const history = useHistory();
	const { state } = useLocation();
	const [MDAs, setMDAs] = useState([]);
	const [MDAName, setMDAName] = useState("");
	const [MDACode, setMDACode] = useState("");
	const [MDARevCode, setMDARevCode] = useState("");
	const [billType, setBillType] = useState("");
	const [billTypes, setBillTypes] = useState([]);
	const [profileCategories, setProfileCategories] = useState([]);
	const [profileCategory, setProfileCategory] = useState("");
	const [profileCategoryCode, setProfileCategoryCode] = useState("");
	const [profiles, setProfiles] = useState([]);
	const [profile, setProfile] = useState("");
	const [revenueHeads, setRevenueHeads] = useState([]);
	const [revenue, setRevenue] = useState("");
	const [orgDetails, setOrgDetails] = useState(null);
	const [orgProfiles, setOrgProfiles] = useState([]);
	const [amount, setAmount] = useState("");
	const [quantity, setQuantity] = useState(1);
	const [monthCount, setMonthCount] = useState(1);
	const [fetchingOrgProfiles, setFetchingOrgProfiles] = useState(false);
	const [addingProfile, setAddingProfile] = useState(false);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const headers = [
		["MDA", "MDAName"],
		["Category", "ProfileCategory"],
		["Profile", "Profile"],
		["Revenue Code", "RevenueCode"]
	];

	const getOrgProfiles = async () => {
		setFetchingOrgProfiles(true);

		try {
			const res = await axios
				.get(
					`admin/corporate-taxpayer/getcoysinglepagetin?userId=${state?.userId}&profile=${state.profileId}`
				)
				.then((res) => (res.data || {}).data);

			setOrgProfiles(res.result.StateCorporateProfileSinglePages);
			setOrgDetails(res.result);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors?.[0] ||
						error.response.data?.msg
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setFetchingOrgProfiles(false);
		}
	};

	const getMDAs = async () => {
		try {
			const res = await axios
				.get(`/settings/mdacompany`)
				.then((res) => (res.data || {}).data);

			setMDAs(res);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors?.[0] ||
						error.response.data?.msg
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const getRevenueHeads = async () => {
		try {
			const res = await axios
				.get(`/settings/mdalinkedrevenue/${MDARevCode}`)
				.then((res) => (res.data || {}).data);

			setRevenueHeads(res);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors?.[0] ||
						error.response.data?.msg
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const getProfileCategories = async () => {
		try {
			const res = await axios
				.get(`settings/profilecategorys/${MDACode}`)
				.then((res) => (res.data || {}).data);

			setProfileCategories(res);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors?.[0] ||
						error.response.data?.msg
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const getProfiles = async () => {
		try {
			const res = await axios
				.get(
					`categorytarrifcommerces/${MDACode}/${profileCategoryCode}`
				)
				.then((res) => (res.data || {}).data);

			setProfiles(res);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors?.[0] ||
						error.response.data?.msg
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	useEffect(() => {
		getRevenueHeads();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [MDARevCode]);

	useEffect(() => {
		getMDAs();
		getOrgProfiles();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getProfileCategories();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [MDACode]);

	useEffect(() => {
		getProfiles();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profileCategoryCode]);

	//set amount
	useEffect(() => {
		const selectedProfile = profiles?.find(
			(each) => each.Description === profile
		);

		if (billType === "Registration")
			setAmount(selectedProfile.RegistrationFee);
		else if (billType === "Renewal") setAmount(selectedProfile.RenewalFee);
		else if (billType === "Fixed") setAmount(selectedProfile.FixedFee);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [billType]);

	//filter BilType List
	useEffect(() => {
		let billList = ["Manual"];

		const selectedProfile = profiles?.find(
			(each) => each.Description === profile
		);

		if (selectedProfile?.RegistrationFee) billList.push("Registration");
		if (selectedProfile?.FixedFee) billList.push("Fixed");
		if (selectedProfile?.RenewalFee) billList.push("Renewal");

		setBillTypes(billList);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const addProfile = async (e) => {
		e.preventDefault();

		setAddingProfile(true);

		try {
			const res = await axios
				.post(
					`/admin/individual-taxpayer/corporatesinglepagetin/${state.userId}`,
					{
						tin: orgDetails?.TIN,
						tinname: orgDetails?.tinName,
						profile: orgDetails?.ProfileId,
						org: orgDetails?.OrgName,
						officeaddr: orgDetails?.Office_Address,
						state: orgDetails?.State,
						lga: orgDetails?.Lga,
						city: orgDetails?.City,
						zone: orgDetails?.Zone,
						phone: orgDetails?.Phone_Number,
						email: orgDetails?.Email_Address,
						rcnum: orgDetails?.RC_Number,
						bizcategory: orgDetails?.Business_Category,
						economicsector: orgDetails?.Economic_Sector,
						ownerESBN: orgDetails?.Business_Owner_Anssid,
						inputter,
						authoriser: orgDetails?.Authoriser,
						orgProfiles: [
							...orgProfiles,
							{
								mdacode: MDACode,
								mdaname: MDAName,
								billtype: billType,
								profilecategory: profileCategory,
								profile,
								amount: `${amount}`,
								qty: `${quantity}`,
								mthqty: `${monthCount}`,
								revenuecode: revenue
							}
						]
					}
				)
				.then((res) => res.data);

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.msg
			});

			setMDAName("");
			setMDACode("");
			setMDARevCode("");
			setBillType("");
			setProfileCategory("");
			setProfileCategoryCode("");
			setProfile("");
			setRevenue("");
			setAmount("");

			getOrgProfiles();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setAddingProfile(false);
		}
	};

	return (
		<section className={styles.wrapper}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<button
				onClick={() => history.goBack()}
				className={styles1.back__btn}
				style={{ marginBottom: "10px" }}
			>
				<img src={arrowLeft} alt="go back arrow icon" /> Back
			</button>
			<h1 className={styles.companyName}>{state?.orgName}</h1>
			<div className={styles.corpDeets}>
				<p>
					Phone Number: <span>{state?.phoneNumber}</span>
				</p>{" "}
				|
				<p>
					ESBN: <span>{state?.ESBN}</span>
				</p>
			</div>
			<form onSubmit={addProfile} className={styles.form}>
				<div className={styles.input__div}>
					<div>
						<label htmlFor="MDAName">
							MDA <span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="MDAName"
							value={MDAName}
							onChange={(e) => {
								setMDAName(e.target.value);
								setMDACode(
									e.target.selectedOptions[0].getAttribute(
										"mdacode"
									)
								);
								setMDARevCode(
									e.target.selectedOptions[0].getAttribute(
										"mdarevcode"
									)
								);
							}}
						>
							<option value="">Select MDA</option>
							{MDAs?.map((MDA) => (
								<option
									mdacode={MDA.CoyId}
									mdarevcode={MDA.income_group}
									key={MDA.CoyId}
								>
									{MDA.Coyname}
								</option>
							))}
						</select>
					</div>
					<div>
						<label htmlFor="profileCategory">
							Profile Category{" "}
							{!MDAName && (
								<span
									style={{
										fontSize: "12.5px",
										color: "#7e7e7e"
									}}
								>
									(Pick MDA first)
								</span>
							)}{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="profileCategory"
							value={profileCategory}
							onChange={(e) => {
								setProfileCategory(e.target.value);
								setProfileCategoryCode(
									e.target.selectedOptions[0].getAttribute(
										"profilecode"
									)
								);
							}}
							disabled={!MDAName}
						>
							<option value="">Select Profile Category</option>
							{profileCategories?.map((profile) => (
								<option
									profilecode={profile.Code}
									key={profile.id}
								>
									{profile.Description}
								</option>
							))}
						</select>
					</div>
					<div>
						<label htmlFor="profile">
							Profile{" "}
							{!profileCategory && (
								<span
									style={{
										fontSize: "12.5px",
										color: "#7e7e7e"
									}}
								>
									(Pick Profile Category first)
								</span>
							)}{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="profile"
							value={profile}
							onChange={(e) => setProfile(e.target.value)}
							disabled={!profileCategory}
						>
							<option value="">Select Profile</option>
							{profiles?.map((profile) => (
								<option key={profile.Code}>
									{profile.Description}
								</option>
							))}
						</select>
					</div>
					<div>
						<label htmlFor="billType">
							Bill Type{" "}
							{!profile && (
								<span
									style={{
										fontSize: "12.5px",
										color: "#7e7e7e"
									}}
								>
									(Pick Profile first)
								</span>
							)}{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="billType"
							value={billType}
							onChange={(e) => setBillType(e.target.value)}
							disabled={!profile}
						>
							<option value="">Select Bill Type</option>
							{billTypes?.map((type) => (
								<option key={type}>{type}</option>
							))}
						</select>
					</div>
					<div>
						<label htmlFor="revenue">
							Revenue{" "}
							{!profile && (
								<span
									style={{
										fontSize: "12.5px",
										color: "#7e7e7e"
									}}
								>
									(Pick MDA first)
								</span>
							)}{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="revenue"
							value={revenue}
							onChange={(e) => {
								setRevenue(e.target.value);
							}}
						>
							<option value="">
								{revenueHeads
									? "Select Revenue Head"
									: "Loading..."}
							</option>
							{revenueHeads?.map((revenueHead) => (
								<option
									key={revenueHead.Code}
									value={revenueHead.Code}
								>
									{revenueHead.Description}
								</option>
							))}
						</select>
					</div>
					<div>
						<label htmlFor="amount">
							Amount <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="amount"
							type="number"
							placeholder="Amount"
							value={amount}
							onChange={(e) => setAmount(e.target.value)}
							disabled={billType !== "Manual"}
						/>
					</div>
					<div>
						<label htmlFor="quantity">
							Quantity of units{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="quantity"
							type="number"
							placeholder="Quantity"
							value={quantity}
							onChange={(e) => setQuantity(e.target.value)}
						/>
					</div>
					<div>
						<label htmlFor="monthCount">
							Month Count <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="monthCount"
							type="number"
							placeholder="Month Count"
							value={monthCount}
							onChange={(e) => setMonthCount(e.target.value)}
						/>
					</div>
				</div>
				<button
					type="submit"
					disabled={
						addingProfile ||
						!MDAName ||
						!profileCategory ||
						!profile ||
						!billType ||
						!revenue ||
						!amount ||
						!quantity ||
						!monthCount
					}
					className="primary__btn"
				>
					{addingProfile ? "Adding Profile..." : "Add Profile"}
				</button>
			</form>
			{fetchingOrgProfiles ? (
				<p style={{ textAlign: "center" }}>Loading...</p>
			) : (
				<Table headers={headers} data={orgProfiles} />
			)}
		</section>
	);
};

export default CorporateProfiling;
