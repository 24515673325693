import styles from "./styles.module.css";

const Modal = ({ showing, toggle, children, small = false, style={} }) => (
	<>
		<div className={[styles.children, small ? styles.small__modal : ''].join(' ')} style={style}>{children}</div>
		<div className={styles.modal} onClick={() => toggle(!showing)}>
			<button
				onClick={() => toggle(!showing)}
				className={styles.close__btn}
			>
				Close Table
			</button>
		</div>
	</>
);

export default Modal;
