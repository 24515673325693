import { useState, useEffect, createRef, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { formatAmount } from "../../../utils";
import Table from "../../../components/Table4";
import Alert from "../../../components/Alert";
import Modal from "../../../components/Modal/regular";
import GoBackButton from "../../../components/goBackButton";
import styles from "../Paye-Record/style.module.css";
import styles1 from "../../MDAs/style.module.css";
import authViewStyle from "../../PAYE/Authorisation/style.module.css";
import anssidStyles from "../../StateTIN/style.module.css";
import styles2 from "../../../Direct-assessment/Create-direct-assessment/style.module.css";
import XcircleRed from "../../../assets/x-circle-red.svg";
import closeBtn from "../../../assets/x-circle-black.svg";

const ReviewerView = () => {
	let userId;
	const { state: locationState } = useLocation();
	const [loading, setLoading] = useState(false);
	const [finished, setFinished] = useState("0");
	const [data, setData] = useState([]);
	const [receiptData, setReceiptData] = useState(null);
	const [isDeclineModal, setIsDeclineModal] = useState(false);
	const [declineConfirmation, setDeclineConfirmation] = useState(false);
	const [requestConfirmation, setRequestConfirmation] = useState(false);
	const [declineReason, setDeclineReason] = useState("");
	const [billShow, setBillShow] = useState(false);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const filterRef = createRef();
	const userIdRef = useRef(userId);

	useEffect(() => {
		if (
			JSON.parse(localStorage.getItem("user_info")).account_type ===
			"corporate"
		) {
			userIdRef.current = JSON.parse(
				localStorage.getItem("user_info")
			).anssid;
		} else {
			userIdRef.current = locationState.CoyESBN;
		}
		getBillDetails()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finished === "1"]);

	const getBillDetails =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);

			if (!filterRef.current?.classList.contains(styles1.no__display)) {
				filterRef.current?.classList.toggle(styles1.no__display);
			}

			try {
				await axios
					.get(
						`/dareports/tmspayedisplaybilldetails/${locationState.BillNumber}`
					)
					.then((res) => {
						const billdataArray = res.data.data || [];
						setData(billdataArray);
					});
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.message ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			} finally {
				setFinished("1");
				setLoading(false);
			}
		};

	const fetchReceipt = async () => {
		try {
			const res = await axios
				.get(`/gettmspaye/${locationState.BillNumber}/1`)
				.then((res) => res.data || {});
			setReceiptData(res);
		} catch (err) {
			console.error(err);
		} finally {
			setFinished("1");
		}
	};

	useEffect(() => {
		fetchReceipt();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finished === "1"]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const requestApproval = async () => {
		try {
			const { msg } = await axios
				.put("processpaye", {
					billno: locationState.BillNumber,
					approve: "5", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval
					reason4Reject: "",
					recId: locationState.id
				})
				.then((res) => res.data);
			setDeclineReason("");
			if (data.status === 200) {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: "File has successfully been sent for Approved"
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: msg
				});
			}
			setRequestConfirmation(false);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: error.response.data?.msg
				});
			}
		}
		setRequestConfirmation(false);
	};

	const decline = async () => {
		setLoading(true);
		try {
			const { msg } = await axios
				.put("processpaye", {
					billno: locationState.BillNumber,
					approve: "2", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval
					reason4Reject: declineReason,
					recId: locationState.id
				})
				.then((res) => res.data);
			setDeclineReason("");
			setIsDeclineModal(false);

			if (data.status === 200) {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: "Bill has successfully been Denied"
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: msg
				});
			}
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: error.response.data?.msg
				});
			}
		} finally {
			setLoading(false);
		}
		setDeclineReason("");
		setIsDeclineModal(false);
	};

	const headers = [
		["Staff Name ", "StaffName"],
		["Annual Income ", "AnnualPay", formatAmount],
		["Monthly Income ", "MthPay", formatAmount],
		["Total Relief", "TotRelief", formatAmount],
		["Taxable Income", "TaxableIncome", formatAmount],
		["Annual Tax Due ", "AnnualTaxDue", formatAmount],
		["Monthly Tax Due", "MthTaxDue", formatAmount],
		["Development Levy", "DevLevy", formatAmount]
	];

	return (
		<main className={[styles2.wrapper, styles.main].join(" ")}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<GoBackButton />

			<div className={styles.downloadSec}>
				<h2 className={styles.companyDetailsHeader}>Company Details</h2>
				<div
					className={styles.voucher}
					onClick={() => setBillShow(true)}
				>
					<h4>View Bill </h4>
				</div>
			</div>
			<div className={styles.employeeDetails}>
				<div>
					<div className={styles.employeeDetail}>
						<h4 className={styles.detailsKey}>Company Name: </h4>
						<h4 className={styles.detailsValue}>
							{locationState.CoyName}
						</h4>
					</div>
					<div className={styles.employeeDetail}>
						<h4 className={styles.detailsKey}>ESBN: </h4>
						<h4 className={styles.detailsValue}>
							{locationState.CoyESBN}
						</h4>
					</div>
					<div className={styles.employeeDetail}>
						<h4 className={styles.detailsKey}>PAYE Bill Number:</h4>
						<h4 className={styles.detailsValue}>
							{locationState.BillNumber}
						</h4>
					</div>
					<div className={styles.employeeDetail}>
						<h4 className={styles.detailsKey}>
							Amount for PAYE Type:
						</h4>
						{locationState.PayeType === "Yearly" ? (
							<h4 className={styles.detailsValue}>
								&#8358;{" "}
								{formatAmount(locationState.totAmtCharged)}
							</h4>
						) : (
							""
						)}
						{locationState.PayeType === "Monthly" ? (
							<h4 className={styles.detailsValue}>
								&#8358;{" "}
								{formatAmount(locationState.MonthlyTypeAmtBill)}
							</h4>
						) : (
							""
						)}

						{locationState.PayeType === "Period" ? (
							<h4 className={styles.detailsValue}>
								&#8358;{" "}
								{formatAmount(locationState.PeriodTypeAmtBill)}
							</h4>
						) : (
							""
						)}
					</div>
				</div>
				<div>
					<div className={styles.employeeDetail}>
						<h4 className={styles.detailsKey}>Assessment Type: </h4>
						<h4 className={styles.detailsValue}>
							{" "}
							{locationState.PayeType}
						</h4>
					</div>
					{locationState.PayeType === "Monthly" ? (
						<div className={styles.employeeDetail}>
							<h4 className={styles.detailsKey}>
								Schedule Period:
							</h4>
							<h4 className={styles.detailsValue}>
								{locationState.fiscalMth}&nbsp;
								{locationState.fiscalYear}
							</h4>
						</div>
					) : (
						""
					)}
					{locationState.PayeType === "Yearly" ? (
						<div className={styles.employeeDetail}>
							<h4 className={styles.detailsKey}>
								Schedule Period:
							</h4>
							<h4 className={styles.detailsValue}>
								{locationState.fiscalYear}
							</h4>
						</div>
					) : (
						""
					)}
					{locationState.PayeType === "Period" ? (
						<div className={styles.employeeDetail}>
							<h4 className={styles.detailsKey}>
								Schedule Period:
							</h4>
							<h4 className={styles.detailsValue}>
								{locationState.PayePeriodStartDate}/
								{locationState.PayePeriodEndDate}
							</h4>
						</div>
					) : (
						""
					)}
					<div className={styles.employeeDetail}>
						<h4 className={styles.detailsKey}>Process Status: </h4>
						<h4
							className={
								styles[`status-${locationState.ApproveFlg}`]
							}
						>
							{locationState.ProcessStatus}
						</h4>
					</div>
					<div className={styles.employeeDetail}>
						{locationState.PayeType === "Yearly" ? (
							<>
								<h4 className={styles.detailsKey}>
									Monthly Due:
								</h4>
								<h4 className={styles.detailsValue}>
									&#8358;{" "}
									{formatAmount(
										locationState.totMthAmtBilled
									)}
								</h4>
							</>
						) : (
							""
						)}
						{locationState.PayeType === "Period" ? (
							<>
								<h4 className={styles.detailsKey}>
									Monthly Due:
								</h4>
								<h4 className={styles.detailsValue}>
									&#8358;{" "}
									{formatAmount(
										locationState.totMthAmtBilled
									)}
								</h4>
							</>
						) : (
							""
						)}
					</div>
				</div>
				<div></div>
			</div>

			<h2 className={styles.companyDetailsHeader}> Employee Details </h2>
			<div className={styles1.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						onPageChanged={getBillDetails}
					/>
				)}
			</div>
			{requestConfirmation && (
				<div className={styles.cancelMiniModal}>
					<p>
						Are you sure you want to&nbsp;
						<strong style={{ color: "#34AA63" }}>
							Rquest Approval
						</strong>
						&nbsp;
						<br />
						for this PAYE Assessment with&nbsp;
						<strong>{locationState.CoyESBN}</strong> ESBN?
					</p>
					<div className={styles.miniModalBtns}>
						<button
							style={{ backgroundColor: "#ffff", color: "#000" }}
							onClick={() => setRequestConfirmation(false)}
						>
							No
						</button>
						<button
							style={{ backgroundColor: "#d73e3e" }}
							onClick={() => {
								requestApproval();
							}}
						>
							Yes
						</button>
					</div>
				</div>
			)}

			{declineConfirmation && (
				<div className={styles.cancelMiniModal}>
					<p>
						Are you sure you want to &nbsp;
						<strong style={{ color: "#d73e3e" }}>Decline</strong>
						&nbsp;this PAYE <br />
						Assessment with &nbsp;
						<strong>{locationState.CoyESBN}</strong> ESBN?
					</p>
					<div className={styles.miniModalBtns}>
						<button
							style={{ backgroundColor: "#ffff", color: "#000" }}
							onClick={() => setDeclineConfirmation(false)}
						>
							No
						</button>
						<button
							style={{ backgroundColor: "#d73e3e" }}
							onClick={() => {
								setIsDeclineModal(true);
								setDeclineConfirmation(false);
							}}
						>
							Yes
						</button>
					</div>
				</div>
			)}
			{locationState.ApproveFlg === "0" && (
				<div className={styles.approveRevoke_btn}>
					<button
						className={`primary__btn ${anssidStyles.create__btn}`}
						onClick={() => {
							setRequestConfirmation(true);
							setDeclineConfirmation(false);
						}}
					>
						Request Approval
					</button>
					<button
						className={["secondary__btn", styles.revoke__btn].join(
							" "
						)}
						onClick={() => {
							setDeclineConfirmation(true);
							setRequestConfirmation(false);
						}}
					>
						Decline
					</button>
				</div>
			)}

			{isDeclineModal && (
				<Modal toggle={setIsDeclineModal} showing={isDeclineModal}>
					<div className={authViewStyle.approvalSuccess__badge}>
						<img
							src={XcircleRed}
							alt="close button icon"
							style={{ width: "40px", height: "40px" }}
						/>
						<span>
							<h3>Declined </h3>
						</span>
					</div>
					<div className={authViewStyle.content}>
						<p>
							You have Declined <q>{locationState.CoyName}</q>
							PAYE Filing, an email would <br /> be sent to notify
							them accordingly.
						</p>
						<label htmlFor="reasonForDecline">
							Reason For Declining
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="reasonForDecline"
							placeholder="write the reason for declining this PAYE Assessment"
							type="text"
							value={declineReason}
							onChange={(e) => setDeclineReason(e.target.value)}
						/>
						<div className={authViewStyle.continueBtn}>
							<button
								className={[
									"primary__btn",
									authViewStyle.contBtn
								].join(" ")}
								onClick={() => {
									decline();
								}}
							>
								{loading ? "Continue..." : "Continue"}
							</button>
						</div>
					</div>
				</Modal>
			)}
			{billShow && (
				<Modal toggle={setBillShow} showing={billShow}>
					<div className={styles.closeBillbtn}>
						<img
							src={closeBtn}
							alt="close Button"
							onClick={() => setBillShow(false)}
						/>
					</div>
					{receiptData && (
						<div
							dangerouslySetInnerHTML={{
								__html: receiptData
							}}
							className={styles.doc__area}
							style={{
								marginTop: "24px"
							}}
						></div>
					)}
				</Modal>
			)}
		</main>
	);
};

export default ReviewerView;
