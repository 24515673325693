import { useEffect, useState, createRef, useMemo } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";

import ArrowDown from "../../assets/arrowDown.svg";
import ArrowUp from "../../assets/arrowUp.svg";
import Spinner from "../../components/spinner";
import Alert from "../../components/Alert";

import styles from "./style.module.css";

const FilterComponent = ({ title, filterText, onFilter }) => (
	<>
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "flex-end",
				width: "100%"
			}}
		>
			<p>
				<b>{title}</b>
			</p>
			<div>
				<label htmlFor="search">Search</label>
				<input
					id="search"
					type="text"
					onChange={onFilter}
					placeholder="Description"
					value={filterText}
					style={{ marginBottom: 10 }}
				/>
			</div>
		</div>
	</>
);

export const Card = ({
	cardName,
	codeValue,
	descriptionValue,
	onCodeChange,
	onDescriptionChange,
	urlSubstring,
	children,
	altChildren,
	state = {}
}) => {
	const [open, toggleOpen] = useState(false);
	const [sendingStatus, setSendingStatus] = useState(false);
	const [editingStatus, setEditingStatus] = useState(false);
	const [resourceId, setResourceId] = useState(null);
	const [loading, setLoadingStatus] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const codeInputRef = createRef();
	const [previousRecords, setPreviousRecords] = useState([]);
	const accessToken = localStorage.getItem("access_token");

	const [filterText, setFilterText] = useState("");

	const filteredPreviousRecords = (previousRecords || []).filter((record) =>
		record.Description.toLowerCase().includes(filterText.toLowerCase())
	);

	const subHeaderComponentMemo = useMemo(() => {
		return (
			<FilterComponent
				onFilter={(e) => setFilterText(e.target.value)}
				filterText={filterText}
				title={cardName}
			/>
		);
	}, [cardName, filterText]);

	const columns = [
		{
			selector: "Description",
			name: "Description",
			sortable: true
		},
		{
			name: "Action",
			sortable: false,
			cell: (record) => (
				<div className={styles.each__record}>
					<button
						type="button"
						className={styles.edit__btn}
						onClick={() => {
							setEditingStatus(true);
							setResourceId(record.id);
							onCodeChange(record.Code);
							onDescriptionChange(record.Description);
							codeInputRef.current.focus();
						}}
						disabled={editingStatus}
					>
						Edit
					</button>
					<button
						type="button"
						className={styles.deactivate__btn}
						onClick={() => deactivate(record.id)}
					>
						{record.Deactivated ? "Activate" : "Deactivate"}
					</button>
				</div>
			)
		}
	];

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	useEffect(() => {
		getPreviousRecords();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getPreviousRecords = async () => {
		setLoadingStatus(true);
		try {
			const request = await axios.get(`/settings/${urlSubstring}`, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			});

			setLoadingStatus(false);
			setPreviousRecords(request.data.data);
		} catch (error) {
			setLoadingStatus(false);
			if (error.response) {
				console.log("response", error.response.data.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.message
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		}
	};

	const submit = async (e, id) => {
		if (e) e.preventDefault();

		setSendingStatus(true);

		try {
			if (editingStatus) {
				const { data } = await axios.put(
					`/settings/${urlSubstring}/${id}`,
					{
						Code: codeValue,
						Description: descriptionValue,
						...state
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				);

				if (!data.data) {
					return setAlert({
						...alert,
						showing: true,
						type: "error",
						message: "Edit failed. Please try again."
					});
				}

				const index = previousRecords.findIndex((pr) => pr.id === id);

				if (index < 0) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: `Record not found`
					});
					setSendingStatus(false);
					setEditingStatus(false);
					onDescriptionChange("");
					onCodeChange("");
					return;
				}

				const updatedPrevious = previousRecords.slice();
				updatedPrevious.splice(index, 1, data.data);
				setPreviousRecords(updatedPrevious);
				setSendingStatus(false);
				setEditingStatus(false);

				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: `Record Edited`
				});
				onDescriptionChange("");
				onCodeChange("");
			} else {
				const { data } = await axios.post(
					`/settings/${urlSubstring}`,
					{
						Code: codeValue,
						Description: descriptionValue,
						...state
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				);
				setSendingStatus(false);

				if (!data.data) {
					return setAlert({
						...alert,
						showing: true,
						type: "error",
						message: "Something bad happened"
					});
				}

				const updatedPrevious = previousRecords.slice();
				updatedPrevious.push(data.data);
				setPreviousRecords(updatedPrevious);

				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: "Saved!"
				});
				onDescriptionChange("");
				onCodeChange("");
			}
		} catch (error) {
			setSendingStatus(false);
			if (error.response) {
				console.log("response", error.response.data.msg);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.message
				});
			} else if (error.request) {
				console.log("request", error.request);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: "Something went wrong. Check your internet."
				});
			} else {
				console.log("Error", error.message);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const deactivate = async (id) => {
		try {
			const { data } = await axios.put(
				`/settings/${urlSubstring}/${id}`,
				{ deactivate: true },
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);

			if (!data.data) {
				return setAlert({
					...alert,
					showing: true,
					type: "error",
					message: "Something bad happened"
				});
			}

			const index = previousRecords.findIndex((pr) => pr.id === id);
			const record = data.data;

			if (index < 0) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: `Record not found`
				});
			}

			const updatedPrevious = previousRecords.slice();
			updatedPrevious[index] = record;
			setPreviousRecords(updatedPrevious);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: `Record ${
					record.Deactivated ? "Deactivated" : "Activated"
				}`
			});
		} catch (error) {
			if (error.response) {
				console.log("response", error.response.data.msg);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.msg
				});
			} else {
				console.log("Error", error.message);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	return (
		<>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles.card}>
				<div
					className={
						open
							? [styles.card__top, styles.bordered].join(" ")
							: styles.card__top
					}
				>
					<h5>{cardName}</h5>
					<button onClick={() => toggleOpen(!open)}>
						{open ? (
							<img src={ArrowUp} alt="up arrow" />
						) : (
							<img src={ArrowDown} alt="down arrow" />
						)}
					</button>
				</div>
				{open && (
					<>
						<form
							className={styles.idDeetsForm}
							onSubmit={(e) => submit(e, resourceId)}
						>
							<div className={styles.input__container}>
								<div>
									<label htmlFor={`${cardName}code`}>
										Code <span>*</span>
									</label>
									<input
										type="text"
										id={`${cardName}code`}
										name={`${cardName}code`}
										value={codeValue}
										onChange={(e) =>
											onCodeChange(e.target.value)
										}
										placeholder="eg 10101010"
										required
										ref={codeInputRef}
									/>
									{children}
								</div>
								<div>
									<label htmlFor={`${cardName}description`}>
										Description <span>*</span>
									</label>
									<input
										type="text"
										id={`${cardName}description`}
										name={`${cardName}description`}
										value={descriptionValue}
										onChange={(e) =>
											onDescriptionChange(e.target.value)
										}
										placeholder="description"
										required
									/>
									{altChildren}
								</div>
							</div>
							{editingStatus ? (
								<button
									type="button"
									className={styles.cancelEdit__btn}
									onClick={() => {
										setEditingStatus(false);
										onCodeChange("");
										onDescriptionChange("");
									}}
								>
									Cancel Edit
								</button>
							) : (
								<button
									disabled={
										sendingStatus ||
										!codeValue ||
										!descriptionValue
									}
									type="button"
									className={styles.addNew__btn}
									onClick={submit}
								>
									+ Add New {sendingStatus && <Spinner />}
								</button>
							)}
							<button
								type="submit"
								className="primary__btn"
								disabled={
									sendingStatus ||
									!codeValue ||
									!descriptionValue
								}
							>
								{sendingStatus ? "Saving..." : "Save"}
							</button>
						</form>
						{loading && (
							<p
								style={{
									paddingBottom: "30px",
									textAlign: "center"
								}}
							>
								Loading...
							</p>
						)}
						{!loading && previousRecords && (
							<div className={styles.records}>
								{/* <p>
									<b>
										{previousRecords.length} previous{" "}
										{previousRecords.length === 1
											? "entry"
											: "entries"}
									</b>
								</p> */}
								<DataTable
									title={subHeaderComponentMemo}
									columns={columns}
									data={filteredPreviousRecords}
									progressPending={loading}
									pagination
									paginationTotalRows={
										filteredPreviousRecords.length
									}
									paginationPerPage={10}
								/>
							</div>
						)}
					</>
				)}
			</div>
		</>
	);
};

const IDDetails = () => {
	//Title
	const [titleCode, setTitleCode] = useState("");
	const [titleDescription, setTitleDescription] = useState("");

	//Gender
	const [genderCode, setGenderCode] = useState("");
	const [genderDescription, setGenderDescription] = useState("");

	//Country
	const [countryCode, setCountryCode] = useState("");
	const [countryDescription, setCountryDescription] = useState("");

	//State
	const [stateCode, setStateCode] = useState("");
	const [stateDescription, setStateDescription] = useState("");

	//LGA
	const [LGACode, setLGACode] = useState("");
	const [LGADescription, setLGADescription] = useState("");

	//City
	const [cityCode, setCityCode] = useState("");
	const [cityDescription, setCityDescription] = useState("");

	//Occupation
	const [occupationCode, setOccupationCode] = useState("");
	const [occupationDescription, setOccupationDescription] = useState("");

	//Identity Type
	const [identityTypeCode, setIdentityTypeCode] = useState("");
	const [identityTypeDescription, setIdentityTypeDescription] = useState("");

	//MDA
	const [MDACode, setMDACode] = useState("");
	const [MDADescription, setMDADescription] = useState("");

	//Agency Type
	const [agencyTypeCode, setAgencyTypeCode] = useState("");
	const [agencyTypeDescription, setAgencyTypeDescription] = useState("");

	//Revenue Type
	const [revenueHeadCode, setRevenueHeadCode] = useState("");
	const [revenueHeadDescription, setRevenueHeadDescription] = useState("");
	const [revenueHeadCallbackUrl, setRevenueHeadCallbackUrl] = useState("");
	const [revenueHeadCallbackToken, setRevenueHeadCallbackToken] =
		useState("");

	//Bill Group
	const [billGroupCode, setBillGroupCode] = useState("");
	const [billGroupDescription, setBillGroupDescription] = useState("");

	//Economic Sector
	const [economicSectorCode, setEconomicSectorCode] = useState("");
	const [economicSectorDescription, setEconomicSectorDescription] =
		useState("");

	//Revenue Source
	const [revenueSourceCode, setRevenueSourceCode] = useState("");
	const [revenueSourceDescription, setRevenueSourceDescription] =
		useState("");

	//Category
	const [categoryCode, setCategoryCode] = useState("");
	const [categoryDescription, setCategoryDescription] = useState("");

	//PaymentStatus
	const [paymentStatusCode, setPaymentStatusCode] = useState("");
	const [paymentStatusDescription, setPaymentDescription] = useState("");

	//Tax Authority
	const [taxAuthorityCode, setTaxAuthorityCode] = useState("");
	const [taxAuthorityDescription, setTaxAuthorityDescription] = useState("");

	//Tax Office
	const [taxOfficeCode, setTaxOfficeCode] = useState("");
	const [taxOfficeDescription, setTaxOfficeDescription] = useState("");

	//Location
	const [locationCode, setLocationCode] = useState("");
	const [locationDescription, setLocationDescription] = useState("");

	return (
		<div className={styles.settings__page}>
			<Card
				cardName="Title"
				codeValue={titleCode}
				onCodeChange={setTitleCode}
				descriptionValue={titleDescription}
				onDescriptionChange={setTitleDescription}
				urlSubstring={"title"}
			/>
			<Card
				cardName="Gender"
				codeValue={genderCode}
				onCodeChange={setGenderCode}
				descriptionValue={genderDescription}
				onDescriptionChange={setGenderDescription}
				urlSubstring={"gender"}
			/>
			<Card
				cardName="Country"
				codeValue={countryCode}
				onCodeChange={setCountryCode}
				descriptionValue={countryDescription}
				onDescriptionChange={setCountryDescription}
				urlSubstring={"country"}
			/>
			<Card
				cardName="State"
				codeValue={stateCode}
				onCodeChange={setStateCode}
				descriptionValue={stateDescription}
				onDescriptionChange={setStateDescription}
				urlSubstring={"state"}
			/>
			<Card
				cardName="LGA"
				codeValue={LGACode}
				onCodeChange={setLGACode}
				descriptionValue={LGADescription}
				onDescriptionChange={setLGADescription}
				urlSubstring={"lga"}
			/>
			<Card
				cardName="City"
				codeValue={cityCode}
				onCodeChange={setCityCode}
				descriptionValue={cityDescription}
				onDescriptionChange={setCityDescription}
				urlSubstring={"city"}
			/>
			<Card
				cardName="Occupation"
				codeValue={occupationCode}
				onCodeChange={setOccupationCode}
				descriptionValue={occupationDescription}
				onDescriptionChange={setOccupationDescription}
				urlSubstring={"occupation"}
			/>
			<Card
				cardName="Economic Sector"
				codeValue={economicSectorCode}
				onCodeChange={setEconomicSectorCode}
				descriptionValue={economicSectorDescription}
				onDescriptionChange={setEconomicSectorDescription}
				urlSubstring={"economicsector"}
			/>
			<Card
				cardName="Identity Type"
				codeValue={identityTypeCode}
				onCodeChange={setIdentityTypeCode}
				descriptionValue={identityTypeDescription}
				onDescriptionChange={setIdentityTypeDescription}
				urlSubstring={"identitytype"}
			/>
			<Card
				cardName="MDA"
				codeValue={MDACode}
				onCodeChange={setMDACode}
				descriptionValue={MDADescription}
				onDescriptionChange={setMDADescription}
				urlSubstring={"mdasetup"}
			/>
			<Card
				cardName="Agency Type"
				codeValue={agencyTypeCode}
				onCodeChange={setAgencyTypeCode}
				descriptionValue={agencyTypeDescription}
				onDescriptionChange={setAgencyTypeDescription}
				urlSubstring={"agency"}
			/>
			<Card
				cardName="Revenue Head"
				codeValue={revenueHeadCode}
				onCodeChange={setRevenueHeadCode}
				descriptionValue={revenueHeadDescription}
				onDescriptionChange={setRevenueHeadDescription}
				urlSubstring={"revenuehead"}
				state={{
					CallbackUrl: revenueHeadCallbackUrl,
					Token: revenueHeadCallbackToken
				}}
				altChildren={
					<div>
						<label htmlFor={`Revenue HeadcallbackToken`}>
							Callback Token
						</label>
						<input
							type="text"
							id={`Revenue HeadcallbackToken`}
							name={`Revenue HeadcallbackToken`}
							value={revenueHeadCallbackToken}
							onChange={(e) =>
								setRevenueHeadCallbackToken(e.target.value)
							}
							placeholder="easacd23esds232sd"
						/>
					</div>
				}
			>
				<div>
					<label htmlFor={`Revenue HeadcallbackUrl`}>
						Callback URL
					</label>
					<input
						type="text"
						id={`Revenue HeadcallbackUrl`}
						name={`Revenue HeadcallbackUrl`}
						value={revenueHeadCallbackUrl}
						onChange={(e) =>
							setRevenueHeadCallbackUrl(e.target.value)
						}
						placeholder="https://example.com"
					/>
				</div>
			</Card>
			<Card
				cardName="Bill Group"
				codeValue={billGroupCode}
				onCodeChange={setBillGroupCode}
				descriptionValue={billGroupDescription}
				onDescriptionChange={setBillGroupDescription}
				urlSubstring={"billgroup"}
			/>
			<Card
				cardName="Revenue Source"
				codeValue={revenueSourceCode}
				onCodeChange={setRevenueSourceCode}
				descriptionValue={revenueSourceDescription}
				onDescriptionChange={setRevenueSourceDescription}
				urlSubstring={"revenuesource"}
			/>
			<Card
				cardName="Category"
				codeValue={categoryCode}
				onCodeChange={setCategoryCode}
				descriptionValue={categoryDescription}
				onDescriptionChange={setCategoryDescription}
				urlSubstring={"category"}
			/>
			<Card
				cardName="Payment Status"
				codeValue={paymentStatusCode}
				onCodeChange={setPaymentStatusCode}
				descriptionValue={paymentStatusDescription}
				onDescriptionChange={setPaymentDescription}
				urlSubstring={"paymentstatus"}
			/>
			<Card
				cardName="Tax Authority"
				codeValue={taxAuthorityCode}
				onCodeChange={setTaxAuthorityCode}
				descriptionValue={taxAuthorityDescription}
				onDescriptionChange={setTaxAuthorityDescription}
				urlSubstring={"taxauthority"}
			/>
			<Card
				cardName="Tax Office"
				codeValue={taxOfficeCode}
				onCodeChange={setTaxOfficeCode}
				descriptionValue={taxOfficeDescription}
				onDescriptionChange={setTaxOfficeDescription}
				urlSubstring={"taxoffice"}
			/>
			<Card
				cardName="Location"
				codeValue={locationCode}
				onCodeChange={setLocationCode}
				descriptionValue={locationDescription}
				onDescriptionChange={setLocationDescription}
				urlSubstring={"locationsetup"}
			/>
		</div>
	);
};

export default IDDetails;