import React from "react";
import { useEffect, useState } from "react";
import {
	useParams,
	useHistory
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Alert from "../components/Alert";
import styles from "./style.module.css";
import styles1 from "../Identity-management-individual/style.module.css";
import Spinner from "../../src/components/spinner";
import constants from "../constants";

axios.defaults.base1URL = constants.BASE1_URL;
const ViewMaker = () => {
	const token = localStorage.getItem("access_token");
	const history = useHistory();
	const user = JSON.parse(localStorage.getItem("user_info"));
	const [esbn, setESBN] = useState("");
	const [customerName, setCusName] = useState("");
	const [branchName, setBank] = useState("");
	const [billNumber, setBillNumber] = useState("");
	const [paymentMethod, setPayMth] = useState("");
	const [totalAmountPaid, setTAmount] = useState("");
	const [paymentDate, setPaymentDate] = useState("");
	const [address, setAddress] = useState("");
	const [depositorsPhone, setDepositorsPhone] = useState("");
	const [depositorsRcNumber, setDepositorsRcNumber] = useState("");
	const [depositSlipNumber, setDepositSlipNumber] = useState("");
	const [paymentRefNumber, setPaymentRefNumber] = useState("");
	const [receiptNumber, setReceiptNumber] = useState("");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const [getrev, setRevhead] = useState([]);
	const [approveRejectReason, setApproveRejectReason] = useState("");
	const [msg] = useState("");
	const [confirmationModal] = useState(false);
	const [getmdas, setMdahead] = useState([]);
	const [revenue, setRevenue] = useState("");
	const [mda, setMdas] = useState("");

	const { id } = useParams();

	const [loading, setLoading] = useState(false);
	const [rejectLoading, setRejectLoading] = useState(false);

	const [setAllData] = useState();
	const [approveRejectModal, setApproveRejectModal] = useState(false);

	const getManualRecon = async () => {
		setLoading(true);
		try {
			const response = await axios.get(
				`${constants.BASE1_URL}/manualRecon/getAManualReconRecord/${id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);

			const data = response.data;

			setLoading(false);

			if (response.status === 200) {
				const {
					esbn,
					customerName,
					branchName,
					billNumber,
					paymentMethod,
					totalAmountPaid,
					address,
					revenue,
					paymentDate,
					depositorsPhone,
					depositorsRcNumber,
					depositSlipNumber,
					paymentRefNumber,
					receiptNumber,
					mda
				} = data.data;

				setESBN(esbn);
				setCusName(customerName);
				setBank(branchName);
				setBillNumber(billNumber);
				setPayMth(paymentMethod);
				setTAmount(totalAmountPaid);
				setAddress(address);
				setRevenue(revenue);
				setPaymentDate(paymentDate);
				setDepositorsPhone(depositorsPhone);
				setDepositorsRcNumber(depositorsRcNumber);
				setDepositSlipNumber(depositSlipNumber);
				setPaymentRefNumber(paymentRefNumber);
				setReceiptNumber(receiptNumber);
				setMdas(mda);
				setAllData(data.data);
			}
		} catch (error) {
			setLoading(false);
			// Handle the error here, such as displaying a message to the user
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message:
					error.response.data?.msg ||
					error.response.data?.errors?.[0] ||
					error.response.data?.errors?.[0]?.message ||
					error.response.data?.errors?.details[0]?.message ||
					error.response.message
			});
		}
	};

	useEffect(() => {
		getManualRecon();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleReject(event) {
		event.preventDefault();
		setApproveRejectModal(true);
	}

	async function reject(event) {
		event.preventDefault();
		setRejectLoading(true);
		const response = await fetch(
			`${constants.BASE1_URL}/manualRecon/authorizeManualReconRecord/${id}`,
			{
				method: "PATCH",
				body: JSON.stringify({
					approveRejectFlag: 2,
					authoriser: user.fullname,
					approveRejectReason: approveRejectReason
				}),
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				}
			}
		);

		if (response) setRejectLoading(false);
		if (response.ok) {
			setApproveRejectModal(false);
			history.push("/Maker-checker/authoriser");
		}
	}

	async function handleAuthorise(event) {
		event.preventDefault();
		setRejectLoading(true);
		const response = await fetch(
			`${constants.BASE1_URL}/manualRecon/authorizeManualReconRecord/${id}`,
			{
				method: "PATCH",
				body: JSON.stringify({
					approveRejectFlag: 1,
					authoriser: user.fullname
				}),
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				}
			}
		);

		if (response && response.ok) {
			setRejectLoading(false);
			history.push("/Maker-checker/authoriser");
		}
	}

	const RevenueHead = async () => {
		const res = await axios
			.get(`/settings/mdalinkedrevenue/${mda}`)
			.then((res) => (res.data || {}).data);
		setRevhead(res);
	};

	useEffect(() => {
		RevenueHead();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mda]);

	const Mdas = async () => {
		const res = await axios
			.get(`/settings/allmdas`)
			.then((res) => (res.data || {}).data);
		setMdahead(res);
	};

	useEffect(() => {
		Mdas();
	}, []);

	return (
		<div>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form className={[styles1.columned__form, styles.main].join(" ")}>
				<div className={styles1.double__inputs}>
					<div>
						<label className={styles1.input__label} htmlFor="esbn">
							ESBN <span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="esbn"
							type="text"
							id="esbn"
							value={esbn}
							onChange={(e) => setESBN(e.target.value)}
							placeholder="eg. 01"
							required
							disabled
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="customerName"
						>
							Customer Name
						</label>
						<input
							name="customerName"
							type="text"
							id="customerName"
							value={customerName}
							onChange={(e) => setCusName(e.target.value)}
							required
							disabled
						/>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="billNumber"
						>
							Bill Number <span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="billNumber"
							type="text"
							id="billNumber"
							value={billNumber}
							onChange={(e) => setBillNumber(e.target.value)}
							placeholder="eg. 01"
							required
							disabled
						/>
					</div>
					<div>
						<label className={styles1.input__label} htmlFor="bank">
							Bank
						</label>
						<input
							name="bank"
							type="text"
							id="bank"
							value={branchName}
							onChange={(e) => setBank(e.target.value)}
							required
							disabled
						/>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="address"
						>
							Address <span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="address"
							type="text"
							id="address"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							placeholder="eg. New Haven "
							disabled
						/>
					</div>
				</div>
				<hr />
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="depositorsPhone"
						>
							Depositor’s Phone No.{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="depositorsPhone"
							type="text"
							id="depositorsPhone"
							value={depositorsPhone}
							onChange={(e) => setDepositorsPhone(e.target.value)}
							placeholder="Enter Phone Number"
							required
							disabled
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="depositSlipNumber"
						>
							Deposit Slip No.
						</label>
						<input
							name="depositSlipNumber"
							type="text"
							id="depositSlipNumber"
							value={depositSlipNumber}
							onChange={(e) =>
								setDepositSlipNumber(e.target.value)
							}
							required
							disabled
						/>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="depositor"
						>
							Depositor’s RC-No{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="depositor"
							type="text"
							id="depositor"
							value={depositorsRcNumber}
							onChange={(e) =>
								setDepositorsRcNumber(e.target.value)
							}
							placeholder="eg. 01"
							required
							disabled
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="paymentref"
						>
							Payment Ref No.
						</label>
						<input
							name="paymentref"
							type="text"
							id="paymentref"
							value={paymentRefNumber}
							onChange={(e) =>
								setPaymentRefNumber(e.target.value)
							}
							required
							disabled
						/>
					</div>
				</div>
				<hr />
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="amount"
						>
							Total Amount Paid{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="amount"
							type="number"
							id="amount"
							value={totalAmountPaid}
							onChange={(e) => setTAmount(e.target.value)}
							placeholder="eg. 01"
							required
							disabled
						/>
					</div>
					<div>
						<label className={styles1.input__label} htmlFor="recpt">
							Receipt No
						</label>
						<input
							name="recpt"
							type="text"
							id="recpt"
							value={receiptNumber}
							onChange={(e) => setReceiptNumber(e.target.value)}
							required
							disabled
						/>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="paymentMethod"
						>
							Payment Method{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="paymentMethod"
							type="text"
							id="paymentMethod"
							value={paymentMethod}
							onChange={(e) => setPayMth(e.target.value)}
							placeholder="eg. 01"
							required
							disabled
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="PaymentDate"
						>
							Payment Date
						</label>
						<input
							name="PaymentDate"
							type="date"
							id="PaymentDate"
							value={paymentDate}
							onChange={(e) => setPaymentDate(e.target.value)}
							required
							disabled
						/>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="mda">
							MDAS <span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="mda"
							className={styles.input__medium}
							name="mda"
							onChange={(e) => {
								setMdas(e.target.value);
							}}
							value={mda}
							required
							disabled
						>
							<option value={""}>
								{getmdas ? "--Select MDA --" : "Loading..."}
							</option>
							{getmdas &&
								getmdas.map((getMdas) => (
									<option value={getMdas.Code}>
										{getMdas.Description}
									</option>
								))}
						</select>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="revenue"
						>
							Revenue <span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="revenue"
							className={styles.input__medium}
							name="revenue"
							onChange={(e) => {
								setRevenue(e.target.value);
							}}
							value={revenue}
							required
							disabled
						>
							<option value={""}>
								{getrev
									? "--Select Revenue head--"
									: "Loading..."}
							</option>
							{getrev &&
								getrev.map((getRev) => (
									<option value={getRev.Code}>
										{getRev.Description}
									</option>
								))}
						</select>
					</div>
				</div>

				<div className={styles.setbtn}>
					<div>
						<button
							onClick={handleReject}
							className={[
								"tertiary__btn",
								styles.center_btn
							].join(" ")}
						>
							Reject
						</button>
					</div>
					<div>
						{rejectLoading ? (
							<div className={[styles.btn]}>
								<Spinner />
							</div>
						) : (
							<button
								onClick={handleAuthorise}
								className={["primary__btn", styles.btn].join(
									" "
								)}
							>
								Approve
							</button>
						)}
					</div>
				</div>
			</form>
			{approveRejectModal && (
				<div className="approveRejectModalBg">
					<div className="approveRejectFlagModal">
						<div>
							<p>Authoriser</p>
							<p>{user && user.fullname}</p>
						</div>
						<div>
							<p>Rejection Reason</p>
							<input
								type="text"
								onChange={(e) =>
									setApproveRejectReason(e.target.value)
								}
							/>
						</div>
						{rejectLoading ? (
							loading && <Spinner />
						) : (
							<button
								onClick={reject}
								className={[
									"tertiary__btn",
									styles.center_btn
								].join(" ")}
							>
								Reject
							</button>
						)}
						{approveRejectModal && (
							<p
								onClick={() =>
									setApproveRejectModal(!approveRejectModal)
								}
							>
								close
							</p>
						)}
					</div>
				</div>
			)}

			{confirmationModal && (
				<div className="approveRejectModalBg">
					<div className="approveRejectFlagModal">
						<div>{msg}</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ViewMaker;
