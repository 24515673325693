import React, { useEffect, useRef } from "react";

export const H1Document = ({ data, getFile }) => {
	const H1ModalRef = useRef();

	const totalTaxDue = data?.data?.EmployeeDetails?.reduce(
		(accumulator, currentValue) =>
			accumulator + Number(currentValue.annualCalcTaxDue),
		0
	);

	const totalAmountPaid = 0;

	useEffect(() => {
		getFile(document.getElementById("H1ModalRef").outerHTML);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [H1ModalRef.current]);

	return (
		<div
			ref={H1ModalRef}
			id="H1ModalRef"
			style={{
				height: "auto",
				width: "10in",
				margin: "auto",
				padding: ".4rem",
				border: "1px solid rgb(0, 0, 0)",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "100%",
				fontFamily: "Arial, Helvetica, sans-serif"
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center"
				}}
			>
				<img
					src="https://enunustategovernment.netlify.app/WhatsApp%20Image%202023-11-20%20at%2020.28.23_c0f4fe22.jpg"
					style={{ width: "60%" }}
					alt="Logo"
				/>
			</div>
			<h1
				style={{
					margin: "10px 0 0",
					fontSize: "22px",
					textAlign: "center",
					color: "#00a331"
				}}
			>
				H1 FILING
			</h1>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					fontSize: "15px",
					gap: "5rem",
					border: "1px solid green",
					padding: "10px"
				}}
			>
				<table>
					<tbody>
						<tr>
							<td
								style={{
									color: "#4b4b4b",
									fontWeight: "bolder"
								}}
							>
								Organisation:
							</td>
							<td
								style={{
									color: "#00a331",
									fontWeight: "bolder"
								}}
							>
								{data?.data?.CompanyDetails?.name ?? ""}
							</td>
						</tr>
						<tr>
							<td
								style={{
									padding: "5px 0",
									color: "#4b4b4b",
									fontWeight: "bolder"
								}}
							>
								ESBN:
							</td>
							<td
								style={{
									color: "#00a331",
									fontWeight: "bolder"
								}}
							>
								{data?.data?.esbn ?? ""}
							</td>
						</tr>
						<tr>
							<td
								style={{
									width: "150px",
									padding: "5px 0",
									color: "#4b4b4b",
									fontWeight: "bolder"
								}}
							>
								Email Address:
							</td>
							<td
								style={{
									color: "#00a331",
									fontWeight: "bolder"
								}}
							>
								{data?.data?.CompanyDetails?.email_Address ??
									""}
							</td>
						</tr>
						<tr>
							<td
								style={{
									color: "#4b4b4b",
									fontWeight: "bolder"
								}}
							>
								Sector:
							</td>
							<td
								style={{
									color: "#00a331",
									fontWeight: "bolder"
								}}
							>
								{data?.data?.CompanyDetails?.economic_Sector ??
									""}
							</td>
						</tr>
					</tbody>
				</table>
				<table>
					<tbody>
						<tr>
							<td
								style={{
									color: "#4b4b4b",
									fontWeight: "bolder"
								}}
							>
								Address:
							</td>
							<td
								style={{
									color: "#00a331",
									fontWeight: "bolder"
								}}
							>
								{data?.data?.CompanyDetails
									?.state_Office_Address ?? ""}
							</td>
						</tr>
						<tr>
							<td
								style={{
									padding: "5px 0",
									color: "#4b4b4b",
									fontWeight: "bolder"
								}}
							>
								Contact Phone:
							</td>
							<td
								style={{
									color: "#00a331",
									fontWeight: "bolder"
								}}
							>
								{data?.data?.CompanyDetails?.phone_Number ?? ""}
							</td>
						</tr>
						<tr>
							<td
								style={{
									padding: "5px 0",
									color: "#4b4b4b",
									fontWeight: "bolder"
								}}
							>
								Filing Year:
							</td>
							<td
								style={{
									color: "#00a331",
									fontWeight: "bolder"
								}}
							>
								{data?.data?.annualReturnInfo?.filingYear ?? ""}
							</td>
						</tr>
						<tr>
							<td
								style={{
									color: "#4b4b4b",
									fontWeight: "bolder"
								}}
							>
								Submission Status:
							</td>
							<td style={{ color: "#000", fontWeight: "bolder" }}>
								&nbsp;{" "}
								{data?.data?.annualReturnInfo?.filingStatus.toUpperCase() ||
									"PENDING"}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<h3 style={{ marginBottom: "0", color: "#00a331" }}>
				EMPLOYEE DETAILS
			</h3>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					padding: "10px 15px",
					backgroundColor: "rgb(179, 255, 230)",
					marginBottom: "10px"
				}}
			>
				<p style={{ margin: "0", fontWeight: "bold" }}>
					Total Employee:{" "}
					<span>
						{data?.data?.CompanyDetails?.number_Of_Employees}
					</span>{" "}
				</p>
				<p style={{ margin: "0", fontWeight: "bold" }}>
					Total Tax Due: <span>N{totalTaxDue}</span>{" "}
				</p>
			</div>
			<table style={{ borderCollapse: "collapse", width: "100%" }}>
				<thead style={{ border: "1px solid #000" }}>
					<tr
						style={{
							backgroundColor: "rgb(0, 112, 84)",
							color: "white",
							fontSize: "16px"
						}}
					>
						<th>S/N</th>
						<th>Employee Name</th>
						<th>No. of Months</th>
						<th>Employee ESBN</th>
						<th>Total Gross Salary</th>
						<th>Total Relief</th>
						<th>Annual Tax Due</th>
					</tr>
				</thead>
				<tbody style={{ border: "1px solid #000" }}>
					{data?.data?.EmployeeDetails &&
						data?.data?.EmployeeDetails?.map((employee, index) => (
							<React.Fragment key={employee?.id + index}>
								<tr style={{ border: "1px solid #000" }}>
									<td style={{ border: "1px solid #000" }}>
										{index + 1}
									</td>
									<td style={{ border: "1px solid #000" }}>
										{employee?.fullName}
									</td>
									<td style={{ border: "1px solid #000" }}>
										{employee?.monthsInService}
									</td>
									<td style={{ border: "1px solid #000" }}>
										{employee?.eSBN}
									</td>
									<td style={{ border: "1px solid #000" }}>
										{employee?.annualGrossSalary}
									</td>
									<td style={{ border: "1px solid #000" }}>
										{employee?.annualCalcTotalRelief}
									</td>
									<td style={{ border: "1px solid #000" }}>
										{employee?.annualCalcTaxDue}
									</td>
								</tr>
							</React.Fragment>
						))}
				</tbody>
			</table>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<div style={{ width: "50%" }}>
					<p
						style={{
							backgroundColor: "rgb(0, 112, 84)",
							color: "white",
							padding: "7px",
							margin: "2px 5px 0 0"
						}}
					>
						SUPPORTING DOCUMENTS
					</p>
					<table
						style={{
							width: "100%",
							backgroundColor: "#fff",
							padding: "13px",
							border: "1px solid #000",
							margin: "0"
						}}
					>
						<tbody>
							{data?.data?.docs?.map((doc, index) => (
								<React.Fragment key={doc?.id + index}>
									<tr>
										<td
											style={{
												color: "#4b4b4b",
												fontWeight: "bolder"
											}}
										>
											{doc?.description}:
										</td>
										<td style={{ color: "#00a331" }}>
											{doc?.data && (
												<a
													href={doc?.data}
													alt={doc?.description}
													style={{ color: "#00a331" }}
													download
												>
													{doc?.description}
												</a>
											)}
										</td>
									</tr>
								</React.Fragment>
							))}
						</tbody>
					</table>
				</div>
				<div style={{ width: "40%" }}>
					<p
						style={{
							fontWeight: "bold",
							color: "rgb(0, 112, 84)",
							marginBottom: "4px"
						}}
					>
						VARIANCE
					</p>
					<table
						style={{ borderCollapse: "collapse", width: "100%" }}
					>
						<tbody style={{ border: "1px solid #000" }}>
							<tr
								style={{
									textAlign: "center",
									backgroundColor: "rgb(0, 112, 84)",
									color: "white"
								}}
							>
								<td style={{ textAlign: "center" }} colSpan="2">
									{"AMOUNT(N)"}
								</td>
							</tr>
							<tr style={{ border: "1px solid #000" }}>
								<td style={{ border: "1px solid #000" }}>
									TOTAL TAX DUE (1)
								</td>
								<td style={{ border: "1px solid #000" }}>
									N{totalTaxDue}
								</td>
							</tr>
							<tr style={{ border: "1px solid #000" }}>
								<td style={{ border: "1px solid #000" }}>
									TOTAL AMOUNT PAID(2)
								</td>
								<td style={{ border: "1px solid #000" }}>
									N{totalAmountPaid}
								</td>
							</tr>
							<tr style={{ border: "1px solid #000" }}>
								<td style={{ border: "1px solid #000" }}>
									VARIANCE (1-2)
								</td>
								<td style={{ border: "1px solid #000" }}>
									N{totalTaxDue - totalAmountPaid}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<p style={{ padding: "10px", border: "1px solid gray" }}>
				Affirmation: I hereby certify that the above is a complete and
				accurate list of staff of the company that are tax resident in
				ENUGU state. I also certify that the information on their
				salaries and allowances are accurate and exhausive. I make this
				certification knowing it to be true in accordance with the
				provisions of the Personal Income Tax Act(Ammended) 2011 and
				regulations of the Personal Income Tax Act 2004(as ammendde).
			</p>

			<div
				style={{
					display: "flex",
					marginTop: "50px",
					flexDirection: "row-reverse",
					position: "relative",
					justifyContent: "space-between"
				}}
			>
				{/* <!-- <p className="dispatch-ref" style={{margin-top: 65px}}>Dispatch Ref: 82648</p> --> */}
				<div
					className="chair-person"
					style={{ fontSize: "14px", width: "30%" }}
				>
					<div style={{ textAlign: "center" }}>
						{data?.data?.signature?.signature && (
							<img
								src={data?.data?.signature?.signature}
								alt="Signature"
								style={{ width: "60px" }}
							/>
						)}
						<h1 style={{ margin: "1px 0 0", fontSize: "15px" }}>
							{data?.data?.signature?.name ?? ""}
						</h1>
						<p style={{ margin: "5px 0" }}>
							{data?.data?.signature?.designation ?? ""}
						</p>
						<p style={{ margin: "0" }}>
							{data?.data?.signature?.phoneNo ?? ""}
						</p>
					</div>
				</div>

				<div
					className="qr-code"
					style={{
						display: "flex",
						alignItems: "center",
						gap: "10px",
						marginTop: "10px"
					}}
				>
					<td style={{ textAlign: "right", width: "30%" }}>
						<svg
							height="64"
							style={{ height: "64px", width: "64px" }}
							width="64"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M 0 0 L 2 0 L 2 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,2,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,10,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,29,0)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,35,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,47,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,52,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,0)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,60,0)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,0)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,6,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,16,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,17,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,2)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,33,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,47,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,54,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,56,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,58,2)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,2)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,2)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,17,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,21,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,23,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,4)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,35,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,4)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,4)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,4)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,21,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,29,6)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,6)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,6)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,6)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,19,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,21,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,29,8)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,33,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,45,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,8)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,8)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,8)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,6,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,16,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,23,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,10)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,33,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,45,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,47,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,54,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,56,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,58,10)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,10)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,10)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,2,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,10,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,17,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,19,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,23,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,12)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,35,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,45,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,52,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,12)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,60,12)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,12)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,0,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,6,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,12,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,16,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,21,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,29,14)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,33,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,45,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,50,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,54,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,56,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,58,14)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,14)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,62,14)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,2,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,17,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,16)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,33,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,47,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,48,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,50,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,16)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,16)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,16)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,0,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,12,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,17)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,48,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,54,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,56,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,17)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,60,17)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,17)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,10,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,14,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,16,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,17,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,19)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,52,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,54,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,56,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,19)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,60,19)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,19)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,2,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,6,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,12,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,16,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,17,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,21,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,23,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,21)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,47,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,52,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,56,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,58,21)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,60,21)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,62,21)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,6,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,21,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,29,23)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,33,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,35,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,45,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,47,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,48,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,50,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,52,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,58,23)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,23)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,62,23)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,0,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,2,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,12,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,19,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,21,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,29,25)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,33,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,35,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,45,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,48,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,50,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,52,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,54,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,25)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,60,25)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,62,25)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,2,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,6,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,14,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,16,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,23,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,27)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,45,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,50,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,56,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,27)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,27)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,62,27)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,10,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,12,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,14,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,17,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,19,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,23,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,29,29)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,35,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,50,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,54,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,56,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,29)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,29)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,62,29)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,6,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,10,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,16,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,19,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,21,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,31)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,47,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,52,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,31)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,60,31)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,31)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,0,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,2,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,6,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,10,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,12,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,17,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,19,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,23,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,29,33)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,35,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,48,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,52,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,33)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,33)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,33)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,2,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,16,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,17,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,19,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,21,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,23,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,29,35)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,33,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,35,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,45,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,47,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,48,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,52,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,58,35)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,60,35)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,62,35)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,2,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,6,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,12,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,14,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,23,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,37)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,33,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,47,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,50,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,52,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,58,37)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,37)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,37)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,0,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,2,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,6,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,10,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,19,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,21,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,23,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,39)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,33,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,47,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,50,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,54,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,56,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,39)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,39)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,62,39)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,10,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,12,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,17,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,19,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,23,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,41)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,47,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,52,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,54,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,58,41)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,60,41)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,41)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,0,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,10,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,14,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,16,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,21,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,23,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,43)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,47,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,48,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,50,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,56,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,43)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,60,43)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,43)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,0,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,2,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,12,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,16,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,17,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,19,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,45)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,35,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,45,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,48,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,52,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,58,45)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,60,45)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,62,45)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,10,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,14,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,19,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,23,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,29,47)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,33,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,35,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,48,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,52,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,56,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,58,47)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,47)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,47)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,0,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,6,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,12,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,21,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,23,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,29,48)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,33,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,35,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,50,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,58,48)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,48)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,62,48)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,2,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,10,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,16,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,19,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,21,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,27,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,50)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,50)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,50)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,62,50)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,6,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,16,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,19,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,21,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,29,52)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,50,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,52)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,52)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,62,52)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,16,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,19,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,23,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,54)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,48,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,52,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,54)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,54)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,54)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,25,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,29,56)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,31,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,35,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,45,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,50,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,54,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,56)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,60,56)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,62,56)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,17,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,19,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,29,58)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,33,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,35,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,43,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,45,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,54,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,56,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,58,58)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,60,58)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,62,58)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,2,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,4,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,6,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,8,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,10,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,21,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,60)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,33,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,37,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,39,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,41,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,45,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,47,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,48,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,50,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,52,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,54,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,56,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,58,60)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 3 L 0 3 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,60)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 3 L 0 3 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,62,60)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,0,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,2,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,4,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,6,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,8,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,10,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,12,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,14,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,16,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,17,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,19,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,21,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,23,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,25,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,27,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,29,62)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,31,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,33,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,35,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,37,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,39,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,41,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,43,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,45,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,47,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,48,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,50,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,52,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,54,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,56,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#000000"
								transform="matrix(1,0,0,1,58,62)"
							></path>
							<path
								d="M 0 0 L 3 0 L 3 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,60,62)"
							></path>
							<path
								d="M 0 0 L 2 0 L 2 2 L 0 2 Z"
								fill="#FFFFFF"
								transform="matrix(1,0,0,1,62,62)"
							></path>
						</svg>
					</td>
					{/* <!-- <img src="{{qrcode}}" alt="" style={{width: 20%}}> --> */}
					<div style={{ fontSize: "14px" }}>
						<h4
							style={{
								margin: "0",
								padding: "0",
								fontWeight: "bold",
								textTransform: "uppercase"
							}}
						>
							For enquiries
						</h4>
						<p style={{ margin: "3px 0", padding: "0" }}>
							Phone No:{" "}
							<span style={{ fontWeight: "bold" }}>
								0803123456
							</span>{" "}
						</p>
						<p style={{ margin: "3px 0", padding: "0" }}>
							Email:{" "}
							<span style={{ fontWeight: "bold" }}>
								care@enugurevenueservice.gov.ng
							</span>
						</p>
						<p style={{ margin: "0", padding: "0" }}>
							Visit:{" "}
							<span style={{ fontWeight: "bold" }}>
								https://irs.en.gov.ng/home
							</span>
						</p>
					</div>
				</div>
			</div>
			<p
				style={{
					fontSize: "13px",
					backgroundColor: "rgb(207, 207, 207)",
					padding: "1rem"
				}}
			>
				Generated via: www.irs.en.gov.ng on Oct 10, 2023 8:49 AM by
				Admin Housing. Created By:chiomaejim. To Verify the authencity
				of this document visit:https://irs.en.gov.ng and enter the bill
				number. Verify at no cost
			</p>
		</div>
	);
};
