import { useState, useEffect } from "react";
import axios from "axios";
import Alert from "../../components/Alert";
import styles1 from "../../Identity-management-individual/style.module.css";

const AdminChangePassword = () => {
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [isChanging, setIsChanging] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const changePassword = async (e) => {
		e.preventDefault();
		setIsChanging(true);
		try {
			let res = await axios
				.post(`/admin-change-password`, {
					uid: phoneNumber,
					code: email,
					newPassword
				})
				.then((res) => res.data || {});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.message
			});

			setEmail("");
			setPhoneNumber("");
			setNewPassword("");
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		} finally {
			setIsChanging(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<main>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form
				className={styles1.columned__form}
				onSubmit={(e) => changePassword(e)}
			>
				<div className={styles1.double__inputs}>
					<div>
						<label className={styles1.input__label} htmlFor="email">
							Email <span style={{ color: "red" }}>*</span>
						</label>
						<input
							phoneNumber="email"
							type="email"
							id="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="eg. agu@ada.com"
							required
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="phoneNumber"
						>
							Phone Number <span style={{ color: "red" }}>*</span>
						</label>
						<input
							phoneNumber="phoneNumber"
							type="number"
							id="phoneNumber"
							value={phoneNumber}
							onChange={(e) => setPhoneNumber(e.target.value)}
							placeholder="eg. 09011111111"
							required
						/>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="newPassword"
						>
							New Password <span style={{ color: "red" }}>*</span>
						</label>
						<input
							phoneNumber="newPassword"
							type="password"
							id="newPassword"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
							placeholder="eg.*****"
							required
						/>
					</div>
				</div>
				<button className={["primary__btn"].join(" ")}>
					{isChanging ? "Changing..." : "Change Password"}
				</button>
			</form>
		</main>
	);
};

export default AdminChangePassword;
