import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Alert from "../../components/Alert";
import RegularModal from "../../components/Modal/regular";
import Modal from "../../components/Modal";
import styles from "./style.module.css";
import styles1 from "../../Identity-management-individual/style.module.css";
import styles2 from "../../Dashboard/user/style.module.css";
import styles3 from "../Assessment-records/style.module.css";

const CreateDirectAssessment = () => {
	const history = useHistory();
	const historyState = history?.location?.state;
	const userInfo = JSON.parse(localStorage.getItem("user_info"));

	const inputter = JSON.parse(localStorage.getItem("user_info")).email;

	//Taxpayer Details
	const [myESBN, setESBN] = useState("");
	const [firstname, setFirstName] = useState("");
	const [middlename, setMiddleName] = useState("");
	const [lastname, setLastName] = useState("");
	const [address, setAddress] = useState("");
	const [gender, setGender] = useState("");
	const [phoneno, setPhoneNumber] = useState("");
	const [emailaddress, setEmail] = useState("");
	const [coyname, setCoyName] = useState("");

	//Assessment Details
	const [assessmode, setMode] = useState("");
	const [assessrecordtype, setRecordType] = useState("");
	const [receiptNumber, setReceiptNumber] = useState("");
	const [receiptNumberAmount, setReceiptNumberAmount] = useState("");
	const [checkingReceiptNo, setIsCheckingReceiptNo] = useState(false);
	const [assessyear, setYearAssessed] = useState("");
	const [assesscode, setAssessmentCode] = useState("");
	const [asssessduedate, setDueDate] = useState("");
	const [assessrevenueId, setAssessRevenueId] = useState("");
	const [sourceOfIncome, setSourceOfIncome] = useState("");
	const [assesstaxoffice, setTaxOffice] = useState("");
	const [assessmethod, setAssessmentMethod] = useState("");
	// const [assessBySelf, setAssessedBySelf] = useState(true);
	const [bestOfJudgement, setBestOfJudgement] = useState("");
	const [additionalAssessment, setAdditionalAssessment] = useState("");
	const [formerAssessmentCode, setFormerAssessmentCode] = useState("");
	const [formerAssessmentAmount, setFormerAssessmentAmount] = useState("");
	const [additionalAssessmentAmount, setAdditionalAssessmentAmount] =
		useState("");
	const [isSavingPage, setIsSavingPage] = useState(false);

	//All stuff
	const [allAssessmentModes, setAllAssessmentModes] = useState([]);
	const [allRecordTypes, setAllRecordTypes] = useState([]);
	const [allAssessRevenue, setAllAssessRevenue] = useState([]);
	const [allTaxOffices, setAllTaxOffices] = useState([]);
	// const [allMethods, setAllMethods] = useState([]);
	// const [allRatings, setAllRatings] = useState([]);

	const [accountType, setAccountType] = useState("");

	const [tranches, setTranches] = useState([]);

	//Income Analysis
	// const [assessDetailsCode, setAssessDetailsCode] = useState("");
	const [totIncome, setTotalIncome] = useState("");
	const [totRelief, setTotalRelief] = useState(0);
	const [nhis, setNHIS] = useState(0);
	const [lifeInsurance, setLifeInsurance] = useState(0);
	const [pension, setPension] = useState(0);
	const [housing, setHousing] = useState(0);
	const [totIncomeChrg, setTotIncomeCharge] = useState(0);

	//Details of Charges
	const [taxableIncome, setTaxableIncome] = useState("");
	const [Chrg1, setChrg1] = useState("");
	const [Chrg2, setChrg2] = useState("");
	const [Chrg3, setChrg3] = useState("");
	const [Chrg4, setChrg4] = useState("");
	const [Chrg5, setChrg5] = useState("");
	const [Chrg6, setChrg6] = useState("");
	const [discount, setDiscount] = useState("");
	const [discountReason, setDiscountReason] = useState("");
	const [totalCharge, setTotalCharges] = useState("");
	const [totalTaxDue, setTotalTaxDue] = useState("");
	const [remark, setRemark] = useState("");

	const [isCalculating, setIsCalculating] = useState(false);

	const [isPreviewShowing, setIsPreviewShowing] = useState(false);

	const [checkingAssessmentCode, setIsCheckingAssessmentCode] =
		useState(false);

	// const [showingGenerateButton, setShowingGenerateButton] = useState(true);
	const [gettingInvoice, setIsGettingInvoice] = useState(false);
	const [invoiceData, setInvoiceData] = useState(null);

	const [declineModalStatus, setDeclineModalStatus] = useState({
		declining: false,
		showing: false
	});
	const [approveModalStatus, setApproveModalStatus] = useState({
		approving: false,
		showing: false
	});

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	useEffect(() => {
		setTotalTaxDue(parseFloat(totalCharge) + 500);
	}, [totalCharge]);

	useEffect(() => {
		const calcT2ITotalIncome = async () => {
			try {
				const res = await axios
					.get(`assessdatax2income/${totalCharge}`)
					.then((res) => (res.data || {}).data);

				setTotalIncome(res.Total_Income);
				setTaxableIncome(res.Taxable_Income);
			} catch (error) {
				if (error.response) {
					setAlert((alert) => ({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					}));
				} else {
					setAlert((alert) => ({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					}));
				}
			}
		};
		if (assessmode === "Tax-to-Income" && totalCharge) calcT2ITotalIncome();
	}, [assessmode, totalCharge]);

	//if in editing mode, fill details
	useEffect(() => {
		if (historyState?.edit || historyState?.view) {
			setESBN(historyState.ESBN);
			setAssessmentCode(historyState.AssessmentCode);
			setMode(historyState.AssessmentMode);
			setRecordType(historyState.AssessmentRecordType);
			setYearAssessed(historyState.AssessmentYear);
			setDueDate(historyState.AsssessmentDueDate);
			setAssessmentMethod(historyState.AssessmentMethod || "");
			setAssessRevenueId(historyState.AssessmentRevenueId);
			setSourceOfIncome(historyState.IncomeSource);
			setTaxOffice(historyState.AssessmentTaxOffice);
			// setAssessedBySelf();
			setBestOfJudgement(
				historyState.AssessmentRating === false ? "No" : "Yes"
			);
			setAdditionalAssessmentAmount(historyState.Additn_AssNo);

			setTotalIncome(historyState.TotalIncome);
			setTotalRelief(historyState.TotalRelief || 0);
			setDiscount(historyState.Discount);
			setDiscountReason(historyState.DiscountReason);
			setNHIS(historyState.NHIS || 0);
			setLifeInsurance(historyState.LIFE || 0);
			setPension(historyState.Pension || 0);
			setHousing(historyState.Housing || 0);
			setTotIncomeCharge(historyState.TotalIncomeCharge);
			setChrg1(historyState.TranchChrg1);
			setChrg2(historyState.TranchChrg2);
			setChrg3(historyState.TranchChrg3);
			setChrg4(historyState.TranchChrg4);
			setChrg5(historyState.TranchChrg5);
			setChrg6(historyState.TranchChrg6);
			setTaxableIncome(historyState.TaxableIncome);
			setTotalCharges(historyState.TotalCharge);
			setTotalTaxDue(historyState.TotalTaxDue);
			setRemark(historyState.Remark || "");
			if (historyState.Additn_Flg === "N") setAdditionalAssessment("No");
			else setAdditionalAssessment("Yes");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Get ESBN-related data
	useEffect(() => {
		const getESBN = async () => {
			try {
				const res = await axios
					.get(`/users/anssid?anssid=${myESBN}`)
					.then((res) => (res.data || {}).data);

				setAccountType(res.accounttype);

				if (res.accounttype === "individual") {
					setFirstName(`${res.firstname}`);
					setMiddleName(`${res.middlename}`);
					setLastName(`${res.surname}`);
					setGender(res.sex);
					setPhoneNumber(res.phonenumber);
					setAddress(res.residentialaddress);
				} else {
					setPhoneNumber(res.phoneNumber);
					setAddress(res.stateOfficeAddress);
					setCoyName(res.name);
				}
				setEmail(res.email);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			}
		};

		if (myESBN?.length >= 10) getESBN();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [myESBN]);

	useEffect(() => {
		async function getAll() {
			try {
				//Get all Assessment Modes
				const getAssessModes = axios.get(`/settings/assessmodes`);

				//Get all Assessment Record Types
				const getAssessRecordTypes = axios.get(
					`/settings/assessrecords`
				);

				//Get all Assessment Revenue Types
				const getAssessRevenueTypes = axios.get(
					`/settings/assessrevenue`
				);

				//Get all Tax Offices
				const getAllTaxOffices = axios.get(`/settings/assesstaxoffice`);

				const results = await Promise.all([
					getAssessModes,
					getAssessRecordTypes,
					getAssessRevenueTypes,
					getAllTaxOffices
				]);

				results.forEach((each, i) => {
					if (i === 0) {
						setAllAssessmentModes(each.data.data);
					}
					if (i === 1) {
						setAllRecordTypes(each.data.data);
					}
					if (i === 2) {
						setAllAssessRevenue(each.data.data);
					}
					if (i === 3) {
						setAllTaxOffices(each.data.data);
					}
				});
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response?.data?.msg ||
							error.response?.data?.message
					});
				}
			}
		}

		//Get all Assessment Methods
		// const getAllMethods = async () => {
		// 	const res = await axios
		// 		.get(`/settings/assessmethods`)
		// 		.then((res) => (res.data || {}).data);

		// 	setAllMethods(res);
		// };

		//Get all Assessment Ratings
		// const getAllRatings = async () => {
		// 	const res = await axios
		// 		.get(`/settings/assessratings`)
		// 		.then((res) => (res.data || {}).data);

		// 	setAllRatings(res);
		// };

		getAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	// 	if (assessmethod === "Back-Office") setAssessedBySelf("No");
	// }, [assessmethod]);

	//Save
	const save = async () => {
		setIsSavingPage(true);

		try {
			let assessmentCode;
			// let taxToIncomeIncome;
			if (!historyState?.edit) {
				assessmentCode = await axios.get(`gettmsids/01`);
				if (assessmentCode)
					setAssessmentCode(`${assessmentCode.data.Id}`);
			}

			// if (assessmode === "Tax-to-Income") {
			// 	let { data } = await axios.get(
			// 		`assessdatax2income/${totalCharge}`
			// 	);

			// 	taxToIncomeIncome = data.data;
			// }

			// eslint-disable-next-line no-unused-vars
			let { data, msg } = await axios
				.post(`/tms`, {
					assesscode: assessmentCode.data.Id,
					daPaye_flg: "DA",
					esbn: myESBN,
					name: `${firstname} ${middlename} ${lastname}`,
					coy_name: coyname,
					address,
					email_address: emailaddress,
					phone_number: phoneno,
					assessmode,
					assessrecordtype,
					assessyear,
					asssessmentduedate: asssessduedate,
					assessmethod,
					assessrevenueid: assessrevenueId,
					assesstaxoffice,
					assessbyself: "",
					incsource: sourceOfIncome || " ",
					cra: "",
					tccnumber: "",
					addtnflg: additionalAssessment === "Yes" ? "Y" : "N",
					backlog_flg: assessrecordtype === "Backlogs" ? true : false,
					paymentref: receiptNumber,
					// assessBySelf: assessBySelf === "Yes" ? true : false,
					assessrating: bestOfJudgement === "Yes" ? true : false,

					//Income
					// assessDetailsCode: "001",
					totalincome: `${totIncome}`,
					totalrelief: `${totRelief}`,
					discount,
					discountreason: discountReason,
					nhis: `${nhis}`,
					life: `${lifeInsurance}`,
					pension: `${pension}`,
					housing: `${housing}`,
					totalincomecharge: `${totIncomeChrg}`,
					chrg1: Chrg1,
					chrg2: Chrg2,
					chrg3: Chrg3,
					chrg4: Chrg4,
					chrg5: Chrg5,
					chrg6: Chrg6,
					devlevy: "500",
					taxableincome: `${taxableIncome}`,
					totalcharge: `${totalCharge}`,
					totaltaxdue: `${totalTaxDue}`,
					paymentstatus: "Pending",
					processstatus: "Pending",
					remark,
					inputter,
					// authoriser: "",
					deactivated: false
				})
				.then((res) => res.data || {});

			getInvoice(data?.AssessmentCode);
			// setShowingGenerateButton(true);

			setAssessmentCode("");
			setESBN("");
			setFirstName("");
			setMiddleName("");
			setLastName("");
			setCoyName("");
			setEmail("");
			setPhoneNumber("");
			setMode("");
			setRecordType("");
			setYearAssessed("");
			setDueDate("");
			setSourceOfIncome("");
			setAssessmentMethod("");
			setAssessRevenueId("");
			setTaxOffice("");
			// setAssessedBySelf();
			setBestOfJudgement("");
			setAdditionalAssessment("");

			setTotalIncome("");
			setTotalRelief("");
			setDiscount("");
			setDiscountReason("");
			setNHIS(0);
			setLifeInsurance(0);
			setPension(0);
			setHousing(0);
			setTotIncomeCharge(0);
			setTranches([]);
			setChrg1("");
			setChrg2("");
			setChrg3("");
			setChrg4("");
			setChrg5("");
			setChrg6("");
			setTaxableIncome(0);
			setTotalCharges(0);
			setTotalTaxDue(0);
			setRemark("");

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: msg
			});
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		} finally {
			setIsSavingPage(false);
		}
	};

	//Get Invoice if on View
	useEffect(() => {
		if (historyState?.view) {
			(async () => {
				try {
					getInvoice(historyState?.AssessmentCode);
				} catch (error) {
					if (error.response) {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message:
								error.response.data?.msg ||
								error.response.data?.errors?.[0]?.message ||
								error.response.data?.errors?.details[0]
									?.message ||
								error.response?.data?.message
						});
					} else {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message: error.response.data?.msg
						});
					}
				}
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const checkAssessmentCode = async () => {
		setIsCheckingAssessmentCode(true);

		try {
			let res = await axios
				.get(`/tms/${formerAssessmentCode}`)
				.then((res) => (res.data || {}).data);

			setFormerAssessmentAmount(res.TotalTaxDue);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setIsCheckingAssessmentCode(false);
		}
	};

	const checkReceiptNumber = async () => {
		setIsCheckingReceiptNo(true);

		try {
			let res = await axios
				.get(`/gettmspayref/${receiptNumber}`)
				.then((res) => (res.data || {}).data);

			setReceiptNumberAmount(res.Amount);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setIsCheckingReceiptNo(false);
		}
	};

	const getInvoice = async (assessCode) => {
		setIsGettingInvoice(true);

		try {
			let res = await axios
				.get(`/tmsgetnotice/${assessCode}`)
				.then((res) => res);

			setInvoiceData(res.data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setIsGettingInvoice(false);
		}
	};

	//Edit
	const edit = async () => {
		setIsSavingPage(true);

		try {
			// let taxToIncomeIncome;

			// if (assessmode === "Tax-to-Income") {
			// 	let { data } = await axios.get(
			// 		`assessdatax2income/${totalCharge}`
			// 	);

			// 	taxToIncomeIncome = data.data;
			// }

			// eslint-disable-next-line no-unused-vars
			let { _, msg } = await axios
				.put(`/tms/${historyState?.AssessmentCode}`, {
					assesscode,
					daPaye_flg: "DA",
					esbn: myESBN,
					name: `${firstname} ${middlename} ${lastname}`,
					coy_name: coyname,
					address,
					email_address: emailaddress,
					phone_number: phoneno,
					assessmode,
					assessrecordtype,
					assessyear,
					asssessmentduedate: asssessduedate,
					assessmethod,
					assessrevenueid: assessrevenueId,
					assesstaxoffice,
					assessbyself: "",
					incsource: sourceOfIncome || " ",
					cra: "",
					tccnumber: "",
					addtnflg: additionalAssessment === "Yes" ? "Y" : "N",
					backlog_flg: assessrecordtype === "Backlogs" ? true : false,
					paymentref: receiptNumber,
					// assessBySelf: assessBySelf === "Yes" ? true : false,
					assessrating: bestOfJudgement === "Yes" ? true : false,

					//Income
					// assessDetailsCode: "001",
					totalincome: `${totIncome}`,
					totalrelief: `${totRelief}`,
					discount,
					discountreason: discountReason,
					nhis: `${nhis}`,
					life: `${lifeInsurance}`,
					pension: `${pension}`,
					housing: `${housing}`,
					totalincomecharge: `${totIncomeChrg}`,
					chrg1: Chrg1,
					chrg2: Chrg2,
					chrg3: Chrg3,
					chrg4: Chrg4,
					chrg5: Chrg5,
					chrg6: Chrg6,
					devlevy: "500",
					taxableincome: `${taxableIncome}`,
					totalcharge: `${totalCharge}`,
					totaltaxdue: `${totalTaxDue}`,
					paymentstatus: "Pending",
					processstatus: "Approved",
					remark,
					inputter,
					// authoriser: "",
					deactivated: false
				})
				.then((res) => res.data || {});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: msg
			});

			setAssessmentCode("");
			setESBN("");
			setFirstName("");
			setMiddleName("");
			setLastName("");
			setCoyName("");
			setEmail("");
			setPhoneNumber("");
			setMode("");
			setRecordType("");
			setYearAssessed("");
			setDueDate("");
			setSourceOfIncome("");
			setAssessmentMethod("");
			setAssessRevenueId("");
			setTaxOffice("");
			// setAssessedBySelf();
			setBestOfJudgement("");
			setAdditionalAssessment("");

			setTotalIncome("");
			setTotalRelief("");
			setDiscount("");
			setDiscountReason("");
			setNHIS(0);
			setLifeInsurance(0);
			setPension(0);
			setHousing(0);
			setTotIncomeCharge(0);
			setTranches([]);
			setChrg1("");
			setChrg2("");
			setChrg3("");
			setChrg4("");
			setChrg5("");
			setChrg6("");
			setTaxableIncome(0);
			setTotalCharges(0);
			setTotalTaxDue(0);
			setRemark("");
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		} finally {
			setIsSavingPage(false);
		}
	};

	const decline = async () => {
		try {
			setDeclineModalStatus((status) => ({
				...status,
				declining: true
			}));

			const req = await axios
				.put("/tmsprocess", {
					billCode: historyState.AssessmentCode,
					processUpd: "4",
					MDA_Flg: "01",
					coy: "00",
					userid: userInfo.email
				})
				.then((res) => res.data || {});

			setDeclineModalStatus({
				declining: false,
				showing: false
			});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: req.msg
			});
		} catch (error) {
			setDeclineModalStatus({
				declining: false,
				showing: false
			});
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		}
	};

	const approve = async () => {
		try {
			setApproveModalStatus((status) => ({
				...status,
				approving: true
			}));

			const req = await axios
				.put("/tmsprocess", {
					billCode: historyState.AssessmentCode,
					processUpd: "2",
					MDA_Flg: "01",
					coy: "00",
					userid: userInfo.email
				})
				.then((res) => res.data || {});

			setApproveModalStatus({
				approving: false,
				showing: false
			});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: req.msg
			});
		} catch (error) {
			setApproveModalStatus({
				approving: false,
				showing: false
			});

			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		}
	};

	// useEffect(() => {
	// 	const delay = () =>
	// 		setTimeout(() => history.push("/direct-assessment/records"), 3000);

	// 	if (alert.type === "success") {
	// 		delay();
	// 	}

	// 	return () => clearTimeout(delay);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [alert]);

	//Calculate Taxable Income
	useEffect(() => {
		if (assessmode !== "Tax-to-Income") {
			setTaxableIncome(totIncome - totRelief);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totIncome, totRelief]);

	//Calculate Total Relief
	useEffect(() => {
		setTotalRelief(
			parseInt(nhis) +
				parseInt(housing) +
				parseInt(pension) +
				parseInt(lifeInsurance)
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nhis, housing, pension, lifeInsurance]);

	//Get Tranch Charges
	const getTranchCharges = async () => {
		setIsCalculating(true);

		try {
			const res = await axios
				.get(`/assessdatranchcharges/${taxableIncome}`)
				.then((res) => (res.data || {}).data);

			setTranches(res);
			res.forEach((tranch) => {
				if (tranch.incTranchNo === "1") setChrg1(tranch.tranchTax);
				if (tranch.incTranchNo === "2") setChrg2(tranch.tranchTax);
				if (tranch.incTranchNo === "3") setChrg3(tranch.tranchTax);
				if (tranch.incTranchNo === "4") setChrg4(tranch.tranchTax);
				if (tranch.incTranchNo === "5") setChrg5(tranch.tranchTax);
				if (tranch.incTranchNo === "6") setChrg6(tranch.tranchTax);
			});

			setTotalCharges(res[res.length - 2]?.totTaxCalc);
			setTotIncomeCharge(res[res.length - 3]?.IncomeTax);
			setTotalTaxDue(parseInt(res[res.length - 2]?.totTaxCalc) + 500);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors?.[0] ||
						error.response.data?.msg
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		} finally {
			setIsCalculating(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const printInvoice = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(invoiceData);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		w.onafterprint = () => {
			w.close();
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		};
	};

	useEffect(() => {
		setDueDate(assessyear !== "" ? `${assessyear}-12-31` : "");
	}, [assessyear]);

	const formatDate = () => {
		const input = asssessduedate;
		const inputDate = new Date(input);

		const year = inputDate.getFullYear();
		const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
		const day = inputDate.getDate().toString().padStart(2, "0");

		const formattedDate = `${year}-${month}-${day}`;

		return formattedDate;
	};

	return (
		<main className={[styles2.wrapper, styles.main].join(" ")}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{declineModalStatus.showing && (
				<RegularModal
					showing={declineModalStatus.showing}
					toggle={setDeclineModalStatus}
				>
					<p className={styles3.revoke__warning}>
						Are you sure want to decline assessment?
					</p>
					<div className={styles3.revoke__btns}>
						<button
							onClick={() =>
								setDeclineModalStatus({
									showing: false,
									declining: false
								})
							}
							className="secondary__btn"
						>
							Cancel
						</button>
						<button onClick={decline}>
							{declineModalStatus.declining
								? "Declining..."
								: "Decline"}
						</button>
					</div>
				</RegularModal>
			)}
			{approveModalStatus.showing && (
				<RegularModal
					showing={approveModalStatus.showing}
					toggle={setApproveModalStatus}
				>
					<p className={styles3.revoke__warning}>
						Are you sure want to approve assessment?
					</p>
					<div className={styles3.revoke__btns}>
						<button
							onClick={() =>
								setApproveModalStatus({
									showing: false,
									approving: false
								})
							}
							className="secondary__btn"
						>
							Cancel
						</button>
						<button className="primary__btn" onClick={approve}>
							{approveModalStatus.approving
								? "Approving..."
								: "Approve"}
						</button>
					</div>
				</RegularModal>
			)}
			<form className={styles1.columned__form}>
				<h5>Taxpayer Details</h5>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="myESBN"
						>
							ID (ESBN) <span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="myESBN"
							type="text"
							id="myESBN"
							value={myESBN}
							onChange={(e) => setESBN(e.target.value)}
							placeholder="eg. 01"
							disabled={historyState?.view}
							required
						/>
					</div>
					{/* <div>
						<label
							className={styles1.input__label}
							htmlFor="assesscode"
						>
							Assessment Code
						</label>
						<input
							name="assesscode"
							type="text"
							id="assesscode"
							value={assesscode}
							onChange={(e) => setAssessmentCode(e.target.value)}
							required
						/>
					</div> */}
				</div>
				<div className={styles1.double__inputs}>
					{accountType === "individual" ? (
						<>
							<div>
								<label
									className={styles1.input__label}
									htmlFor="name"
								>
									First Name
								</label>
								<input
									name="firstname"
									type="text"
									id="firstname"
									value={firstname}
									onChange={(e) =>
										setFirstName(e.target.value)
									}
									placeholder="eg. Ada"
									disabled
									required
								/>
							</div>
							<div>
								<label
									className={styles1.input__label}
									htmlFor="name"
								>
									Middle Name
								</label>
								<input
									name="middlename"
									type="text"
									id="middlename"
									value={middlename}
									onChange={(e) =>
										setMiddleName(e.target.value)
									}
									placeholder="eg. Jennifer"
									disabled
									required
								/>
							</div>
							<div>
								<label
									className={styles1.input__label}
									htmlFor="name"
								>
									Last Name
								</label>
								<input
									name="lastname"
									type="text"
									id="lastname"
									value={lastname}
									onChange={(e) =>
										setLastName(e.target.value)
									}
									placeholder="eg. Agu"
									disabled
									required
								/>
							</div>
						</>
					) : (
						<div>
							<label
								className={styles1.input__label}
								htmlFor="companyName"
							>
								Company
							</label>
							<input
								name="companyName"
								type="text"
								id="companyName"
								value={coyname}
								onChange={(e) => setCoyName(e.target.value)}
								placeholder="eg. Agu & Sons Limited"
								disabled
								required
							/>
						</div>
					)}

					<div>
						<label
							className={styles1.input__label}
							htmlFor="address"
						>
							Address
						</label>
						<input
							name="address"
							type="text"
							id="address"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							placeholder="eg. No 1 Obosi street, Enugu"
							disabled
							required
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="gender"
						>
							Gender
						</label>
						<select
							id="gender"
							value={gender}
							onChange={(e) => setGender(e.target.value)}
							disabled
							required
						>
							<option value="">Select</option>
							<option>Male</option>
							<option>Female</option>
						</select>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="phoneNumber"
						>
							Phone Number
						</label>
						<input
							name="phoneNumber"
							type="text"
							id="phoneNumber"
							value={phoneno}
							onChange={(e) => setPhoneNumber(e.target.value)}
							placeholder="eg. +2348012456789"
							disabled
							required
						/>
					</div>
					<div>
						<label className={styles1.input__label} htmlFor="email">
							Email
						</label>
						<input
							name="email"
							type="email"
							id="email"
							value={emailaddress}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="eg. Ada@agu.com"
							disabled
							required
						/>
					</div>
				</div>
				<hr className={styles1.divider} />
				<h5>Details of Assessment</h5>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="assessmode"
						>
							Assessment Mode
						</label>
						<select
							id="assessmode"
							value={assessmode}
							onChange={(e) => setMode(e.target.value)}
							disabled={historyState?.view}
							required
						>
							<option value="">Select</option>
							{allAssessmentModes?.map((assessmode) => (
								<option key={assessmode.id}>
									{assessmode.Description}
								</option>
							))}
						</select>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="assessrecordtype"
						>
							Record Type
						</label>
						<select
							id="assessrecordtype"
							value={assessrecordtype}
							onChange={(e) => setRecordType(e.target.value)}
							disabled={historyState?.view}
							required
						>
							<option value="">Select</option>
							{allRecordTypes?.map((record) => (
								<option key={record.id}>
									{record.Description}
								</option>
							))}
						</select>
					</div>
					{assessrecordtype === "Backlogs" && (
						<div style={{ marginBottom: "15px" }}>
							<label
								className={styles1.input__label}
								htmlFor="receiptNumber"
							>
								Receipt Number
							</label>
							<input
								name="receiptNumber"
								type="text"
								id="receiptNumber"
								value={receiptNumber}
								onChange={(e) =>
									setReceiptNumber(e.target.value)
								}
								placeholder="eg. 74686763899"
								disabled={historyState?.view}
								required
							/>
							<button
								type="button"
								className={["primary__btn"].join(" ")}
								onClick={() => checkReceiptNumber()}
							>
								{checkingReceiptNo ? "Checking..." : "Check"}
							</button>
							{receiptNumberAmount && (
								<span
									className={styles.former__assessment_amount}
								>
									Amount Paid: &#8358;
									{new Intl.NumberFormat().format(
										receiptNumberAmount
									)}
								</span>
							)}
						</div>
					)}
					<div>
						<label
							className={styles1.input__label}
							htmlFor="assessyear"
						>
							Year Assessed
						</label>
						<select
							id="assessyear"
							value={assessyear}
							onChange={(e) => setYearAssessed(e.target.value)}
							disabled={historyState?.view}
							required
						>
							<option value="">Select</option>
							<option>2000</option>
							{new Array(new Date().getFullYear() - 2000)
								.fill(0)
								.map((_, i) => (
									<option key={i}>{2000 + i + 1}</option>
								))}
						</select>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="asssessduedate"
						>
							Due Date
						</label>
						<input
							name="asssessduedate"
							type="date"
							id="asssessduedate"
							value={
								asssessduedate ? formatDate() : asssessduedate
							}
							onChange={(e) => setDueDate(e.target.value)}
							disabled={true}
							required
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="assessrevenueId"
						>
							Type of Income
						</label>
						<select
							id="assessrevenueId"
							value={assessrevenueId}
							onChange={(e) => setAssessRevenueId(e.target.value)}
							disabled={historyState?.view}
							required
						>
							<option value="">Select</option>
							{allAssessRevenue?.map((revenue) => (
								<option key={revenue.id}>
									{revenue.Description}
								</option>
							))}
						</select>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="sourceOfIncome"
						>
							Source of Income
						</label>
						<input
							name="sourceOfIncome"
							type="text"
							id="sourceOfIncome"
							value={sourceOfIncome}
							onChange={(e) => setSourceOfIncome(e.target.value)}
							disabled={historyState?.view}
							placeholder="eg. "
							required
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="assesstaxoffice"
						>
							Tax Office
						</label>
						<select
							id="assesstaxoffice"
							value={assesstaxoffice}
							onChange={(e) => setTaxOffice(e.target.value)}
							disabled={historyState?.view}
							required
						>
							<option value="">Select</option>
							{allTaxOffices?.map((office) => (
								<option key={office.id}>
									{office.Description}
								</option>
							))}
						</select>
					</div>
					{/* <div>
						<label
							className={styles1.input__label}
							htmlFor="assessmethod"
						>
							Assessment Method
						</label>
						<select
							id="assessmethod"
							value={assessmethod}
							onChange={(e) =>
								setAssessmentMethod(e.target.value)
							}
							required
						>
							<option value="">Select</option>
							{allMethods?.map((method) => (
								<option key={method.id}>
									{method.Description}
								</option>
							))}
						</select>
					</div>
					<div className={styles.radio__button_div}>
						<span>Assessed By Self</span>
						<div>
							<div>
								<input
									name="assessedBySelf"
									id="assessedBySelfYes"
									type="radio"
									value="Yes"
									onChange={(e) =>
										setAssessedBySelf(e.target.value)
									}
									checked={assessBySelf === "Yes"}
									required
								/>
								<label
									className={styles1.input__label}
									htmlFor="assessedBySelfYes"
								>
									Yes
								</label>
							</div>
							<div>
								<input
									name="assessedBySelf"
									id="assessedBySelfNo"
									type="radio"
									value="No"
									onChange={(e) =>
										setAssessedBySelf(e.target.value)
									}
									checked={assessBySelf === "No"}
									required
								/>
								<label
									className={styles1.input__label}
									htmlFor="assessedBySelfNo"
								>
									No
								</label>
							</div>
						</div>
					</div> */}
					<div className={styles.radio__button_div}>
						<span className={styles1.input__label}>
							Best of Judgement
						</span>
						<div>
							<div>
								<input
									name="bestOfJudgement"
									id="bestOfJudgementYes"
									type="radio"
									value="Yes"
									onChange={(e) =>
										setBestOfJudgement(e.target.value)
									}
									checked={bestOfJudgement === "Yes"}
									disabled={historyState?.view}
									required
								/>
								<label
									className={styles1.input__label}
									htmlFor="bestOfJudgementYes"
								>
									Yes
								</label>
							</div>
							<div>
								<input
									name="bestOfJudgement"
									id="bestOfJudgementNo"
									type="radio"
									value="No"
									onChange={(e) =>
										setBestOfJudgement(e.target.value)
									}
									checked={bestOfJudgement === "No"}
									disabled={historyState?.view}
									required
								/>
								<label
									className={styles1.input__label}
									htmlFor="bestOfJudgementNo"
								>
									No
								</label>
							</div>
						</div>
					</div>
					<div className={styles.radio__button_div}>
						<span className={styles1.input__label}>
							Additional Assessment
						</span>
						<div>
							<div>
								<input
									name="additionalAssessment"
									id="additionalAssessmentYes"
									type="radio"
									value="Yes"
									onChange={(e) =>
										setAdditionalAssessment(e.target.value)
									}
									checked={additionalAssessment === "Yes"}
									disabled={historyState?.view}
									required
								/>
								<label
									className={styles1.input__label}
									htmlFor="additionalAssessmentYes"
								>
									Yes
								</label>
							</div>
							<div>
								<input
									name="additionalAssessment"
									id="additionalAssessmentNo"
									type="radio"
									value="No"
									onChange={(e) =>
										setAdditionalAssessment(e.target.value)
									}
									checked={additionalAssessment === "No"}
									disabled={historyState?.view}
									required
								/>
								<label
									className={styles1.input__label}
									htmlFor="additionalAssessmentNo"
								>
									No
								</label>
							</div>
						</div>
					</div>
					{additionalAssessment === "Yes" && (
						<div>
							<label
								className={styles1.input__label}
								htmlFor="formerAssessmentCode"
							>
								Former Bill Number
							</label>
							<input
								name="formerAssessmentCode"
								type="text"
								id="formerAssessmentCode"
								value={formerAssessmentCode}
								onChange={(e) =>
									setFormerAssessmentCode(e.target.value)
								}
								placeholder="eg. AB20241000000000EN"
								disabled={historyState?.view}
								required
							/>
							<button
								type="button"
								className={["primary__btn"].join(" ")}
								onClick={() => checkAssessmentCode()}
							>
								{checkingAssessmentCode
									? "Checking..."
									: "Check"}
							</button>
							{formerAssessmentAmount && (
								<span
									className={styles.former__assessment_amount}
								>
									Amount Paid: &#8358;
									{new Intl.NumberFormat().format(
										formerAssessmentAmount
									)}
								</span>
							)}
						</div>
					)}
					{formerAssessmentAmount && (
						<div>
							<label
								className={styles1.input__label}
								htmlFor="additionalAssessmentAmount"
							>
								<b>Additional Assessment Amount</b>{" "}
								&nbsp;(amount in &#8358;)
							</label>
							<input
								name="additionalAssessmentAmount"
								type="number"
								id="additionalAssessmentAmount"
								value={additionalAssessmentAmount}
								onChange={(e) => {
									setAdditionalAssessmentAmount(
										e.target.value
									);
									setTotalCharges(e.target.value);
								}}
								placeholder="amount"
								disabled={historyState?.view}
								required
							/>
						</div>
					)}
				</div>
				<hr className={styles1.divider} />
				<h5>Relief Details</h5>
				<div className={styles1.double__inputs}>
					<div>
						<label className={styles1.input__label} htmlFor="nhis">
							<b>NHIS</b> &nbsp;(amount in &#8358;)
						</label>
						<input
							name="nhis"
							type="number"
							id="nhis"
							value={nhis}
							onChange={(e) => setNHIS(e.target.value)}
							placeholder="amount"
							disabled={
								assessmode === "Tax-to-Income" ||
								historyState?.view
							}
							required
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="lifeInsurance"
						>
							<b>Life Insurance</b> &nbsp;(amount in &#8358;)
						</label>
						<input
							name="lifeInsurance"
							type="number"
							id="lifeInsurance"
							value={lifeInsurance}
							onChange={(e) => setLifeInsurance(e.target.value)}
							placeholder="amount"
							disabled={
								assessmode === "Tax-to-Income" ||
								historyState?.view
							}
							required
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="pension"
						>
							<b>Pension</b> &nbsp;(amount in &#8358;)
						</label>
						<input
							name="pension"
							type="number"
							id="pension"
							value={pension}
							onChange={(e) => setPension(e.target.value)}
							placeholder="amount"
							disabled={
								assessmode === "Tax-to-Income" ||
								historyState?.view
							}
							required
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="housing"
						>
							<b>Housing</b> &nbsp;(amount in &#8358;)
						</label>
						<input
							name="housing"
							type="number"
							id="housing"
							value={housing}
							onChange={(e) => setHousing(e.target.value)}
							placeholder="amount"
							disabled={
								assessmode === "Tax-to-Income" ||
								historyState?.view
							}
							required
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="totRelief"
						>
							<b>Total Relief</b> &nbsp;(amount in &#8358;)
						</label>
						<input
							name="totRelief"
							type="text"
							id="totRelief"
							value={new Intl.NumberFormat().format(totRelief)}
							onChange={(e) => setTotalRelief(e.target.value)}
							required
							disabled
						/>
					</div>
				</div>
				<hr className={styles1.divider} />
				<h5>Discount</h5>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="discount"
						>
							Discount
						</label>
						<input
							name="discount"
							type="number"
							id="discount"
							value={discount}
							onChange={(e) => setDiscount(e.target.value)}
							disabled={
								assessmode === "Tax-to-Income" ||
								historyState?.view
							}
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="discountReason"
						>
							Reason for Discount
						</label>
						<input
							name="discountReason"
							type="text"
							id="discountReason"
							value={discountReason}
							onChange={(e) => setDiscountReason(e.target.value)}
							disabled={
								assessmode === "Tax-to-Income" ||
								historyState?.view
							}
						/>
					</div>
				</div>
				<hr className={styles1.divider} />
				<h5>Income Details & Charges</h5>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="totIncome"
						>
							Total Income &nbsp;(amount in &#8358;)
						</label>
						<input
							name="totIncome"
							type="number"
							id="totIncome"
							value={totIncome}
							onChange={(e) => setTotalIncome(e.target.value)}
							disabled={
								assessmode === "Tax-to-Income" ||
								historyState?.view
							}
							required
						/>
						<button
							className={[
								"primary__btn",
								styles.calculate__btn
							].join(" ")}
							onClick={(e) => {
								e.preventDefault();
								getTranchCharges(totIncome);
							}}
							disabled={
								!totIncome ||
								isCalculating ||
								assessmode === "Tax-to-Income" ||
								historyState?.view
							}
						>
							{isCalculating ? "Calculating..." : "Calculate"}
						</button>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="taxableIncome"
						>
							Taxable Income
						</label>
						<input
							name="taxableIncome"
							type="text"
							id="taxableIncome"
							value={new Intl.NumberFormat().format(
								taxableIncome
							)}
							onChange={(e) => setTaxableIncome(e.target.value)}
							required
							disabled
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="totalCharges"
						>
							Total Tax{" "}
							{assessmode === "Tax-to-Income"
								? ""
								: "Charges Calculated"}
						</label>
						<input
							name="totalCharges"
							type={
								assessmode === "Tax-to-Income"
									? "number"
									: "text"
							}
							id="totalCharges"
							value={
								assessmode === "Tax-to-Income" ||
								historyState?.view ||
								historyState?.edit
									? totalCharge
									: new Intl.NumberFormat().format(
											tranches[tranches.length - 2]
												?.totTaxCalc || 0
										)
							}
							onChange={(e) => setTotalCharges(e.target.value)}
							required
							disabled={
								assessmode !== "Tax-to-Income" ||
								historyState?.view
							}
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="totalTaxDue"
						>
							Total Tax Due
						</label>
						<input
							name="totalTaxDue"
							type="text"
							id="totalTaxDue"
							value={new Intl.NumberFormat().format(
								totalTaxDue || 0
							)}
							onChange={(e) => setTotalTaxDue(e.target.value)}
							required
							disabled
						/>
						<span className={styles.dev__fee_notice}>
							(Development fee of 500 naira inclusive)
						</span>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="remark"
						>
							Remark
						</label>
						<input
							name="remark"
							type="text"
							id="remark"
							value={remark}
							onChange={(e) => setRemark(e.target.value)}
							disabled={
								assessmode === "Tax-to-Income" ||
								historyState?.view
							}
						/>
					</div>
				</div>
				<hr className={styles1.divider} />
				<h5>Tax Calculation Details</h5>
				<div className={styles.tranches__breakdown}>
					{tranches.map(
						(tranch, i) =>
							i < tranches.length - 3 && (
								<div key={tranch.incTranchNo}>
									<label>
										{tranch.incTranchNo === "1"
											? "1st"
											: tranch.incTranchNo === "2"
												? "2nd"
												: tranch.incTranchNo === "3"
													? "3rd"
													: `${tranch.incTranchNo}th`}{" "}
										<b>
											{new Intl.NumberFormat().format(
												tranch.incTranch
											)}
										</b>{" "}
										at <b>{tranch.incRate}%</b>
									</label>
									&#8358;
									<span>
										{new Intl.NumberFormat().format(
											tranch.tranchTax
										)}
									</span>
								</div>
							)
					)}
					{tranches.length > 0 && (
						<>
							<div>
								<label>Income Tax</label>
								&#8358;
								<span>
									{new Intl.NumberFormat().format(
										tranches[tranches.length - 3]?.IncomeTax
									)}
								</span>
							</div>
							<div>
								<label>Development Levy</label>
								&#8358;
								<span>
									{new Intl.NumberFormat().format(500)}
								</span>
							</div>
						</>
					)}
				</div>
				<hr className={styles1.divider} />
				{isPreviewShowing && (
					<Modal
						showing={isPreviewShowing}
						toggle={setIsPreviewShowing}
					>
						<h5 className={styles.preview__title}>Preview</h5>
						<div className={styles.preview__breakdown}>
							<div className={styles.each__breakdown}>
								<b>ESBN</b>
								<p>{myESBN || "N/A"}</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Assessment Code</b>
								<p>{assesscode || "N/A"}</p>
							</div>
							{accountType === "individual" ? (
								<>
									<div className={styles.each__breakdown}>
										<b>First Name</b>
										<p>{firstname || "N/A"}</p>
									</div>
									<div className={styles.each__breakdown}>
										<b>Middle Name</b>
										<p>{middlename || "N/A"}</p>
									</div>
									<div className={styles.each__breakdown}>
										<b>Last name</b>
										<p>{lastname || "N/A"}</p>
									</div>
									<div className={styles.each__breakdown}>
										<b>Gender</b>
										<p>{gender || "N/A"}</p>
									</div>
								</>
							) : (
								<div className={styles.each__breakdown}>
									<b>Company Name</b>
									<p>{coyname || "N/A"}</p>
								</div>
							)}
							<div className={styles.each__breakdown}>
								<b>Phone Number</b>
								<p>{phoneno || "N/A"}</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Address</b>
								<p>{address || "N/A"}</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Email</b>
								<p>{emailaddress || "N/A"}</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Assesment Mode</b>
								<p>{assessmode || "N/A"}</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Record Type</b>
								<p>{assessrecordtype || "N/A"}</p>
							</div>
							{assessrecordtype === "Backlogs" && (
								<div className={styles.each__breakdown}>
									<b>Receipt Number</b>
									<p>{receiptNumber || "N/A"}</p>
								</div>
							)}
							<div className={styles.each__breakdown}>
								<b>Year Assessed</b>
								<p>{assessyear || "N/A"}</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Due Date</b>
								<p>{asssessduedate || "N/A"}</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Type of Income</b>
								<p>{assessrevenueId || "N/A"}</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Source of Income</b>
								<p>{sourceOfIncome || "N/A"}</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Tax Office</b>
								<p>{assesstaxoffice || "N/A"}</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Best of Judgement</b>
								<p>{bestOfJudgement || "N/A"}</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Additional Assessment</b>
								<p>{additionalAssessment || "N/A"}</p>
							</div>
							{additionalAssessment === "Yes" && (
								<>
									<div className={styles.each__breakdown}>
										<b>Former Assesment Code</b>
										<p>{formerAssessmentCode || "N/A"}</p>
									</div>
									<div className={styles.each__breakdown}>
										<b>Former Assesment Amount</b>
										<p>{formerAssessmentAmount || "N/A"}</p>
									</div>
								</>
							)}
							{formerAssessmentAmount && (
								<div className={styles.each__breakdown}>
									<b>Additional Assessment Amount</b>
									<p>{additionalAssessmentAmount || "N/A"}</p>
								</div>
							)}
							<div className={styles.each__breakdown}>
								<b>NHIS</b>
								<p>
									&#8358;{" "}
									{new Intl.NumberFormat().format(nhis) ||
										"N/A"}
								</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Life Insurance</b>
								<p>
									&#8358;{" "}
									{new Intl.NumberFormat().format(
										lifeInsurance
									) || "N/A"}
								</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Pension</b>
								<p>
									&#8358;{" "}
									{new Intl.NumberFormat().format(pension) ||
										"N/A"}
								</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Housing</b>
								<p>
									&#8358;{" "}
									{new Intl.NumberFormat().format(housing) ||
										"N/A"}
								</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Total Relief</b>
								<p>
									&#8358;{" "}
									{new Intl.NumberFormat().format(
										totRelief
									) || "N/A"}
								</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Discount</b>
								<p>{discount || "N/A"}</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Reason for Discount</b>
								<p>{discountReason || "N/A"}</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Total Income</b>
								<p>
									&#8358;{" "}
									{new Intl.NumberFormat().format(
										totIncome
									) || "N/A"}
								</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Taxable Income</b>
								<p>
									&#8358;{" "}
									{new Intl.NumberFormat().format(
										taxableIncome
									) || "N/A"}
								</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Total Tax Charges</b>
								<p>
									&#8358;
									{new Intl.NumberFormat().format(
										tranches[tranches.length - 1]
											?.totTaxCalc || "N/A"
									)}
								</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Total Tax Due</b>
								<p>
									&#8358;{" "}
									{new Intl.NumberFormat().format(
										totalTaxDue
									) || "N/A"}
								</p>
							</div>
							<div className={styles.each__breakdown}>
								<b>Remark</b>
								<p>{remark || "N/A"}</p>
							</div>
						</div>
					</Modal>
				)}
				<div
					className={styles.page__two_buttons}
					style={{ marginBottom: "30px" }}
				>
					{/* check if account has power to authorise, and if in view state */}
					{Boolean(
						userInfo?.roles?.[0]?.Claims.find(
							(claim) => claim.Route === "/tmsprocess"
						)
					) &&
					historyState?.view &&
					historyState?.fromAuthorise ? (
						<>
							<button
								className="tertiary__btn"
								onClick={() =>
									setDeclineModalStatus((declineStatus) => ({
										...declineStatus,
										showing: true
									}))
								}
								type="button"
							>
								Decline
							</button>
							<button
								className="primary__btn"
								onClick={() =>
									setApproveModalStatus((approveStatus) => ({
										...approveStatus,
										showing: true
									}))
								}
								type="button"
							>
								Approve
							</button>
						</>
					) : (
						<>
							<button
								className={["secondary__btn"].join(" ")}
								onClick={(e) => {
									e.preventDefault();
									setIsPreviewShowing(true);
									window.scrollTo({
										top: 0,
										left: 0,
										behavior: "smooth"
									});
								}}
							>
								Preview
							</button>
							<button
								type="submit"
								className={["primary__btn"].join(" ")}
								onClick={(e) => {
									e.preventDefault();
									if (historyState?.edit) edit();
									else save();
								}}
								disabled={
									!totalCharge ||
									!totalTaxDue ||
									(additionalAssessment === "Yes" &&
										!additionalAssessmentAmount) ||
									!myESBN ||
									(assessrecordtype === "Backlogs" &&
										!receiptNumber) ||
									historyState?.view
								}
							>
								{isSavingPage ? "Saving..." : "Save"}
							</button>
						</>
					)}
				</div>
			</form>
			{/* {showingGenerateButton && (
				<button
					className={["secondary__btn"].join(" ")}
					onClick={() => getInvoice()}
				>
					Generate Invoice
				</button>
			)} */}
			{invoiceData &&
				!gettingInvoice &&
				Boolean(
					userInfo?.roles?.[0]?.Claims.find(
						(claim) => claim.Route === "/tmsprocess"
					)
				) && (
					<>
						<div className={styles.notice__btns}>
							{/* <a
								href={invoiceData}
								download="Assessment Notice"
								target="_blank"
								rel="noreferrer"
							>
								<button>Download</button>
							</a> */}
							<button onClick={(e) => printInvoice(e)}>
								Print
							</button>
							{/* <button>Send to Email</button> */}
						</div>
						<div
							dangerouslySetInnerHTML={{ __html: invoiceData }}
							className={styles.doc__area}
						></div>
					</>
				)}
			{gettingInvoice && (
				<p style={{ textAlign: "center" }}>Loading Invoice...</p>
			)}
		</main>
	);
};

export default CreateDirectAssessment;
