import style from "./style.module.css";
import useIntersectionObserver from "../IntersectionObserver";
import observerStyle from "../IntersectionObserver/style.module.css";
const FlexRight = ({
	headingIn,
	headingInExt,
	headingOut,
	Proportional,
	Progressive,
	Regressive,
	content1,
	content2,
	content3,
	content4,
	image
}) => {
	useIntersectionObserver();
	return (
		<main className={style.personalTaxIncome}>
			<h2>{headingOut}</h2>
			<img
				src={image}
				alt="taxTypeImg"
				className={`${observerStyle.animateHomePage} ${observerStyle.slideInRight}`}
			/>
			<div
				className={`${style.paragraphs} ${observerStyle.animateHomePage} ${observerStyle.slideInRight}`}
			>
				<h2>{headingIn}</h2>
				<h2>{headingInExt}</h2>
				<h3 className={style.subHeading}>{Proportional}</h3>
				<p className={style.standAlone}>{content1}</p>
				<p>{content4}</p>
				<h3 className={style.subHeading}>{Progressive}</h3>
				<p>{content2}</p>
				<h3 className={style.subHeading}>{Regressive}</h3>
				<p>{content3}</p>
			</div>
		</main>
	);
};

export default FlexRight;
