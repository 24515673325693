import axios from "axios";
import React, { useState, useEffect } from "react";
import style from "./style.module.css";
import styles3 from "../../../Identity-management-individual/style.module.css";
import Alert from "../../../components/Alert";
import { useHistory } from "react-router-dom";

const userInfo = JSON.parse(localStorage.getItem("user_info"));

const CorporateIndex = () => {
	const history = useHistory();
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const [selectedYear, setSelectedYear] = useState("");
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [typeOfAssessment, setTypeOfAssessment] = useState("");
	const [corpESBN, setCorpESBN] = useState(userInfo.anssid);
	const [periodicStartDate, setPeriodicStartDate] = useState(null);
	const [periodicEndDate, setPeriodicEndDate] = useState(null);
	const [loading, setLoading] = useState(false);
	const [companyDetails, setCompanyDetails] = useState(null);

	const getCorpDetails = async (ESBN) => {
		setLoading(true);
		try {
			const res = await axios.get(`/users/anssid?anssid=${ESBN}`);
			const ESBNdetails = res?.data?.data;

			setCompanyDetails(ESBNdetails);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setLoading(false);
		}
	};

	const checkingFile = async () => {
		if (!companyDetails) return false;
		try {
			const res = await axios.post(`/confirmpaye`, {
				coyname: companyDetails.name,
				esbn: corpESBN,
				paytype: typeOfAssessment,
				mode: "0",
				year: selectedYear,
				mth: selectedMonth,
				payperiodstartdate: periodicStartDate
					? periodicStartDate
					: null,
				payperiodenddate: periodicEndDate ? periodicEndDate : null
			});
			if (res.data.msg === "ok") {
				return true; // Proceed if no assessment exists
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: "This assessment has already been filed!"
				});
			}
		} catch (error) {
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message:
					error.response?.data?.msg ||
					error.message ||
					"An error occurred while confirming PAYE."
			});
		} finally {
			setLoading(false);
		}
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		try {
			// Use the company data to confirm PAYE
			const proceed = await checkingFile();
			if (!proceed) {
				setLoading(false);
				return;
			}
			history.push("/Assessment", {
				retrieveESBNdetails: companyDetails,
				typeOfAssessment,
				corpESBN,
				selectedYear,
				selectedMonth,
				periodicStartDate,
				periodicEndDate,
				mode: "0"
			});
		} catch (error) {
			console.error("Error during form submission:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<main className={style.mobile}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<p className={style.PAYEintro}>
				PAYE filing are filed for current year or month, <br />
				not for the past year.
			</p>
			<h3 className={style.header}>
				Type of Assessment <span style={{ color: "red" }}> * </span>
			</h3>
			{/* ///////////	radioBtns//////// */}
			<div className={style.radioBtns}>
				<div className={style.radioBtn}>
					<input
						type="radio"
						id="YearlyRadio"
						checked={typeOfAssessment === "Yearly"}
						onChange={() => setTypeOfAssessment("Yearly")}
						required
					/>
					<label htmlFor="YearlyRadio" required>
						Yearly
					</label>
				</div>
				<div className={style.radioBtn}>
					<input
						type="radio"
						id="MonthlyRadio"
						checked={typeOfAssessment === "Monthly"}
						onChange={() => setTypeOfAssessment("Monthly")}
						required
					/>
					<label htmlFor="MonthlyRadio" required>
						Monthly
					</label>
				</div>
				<div className={style.radioBtn}>
					<input
						type="radio"
						id="PeriodicRadio"
						checked={typeOfAssessment === "Period"}
						onChange={() => setTypeOfAssessment("Period")}
						required
					/>
					<label htmlFor="PeriodicRadio" required>
						Period
					</label>
				</div>
			</div>
			{/* ///////////	yearly//////// */}
			<form
				onSubmit={handleFormSubmit}
				className={`${styles3.form} ${style.fillingForm}`}
			>
				{typeOfAssessment && (
					<>
						<div className={`${styles3.double__inputs}`}>
							<div>
								<label
									htmlFor="ESBN"
									className={styles3.input__label}
								>
									ESBN{" "}
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="number"
									id="ESBN"
									value={corpESBN}
									onChange={(e) =>
										setCorpESBN(e.target.value)
									}
									placeholder="enter ESBN"
									required
									disabled
								/>
							</div>
							<div>
								<label
									htmlFor="Yearly"
									className={styles3.input__label}
								>
									Year of Assessment
									<span style={{ color: "red" }}> *</span>
								</label>
								<select
									id="Yearly"
									value={selectedYear}
									onChange={(e) =>
										setSelectedYear(e.target.value)
									}
									required
								>
									<option value="">Select year</option>
									{new Array(10).fill(0).map((_, i) => {
										const year =
											new Date().getFullYear() - i;
										return (
											<option key={year} value={year}>
												{year}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						{/* //////monthly///// */}
						{typeOfAssessment === "Monthly" && (
							<div className={`${styles3.double__inputs}`}>
								<div>
									<label
										htmlFor="Monthly"
										className={styles3.input__label}
									>
										Month of Assessment
										<span style={{ color: "red" }}> *</span>
									</label>
									<select
										id="Monthly"
										value={selectedMonth}
										onChange={(e) => {
											setSelectedMonth(e.target.value);
										}}
									>
										<option>select Month</option>
										{[...Array(12)].map((_, index) => (
											<option
												key={index}
												value={new Intl.DateTimeFormat(
													"en",
													{
														month: "long"
													}
												).format(
													new Date(2000, index, 1)
												)}
											>
												{new Intl.DateTimeFormat("en", {
													month: "long"
												}).format(
													new Date(2000, index, 1)
												)}
											</option>
										))}
									</select>
								</div>
								<div></div>
							</div>
						)}
						{/* /////////Periodically */}
						{typeOfAssessment === "Period" && (
							<div className={`${styles3.double__inputs}`}>
								<div>
									<label
										htmlFor="StartDate"
										className={styles3.input__label}
									>
										Start Month of Assessment
										<span style={{ color: "red" }}> *</span>
									</label>
									<input
										type="date"
										id="StartDate"
										value={periodicStartDate}
										onChange={(e) => {
											setPeriodicStartDate(
												e.target.value
											);
										}}
									/>
								</div>
								<div>
									<label
										htmlFor="EndDate"
										className={styles3.input__label}
									>
										End Month of Assessment
										<span style={{ color: "red" }}> *</span>
									</label>
									<input
										type="date"
										id="EndDate"
										value={periodicEndDate}
										onChange={(e) => {
											setPeriodicEndDate(e.target.value);
										}}
									/>
								</div>
							</div>
						)}
						<button
							type="submit"
							className={["primary__btn", style.FillingBtn].join(
								" "
							)}
							onClick={() => getCorpDetails(corpESBN)}
							disabled={
								!corpESBN ||
								!typeOfAssessment ||
								(typeOfAssessment === "yearly" &&
									!selectedYear) ||
								(typeOfAssessment === "Monthly" &&
									!selectedMonth) ||
								(typeOfAssessment === "Period" &&
									(!periodicStartDate || !periodicEndDate)) ||
								!selectedYear
							}
						>
							{loading ? "Continue..." : "Continue"}
						</button>
					</>
				)}
			</form>
		</main>
	);
};

export default CorporateIndex;
