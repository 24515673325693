import { useState, useEffect } from "react";
import axios from "axios";
import Alert from "../../components/Alert";
import Receipt from "./receipt";
import styles from "./style.module.css";
import styles3 from "../../Identity-management-individual/style.module.css";

const SingleInputReceipt = () => {
	// const accessToken = localStorage.getItem("access_token");
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	const [receiptNo, setReceiptNo] = useState("");
	const [data, setData] = useState("");
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const getReceipt = async (e) => {
		e.preventDefault();
		setLoading(true);
		setData("");
		try {
			const data = await axios.post(`/tmsgetreceiptref`, {
				receiptno: receiptNo,
				coy: userInfo?.mdaModuleCoy,
				userid: userInfo?.email
			});
			// if (data.data.status === 1 || data.data.status === 2) {
			// 	throw new Error(data.data.msg);
			// }

			setData(data.data);
		} catch (error) {
			if (error.response) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				}));
			} else if (error.request) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.request
				}));
			} else {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		} finally {
			setLoading(false);
		}
	};

	const printReceipt = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(data);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		w.onafterprint = () => {
			w.close();
		};
	};

	return (
		<div className={styles.receipt__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form className={styles3.columned__form} onSubmit={getReceipt}>
				<div className={styles3.double__inputs}>
					<div>
						<label
							style={{ marginBottom: "15px" }}
							className={styles3.input__label}
							htmlFor="receiptNo"
						>
							Receipt Number
						</label>
						<input
							id="receiptNo"
							name="receiptNo"
							value={receiptNo}
							onChange={(e) => setReceiptNo(e.target.value)}
							placeholder="884663527"
							required
						/>
					</div>
				</div>
				<button
					type="submit"
					className={["primary__btn", styles.submit__btn].join(" ")}
					disabled={!receiptNo}
				>
					Generate Receipt
				</button>
			</form>
			{data && (
				<button
					className={["secondary__btn", styles.print__btn].join(" ")}
					onClick={printReceipt}
				>
					Print
				</button>
			)}
			<Receipt data={data} loading={loading} />
		</div>
	);
};

export default SingleInputReceipt;
