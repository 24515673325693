import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Alert from "../../components/Alert";
import downloadIcon from "../../assets/whiteDownloadIcon.svg";
import goBackIcon from "../../assets/blackArrowLeft.svg";
import Modal from "../../components/Modal/regular";
import styles from "../ReceiptPymtRef/style.module.css";
import styles1 from "./style.module.css";
import styles3 from "../../Identity-management-individual/style.module.css";

const PrintDAManualEntryCertificate = () => {
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	const history = useHistory();
	const [ESBN, setESBN] = useState("");
	const [year, setYear] = useState("");
	const [certificate, setCertificate] = useState("");
	const [loadingCertificate, setLoadingCertificate] = useState(false);
	const [certModalShowing, setCertModalShowing] = useState(false);
	const [certDetails, setCertDetails] = useState(null);
	const [sendingForApproval, setSendingForApproval] = useState(false);
	const [redirecting, setRedirecting] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	useEffect(() => {
		let delay;

		if (redirecting) {
			delay = setTimeout(() => {
				setRedirecting(false);
				history.goBack();
			}, 3000);
		}

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redirecting]);

	const getCertificate = async () => {
		setLoadingCertificate(true);
		setCertificate("");
		try {
			const res = await axios.post(`/manualesbncert`, {
				ESBN,
				startYear: year
				// WaiveFlg: checked ? "Y" : "N",
				// WaiveReason: waiverReason
			});

			setCertificate(res.data.html);
			setCertDetails(res.data.data);
			setCertModalShowing(true);
		} catch (error) {
			if (error.response) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				}));
			} else if (error.request) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.request
				}));
			} else {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		} finally {
			setLoadingCertificate(false);
		}
	};

	useEffect(() => {
		if (!certModalShowing) setCertDetails(null);
	}, [certModalShowing]);

	const requestForApproval = async () => {
		setSendingForApproval(true);
		try {
			const res = await axios.post(`/manualcert4approval`, {
				inputter: userInfo.email,
				...certDetails
			});

			setCertModalShowing(false);
			setAlert((alert) => ({
				...alert,
				showing: true,
				type: "success",
				message: res.data.msg
			}));
			setRedirecting(true);
		} catch (error) {
			if (error.response) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message ||
						error.response?.data.err.message
				}));
			} else if (error.request) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.request
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		} finally {
			setSendingForApproval(false);
		}
	};

	const printCertificate = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(certificate);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		w.onafterprint = () => {
			w.close();
		};
	};

	return (
		<div className={styles.receipt__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{certModalShowing && (
				<Modal toggle={setCertModalShowing} showing={certModalShowing}>
					<>
						<div className={styles1.cert__btns}>
							<button
								className={`secondary__btn ${styles1.download__btn}`}
								onClick={() => printCertificate()}
							>
								<img
									src={downloadIcon}
									alt="download icon"
									style={{ marginRight: "8px" }}
								/>
								Print
							</button>
							{certDetails?.Approval === "N" && (
								<button
									className={`primary__btn ${styles1.request__btn}`}
									onClick={() => requestForApproval()}
								>
									{sendingForApproval
										? "Sending..."
										: "Request for Approval"}
								</button>
							)}
						</div>
						<div
							dangerouslySetInnerHTML={{
								__html: certificate
							}}
						></div>
					</>
				</Modal>
			)}
			<button
				className={styles1.back__btn}
				onClick={() => history.goBack()}
			>
				<img
					src={goBackIcon}
					alt="back arrow icon"
					style={{ marginRight: "10px" }}
				/>
				Go Back
			</button>
			<form
				className={styles3.columned__form}
				style={{ marginBottom: "40px" }}
				onSubmit={(e) => {
					e.preventDefault();
					getCertificate();
				}}
			>
				<div className={styles3.double__inputs}>
					<div>
						<label className={styles3.input__label} htmlFor="ESBN">
							ESBN <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="ESBN"
							name="ESBN"
							className={styles3.select__input}
							value={ESBN}
							onChange={(e) => setESBN(e.target.value)}
							placeholder="eg. 54826648633"
							required
						/>
					</div>
					<div>
						<label className={styles3.input__label} htmlFor="year">
							Year
						</label>
						<select
							id="year"
							value={year}
							onChange={(e) => setYear(e.target.value)}
							required
						>
							<option value="">Select</option>
							<option>2010</option>
							{new Array(new Date().getFullYear() - 2010)
								.fill(0)
								.map((_, i) => (
									<option key={i}>{2010 + i + 1}</option>
								))}
						</select>
					</div>
				</div>
				<button
					type="submit"
					className={["primary__btn", styles1.breakdown__btn].join(
						" "
					)}
					disabled={!year || !ESBN}
				>
					{loadingCertificate
						? "Generating..."
						: "Generate Certificate"}
				</button>
			</form>
		</div>
	);
};

export default PrintDAManualEntryCertificate;
