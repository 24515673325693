import { useEffect, useState } from "react";
import axios from "axios";
import Alert from "../../components/Alert";
import styles from "./style.module.css";

const UserDetails = () => {
	const userId = JSON.parse(localStorage.getItem("user_info")).id;
	const [details, setDetails] = useState(null);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	useEffect(() => {
		const getDetails = async () => {
			setLoading(true);
			try {
				const details = await axios
					.get(
						`/admin/individual-taxpayer/individualsinglepagetin?userId=${userId}`
					)
					.then((res) => res.data.data);

				setDetails(details);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setLoading(false);
			}
		};

		getDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section className={styles.wrapper}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{loading ? (
				<p style={{ textAlign: "center" }}>Loading...</p>
			) : (
				<>
					<img
						src={details?.result?.IdentityPhoto}
						alt="user"
						className={styles.profile__photo}
					/>
					<div className={styles.details}>
						<div className={styles.detail}>
							<span className={styles.detail__name}>TIN</span>
							<span className={styles.detail__value}>
								{details?.result?.TIN || "N/A"}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>NIN</span>
							<span className={styles.detail__value}>
								{details?.result?.NIN || "N/A"}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>ESBN</span>
							<span className={styles.detail__value}>
								{details?.result?.Anssid}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>Title</span>
							<span className={styles.detail__value}>
								{details?.result?.Title}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>Surname</span>
							<span className={styles.detail__value}>
								{details?.result?.Last_Name}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								First Name
							</span>
							<span className={styles.detail__value}>
								{details?.result?.First_Name}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								Middle Name
							</span>
							<span className={styles.detail__value}>
								{details?.result?.Middle_Name}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								Phone Number
							</span>
							<span className={styles.detail__value}>
								{details?.result?.Phone_Number}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>Email</span>
							<span className={styles.detail__value}>
								{details?.result?.Email_Address}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>Gender</span>
							<span className={styles.detail__value}>
								{details?.result?.Gender}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								Date of Birth
							</span>
							<span className={styles.detail__value}>
								{details?.result?.Date_Of_Birth.split("T")[0]}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								Country of Origin
							</span>
							<span className={styles.detail__value}>
								{details?.result?.CountryOfOrigin}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								State of Origin
							</span>
							<span className={styles.detail__value}>
								{details?.result?.StateOfOrigin}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								State of Origin
							</span>
							<span className={styles.detail__value}>
								{details?.result?.StateOfOrigin}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								LGA of Origin
							</span>
							<span className={styles.detail__value}>
								{details?.result?.LGAOfOrigin}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								Residential Address
							</span>
							<span className={styles.detail__value}>
								{details?.result?.ResidentialAddress}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								State of Residence
							</span>
							<span className={styles.detail__value}>
								{details?.result?.ResidentialState}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								LGA of Residence
							</span>
							<span className={styles.detail__value}>
								{details?.result?.ResidentialLGA}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								Zone of Residence
							</span>
							<span className={styles.detail__value}>
								{details?.result?.ResidentialZone}
							</span>
						</div>
					</div>
				</>
			)}
		</section>
	);
};

export default UserDetails;
