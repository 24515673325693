import style from "./style.module.css";
import FlexLeft from "../FlexLeft-template";
import Subscription from "../../Home/subscription/index";
import Footer from "../footer";
import NavBar from "../nav-bar";
import systemOfTaxImg from "../../assets/Home/systemOfTaxImg.svg";
import taxAdministrationImg from "../../assets/Home/taxAdministrationImg.svg";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useEffect } from "react";
import useIntersectionObserver from "../IntersectionObserver";
import observerStyle from "../IntersectionObserver/style.module.css";
const stateGovernment = [
	{
		id: "1.",
		text: "Pay As You Earn (PAYE) -   Companies"
	},
	{
		id: "2.",
		text: "Pay As You Earn (PAYE) -   Federal"
	},
	{
		id: "3.",
		text: "10% Withholding Tax on Bank   Interest"
	},
	{
		id: "4.",
		text: "Pay As You Earn (PAYE) -   State (Adjustment Voucher)"
	},
	{
		id: "5.",
		text: "Withholding Tax On Interest   Payment"
	},
	{
		id: "6.",
		text: "Pay As You Earn (PAYE) -   Local Government"
	},
	{
		id: "7.",
		text: "Pay as You Earn (PAYE) -   (Arrears)"
	},
	{
		id: "8.",
		text: "Direct Assessment Tax   (Arrears/Late)"
	},
	{
		id: "9.",
		text: "Purchase Tax on Hotels"
	},
	{
		id: "10.",
		text: "Education Development Levy"
	},
	{
		id: "11.",
		text: "10% Withholding Tax on   Dividends"
	},
	{
		id: "12.",
		text: "Direct Assessment Tax   (Current)"
	},
	{
		id: "13.",
		text: "Infrastructural Development   Levy"
	},
	{
		id: "14.",
		text: "Mortuary Levy"
	},
	{
		id: "15.",
		text: "Withholding Tax On   Liability"
	},
	{
		id: "16.",
		text: "Penalties Tax"
	},
	{
		id: "17.",
		text: "BOARD OF INTERNAL REVENUE -   7 Mining Truck Tax"
	},
	{
		id: "18.",
		text: "5% Withholding Tax on   Payment to Contractors"
	},
	{
		id: "19.",
		text: "10% Withholding Tax on   Directors Fees"
	},
	{
		id: "20.",
		text: "Recovery from back duty   assessment"
	},
	{
		id: "21.",
		text: "Back Duty Audit"
	},
	{
		id: "22.",
		text: "Capital Gains Tax"
	},
	{
		id: "23.",
		text: "Sale of Ticket/Consolidated   Emblem"
	},
	{
		id: "24.",
		text: "10% Withholding Tax on Rent"
	},
	{
		id: "25.",
		text: "10% Withholding Tax on   Royalty"
	},
	{
		id: "26.",
		text: "Enugu State Property and   Land Use Tax"
	},
	{
		id: "27.",
		text: "Drivers Licenses"
	},
	{
		id: "28.",
		text: "Road Traffic Exams"
	},
	{
		id: "29.",
		text: "Road Traffic Exam Fees"
	}
];

const localGovernment = [
	{
		id: "1.",
		text: "CULTURE & TOURISM"
	},
	{
		id: "2.",
		text: "EDUCATION"
	},
	{
		id: "3.",
		text: "ENSSAA"
	},
	{
		id: "4.",
		text: "ENUGU INTERNAL REVENUE   SERVICE"
	},
	{
		id: "5.",
		text: "ENUGU STATE GAMING &   LOTTO COMMISSION"
	},
	{
		id: "6.",
		text: "ENUGU STATE HOUSING DEV. CORPORATION"
	},
	{
		id: "7.",
		text: "ESWAMA"
	},
	{
		id: "8.",
		text: "MIN. OF AGRICULTURE &   AGRO INDUSTRIALIZATION"
	},
	{
		id: "9.",
		text: "MIN. OF CHILDREN, GENDER   AFFAIRS & SOCIAL DEV."
	},
	{
		id: "10.",
		text: "MIN. OF HEALTH"
	},
	{
		id: "11.",
		text: "MIN. OF HOUSING"
	},
	{
		id: "12.",
		text: "MIN. OF HUMAN DEV. &   POVERTY ERADICATION"
	},
	{
		id: "13.",
		text: "MIN. OF INFORMATION"
	},
	{
		id: "14.",
		text: "MIN. OF JUSTICE"
	},
	{
		id: "15.",
		text: "MIN. OF LANDS"
	},
	{
		id: "16.",
		text: "MIN. OF TRADE, INVESTMENT   & INDUSTRY"
	},
	{
		id: "17.",
		text: "MIN. OF WATER RESOURCES"
	},
	{
		id: "18.",
		text: "MIN. OF YOUTHS & SPORTS"
	},
	{
		id: "19.",
		text: "MOBP"
	},
	{
		id: "20.",
		text: "OFFICE OF THE SURVEYOR   GENERAL"
	},
	{
		id: "21.",
		text: "PARKLANE"
	},
	{
		id: "22.",
		text: "RANGERS MGT CORPORATION"
	},
	{
		id: "23.",
		text: "TOURISM BOARD"
	},
	{
		id: "24.",
		text: "TRANSPORT"
	},
	{
		id: "25.",
		text: "WATER CORPORATION"
	},
	{
		id: "26.",
		text: "CAPITAL TERRITORY   DEVELOPMENT"
	}
];

const TaxSystem = () => {
	useIntersectionObserver();
	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<main>
			<NavBar />
			<section className={style.heroSection}>
				<h2>Tax Systems </h2>
				<p className={style.desktop}>
					Describes the methods of Tax Administration which could be{" "}
					<br />
					Proportional, Progressive or Regressives
				</p>
				<p className={style.taxSystemMobileText}>
					Describes the methods of Tax Administration <br /> which
					could be Proportional, Progressive or <br /> Regressives
				</p>
			</section>
			<section className={style.systemOfTax}>
				<img
					src={systemOfTaxImg}
					alt="taxTypeImg"
					className={`${observerStyle.animateHomePage} ${observerStyle.slideInRight}`}
				/>
				<div
					className={`${style.paragraphs} ${observerStyle.animateHomePage} ${observerStyle.slideInLeft}`}
				>
					<h2>
						<span className={style.underlineGreen}>System</span>
						&nbsp;of Tax
					</h2>
					<h3 className={style.subHeading}>Proportional Tax</h3>
					<p>
						This system is where tax rate is relatively constant
						irrespective of the increase or decrease in income as
						tax payers pay the same percent."
					</p>
					<h3 className={style.subHeading}>Progressive Tax</h3>
					<p>
						Here, tax rate increases as the income of the payer
						increases such as in the case of personal income tax."
					</p>
					<h3 className={style.subHeading}>Regressive Tax</h3>
					<p>
						This is where the tax rate decreases as the income of
						the payer increases as it takes smaller percentage of
						their income since they are usually fixed with examples
						such as toll fees etc."
					</p>
				</div>
			</section>
			<FlexLeft
				heading={
					<div>
						<span className={style.underlineGreen}>Tax</span>
						&nbsp;Administration
					</div>
				}
				content1="
				This relates to the institutional arrangements created by governments to collect taxes and levies on their behalf."
				content2="
				1)	Federal Board of Inland Revenue and other MDAs duly authorized"
				content3="
				2)	State Board of Internal Revenue and other state MDA authorized."
				content4="
				3)	Local Government Revenue Committee"
				image={taxAdministrationImg}
			/>
			<section className={style.governmentCards}>
				<h2 className={style.desktop}>
					<span className={style.underlineGreen}>Tax</span>
					&nbsp;Administration In Enugu State
				</h2>
				<h2 className={style.mobile}>
					<span className={style.underlineGreen}>Tax Ad</span>
					ministration In Enugu State
				</h2>
				<p className={style.description}>
					Tax administration in Enugu state is being administered by
					the Enugu State Board of Internal Revenue Service headed by
					the executive Chairman, a position being held by Mr Emmanuel
					E. Nnamani (FCA).
				</p>
				<div className={style.localState}>
					<div className={style.left}>
						<h3>
							Some of the Revenues and taxes under his watch are:
						</h3>
						<div className={style.governmentCard}>
							{stateGovernment.map((state, index) => (
								<div key={index} className={style.state}>
									<p>{state.id}</p>
									<p className={style.text}>{state.text}</p>
								</div>
							))}
							<p>
								{
									"For other information about the Board, kindly visit "
								}
								{<Link to={"/about-us"}>About Us</Link>}
							</p>
						</div>
					</div>
					<div className={style.right}>
						<h3>Other MDAs Include the following:</h3>
						<div className={style.governmentCard}>
							{localGovernment.map((local, index) => (
								<div key={index} className={style.state}>
									<p>{local.id}</p>
									<p className={style.text}>{local.text}</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
			<Subscription />
			<Footer />
		</main>
	);
};

export default TaxSystem;
