import { useState, useEffect } from "react";
import axios from "axios";
import Alert from "../../components/Alert";
import Receipt from "./receipt";
import styles from "./style.module.css";
import styles2 from "../MDAs/style.module.css";

const ReceiptPage = () => {
	const accessToken = localStorage.getItem("access_token");
	const [values, setValues] = useState({
		paymentItem: "",
		invoiceNo: ""
	});
	const [data, setData] = useState("");
	const [isSendReceipt, setIsSendReceipt] = useState(false);
	const [loading, setLoading] = useState(false);
	const [revenueTypesLoading, setRevenueTypeLoadingState] = useState(false);
	const [revenueTypes, setRevenueTypes] = useState(null);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	useEffect(() => {
		getAllRevenueTypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (e) => {
		const val = e.target.value;
		const name = e.target.name;

		setValues({
			...values,
			[name]: val
		});
	};

	const getAllRevenueTypes = async () => {
		setRevenueTypeLoadingState(true);
		try {
			const request = await axios.get(`/settings/revenuehead`, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			});

			setRevenueTypeLoadingState(false);
			setRevenueTypes(request.data.data);
		} catch (error) {
			setRevenueTypeLoadingState(false);
			if (error.response) {
				console.log("response", error.response.data.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.message
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		}
	};

	const getReceipt = async (e) => {
		e.preventDefault();
		setLoading(true);
		setData("");
		try {
			const data = await axios.get(
				`/txn/invoice/getReceipt?InvoiceNumber=${values.invoiceNo}&RevenueCode=${values.paymentItem}`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);
			setLoading(false);
			if (data.data.status === 1 || data.data.status === 2) {
				throw new Error(data.data.msg);
			}

			setData(data.data);
			setValues({
				paymentItem: "",
				invoiceNo: ""
			});
		} catch (error) {
			setLoading(false);
			if (error.response) {
				console.log("response", error.response.data.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.message
				}));
			} else if (error.request) {
				console.log("resquest", error.request);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.request
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		}
	};

	const sendReceipt = async (e) => {
		e.preventDefault();
		setLoading(true);
		setData("");
		try {
			const data = await axios.post(
				`/txn/invoice/receipt?InvoiceNumber=${values.invoiceNo}&RevenueCode=${values.paymentItem}`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);
			setLoading(false);
			if (data.data.status === 1 || data.data.status === 2) {
				throw new Error(data.data.msg);
			}

			setAlert((alert) => ({
				...alert,
				showing: true,
				type: "success",
				message:
					"Receipt successfully sent to the Taxpayers email address."
			}));
		} catch (error) {
			setLoading(false);
			if (error.response) {
				console.log("response", error.response.data.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.message
				}));
			} else if (error.request) {
				console.log("resquest", error.request);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.request
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		}
	};

	const printReceipt = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(data);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		w.onafterprint = () => {
			w.close();
		};
	};

	return (
		<div className={styles.receipt__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles2.actions__div}>
				{/* <button
					onClick={() => {
						setIsSendReceipt(false);
					}}
				>
					Generate Receipt
				</button> */}
				{data && <button onClick={printReceipt}>Print Receipt</button>}
				<button
					onClick={() => {
						setIsSendReceipt(true);
					}}
					style={{ padding: "0" }}
				>
					Send Receipt
				</button>
			</div>
			<form
				className={styles.form}
				onSubmit={isSendReceipt ? sendReceipt : getReceipt}
			>
				<label style={{ marginBottom: "15px" }} htmlFor="paymentItem">
					Payment Item <span style={{ color: "red" }}>*</span>
				</label>
				<select
					id="paymentItem"
					name="paymentItem"
					style={{ marginTop: "0" }}
					value={values.paymentItem}
					onChange={handleChange}
					required
				>
					<option value="">Select</option>
					{revenueTypesLoading && <option value="">...</option>}
					{revenueTypes &&
						revenueTypes.map((eachType) => (
							<option key={eachType.id} value={eachType.Code}>
								{eachType.Description}
							</option>
						))}
				</select>
				<label style={{ marginBottom: "15px" }} htmlFor="invoiceNo">
					Invoice Number <span style={{ color: "red" }}>*</span>
				</label>
				<input
					id="invoiceNo"
					type="text"
					name="invoiceNo"
					style={{ marginTop: "0" }}
					onChange={handleChange}
					value={values.invoiceNo}
					placeholder="98271-834"
					required
				/>
				<button
					type="submit"
					className={["primary__btn", styles.submit__btn].join(" ")}
					disabled={!values.paymentItem || !values.invoiceNo}
				>
					{isSendReceipt ? "Send Receipt" : "Generate Receipt"}
				</button>
			</form>
			<Receipt data={data} loading={loading} />
		</div>
	);
};

export default ReceiptPage;
