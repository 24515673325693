import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Spinner from "../../components/spinner";
import styles from "../../Identity-management-individual/style.module.css";

const ChangePassword = () => {
	const history = useHistory();

	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const changePassword = async (e) => {
		e?.preventDefault();

		setError("");
		setLoading(true);

		try {
			const res = await axios
				.post(`/auth/change-password`, {
					oldPassword,
					newPassword
				})
				.then((res) => res.data);

			console.log(res);

			history.push("/");
		} catch (err) {
			console.error(err);
			const message =
				err.response?.data?.error ||
				"An error occurred while changing password.";
			setError(message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<form onSubmit={changePassword} className={styles.columned__form}>
			{error && <div className={styles.error}>{error}</div>}
			<div
				className={styles.double__inputs}
				style={{ display: "flex", flexDirection: "column" }}
			>
				<div>
					<label
						className={styles.input__label}
						htmlFor="oldPassword"
					>
						Old Password <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="password"
						id="oldPassword"
						placeholder="***********"
						required
						value={oldPassword}
						onChange={(e) => setOldPassword(e.target.value)}
					/>
				</div>
				<hr className={styles.divider} style={{ maxWidth: "50%" }} />
				<div>
					<label className={styles.input__label} htmlFor="middleName">
						New Password <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="password"
						id="newPassword"
						placeholder="************"
						value={newPassword}
						required
						onChange={(e) => setNewPassword(e.target.value)}
					/>
				</div>
				<div>
					<label className={styles.input__label} htmlFor="middleName">
						Confirm New Password{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="password"
						id="newPassword"
						placeholder="************"
						value={newPassword}
						required
						onChange={(e) => setNewPassword(e.target.value)}
					/>
				</div>
			</div>
			<footer
				className={styles.form__footer}
				style={{ justifyContent: "flex-start" }}
			>
				<button
					type="submit"
					disabled={loading}
					className={[
						"primary__btn",
						styles.primary__button,
            styles.chng__pwd__sub__btn,
						loading ? styles.loading__button : ""
					].join(" ")}
				>
					{loading ? "Changing Password..." : "Change Password"}
					{loading && <Spinner />}
				</button>
			</footer>
		</form>
	);
};

export default ChangePassword;
