import { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Alert from "../../components/Alert";
import styles from "./style.module.css";
import AnnualReturn from "../../assets/user_guide_for_Annual_Return.pdf";
import PAYE from "../../assets/user_guide_for_PAYE.pdf";
const coy_ANSSID = process.env.REACT_APP_ANSSID;

const Home = () => {
	const userInfo = JSON.parse(localStorage.getItem("user_info")) || {};
	const [anssidInfo, setAnssidInfo] = useState({});
	const [eCardMode, setECardMode] = useState(null);
	const [modesShowing, setModesShowing] = useState(false);
	const [generatingCard, setGeneratingCard] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const getAnssid = async () => {
		const res = await axios
			.get(`/system/getuseranssid`)
			.then((res) => (res.data || {}).data);
		setAnssidInfo(res);
	};

	useEffect(() => {
		getAnssid();
	}, []);

	const handleDownload = (documentType) => {
		let pdfFilePath = " ";
		switch (documentType) {
			case "individualESBN":
				pdfFilePath =
					"https://storage.googleapis.com/enugu-state-static/Guide%20to%20ESBN%20Individual.pdf";
				break;
			case "corporateESBN":
				pdfFilePath =
					"https://storage.googleapis.com/enugu-state-static/Guide%20to%20ESBN%20Corporates.pdf";
				break;
			case "paymentWithBillNumber":
				pdfFilePath =
					"https://storage.googleapis.com/enugu-state-static/Guide%20for%20making%20payment%20with%20bill%20Number.pdf";
				break;
			case "paymentWithoutBillNumber":
				pdfFilePath =
					"https://storage.googleapis.com/enugu-state-static/Guide%20to%20making%20payments%20by%20Students%20(Without%20Bill%20Number).pdf";
				break;
			case "PAYE":
				pdfFilePath = PAYE;
				break;
			case "AnnualReturn":
				pdfFilePath = AnnualReturn;
				break;
			default:
				return;
		}

		window.open(pdfFilePath, "_blank", "noopener,noreferrer");
	};

	useEffect(() => {
		const getECard = async () => {
			setGeneratingCard(true);
			try {
				const info = await axios
					.get(
						`/admin/individual-taxpayer/system/generateesbncard/${userInfo.anssid}/${eCardMode}`,
						{
							responseType: eCardMode === 2 ? "blob" : "json"
						}
					)
					.then((res) => res.data);

				if (eCardMode === 2) {
					const url = window.URL.createObjectURL(info);
					const link = document.createElement("a");
					link.href = url;
					link.download = "ESBN-e-card.pdf"; // specify the desired file name
					document.body.appendChild(link);
					link.click();

					// Clean up
					document.body.removeChild(link);
					window.URL.revokeObjectURL(url);
				}

				if (eCardMode === 1) {
					const w = window.open();
					w?.document.write(info);
					w?.document.close();
					w.onload = () => {
						w.focus();
						w.print();
					};

					w.onafterprint = () => {
						w.close();
					};
				}

				if (eCardMode === 3) {
					setAlert({
						...alert,
						showing: true,
						type: "success",
						message: info.msg
					});
				}
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					console.error(error.message);
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setGeneratingCard(false);
				setECardMode(null);
			}
		};

		if (eCardMode) getECard();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eCardMode]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			5000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div className={styles.home}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{userInfo?.account_type === "individual" && (
				<div className={styles.get__ecard_area}>
					<button
						className="primary__btn"
						onClick={() => setModesShowing(!modesShowing)}
					>
						{generatingCard ? "Generating e-card..." : "Get e-card"}
					</button>
					{modesShowing && (
						<div className={styles.modes}>
							<button
								onClick={() => {
									setECardMode(1);
									setModesShowing(false);
								}}
							>
								Print
							</button>
							<button
								onClick={() => {
									setECardMode(2);
									setModesShowing(false);
								}}
							>
								Download as PDF
							</button>
							<button
								onClick={() => {
									setECardMode(3);
									setModesShowing(false);
								}}
							>
								Send as email
							</button>
						</div>
					)}
				</div>
			)}
			<h1 className={styles.salutation}>
				Welcome,{" "}
				{userInfo.account_type === "corporate"
					? userInfo.corpname || userInfo?.firstname
					: userInfo.firstname}
			</h1>
			<p className={styles.basic}>
				Your data has been saved successfully.
				<br />
				Your {coy_ANSSID} number: <b>{userInfo.anssid}</b>
				<br />
				Your registration date:{" "}
				<b>
					{new Date(
						anssidInfo?.createdAt || Date.now()
					).toLocaleDateString()}
				</b>
			</p>
			<p className={styles.details}>
				Your email address: <b>{userInfo.email}</b> <br />
			</p>
			<p className={styles.details}>
				Your phone number: <b>{userInfo.phonenumber}</b>
				<br />
				<br />
			</p>
			<p className={styles.details}>
				Please contact <NavLink to="/support">support</NavLink>, if you
				have any issues.
			</p>
			<div style={{ marginTop: "50px" }}>
				<select onChange={(e) => handleDownload(e.target.value)}>
					<option value="">Download User Guide</option>
					<option value="individualESBN">Individual ESBN</option>
					<option value="corporateESBN">Corporate ESBN</option>
					<option value="paymentWithBillNumber">
						Payment With BillNumber{" "}
					</option>
					<option value="paymentWithoutBillNumber">
						Payment Without Bill Number{" "}
					</option>
					<option value="PAYE">PAYE</option>
					<option value="AnnualReturn">E-Filling </option>
				</select>
			</div>
		</div>
	);
};

export default Home;
