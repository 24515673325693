import { useState, useEffect, createRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Table from "../../../components/Table";
import PaymentModal from "../../../components/Modal/payment.modal";
import styles from "./style.module.css";
import styles1 from "../../MDAs/style.module.css";
import anssidStyles from "../../StateTIN/style.module.css";

const CorporatePayeRecord = () => {
	const corporateESBN = JSON.parse(localStorage.getItem("user_info")).anssid;
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [finished, setFinished] = useState("0");
	const [data, setData] = useState([]);
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [billNumber, setBillNumber] = useState("");
	const filterRef = createRef();
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	useEffect(
		() => {
			getAssessmentRecords()();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[finished === "1"]
	);

	const getAssessmentRecords =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();
			setLoading(true);
			if (!filterRef.current?.classList.contains(styles1.no__display)) {
				filterRef.current?.classList.toggle(styles1.no__display);
			}
			try {
				const response = await axios.get(
					`/dareports/tmspayedisplay?ESBN=${corporateESBN}`
				);

				const { data } = response.data || {};
				if (!data) {
					// handle errors
					return null;
				}

				setData(data);
				return data;
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "success",
						message: error.response.data?.msg
					});
				}
			} finally {
				setFinished("1");
			}
		};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const getStatusColor = (status) => {
		switch (status) {
			case "Approved":
				return "#2F9959";
			case "Pending Review":
				return "brown";
			case "PAID":
				return "#2F9959";
			case "Revoke":
				return "#D73E3E";
			case "Cancel":
				return "#D73E3E";
			case "Pending":
				return "#F99601";
			case "Pending Approval":
				return "#F99601";
			default:
				return "black";
		}
	};

	const processStatusMap = {
		0: "Pending Review",
		1: "Cancel",
		2: "Decline",
		3: "Approved",
		4: "Revoke",
		5: "Pending Approval",
		6: "Delete"
	};

	const getModeOfFilingText = (modeOfFiling) => {
		if (modeOfFiling === "0") {
			return "Self-Service";
		} else if (modeOfFiling === "1") {
			return "Admin";
		} else {
			return "";
		}
	};

	const modifiedData = data.map((item) => {
		const ProcessStatusFlag = processStatusMap[item.ApproveFlg] || "";
		const ModeOfFilingFlag = getModeOfFilingText(item.ModeOfFiling) || "";

		return {
			...item,
			ProcessStatus: (
				<span style={{ color: getStatusColor(ProcessStatusFlag) }}>
					{ProcessStatusFlag}
				</span>
			),
			PaymentStatus: (
				<span style={{ color: getStatusColor(item.PaymentStatus) }}>
					{item.PaymentStatus}
				</span>
			),
			ModeOfFiling: <span>{ModeOfFilingFlag}</span>
		};
	});

	const headers = [
		["Bill Number", "BillNumber"],
		["Type of Assessment", "PayeType"],
		["Fiscal Year ", "fiscalYear"],
		["Duration", "PayTypeMode"],
		["Total Num of Staff", "totStaffNo"],
		["Total Monthly charge", "totMthAmtBilled"],
		["Payment Status", "PaymentStatus"],
		["Process Status", "ProcessStatus"],
		["Reason for Cancellation", "RejectReason"]
	];

	return (
		<main className={styles.corporatePayeRecord}>
			<button
				className={`primary__btn ${anssidStyles.create__btn}`}
				onClick={() => history.push("/PAYE/PAYE-Assessment")}
			>
				File for PAYE{" "}
			</button>
			<div style={{ margin: "0px", marginTop: "32px" }}>
				<div className={styles1.table__div}>
					{loading && !data ? (
						<p style={{ textAlign: "center" }}>Loading...</p>
					) : (
						<Table
							headers={headers}
							data={modifiedData}
							full
							pageSize={20}
							onPageChanged={getAssessmentRecords}
							onPay={(data) => {
								setBillNumber(data.BillNumber);
								setShowPaymentModal(true);
							}}
							onView={(data) => {
								history.push(`/Paye/Review`, {
									...data,
									ProcessStatus:
										processStatusMap[data.ApproveFlg],
									PaymentStatus:
										processStatusMap[data.PaymentStatus],
									ModeOfFiling:
										getModeOfFilingText[data.ModeOfFiling],
									view: true
								});
							}}
						/>
					)}
				</div>
			</div>

			<section>
				{showPaymentModal && (
					<PaymentModal
						showing={showPaymentModal}
						toggle={setShowPaymentModal}
						billNumber={billNumber}
					/>
				)}
			</section>
		</main>
	);
};

export default CorporatePayeRecord;
