import { useState } from "react";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import styles from "../MDAs/style.module.css";

const RATs = () => {
	const [fullTableShowing, toggleFullTable] = useState(false);

	const headers = [
		["Revenue Code", "revenuecode"],
		["AMT", "amt"],
		["Billing Group", "billinggroup"],
    ["Category", "category"],
    ["Revenue Source Name", "revenuesourcename"],
    ["Date Range", "daterange"],
    ["MDA Name", "mdaname"]
	];

	const data = [
		{
			revenuecode: 8920,
			amt: "National Name",
			billinggroup: "fayvorharrison@gmail.com",
			category: "6728913",
			revenuesourcename: "Oil",
      daterange: "Downstream",
      mdaname: "ANSWAMMA"
		},
		{
			revenuecode: 8920,
			amt: "National Name",
			billinggroup: "fayvorharrison@gmail.com",
			category: "6728913",
			revenuesourcename: "Oil",
      daterange: "Downstream",
      mdaname: "ANSWAMMA"
		},
		{
			revenuecode: 8920,
			amt: "National Name",
			billinggroup: "fayvorharrison@gmail.com",
			category: "6728913",
			revenuesourcename: "Oil",
      daterange: "Downstream",
      mdaname: "ANSWAMMA"
		},
		{
			revenuecode: 8920,
			amt: "National Name",
			billinggroup: "fayvorharrison@gmail.com",
			category: "6728913",
			revenuesourcename: "Oil",
      daterange: "Downstream",
      mdaname: "ANSWAMMA"
		}
	];

	return (
		<div className={styles.MDA__page}>
			<div className={styles.actions__div}>
				<button onClick={() => toggleFullTable(!fullTableShowing)}>
					View Entire Table
				</button>
			</div>
			<div className={styles.table__div}>
				<Table headers={headers} data={data} />
			</div>
			{fullTableShowing && (
				<Modal showing={fullTableShowing} toggle={toggleFullTable}>
					<Table headers={headers} data={data} full />
				</Modal>
			)}
		</div>
	);
};

export default RATs;
