/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Spinner from "../../components/spinner";
// import styles from "./style.module.css";
import styles from "../../Identity-management-individual/style.module.css";

// import styles2 from "../MDAs/style.module.css";
import styles3 from "../../Identity-management-individual/style.module.css";
import Table from "../../components/Table";
import { useDebounce } from "./hooks";
import Alert from "../../components/Alert";
import { InvoiceModal } from "../../components/Modal/invoiceModal";
import Receipt from "../ReceiptPymtRef/receipt";
import styles1 from "../DemandNotice/Consolidation/style.module.css";
import multiStyles from "./style.module.css";
import { set } from "date-fns";
// import Table from "../../components/Table5";

const user = JSON.parse(localStorage.getItem("user_info"));

const Water = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [ownersesbn, setownersesbn] = useState("");
	const [BillNumber, setBillNumber] = useState("");
	const [ownersname, setownersname] = useState("");
	const [owneremail, setowneremail] = useState("");
	const [ownersaddrcity, setownersaddrcity] = useState("");
	const [accountType, setAccountType] = useState("individual");
	const [payername, setpayername] = useState("");
	const [propertyaddr, setpropertyaddr] = useState("");
	const [propertycity, setpropertycity] = useState("");
	const [appartmentno, setappartmentno] = useState("");
	const [ownershiptype, setownershiptype] = useState("");
	const [profile, setprofile] = useState("");
	const [profilecategory, setprofilecategory] = useState("");
	const [tagId, settagId] = useState("");
	const [billtype, setbilltype] = useState("");
	const [showInvoice, setShowInvoice] = useState(false);

	const [invoice, setInvoice] = useState("");
	const [authoriser, setauthoriser] = useState("");
	const [phoneno, setPhoneNumber] = useState("");
	const [assesscode, setAssessmentCode] = useState("");
	const [email, setEmail] = useState("");
	const [residentialaddress, setResidentialaddress] = useState("");
	const [RegistrationFee, setRegistrationFee] = useState("");
	const [RenewalFee, setRenewalFee] = useState("");
	const [discount, setDiscount] = useState("");
	const [discountReason, setDiscountReason] = useState("");
	const [disco, setDisco] = useState(0);
	const [resDiscount, setResDiscount] = useState("");
	const [outstand, setOutStanding] = useState("");
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [filterSubCategories, setFilteredSubCategories] = useState([]);
	const [currentBillType, setCurrentBillType] = useState("");
	const [subCatData, setSubCatData] = useState({ value: "", index: null });
	const [subCatDescription, setSubCatDescription] = useState("");
	const [selectMonth, setSelectedMonth] = useState("");
	const [selectYear, setSelectYear] = useState("");
	const [quantity, setQuantity] = useState(0);
	const [totAmount, setTotAmount] = useState("");
	const [waivers, setWaivers] = useState(0);
	const [resWaivers, setResWaivers] = useState("");
	const [totAmountCharged, setTotAmountCharged] = useState("");
	const [amount, setAmount] = useState("");
	const [tableData, setTableData] = useState([]);
	const [mdaKode, setMdaKode] = useState("");
	const [mdaId, setMdaId] = useState("");
	const [category, setCategory] = useState("");
	const [subCategory, setSubCategory] = useState("");
	const [subCaties, setSubCaties] = useState([]);
	const [billCategory, setBillCategory] = useState("");
	const [description, setDescription] = useState("");
	const [billCategories, setBillCategories] = useState([]);
	const [descriptions, setDescriptions] = useState([]);

	const [currentPropertyID, setCurrentPropertyID] = useState({
		value: "",
		index: null
	});
	const [landMeasurement, setLandMeasurement] = useState({
		value: "",
		index: null
	});
	const [mdaProperties, setMDAProperties] = useState({});
	const [areas, setAreas] = useState([]);
	const [zones, setZones] = useState([]);
	const [lgas, setLGAs] = useState([]);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const [profileDetailsArray, setProfileDetailsArray] = React.useState([
		{
			amount: "",
			houseNo: "",
			HouseStr: "",
			houseArea: "",
			houseLGA: "",
			houseZone: "",
			revenuedescription: "",
			profile: "",
			profilecategory: "",
			billtype: "",
			propertyid: "",
			landmeasure: ""
		}
	]);
	const [profileDetails, setProfileDetails] = React.useState({
		amount: "",
		houseNo: "",
		HouseStr: "",
		houseArea: "",
		houseLGA: "",
		houseZone: "",
		revenuedescription: "",
		profile: "",
		profilecategory: "",
		billtype: "",
		propertyid: "",
		landmeasure: ""
	});

	const invoiceModalRef = useRef();

	const handleDiscount = (value) => {
		if (allBilledAmount < value) {
			setAlert({
				showing: true,
				type: "error",
				message: "Discount cannot be higher than amount."
			});
		} else {
			setDiscount(value);
			setAlert({
				showing: false,
				type: null,
				message: ""
			});
		}
	};

	const allBilledAmount = useMemo(() => {
		const allAmount = profileDetailsArray.reduce(
			(accumulator, currentValue) =>
				accumulator + Number(currentValue.amount),
			0
		);
		const newTotalAmount =
			Number(allAmount) > 0 &&
			Number(allAmount) - Number(discount) + Number(outstand) >= 0
				? Number(allAmount) - Number(discount) + Number(outstand)
				: Number(allAmount);
		return newTotalAmount;
	}, [profileDetailsArray, outstand, discount]);

	const getSelectedCategories = (e) => {
		const value = e.target.value;
		if (value?.split("/")) {
			const [company, code] = value?.split("/");
			const filteredSubCategory = subCategories.filter(
				(category) =>
					category.CategoryCode === code && category.Coy === company
			);
			setFilteredSubCategories(filteredSubCategory);
		} else {
			setFilteredSubCategories(value);
		}
	};

	const getSelectedSubCat = (e, i) => {
		handleProfileInArrayChange(e, i);
	};

	const getAmount = useMemo(() => {
		if (subCatData?.value?.split("/")) {
			const [RegistrationFee, RenewalFee, FixedFee, i] =
				subCatData?.value?.split("/");
			const allAvailableBills = { RegistrationFee, RenewalFee, FixedFee };

			if (
				allAvailableBills[currentBillType] &&
				Number(allAvailableBills[currentBillType]) > 0
			) {
				setAlert({
					showing: false,
					type: false,
					message: ""
				});
				return allAvailableBills[currentBillType];
			} else {
				currentBillType &&
					subCatData.value &&
					setAlert({
						showing: true,
						type: "error",
						message: "Tarrif doesn't exist for the sub-category"
					});
				return "";
			}
		} else {
			return 0;
		}
	}, [subCatData, currentBillType]);

	// const calculateAmount = (i) => {
	//     const newProfilesArray = profileDetailsArray.map((child, index) => {
	//         return index === Number(i) ? {
	//             ...child,
	//             amount: child.landmeasure ? child.amount * child.landmeasure : child.amount
	//         } : child;
	//     });
	// }

	const updateAmount = (value, i) => {
		const newProfilesArray = profileDetailsArray.map((child, index) => {
			return index === Number(i)
				? { ...child, amount: String(value) }
				: child;
		});
		setProfileDetailsArray(newProfilesArray);
	};

	const handLandMeasurement = (amount, i) => {
		if (landMeasurement.index >= 0) {
			if (landMeasurement.index === i) {
				console.log("it matches");
				const landMeasurementAmount = landMeasurement.value * amount;
				updateAmount(landMeasurementAmount, i);
			} else {
				console.log(landMeasurement.index, i, "it doesnt match");
				updateAmount(amount, i);
			}
		} else {
			updateAmount(amount, i);
			console.log("index not seen");
		}
	};
	useEffect(() => {
		const i = subCatData.index;

		handLandMeasurement(getAmount, i);
	}, [getAmount, subCatData, landMeasurement]);

	const handleProfileInArrayChange = (e, i) => {
		const value = e.target.value;
		const name = e.target.name;

		const newProfilesArray = profileDetailsArray.map((child, index) => {
			return index === i ? { ...child, [name]: value } : child;
		});

		setProfileDetailsArray(newProfilesArray);
	};

	const addAnotherProfile = () => {
		setProfileDetailsArray([...profileDetailsArray, profileDetails]);
		setProfileDetails({
			...profileDetails,
			amount: "",
			houseNo: "",
			HouseStr: "",
			houseArea: "",
			houseLGA: "",
			houseZone: "",
			revenuedescription: "",
			profile: "",
			profilecategory: "",
			billtype: "",
			propertyid: "",
			landmeasure: ""
		});
	};

	function removeChild(index) {
		const newChildrenArray = [...profileDetailsArray];
		newChildrenArray.splice(index, 1);
		setProfileDetailsArray(newChildrenArray);
	}

	useEffect(() => {
		const getESBN = async () => {
			const res = await axios
				.get(`users/anssid?anssid=${ownersesbn}`)
				.then((res) => (res.data || {}).data);

			setAccountType(res.accountType);
			if (res?.accounttype === "individual") {
				setownersname(`${res.firstname} ${res.surname}`);
				setResidentialaddress(res.residentialaddress);
				setPhoneNumber(res.phonenumber);
			} else {
				setownersname(res?.name);
				setResidentialaddress(res.stateOfficeAddress);
				setPhoneNumber(res.phoneNumber);
			}
			setResidentialaddress(res.residentialaddress);
			setEmail(res.email);
		};
		if (ownersesbn?.length >= 10) getESBN();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownersesbn]);

	useEffect(() => {
		const fetchCategories = async () => {
			// let userInfo = localStorage.getItem("user_info");
			const userInfo = JSON.parse(localStorage.getItem("user_info"));

			const res = await axios
				.get(`settings/profilecategorys/${userInfo.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			console.log("Category--->", res);
			setCategories(res);
		};
		fetchCategories();
	}, []);

	// useEffect(() => {
	// 	const fetchSubCategories = async () => {
	// 		const res = await axios
	// 			.get(`settings/tarrifcommerces/${user?.mdaModuleCoy}`)
	// 			.then((res) => (res.data || {}).data);
	// 		console.log("subCategory--->", res);
	// 		setSubCategories(res);
	// 	};
	// 	fetchSubCategories();
	// }, []);

	const handleCat2Bill = async (cat) => {
		console.log("cat -> ", cat);
		// console.log("kode---->", kode);
		console.log("mdaId---->", mdaId);
		setSubCaties([]);
		try {
			setMdaKode(cat);
			const response = await axios
				.get(`/categorytarrifcommerces/${mdaId}/${cat}`)
				.then((res) => (res.data || {}).data);
			console.log("handleCat2Bill => ", response);
			setSubCaties(response);
		} catch (err) {
			console.error(err);
		}
	};

	// Area
	useEffect(() => {
		const fetchArea = async () => {
			const res = await axios
				.get(`settings/areas/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setAreas(res);
		};
		fetchArea();
	}, []);

	// Zone
	useEffect(() => {
		const fetchZones = async () => {
			const res = await axios
				.get(`settings/zones/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setZones(res);
		};
		fetchZones();
	}, []);

	// LGA
	useEffect(() => {
		const fetchLGAs = async () => {
			const res = await axios
				.get(`settings/lgass/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setLGAs(res);
		};
		fetchLGAs();
	}, []);

	const fetchInvoice = async (billNumber) => {
		await axios
			.get(`mdanotice/${billNumber}/${user?.mdaModuleCoy}`)
			.then((res) => {
				setInvoice(res?.data);
				setShowInvoice(true);
				setTimeout(() => {
					printInvoiceFile();
				}, 3000);
			});
	};

	useEffect(() => {
		if (!!invoice) {
			printInvoiceFile();
		}
	}, [invoice]);

	const printInvoiceFile = () => {
		if (!!invoice) {
			const w = window.open();
			if (w.document) {
				w.document.write(invoice);
				w.document.close();
				w.onload = () => {
					w.focus();
					w.print();
				};

				w.onafterprint = () => {
					w.close();
				};
			}
		} else {
			return;
		}
	};

	const downloadInvoiceFile = () => {
		// setSubmitting(true);
		// html2canvas(invoiceModalRef.current)
		// 	.then((canvas) => {
		// 		const pdf = new jsPDF({
		// 			orientation: 'p',
		// 			unit: 'px',
		// 			format: [800, 1750]
		// 		});
		// 		pdf.setFontSize(9);
		// 		const imgData = canvas.toDataURL('image/png');
		// 		pdf.addImage(imgData, 'JPEG', 0, 0);
		// 		// pdf.output('dataurlnewwindow');
		// 		pdf.save(`invoice.pdf`);
		// 	})
		// 	.finally(() => {
		// 		// setSubmitting(false);
		// 	});
	};

	useEffect(() => {
		const fetchPropId = async (propId, i) => {
			const res = await axios
				.get(`mdapropertyprofile/${propId}/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);

			const newProfilesArray = profileDetailsArray.map((child, index) => {
				return index === Number(i)
					? {
							...child,
							houseNo: res.AppartmentNo,
							HouseStr: res.PropertyAddr,
							houseArea: res.PropertyCity,
							houseLGA: res.PropertyCity,
							houseZone: ""
						}
					: child;
			});
			setProfileDetailsArray(newProfilesArray);
			setMDAProperties(res);
		};
		if (!!currentPropertyID.value && currentPropertyID.index !== null) {
			fetchPropId(currentPropertyID.value, currentPropertyID.index);
		}
	}, [currentPropertyID]);

	const submit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setAlert({
			showing: false,
			type: null,
			message: ""
		});

		let assessmentcode;

		assessmentcode = await axios.get(`gettmsids/01`);
		setAssessmentCode(`${assessmentcode.data.Id}`);

		try {
			const accessToken = localStorage.getItem("access_token");
			await axios
				.post(
					`/billing`,
					{
						coyid: user?.mdaModuleCoy
							? `${user?.mdaModuleCoy}`.trim()
							: "",
						billnumber: assessmentcode.data.Id
							? `${assessmentcode.data.Id}`.trim()
							: "",
						owneremail: email ? `${email}`.trim() : "",
						ownersesbn: ownersesbn ? `${ownersesbn}`.trim() : "",
						ownersname: user?.firstname
							? `${user?.firstname}`.trim()
							: "",
						ownersaddr: residentialaddress
							? `${residentialaddress}`.trim()
							: "",
						ownersaddrcity: mdaProperties?.OwnersAddrCity
							? `${mdaProperties?.OwnersAddrCity}`.trim()
							: "",
						ownersphone: phoneno ? `${phoneno}`.trim() : "",
						payername: ownersname ? `${ownersname}`.trim() : "",
						propertyaddr: mdaProperties?.PropertyAddr
							? `${mdaProperties?.PropertyAddr}`.trim()
							: "",
						propertycity: mdaProperties?.PropertyCity
							? `${mdaProperties?.PropertyCity}`.trim()
							: "",
						propertyid: "",
						ownershiptype: mdaProperties?.OwnershipType
							? `${mdaProperties?.OwnershipType}`.trim()
							: "",
						tagId: mdaProperties?.TagId
							? `${mdaProperties?.TagId}`.trim()
							: "",
						authoriser: mdaProperties?.Authoriser
							? `${mdaProperties?.Authoriser}`.trim()
							: "",
						totamt: allBilledAmount
							? `${allBilledAmount}`.trim()
							: "",
						discount: discount ? `${discount}`.trim() : "",
						discountreason: discountReason
							? `${discountReason}`.trim()
							: "",
						grandtot: allBilledAmount
							? `${allBilledAmount}`.trim()
							: "",
						outstand: outstand ? `${outstand}`.trim() : "",
						billDetails: profileDetailsArray?.map((detail) => ({
							...detail,
							coy: user?.mdaModuleCoy
								? `${user?.mdaModuleCoy}`.trim()
								: "",
							profile: `${detail.profile.split("/")[2]}`,
							profilecategory: `${
								detail.profilecategory.split("+")[0]
							}`.trim(),
							deactivated: false
						})),
						paymtstatus: "Pending",
						procstatus: "Approved",
						inputter: user?.email ? `${user?.email}`.trim() : "",
						deactivated: false
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => {
					setAlert({
						showing: true,
						type: "success",
						message: "Bill generated successfully"
					});
					fetchInvoice(`${assessmentcode.data.Id}`);
					return res?.data;
				});

			setBillNumber("");
			setownersesbn("");
			setownersname("");
			setResidentialaddress("");
			setDiscount("");
			setDiscountReason("");
			// setownersaddr("");
			setownersaddrcity("");
			setPhoneNumber("");
			setEmail("");
			setowneremail("");
			setpayername("");
			setpropertyaddr("");
			setpropertycity("");
			setownershiptype("");
			setappartmentno("");
			settagId("");
			setprofilecategory("");
			setprofile("");
			setauthoriser("");
			setbilltype("");
			setRegistrationFee("");
			setRenewalFee("");
		} catch (err) {
			console.error(err);
			setAlert({
				showing: true,
				type: "error",
				message: "Bill generation not successful."
			});
		} finally {
			setLoading(false);
		}
	};

	// console.log(profileDetailsArray[0]?.profile?.split('/')[2], profileDetailsArray[0]?.profilecategory?.split('/')[2])

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const handleTotalAmount = () => {
		if (!isNaN(quantity) && quantity > 0) {
			const calculatedTotAmount =
				Number(setSubCategories[0].FixedFee) * quantity;
			console.log("calculatedTotAmount", calculatedTotAmount);

			setTotAmount(calculatedTotAmount);
		} else {
			setTotAmount("");
		}
	};

	const handleAddBill = () => {
		const newBill = {
			quantity,
			totAmount,
			disco,
			waivers,
			totAmountCharged
		};
		setTableData([...tableData, newBill]);

		setQuantity(null);
		setTotAmount(null);
		setDisco(null);
		setWaivers(null);
		setTotAmountCharged(null);
	};

	const headers = [
		["Unit Price", "mda"],
		[`Number of month paying for `, "billtype"],
		["Total Amount ", "billCategory"],
		["Discount  ", "description"],
		["Waiver", "revenue"],
		["Total Amount Charged", "amount"]
		// ["Outstanding", "outstanding"],
		// ["OutstandingDescription  ", "OutstandingDesciption"]
	];

	return (
		<>
			<form className={styles.form} onSubmit={submit}>
				{alert.showing && (
					<Alert text={alert.message} type={alert.type} />
				)}

				<header>
					<h2>Taxpayer Water bill</h2>
					<h2>Are you the one? No, i have been changed</h2>
				</header>
				<hr className={styles.divider} />
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="ownersesbn"
						>
							Esbn
						</label>
						<input
							type="text"
							onChange={(e) => setownersesbn(e.target.value)}
							id="ownersesbn"
							placeholder=""
							value={ownersesbn}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="ownersname"
						>
							Name <span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="text"
							onChange={(e) => setownersname(e.target.value)}
							id="ownersname"
							placeholder=""
							required
							//  disabled
							value={ownersname}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<br />
						<label
							className={styles.input__label}
							htmlFor="residentialaddress"
						>
							Address
						</label>
						<input
							type="text"
							onChange={(e) =>
								setResidentialaddress(e.target.value)
							}
							id="residentialaddress"
							placeholder=""
							//  disabled
							value={residentialaddress}
						/>
					</div>
					<div>
						<br />
						<label
							className={styles.input__label}
							htmlFor="phonenumber"
						>
							Phone
						</label>
						<input
							type="text"
							onChange={(e) => setPhoneNumber(e.target.value)}
							id="phonenumber"
							placeholder=""
							//  disabled
							value={phoneno}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<br />
						<label className={styles.input__label} htmlFor="email">
							Email
						</label>
						<input
							type="text"
							onChange={(e) => setEmail(e.target.value)}
							id="email"
							placeholder=""
							//  disabled
							value={email}
						/>
					</div>
					<div>
						<br />
						<label
							className={styles.input__label}
							htmlFor="discount"
						>
							Discount
						</label>
						<input
							type="text"
							onChange={(e) => handleDiscount(e.target.value)}
							id="discount"
							placeholder=""
							value={discount}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<br />
						<label
							className={styles.input__label}
							htmlFor="discountReason"
						>
							Discount reason
						</label>
						<input
							type="text"
							onChange={(e) => setDiscountReason(e.target.value)}
							id="discountReason"
							placeholder=""
							value={discountReason}
						/>
					</div>
					<div>
						<br />
						<label
							className={styles.input__label}
							htmlFor="outstanding"
						>
							Outstanding
						</label>
						<input
							type="text"
							onChange={(e) => setOutStanding(e.target.value)}
							id="outstanding"
							placeholder=""
							value={outstand}
						/>
					</div>
				</div>
				<hr className={styles.divider} />

				{/* Profile ID details */}
				{profileDetailsArray.map((child, index) => (
					<React.Fragment key={`profile${index}`}>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="revenuedescription"
								>
									Property ID
								</label>
								<input
									type="text"
									name="propertyid"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
										setCurrentPropertyID({
											value: e.target.value,
											index
										});
									}}
									id="propertyid"
									placeholder=""
									value={child.propertyid}
								/>
							</div>
						</div>
						<br />
						<h3>Address</h3>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="houseNo"
								>
									No
								</label>
								<input
									type="text"
									name="houseNo"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									id="houseNo"
									placeholder=""
									//  disabled
									value={child.houseNo}
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="HouseStr"
								>
									Street
								</label>
								<input
									type="text"
									name="HouseStr"
									onChange={(e) =>
										handleProfileInArrayChange(e, index)
									}
									id="HouseStr"
									placeholder=""
									//  disabled
									value={child.HouseStr}
								/>
							</div>
						</div>

						<br />
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="houseLGA"
								>
									LGA
								</label>
								<select
									id="houseLGA"
									className={styles.input__long}
									name="houseLGA"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									value={child.houseLGA}
									required
								>
									<option>--Select LGA--</option>
									{lgas?.map((lga, index) => (
										<React.Fragment
											key={`${lga.Description}${index}`}
										>
											<option value={lga.Description}>
												{lga.Description}
											</option>
										</React.Fragment>
									))}
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="houseZone"
								>
									Zone
								</label>
								<select
									id="houseZone"
									className={styles.input__long}
									name="houseZone"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									value={child.houseZone}
									required
								>
									<option>--Select Zone--</option>
									{zones?.map((zone, index) => (
										<React.Fragment
											key={`${zone.Description}${index}`}
										>
											<option value={zone.Description}>
												{zone.Description}
											</option>
										</React.Fragment>
									))}
								</select>
							</div>
						</div>

						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="revenuedescription"
								>
									Description{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									type="text"
									name="revenuedescription"
									onChange={(e) =>
										handleProfileInArrayChange(e, index)
									}
									id="revenuedescription"
									placeholder=""
									required
									//  disabled
									value={child.revenuedescription}
								/>
							</div>
						</div>
						<br />
						<hr className={styles.divider} />
						<div className={styles.select__wrapper}></div>
						<div className={styles.select__wrapper}>
							<div>
								<label htmlFor="billtype">
									Bill Type{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="billtype"
									className={styles.input__medium}
									name="billtype"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
										setCurrentBillType(e.target.value);
									}}
									value={child.billtype}
									required
								>
									<option>--Select BillType--</option>
									<option value={"RenewalFee"}>
										Renewal
									</option>
									<option value={"RegistrationFee"}>
										Registration
									</option>
									<option value={"FixedFee"}>
										Fixed Amount
									</option>
								</select>
							</div>
							<div>
								<label htmlFor="category">
									Category{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="category"
									className={styles.input__medium}
									name="category"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
										getSelectedCategories(e);
										setCategory(e.target.value);

										// handleCat2Bill(
										// 	e.target.selectedOptions[0].getAttribute(
										// 		"catcode"
										// 	)
										// );
									}}
									value={category}
									required
								>
									<option>--Select Category--</option>
									{categories?.map((category, index) => (
										<option
											value={category.Description}
											catcode={category.Code}
											index={index}
										>
											{category.Description}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="subCategory">
									Sub-Category{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="subCategory"
									className={styles.input__medium}
									name="subCategory"
									value={subCategory}
									onChange={(e) => {
										setSubCategory(e.target.value);

										// setSubCatDescription(
										// 	e.target.value.split("+")[0]
										// );
										// setSubCatData({
										// 	value: e.target.value.split("+")[1],
										// 	index
										// });
										// getSelectedSubCat(e, index);
									}}
									required
								>
									<option>--Select Sub-Category--</option>
									{subCaties.map((subcat, index) => (
										<option
											value={subcat.Description}
											index={index}
											subCatCode={subcat.Code}
										>
											{subcat.Description}
										</option>
									))}

									{/* {filterSubCategories.length &&
										filterSubCategories?.map(
											(sub, index) => (
												<React.Fragment
													key={sub?.id + index}
												>
													<option
														value={
															sub?.Description +
															"+" +
															sub?.RegistrationFee +
															"/" +
															sub?.RenewalFee +
															"/" +
															sub?.FixedFee +
															"/" +
															index
														}
													>
														{sub?.Description}
													</option>
												</React.Fragment>
											)
										)} */}
								</select>
							</div>
						</div>
						{/* /////// second row//////// */}

						{/* yearlyyyyy          monthly yyyyyyyy */}
						<div className={multiStyles.year}>
							<div>
								<label
									className={styles1.input__label}
									htmlFor="selectYear"
								>
									Fiscal Year
								</label>
								<select
									id="selectYear"
									value={selectYear}
									onChange={(e) =>
										setSelectYear(e.target.value)
									}
									// disabled={historyState?.view}
									required
								>
									<option value="">Select</option>
									<option>2013</option>
									{new Array(new Date().getFullYear() - 2013)
										.fill(0)
										.map((_, i) => (
											<option key={i}>
												{2013 + i + 1}
											</option>
										))}
								</select>
							</div>
							<div>
								<label htmlFor="selectMonth">
									Fiscal Monthly
								</label>
								<select
									id="selectMonth"
									value={selectMonth}
									onChange={(e) => {
										setSelectedMonth(e.target.value);
									}}
								>
									<option value="" disabled>
										Select
									</option>
									{[...Array(12)].map((_, index) => (
										<option key={index} value={index}>
											{new Intl.DateTimeFormat("en", {
												month: "long"
											}).format(new Date(2000, index, 1))}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="quantity">
									How many month are you paying for?
								</label>
								<input
									type="number"
									onChange={(e) =>
										setQuantity(e.target.value)
									}
									id="quantity"
									placeholder=""
									//  disabled
									value={quantity}
								/>
							</div>
						</div>
						{child.profilecategory.includes("cubic meter") && (
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="landmeasure"
									>
										Land Measurement{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										name="landmeasure"
										onChange={(e) => {
											setLandMeasurement({
												value: e.target.value,
												index
											});
											handleProfileInArrayChange(
												e,
												index
											);
										}}
										id="landmeasure"
										required
										value={child.landmeasure}
									/>
								</div>
							</div>
						)}

						<div className={multiStyles.year}>
							<div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="amount"
									>
										Unit Amount
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										name="amount"
										onChange={(e) =>
											handleProfileInArrayChange(e, index)
										}
										id="amount"
										required
										value={child.amount}
									/>
								</div>
							</div>
							<div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="totAmount"
									>
										Total Number of User/Properties
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="number"
										name="totAmount"
										onChange={(e) =>
											setTotAmount(e.target.value)
										}
										id="totAmount"
										required
										value={totAmount}
									/>
								</div>
							</div>
						</div>

						{/* <div className={multiStyles.year}>
							<div>
								<label
									style={{ marginBottom: "15px" }}
									className={styles3.input__label}
									htmlFor="disco"
								>
									Discount
								</label>
								<input
									id="disco"
									type="number"
									name="disco"
									style={{ marginTop: "0" }}
									onChange={(e) => {
										setDisco(e.target.value);
									}}
									value={disco}
								/>
							</div>
							<div>
								<label
									style={{ marginBottom: "15px" }}
									className={styles3.input__label}
									htmlFor="resDiscount"
								>
									Reasons for Discount
								</label>
								<input
									id="resDiscount"
									type="text"
									name="resDiscount"
									style={{ marginTop: "0" }}
									onChange={(e) => {
										setResDiscount(e.target.value);
									}}
									value={resDiscount}
								/>
							</div>
						</div> */}

						<div className={multiStyles.year}>
							<div>
								<label
									style={{ marginBottom: "15px" }}
									className={styles3.input__label}
									htmlFor="waivers"
								>
									Waivers
								</label>
								<input
									id="waivers"
									type="number"
									name="waivers"
									style={{ marginTop: "0" }}
									onChange={(e) => {
										setWaivers(e.target.value);
									}}
									value={waivers}
									placeholder="Enter Outstanding Description"
								/>
							</div>
							<div>
								<label
									style={{ marginBottom: "15px" }}
									className={styles3.input__label}
									htmlFor="resWaivers"
								>
									Reasons for Waivers
								</label>
								<input
									id="resWaivers"
									type="text"
									name="resWaivers"
									style={{ marginTop: "0" }}
									onChange={(e) => {
										setResWaivers(e.target.value);
									}}
									value={resWaivers}
									placeholder="Enter Outstanding Description"
								/>
							</div>
						</div>
						<div className={multiStyles.year}>
							<div>
								<label
									// className={styles.input__label}
									htmlFor="totAmountCharged"
								>
									Total Amount Charged
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									id="totAmountCharged"
									type="number"
									name="totAmountCharged"
									onChange={(e) =>
										setTotAmountCharged(e.target.va)
									}
									value={totAmountCharged}
									required
								/>
							</div>
							{/* <button
								className={[
									"primary__btn",
									styles1.add_btn
								].join(" ")}
								onClick={handleAddBill}
							>
								Add Bill
							</button> */}
						</div>
						{/* <div className={styles1.table__div}>
							<Table headers={headers} data={tableData} full />
						</div> */}
						<br />
						{/* {profileDetailsArray.length > 1 && (
							<button
								type="button"
								className={styles.add__another__child__btn}
								style={{
									color: "#F0372E",
									textAlign: "left",
									padding: 0,
									marginBottom: 10,
									marginTop: 10
								}}
								onClick={() => {
									removeChild(index);
								}}
							>
								- Remove Property {index + 1}
							</button>
						)} */}
						{/* <hr className={styles.divider} /> */}
					</React.Fragment>
				))}

				{/* <hr className={styles.divider} /> */}
				{/* <button
					type="button"
					className={styles.add__another__child__btn}
					onClick={addAnotherProfile}
				>
					+ Add another property
				</button> */}
				{/* <hr className={styles.divider} /> */}
				{/* <div className={styles.double__inputs}>
					<h4 style={{ fontWeight: "bold" }}>
						Total Amount Billed:{" "}
					</h4>
					&nbsp;
					<h4 style={{ fontWeight: 300 }}>
						&#8358;{allBilledAmount}
					</h4>
				</div> */}
				{/* <div className={styles.double__inputs}>
					<h4 style={{ fontWeight: "bold" }}>Total Amount: </h4>&nbsp;
					<h4 style={{ fontWeight: 300 }}>
						&#8358;{allBilledAmount}
					</h4>
				</div> */}

				{/* <hr className={styles.divider} /> */}
				{/* total and stuffs */}
				<footer className={styles.form__footer}>
					<button
						disabled={loading}
						type="submit"
						className={[
							"primary__btn",
							styles.primary__button,
							loading ? styles.loading__button : ""
						].join(" ")}
					>
						Generate bill
						{loading && <Spinner />}
					</button>
				</footer>
			</form>
			{!!invoice && <Receipt data={invoice} loading={loading} />}
		</>
	);
};

export default Water;

const htmlInvoice = (htmlString) => {
	return { __html: htmlString };
};
