import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Alert from "../../src/components/Alert";
import styles1 from "../../src/Dashboard/MDAs/style.module.css";
import Table from "../components/Table";
import styles from "./style.module.css";
import constants from "../constants";

axios.defaults.base1URL = constants.BASE1_URL;

const Inputter = () => {
	const history = useHistory();

	const [loading, setLoading] = useState(false);

	const [data, setData] = useState();
	const [total, setTotal] = useState(0);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const headers = [
		["ESBN", "esbn"],
		["Customer Name", "customerName"],
		["Bank Branch", "branchName"],
		["Bill Number", "billNumber"],
		["Payment Method", "paymentMethod"],
		["Total Amount", "totalAmountPaid"],
		["Revenue", "revenue"],
		["Reciept Number", "receiptNumber"],
		["Payment Date", "paymentDate"],
		["Status", "approveRejectFlag", getStatusText]
	];

	const _getAnalytics = ({ currentPage = 1, pageSize = 20 }) => {
		let query = `page=${currentPage}&limit=${pageSize}&`;

		return axios
			.get(
				`${constants.BASE1_URL}/manualRecon/getAllManualReconRecords?${query}`
			)
			.then((res) => (res.data || {}).data);
	};

	const getAnalytics =
		({ currentPage = 1, pageSize = 20 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			try {
				const info = await _getAnalytics({ currentPage, pageSize });

				if (info) {
					setData(info.data);
					setTotal(info.totalItems);
				}

				setAlert({
					showing: true,
					type: "success",
					message: "data has been retrieved"
				});
			} catch (error) {
				setAlert({
					showing: true,
					type: "error",
					message:
						error.response.data?.error.data ||
						error.response.data?.errors?.[0]?.error ||
						error.response.data?.errors?.details[0]?.message
				});
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		getAnalytics()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function getStatusText(approveRejectFlag) {
		if (approveRejectFlag === 0) {
			return "Pending";
		} else if (approveRejectFlag === 1) {
			return "Approved";
		} else {
			return "Denied";
		}
	}

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<>
			<div className={styles1.table__div}>
				{alert.showing && (
					<Alert text={alert.message} type={alert.type} />
				)}
				<button
					className={["primary__btn", styles.add__newbtns].join(" ")}
					onClick={() => history.push("/addnew")}
				>
					Add New
				</button>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						total={total}
						onPageChanged={getAnalytics}
						pageSize={20}
						onEdit={(data) => {
							history.push(`/maker-checker/edit/${data.id}`);
						}}
					/>
				)}
			</div>
		</>
	);
};

export default Inputter;
