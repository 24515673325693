import { useState, useEffect, createRef, useMemo } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import Alert from "../../components/Alert";
import styles from "./style.module.css";

const FilterComponent = ({ filterText, onFilter }) => (
	<>
		<div>
			<div>
				<input
					type="text"
					onChange={onFilter}
					placeholder="Description"
					value={filterText}
				/>
			</div>
		</div>
	</>
);

const RevenueDetails = () => {
	const accessToken = localStorage.getItem("access_token");
	const [values, setValues] = useState({
		RevenueDetailsCode: "",
		Description: "",
		RevenueHead: "",
		RevenueSource: "",
		BillGroup: "",
		HandlingSystem: "",
		Category: "",
		MDA: "",
		AgencyType: ""
	});
	const [sendingStatus, setSendingStatus] = useState(false);
	const [revenueHeads, setRevenueHeads] = useState([]);
	const [revenueSources, setRevenueSources] = useState([]);
	const [billgroups, setBillGroups] = useState([]);
	const [categories, setCategories] = useState([]);
	const [mdas, setMdas] = useState([]);
	const [agencyTypes, setAgencyTypes] = useState([]);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const codeInputRef = createRef();

	const [editingStatus, setEditingStatus] = useState(false);
	const [loading, setLoadingStatus] = useState(false);
	const [resourceId, setResourceId] = useState(null);
	const [previousRecords, setPreviousRecords] = useState(null);

	const [filterText, setFilterText] = useState("");

	const filteredPreviousRecords = (previousRecords || []).filter((record) =>
		record.Description.toLowerCase().includes(filterText.toLowerCase())
	);

	const subHeaderComponentMemo = useMemo(() => {
		return (
			<FilterComponent
				onFilter={(e) => setFilterText(e.target.value)}
				filterText={filterText}
			/>
		);
	}, [filterText]);

	const columns = [
		{
			selector: "RevenueDetailsCode",
			name: "Revenue Details Code",
			sortable: true
		},
		{
			selector: "Description",
			name: "Description",
			sortable: true
		},
		{
			name: "Action",
			sortable: false,
			cell: (record) => (
				<div
					className={styles.each__record}
					style={{ flexDirection: "column", height: "unset" }}
				>
					<button
						type="button"
						className={styles.deactive__btn}
						onClick={() => {
							setEditingStatus(true);
							setResourceId(record.id);
							setValues(record);
							codeInputRef.current.focus();
						}}
						// disabled={editingStatus}
					>
						Edit
					</button>
					<button
						type="button"
						className={styles.delete__btn}
						onClick={() => deactivate(record.id)}
					>
						{record.Deactivated ? "Activate" : "Deactivate"}
					</button>
				</div>
			)
		}
	];

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	useEffect(() => {
		getPreviousRecords();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		async function getOptions() {
			try {
				const getRevenueHeads = axios.get(`/settings/revenuehead`);

				const getRevenueSources = axios.get(`/settings/revenuesource`);

				const getBillGroups = axios.get(`/settings/billgroup`);

				const getCategories = axios.get(`/settings/category`);

				const getMdas = axios.get(`/settings/mdasetup`);

				const getAgencyTypes = axios.get(`/settings/agency`);

				const results = await Promise.all([
					getRevenueHeads,
					getRevenueSources,
					getBillGroups,
					getCategories,
					getMdas,
					getAgencyTypes
				]);

				results.forEach((each, i) => {
					if (i === 0) {
						setRevenueHeads(each.data.data);
					}
					if (i === 1) {
						setRevenueSources(each.data.data);
					}
					if (i === 2) {
						setBillGroups(each.data.data);
					}
					if (i === 3) {
						setCategories(each.data.data);
					}
					if (i === 4) {
						setMdas(each.data.data);
					}
					if (i === 5) {
						setAgencyTypes(each.data.data);
					}
				});
			} catch (e) {
				if (e.response) {
					console.log("response", e.response.data.message);
					setAlert((alert) => ({
						...alert,
						showing: true,
						type: "error",
						message: e.response.data.message
					}));
				} else {
					console.log("Error", e.message);
					setAlert((alert) => ({
						...alert,
						showing: true,
						type: "error",
						message: e.message
					}));
				}
			}
		}

		getOptions();
	}, [accessToken]);

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;

		setValues({
			...values,
			[name]: value
		});
	};

	const getPreviousRecords = async () => {
		setLoadingStatus(true);
		try {
			const request = await axios.get(`/settings/revenuedetails`);

			setLoadingStatus(false);
			setPreviousRecords(request.data.data);
		} catch (error) {
			setLoadingStatus(false);
			if (error.response) {
				console.log("response", error.response.data.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.message
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		}
	};

	const deactivate = async (id) => {
		try {
			const { data } = await axios.put(`/settings/revenuedetails/${id}`, {
				deactivate: true
			});

			if (!data.data) {
				return setAlert({
					...alert,
					showing: true,
					type: "error",
					message: "Something bad happened"
				});
			}

			const index = previousRecords.findIndex((pr) => pr.id === id);
			const record = data.data;

			if (index < 0) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: `Record not found`
				});
			}

			const updatedPrevious = previousRecords.slice();
			updatedPrevious[index] = record;
			setPreviousRecords(updatedPrevious);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: `Record ${
					record.Deactivated ? "Deactivated" : "Activated"
				}`
			});
		} catch (error) {
			if (error.response) {
				console.log("response", error.response.data.msg);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.msg
				});
			} else {
				console.log("Error", error.message);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const submit = async (e, id) => {
		if (e) e.preventDefault();

		setSendingStatus(true);

		try {
			if (editingStatus) {
				const { data } = await axios.put(
					`/settings/revenuedetails/${id}`,
					{ ...values }
				);

				if (!data.data) {
					return setAlert({
						...alert,
						showing: true,
						type: "error",
						message: "Edit failed. Please try again."
					});
				}

				const index = previousRecords.findIndex((pr) => pr.id === id);

				if (index < 0) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: `Record not found`
					});
					setSendingStatus(false);
					setEditingStatus(false);
					setValues({
						RevenueDetailsCode: "",
						Description: "",
						RevenueHead: "",
						RevenueSource: "",
						BillGroup: "",
						Category: "",
						MDA: "",
						AgencyType: ""
					});
					return;
				}

				const updatedPrevious = previousRecords.slice();
				updatedPrevious.splice(index, 1, data.data);
				setPreviousRecords(updatedPrevious);
				setSendingStatus(false);
				setEditingStatus(false);

				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: `Record Edited`
				});

				setValues({
					RevenueDetailsCode: "",
					Description: "",
					RevenueHead: "",
					RevenueSource: "",
					BillGroup: "",
					Category: "",
					MDA: "",
					AgencyType: ""
				});
			} else {
				await axios.post(`/settings/revenuedetails`, { ...values });
				setSendingStatus(false);
				setValues({
					revenueDetailsCode: "",
					description: "",
					revenueHead: "",
					revenueSource: "",
					billGroup: "",
					category: "",
					MDA: "",
					agencyType: ""
				});
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: "Saved!"
				});
			}
		} catch (e) {
			setSendingStatus(false);
			if (e.response) {
				console.log("response", e.response.data.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: e.response.data.message
				}));
			} else {
				console.log("Error", e.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: e.message
				}));
			}
		}
	};

	return (
		<div className={styles.settings__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form
				className={styles.anssid__form}
				onSubmit={(e) => submit(e, resourceId)}
			>
				<div className={styles.inputs__div}>
					<div>
						<label htmlFor="revenueDetailsCode">
							Revenue Details Code <span>*</span>
						</label>
						<input
							type="text"
							id="revenueDetailsCode"
							name="RevenueDetailsCode"
							value={values.RevenueDetailsCode}
							onChange={handleChange}
							placeholder="Code"
							required
							ref={codeInputRef}
						/>
					</div>
					<div>
						<label htmlFor="description">
							Description <span>*</span>
						</label>
						<input
							type="text"
							id="description"
							name="Description"
							value={values.Description}
							onChange={handleChange}
							placeholder="description"
							required
						/>
					</div>
				</div>
				<div className={styles.inputs__div}>
					<div>
						<label htmlFor="revenueHead">Revenue Head</label>
						<select
							id="revenueHead"
							name="RevenueHead"
							value={values.RevenueHead}
							onChange={handleChange}
						>
							<option value="">--Select Revenue Head--</option>
							{revenueHeads.length ? (
								revenueHeads.map((option) => (
									<option
										key={option.Code}
										value={option.Code}
									>
										{option.Description}
									</option>
								))
							) : (
								<option>...</option>
							)}
						</select>
					</div>
					<div>
						<label htmlFor="revenueSource">Revenue Source</label>
						<select
							id="revenueSource"
							name="RevenueSource"
							value={values.RevenueSource}
							onChange={handleChange}
						>
							<option value="">--Select Revenue Source--</option>
							{revenueSources.length ? (
								revenueSources.map((option) => (
									<option
										key={option.Code}
										value={option.Code}
									>
										{option.Description}
									</option>
								))
							) : (
								<option>...</option>
							)}
						</select>
					</div>
				</div>
				<div className={styles.inputs__div}>
					<div>
						<label htmlFor="billGroup">Bill Group</label>
						<select
							id="billGroup"
							name="BillGroup"
							value={values.BillGroup}
							onChange={handleChange}
						>
							<option value="">--Select Bill Group--</option>
							{billgroups.length ? (
								billgroups.map((option) => (
									<option
										key={option.Code}
										value={option.Code}
									>
										{option.Description}
									</option>
								))
							) : (
								<option>...</option>
							)}
						</select>
					</div>
					<div>
						<label htmlFor="category">Category</label>
						<select
							id="category"
							name="Category"
							value={values.Category}
							onChange={handleChange}
						>
							<option value="">--Select Category--</option>
							{categories.length ? (
								categories.map((option) => (
									<option
										key={option.Code}
										value={option.Code}
									>
										{option.Description}
									</option>
								))
							) : (
								<option>...</option>
							)}
						</select>
					</div>
				</div>
				<div className={styles.inputs__div}>
					<div className={styles.single__input__div}>
						<label htmlFor="handlingSystem">Handling System</label>
						<input
							id="handlingSystem"
							name="HandlingSystem"
							value={values.HandlingSystem}
							type="text"
							placeholder="eg Sample handling system"
							onChange={handleChange}
						/>
					</div>
				</div>
				<div className={[styles.inputs__div, styles.last].join(" ")}>
					<div>
						<label htmlFor="mda">MDA</label>
						<select
							id="mda"
							name="MDA"
							value={values.MDA}
							onChange={handleChange}
						>
							<option value="">--Select MDA--</option>
							{mdas.length ? (
								mdas.map((option) => (
									<option
										key={option.Code}
										value={option.Code}
									>
										{option.Description}
									</option>
								))
							) : (
								<option>...</option>
							)}
						</select>
					</div>
					<div>
						<label htmlFor="agencyType">Agency Type</label>
						<select
							id="agencyType"
							name="AgencyType"
							value={values.AgencyType}
							onChange={handleChange}
						>
							<option value="">--Select Agency Type--</option>
							{agencyTypes.length ? (
								agencyTypes.map((option) => (
									<option
										key={option.Code}
										value={option.Code}
									>
										{option.Description}
									</option>
								))
							) : (
								<option>...</option>
							)}
						</select>
					</div>
				</div>
				<button
					type="submit"
					style={{ marginBottom: "60px" }}
					className="primary__btn"
					disabled={
						sendingStatus ||
						!values.RevenueDetailsCode ||
						!values.Description
					}
				>
					{sendingStatus ? "Saving..." : "Save"}
				</button>
				{loading && (
					<p
						style={{
							paddingBottom: "30px",
							textAlign: "center"
						}}
					>
						Loading...
					</p>
				)}
				{!loading && previousRecords && (
					<div className={styles.records}>
						<p>
							<b>
								{previousRecords.length} previous{" "}
								{previousRecords.length === 1
									? "entry"
									: "entries"}
							</b>
						</p>
						<table className={styles.table}>
							{loading && (
								<tbody>
									<tr>
										<td>
											<p style={{ textAlign: "center" }}>
												Loading...
											</p>
										</td>
									</tr>
								</tbody>
							)}
							{!previousRecords?.length && !loading ? (
								<tbody>
									<tr>
										<td>
											<p style={{ textAlign: "center" }}>
												No Existing Records.
											</p>
										</td>
									</tr>
								</tbody>
							) : (
								<tbody>
									<DataTable
										title="Revenue Details Settings"
										columns={columns}
										data={filteredPreviousRecords}
										progressPending={loading}
										pagination
										paginationTotalRows={
											filteredPreviousRecords.length
										}
										paginationPerPage={10}
										subHeader
										subHeaderComponent={
											subHeaderComponentMemo
										}
									/>
								</tbody>
							)}
						</table>
					</div>
				)}
			</form>
		</div>
	);
};

export default RevenueDetails;
