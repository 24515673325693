/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Alert from "../../src/components/Alert";
import Receipt from "../Dashboard/Receipt/receipt";
import styles2 from "../../src/Dashboard/MDAs/style.module.css";
import styles from "./style.module.css";
import styles3 from "../../src/Identity-management-individual/style.module.css";
import constants from "../constants";

axios.defaults.testURL = constants.TEST_URL;
const GenReceipt = () => {
	const [values, setValues] = useState({
		paymentItem: "",
		invoiceNo: ""
	});

	const accessToken = localStorage.getItem("access_token");
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	const [paymentReference, setPaymentReference] = useState("");
	const [assessmentCode, setAssessmentCode] = useState("");
	const [receiptOpt, setReceiptOpt] = useState("");
	const [mdaFlag, setMdaFlag] = useState("");

	const [data, setData] = useState("");
	const [receiptno, setReceipt] = useState("");

	const [revenueTypesLoading, setRevenueTypeLoadingState] = useState(false);
	const [revenueTypes, setRevenueTypes] = useState(null);
	// const [isSendReceipt, setIsSendReceipt] = useState(false);
	const [loading, setLoading] = useState(false);
	const [display, setDisplay] = useState("");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const getReceipt = async (e) => {
		e.preventDefault();
		let userInfo = JSON.parse(localStorage.getItem("user_info"));
		// console.log("email(userinfo) => ", userInfo.email);
		setLoading(true);

		try {
			const response = await axios.post(
				`${constants.BASE1_URL}/provreceipt`,
				{
					receiptno: receiptno,
					coy: userInfo.mdaModuleCoy,
					userid: userInfo.email //"admin@test.com"
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`
					}
				}
			);

			const data = response.data;
			console.log(data, response);
			setDisplay(data);
			setData(data);
		} catch (error) {
			console.error("Error fetching receipt:", error);
			// Handle error as needed
		} finally {
			setLoading(false);
		}
	};

	// const getReceipt = async (e) => {
	// 	e.preventDefault();
	// 	setLoading(true);
	// 	const response = await fetch(
	// 		`https://beta.norlics.com/api/provreceipt`,
	// 		{
	// 			method: "POST",
	// 			headers: {
	// 				"Content-Type": "application/json",
	// 				Authorization: `Bearer ${accessToken}`
	// 			},
	// 			body: JSON.stringify({
	// 				receiptno: receiptno,
	// 				coy: "07",
	// 				userid: "admin@test.com"
	// 			})
	// 		}
	// 	);
	// 	const data = await response.text();
	// 	console.log(data, response);
	// 	setDisplay(data);
	// 	setData(data);
	// };

	const printReceipt = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(data);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		w.onafterprint = () => {
			w.close();
		};
	};

	return (
		<div className={styles.receipt__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}

			<form className={styles3.columned__form} onSubmit={getReceipt}>
				<div className={styles3.double__inputs}>
					<div>
						<label
							style={{ marginBottom: "15px" }}
							className={styles3.input__label}
							htmlFor="paymentReference"
						>
							Reciept Number{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="paymentReference"
							name="paymentReference"
							style={{ marginTop: "0" }}
							className={styles3.select__input}
							value={receiptno}
							onChange={(e) => setReceipt(e.target.value)}
							placeholder="HBP|BAH|ANIGR|4-01-1998|250295"
							required
						/>
					</div>
				</div>

				<button
					type="submit"
					className={["primary__btn", styles.send__receipt__btn].join(
						" "
					)}
				>
					{/* {isSendReceipt ? "Send Receipt" : "Generate Receipt"} */}
					Generate Receipt
				</button>
			</form>
			{data && (
				<button
					className={["secondary__btn", styles.print__btn].join(" ")}
					onClick={printReceipt}
				>
					Print
				</button>
			)}
			<br />
			<Receipt data={display} />
		</div>
	);
};

export default GenReceipt;
