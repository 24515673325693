import React, { useEffect, useState, useRef } from "react";
import style from "./style.module.css";
import Table from "../components/Table";
import axios from "axios";
import Alert from "../components/Alert";
import constants from "../constants";

axios.defaults.base1URL = constants.BASE1_URL;

const ReducingBalanceReceipt = () => {
	const ref = useRef(null);
	const token = localStorage.getItem("access_token");

	const header = [
		["MDA", "MDA"],
		["Profile Category", "Profile_Category"],
		["Profile", "Profile"],
		["Revenue Head", "Revenue_Head"],
		["Receipted Amount", "Amount_Paid"]
	];
	const header2 = [
		["Company Name", "Company_Name"],
		["Company Address", "Company_Address"],
		["Amount from Profile", "Amount_Paid"]
	];

	const [paymentR, setPaymentR] = useState("");
	const [MDAs, setMDAs] = useState([]);
	const [MDAName, setMDAName] = useState("");
	const [MDACode, setMDACode] = useState("");
	const [MDARevCode, setMDARevCode] = useState("");
	const [profileCategories, setProfileCategories] = useState([]);
	const [profileCategory, setProfileCategory] = useState("");
	const [profileCategoryCode, setProfileCategoryCode] = useState("");
	const [profiles, setProfiles] = useState([]);
	const [profile, setProfile] = useState("");
	const [revenueHeads, setRevenueHeads] = useState([]);
	const [revenue, setRevenue] = useState("");
	const [amountPaid, setAmount] = useState("");
	const [taxpayer, setTaxPayer] = useState([]);
	const [readyToPrintAll, setReadyToPrintAll] = useState(false);
	const [taxPayersToPrint, setTaxPayersToPrint] = useState([]);
	const [indexOfTaxPayerToRemove, setindexOfTaxPayerToRemove] =
		useState(null);
	const [removingTaxPayer, setRemovingTaxPayer] = useState(false);
	const [receiptData, setReceiptData] = useState(); // Step 1: State to store fetched data
	const [taxinfoData, setTaxInfoData] = useState([]); // Step 1: State to store fetched data
	const [tax, setTaxInfo] = useState([]);
	const [companyaddress, setCompAddress] = useState("");
	const [companyName, setCompName] = useState("");
	const [amountTotal, setAmountTotal] = useState(0);
	const [loadingButton1, setLoadingButton1] = useState(false);
	const [loadingButton2, setLoadingButton2] = useState(false);

	// const [remainingTotal, setRemainingTotal] = useState(0);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	let templateStr = `
	<html>
	<head>
		<style>
			/* Style for regular display */
	
			#print_root {
				width: 100%;
				height: 50%;
				/* Adjust height as needed */
			}
	
			/* Style for printing */
			@media print {
				@page {
					size: landscape;
				}
	
				#print_root {
					page-break-after: always;
					// height: 100px;
				}
			}
		</style>
	</head>
	
	<body>
			{PRINT_STR}
	</body>
	</html>
	`;

	const handleBulkPrinting = (e) => {
		e?.preventDefault();

		if (!!taxpayer) {
			templateStr = templateStr.replace(
				"{PRINT_STR}",
				taxpayer.join("\b")
			);
			const w = window.open();
			w.document.write(templateStr);
			w.document.close();
			w.onload = () => {
				w.focus();
				w.print();
			};

			w.onafterprint = () => {
				w.close();
				setTaxPayersToPrint([]);
				setTaxPayer([]);
				setReadyToPrintAll(false);
			};
		} else {
			return;
		}
	};

	useEffect(() => {
		if (readyToPrintAll) {
			handleBulkPrinting();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readyToPrintAll]);

	const getMDAs = async () => {
		try {
			const res = await axios
				.get(`/settings/mdacompany`)
				.then((res) => (res.data || {}).data);

			setMDAs(res);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors?.[0] ||
						error.response.data?.msg
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const toggleAddToPrint = async (id) => {
		try {
			const taxPay = await axios
				.get(`${constants.BASE1_URL}/reducingBal/getTaxPayerInfo/${id}`)
				.then((res) => res.data || { data: [] });

			setTaxPayersToPrint((taxPayerNos) => [...taxPayerNos, id]);
			setTaxPayer((taxPays) => [...taxPays, taxPay]);
			setTaxPayer((taxPays) => [...taxPays, "\n \n \n \n \n \n"]);
		} catch (error) {
			console.error(error);
		}
	};

	const removeFromPrint = (id) => {
		setTaxPayersToPrint((taxPayerNos) =>
			taxPayerNos.filter((number, i) => {
				if (number === id) setindexOfTaxPayerToRemove(i * 2);
				setRemovingTaxPayer(true);
				return number !== id;
			})
		);
	};

	const removeFromTaxPayersToPrint = () => {
		setTaxPayer((taxPays) =>
			taxPays.filter((taxPay, i) => i !== indexOfTaxPayerToRemove)
		);
		setTaxPayer((taxPays) =>
			taxPays.filter((taxPay, i) => i !== indexOfTaxPayerToRemove)
		);
		setRemovingTaxPayer(false);
	};

	useEffect(() => {
		if (removingTaxPayer) {
			removeFromTaxPayersToPrint();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [removingTaxPayer]);

	const getProfiles = async () => {
		try {
			const res = await axios
				.get(
					`categorytarrifcommerces/${MDACode}/${profileCategoryCode}`
				)
				.then((res) => (res.data || {}).data);

			setProfiles(res);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors?.[0] ||
						error.response.data?.msg
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const getProfileCategories = async () => {
		try {
			const res = await axios
				.get(`settings/profilecategorys/${MDACode}`)
				.then((res) => (res.data || {}).data);

			setProfileCategories(res);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors?.[0] ||
						error.response.data?.msg
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const getRevenueHeads = async () => {
		try {
			const res = await axios
				.get(`/settings/mdalinkedrevenue/${MDARevCode}`)
				.then((res) => (res.data || {}).data);

			setRevenueHeads(res);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors?.[0] ||
						error.response.data?.msg
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	useEffect(() => {
		getRevenueHeads();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [MDARevCode]);
	// Define getReceiptNo inside the component if it uses component's state or props
	useEffect(() => {
		getProfileCategories();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [MDACode]);

	useEffect(() => {
		getProfiles();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profileCategoryCode]);

	useEffect(() => {
		getMDAs();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	//find receipt details
	const getReceiptNo = async () => {
		// Assuming 'paymentR' is the input value you want to check
		if (paymentR && paymentR.length >= 6) {
			try {
				const res = await axios.post(
					`${constants.BASE1_URL}/reducingBal/getReducingBalReceiptInfo`,
					{
						header: {
							Authorization: `Bearer ${token}` // Ensure 'token' is accessible here
						},
						receiptNo: paymentR // Ensure 'paymentR' is accessible here
					}
				);
				const data = res.data.data;
				console.log("get receipt", data);
				setReceiptData(data); // Step 2: Store fetched data in state
				setTaxInfo(data?.data?.ReducingBalTaxInfo);
				setTaxInfoData(data?.data?.ReducingBalTaxPayer);
			} catch (error) {
				setAlert({
					showing: true,
					type: "error",
					message:
						error.response?.data?.message ||
						error.response.data?.errors?.[0]?.error ||
						error.response.data?.errors?.details[0]?.message
				});
			}
		}
	};

	useEffect(() => {
		getReceiptNo(); // Fetch data when component mounts

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentR]); // Empty dependency array means this runs once on mount

	//add profile
	const addProfile = async (e) => {
		ref.current?.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest",
			offset: { top: -100 }
		});
		e.preventDefault();
		setLoadingButton1(true);
		try {
			await axios.post(
				`${constants.BASE1_URL}/reducingBal/createTaxInfo`,
				{
					reducingBalReceiptNumberId: receiptData.data.id,
					mda: MDAName,
					profileCategory: profile,
					profile: profileCategory,
					revenueHead: revenue,
					amountPaid: Number(amountPaid),
					receiptNo: paymentR
				}
			);

			getReduceTaxInfo([]);
			refreshReceiptNo(paymentR);
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response?.data?.message ||
					error.response.data?.errors?.[0]?.message ||
					error.response.data?.errors?.details[0]?.message
			});
		} finally {
			setLoadingButton1(false); // Revert back to "Add Profile"
		}
	};

	useEffect(() => {
		getReduceTaxInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const refreshReceiptNo = async (paymentR) => {
		// Assuming 'paymentR' is the input value you want to check
		if (paymentR && paymentR.length >= 6) {
			try {
				const res = await axios.post(
					`${constants.BASE1_URL}/reducingBal/getReducingBalReceiptInfo`,
					{
						header: {
							Authorization: `Bearer ${token}` // Ensure 'token' is accessible here
						},
						receiptNo: paymentR // Ensure 'paymentR' is accessible here
					}
				);
				const data = res.data.data;
				console.log("get receipt", data);
				setReceiptData(data); // Step 2: Store fetched data in state
				setTaxInfo(data?.data?.ReducingBalTaxInfo);
				setTaxInfoData(data?.data?.ReducingBalTaxPayer);
			} catch (error) {
				setAlert({
					showing: true,
					type: "error",
					message:
						error.response?.data?.message ||
						error.response.data?.errors?.[0]?.error ||
						error.response.data?.errors?.details[0]?.message
				});
			}
		}
	};

	//get receipt number data
	const getReduceTaxInfo = async () => {
		try {
			const res = await axios
				.get(
					`${constants.BASE1_URL}/reducingBal/getReducingBalanceDetails/${receiptData.data.id}`
				)
				.then((res) => (res.data || {}).data);
			setTaxInfo(res.ReducingBalTaxInfo);
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response.data?.message ||
					error.response.data?.errors?.[0]?.error ||
					error.response.data?.errors?.details[0]?.message
			});
		}
	};

	//create taxpayerinfo function
	const taxPayerInfo = async (e) => {
		console.log("hello", {
			reducingBalReceiptNumberId: receiptData.data.id,
			companyName: companyName,
			companyAddress: companyaddress,
			amountPaid: Number(amountTotal),
			receiptNo: paymentR
		});
		ref.current?.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest",
			offset: { top: -100 }
		});
		e.preventDefault();
		setLoadingButton2(true);
		try {
			await axios.post(
				`${constants.BASE1_URL}/reducingBal/createTaxPayerInfo`,
				{
					reducingBalReceiptNumberId: receiptData.data.id,
					companyName: companyName,
					companyAddress: companyaddress,
					amountPaid: Number(amountTotal),
					receiptNo: paymentR
				}
			);
			getReduceTaxInfo([]);
			getTaxPayerInfo();
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response.data?.message ||
					error.response.data?.errors?.[0]?.error ||
					error.response.data?.errors?.details[0]?.message
			});
		} finally {
			setLoadingButton2(false); // Revert back to "Add Profile"
		}
	};

	const getTaxPayerInfo = async () => {
		const info = await axios
			.get(
				`${constants.BASE1_URL}/reducingBal/getReducingBalanceDetails/${receiptData.data.id}`
			)
			.then((res) => (res.data || {}).data);

		setTaxInfoData(info.ReducingBalTaxPayer);
	};

	useEffect(() => {
		getTaxPayerInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//remove item from the list
	const removeItem = async (id) => {
		try {
			await axios.delete(
				`${constants.BASE1_URL}/reducingBal/deleteTaxInfoRecord/${id}/${paymentR}`
			);

			const updatedList = tax.filter((item) => item.id !== id);
			setTaxInfo(updatedList);
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response.data?.message ||
					error.response.data?.errors?.[0]?.error ||
					error.response.data?.errors?.details[0]?.message
			});
		} finally {
		}
	};
	const handleRemove = async (id) => {
		try {
			await axios.delete(
				`${constants.BASE1_URL}/reducingBal/deleteTaxPayerInfoRecord/${id}/${paymentR}`
			);
			const updatedList = taxinfoData.filter((item) => item.id !== id);
			setTaxInfoData(updatedList);
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response.data?.message ||
					error.response.data?.errors?.[0]?.error ||
					error.response.data?.errors?.details[0]?.message
			});
		}
	};

	// const totalReceipt = Number(receiptData?.data.Total_Amount).toFixed(2);
	// const amountUsed = Number(receiptData?.amountPaid).toFixed(2);
	const remainingBal = Number(receiptData?.remainingBalance).toFixed(2);

	useEffect(() => {
		// Calculate the total amount using reduce
		const total = tax
			.reduce((sum, item) => {
				return sum + Number(item.Amount_Paid);
			}, 0)
			.toFixed(2); // Fix the total to 2 decimal places

		// Update the state with the calculated total amount
		setAmountTotal(total);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tax]); // Depend on tax array

	const sumtax = taxinfoData
		.reduce((sum, item) => {
			return sum + Number(item.Amount_Paid);
		}, 0)
		.toFixed(2);

	useEffect(() => {
		if (readyToPrintAll) {
			handleBulkPrinting();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readyToPrintAll]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div className={style.layout}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div>
				<label htmlFor="paymentReceipt">
					Payment Reciept Number{" "}
					<span style={{ color: "red" }}>*</span>
				</label>
				<input
					className={style.inputs1}
					id="paymentReceipt"
					type="text"
					placeholder="enter payment receipt number"
					value={paymentR}
					onChange={(e) => setPaymentR(e.target.value)}
				/>
			</div>

			<div className={style.receipt__row}>
				{receiptData ? (
					<div>
						{/* Display other data as needed */}
						<p>
							Payer Name:<b> {receiptData?.data.Payer_Name}</b>
						</p>

						<p>
							Address: <b>{receiptData?.data.Address}</b>
						</p>
						<br />
						<p>
							Total Amount:{" "}
							<b>
								{" "}
								{receiptData?.data.Total_Amount?.toLocaleString(
									"en-US",
									{
										style: "currency",
										currency: "NGN"
									}
								)}
							</b>
						</p>
						<p style={{ color: "red" }}>
							Total Amount Used:{" "}
							<b>
								{" "}
								{receiptData?.amountPaid?.toLocaleString(
									"en-US",
									{
										style: "currency",
										currency: "NGN"
									}
								)}
							</b>
						</p>
						<p style={{ color: "green" }}>
							Remaining Balance:{" "}
							<b>
								{" "}
								{remainingBal?.toLocaleString("en-US", {
									style: "currency",
									currency: "NGN"
								})}{" "}
							</b>
						</p>
					</div>
				) : (
					<p></p>
				)}
			</div>

			{receiptData ? (
				<section className={style.section}>
					<hr />
					<h2>Tax Information</h2>
					<p className={style.pad}>
						Enter the information paid to each revenue head, click
						on "Add Profile" to include it.
					</p>
					<p>Repeat this process to add revenue head.</p>
					<form className={style.form} onSubmit={addProfile}>
						<div className={style.triple}>
							<div>
								<label htmlFor="MDAName">
									MDA <span style={{ color: "red" }}>*</span>
								</label>
								<select
									className={style.inputs}
									id="MDAName"
									value={MDAName}
									onChange={(e) => {
										setMDAName(e.target.value);
										setMDACode(
											e.target.selectedOptions[0].getAttribute(
												"mdacode"
											)
										);
										setMDARevCode(
											e.target.selectedOptions[0].getAttribute(
												"mdarevcode"
											)
										);
									}}
								>
									<option value="">Select MDA</option>
									{MDAs?.map((MDA) => (
										<option
											mdacode={MDA.CoyId}
											mdarevcode={MDA.income_group}
											key={MDA.CoyId}
										>
											{MDA.Coyname}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="profcat">
									Profile Category{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									className={style.inputs}
									id="profileCategory"
									value={profileCategory}
									onChange={(e) => {
										setProfileCategory(e.target.value);
										setProfileCategoryCode(
											e.target.selectedOptions[0].getAttribute(
												"profilecode"
											)
										);
									}}
									disabled={!MDAName}
								>
									<option value="">
										Select Profile Category
									</option>
									{profileCategories?.map((profile) => (
										<option
											profilecode={profile.Code}
											key={profile.id}
										>
											{profile.Description}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="profile">
									Profile{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									className={style.inputs}
									id="profile"
									value={profile}
									onChange={(e) => setProfile(e.target.value)}
									disabled={!profileCategory}
								>
									<option value="">Select Profile</option>
									{profiles?.map((profile) => (
										<option key={profile.Code}>
											{profile.Description}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className={style.triple}>
							<div>
								<div>
									<label
										className={style.input__label}
										htmlFor="mda"
									>
										Revenue{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<select
										className={style.inputs}
										id="mda"
										name="mda"
										onChange={(e) => {
											setRevenue(e.target.value);
										}}
										value={revenue}
										required
									>
										<option>
											{revenueHeads
												? "--Select Revenue head--"
												: "Loading..."}
										</option>
										{revenueHeads &&
											revenueHeads.map((getRev) => (
												<option value={getRev.Code}>
													{getRev.Description}
												</option>
											))}
									</select>
								</div>
							</div>
							<div>
								<label htmlFor="amount">
									Amount Paid{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									className={style.inputs}
									value={amountPaid}
									type="number"
									onChange={(e) => {
										setAmount(e.target.value);
									}}
								/>
							</div>
						</div>
						<div className={style.button_container}>
							<button
								className={[
									"primary__btn",
									style.buttonContainer
								].join(" ")}
								type="submit"
							>
								{loadingButton1 ? "Adding..." : "Add Profile"}
							</button>
						</div>
					</form>

					<div ref={ref}>
						<Table
							headers={header}
							data={tax}
							onDelete={(data) => removeItem(data.id)}
							full
						/>
						<p>
							Total of Mda :<b>{amountTotal}</b>
						</p>
					</div>
				</section>
			) : (
				<p></p>
			)}

			{tax && tax.length > 0 && (
				<section>
					<hr />
					<div>
						<h2>Tax Payers</h2>
						<p>
							Entering the information of each tax payer, click on
							"Add Payer" to include it.
						</p>
						<p>Repeat this process to add additional tax payers.</p>
					</div>
					<div>
						<form className={style.form} onSubmit={taxPayerInfo}>
							<div className={style.triple}>
								<div>
									<label htmlFor="taxPayer">
										Company name{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										className={style.inputs}
										value={companyName}
										onChange={(e) => {
											setCompName(e.target.value);
										}}
										placeholder="Enter Name"
									/>
								</div>
								<div>
									<label htmlFor="amount">
										Company address{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										className={style.inputs}
										value={companyaddress}
										onChange={(e) => {
											setCompAddress(e.target.value);
										}}
										placeholder="Enter Address"
									/>
								</div>
							</div>
							<div className={style.button_container}>
								<button
									className={[
										"primary__btn",
										style.buttonContainer
									].join(" ")}
									type="submit"
								>
									{loadingButton2 ? "Adding..." : "Add Payer"}
								</button>
							</div>
						</form>

						<div ref={ref}>
							<Table
								headers={header2}
								data={taxinfoData}
								printArray={taxPayersToPrint}
								onAddToPrint={(data) => {
									toggleAddToPrint(data.id);
								}}
								onRemoveFromPrint={(data) =>
									removeFromPrint(data.id)
								}
								onDelete={(data) => handleRemove(data.id)}
								full
							/>
							<p>
								Total used by Payer: <b> {sumtax}</b>
							</p>
						</div>
					</div>
				</section>
			)}

			{taxinfoData && taxinfoData.length > 0 && (
				<div className={style.button_container}>
					<button
						onClick={handleBulkPrinting}
						className={["primary__btn", style.print_btn].join(" ")}
					>
						Print {taxPayersToPrint.length}
					</button>
				</div>
			)}
		</div>
	);
};

export default ReducingBalanceReceipt;
