import { useState, useEffect } from "react";
import axios from "axios";
import Alert from "../../components/Alert";
import styles from "../ReceiptPymtRef/style.module.css";
import styles1 from "./style.module.css";
import styles3 from "../../Identity-management-individual/style.module.css";

const DAManualEntryCertificate = () => {
	const inputter = JSON.parse(localStorage.getItem("user_info")).email;

	const [ESBN, setESBN] = useState("");
	const [gender, setGender] = useState("");
	const [year, setYear] = useState("");
	const [taxpayerName, setTaxPayerName] = useState("");
	const [address, setAddress] = useState("");
	const [sourceOfIncome, setSourceOfIncome] = useState("");
	const [checkingRecord, setCheckingRecord] = useState(false);
	const [record, setRecord] = useState(null);
	const [edit, setEdit] = useState(false);
	const [saving, setSaving] = useState(false);
	const [showingTable, setShowingTable] = useState(false);
	const [year1, setYear1] = useState({
		totalIncome: "",
		totalTaxableIncome: "",
		taxPaid: "",
		taxReceiptNo: "",
		dateOfIssuance: "",
		dateOfPayment: ""
	});
	const [year2, setYear2] = useState({
		totalIncome: "",
		totalTaxableIncome: "",
		taxPaid: "",
		taxReceiptNo: "",
		dateOfIssuance: "",
		dateOfPayment: ""
	});
	const [year3, setYear3] = useState({
		totalIncome: "",
		totalTaxableIncome: "",
		taxPaid: "",
		taxReceiptNo: "",
		dateOfIssuance: "",
		dateOfPayment: ""
	});
	const [year4, setYear4] = useState({
		totalIncome: "",
		totalTaxableIncome: "",
		taxPaid: "",
		taxReceiptNo: "",
		dateOfIssuance: "",
		dateOfPayment: ""
	});
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	//Get ESBN-related data
	useEffect(() => {
		const getESBN = async () => {
			setTaxPayerName("");
			setAddress("");
			setShowingTable(false);
			try {
				const res = await axios
					.get(`/users/anssid?anssid=${ESBN}`)
					.then((res) => (res.data || {}).data);

				if (res.accounttype === "individual") {
					setTaxPayerName(
						`${res.firstname} ${res.middlename} ${res.surname}`
					);
					setAddress(res.residentialaddress || "");
					setGender(res.sex);
				} else {
					setAddress(res.stateOfficeAddress || "");
					setTaxPayerName(res.name);
				}
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			}
		};

		if (ESBN?.length >= 10) getESBN();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ESBN]);

	const checkRecords = async () => {
		try {
			setCheckingRecord(true);
			setShowingTable(false);
			setYear1({
				totalIncome: "",
				totalTaxableIncome: "",
				taxPaid: "",
				taxReceiptNo: "",
				dateOfIssuance: "",
				dateOfPayment: ""
			});
			setYear2({
				totalIncome: "",
				totalTaxableIncome: "",
				taxPaid: "",
				taxReceiptNo: "",
				dateOfIssuance: "",
				dateOfPayment: ""
			});
			setYear3({
				totalIncome: "",
				totalTaxableIncome: "",
				taxPaid: "",
				taxReceiptNo: "",
				dateOfIssuance: "",
				dateOfPayment: ""
			});
			setYear4({
				totalIncome: "",
				totalTaxableIncome: "",
				taxPaid: "",
				taxReceiptNo: "",
				dateOfIssuance: "",
				dateOfPayment: ""
			});

			//make API call
			const { data: res, msg } = await axios
				.get(`/tmscertmakeup/${ESBN}/${year}`)
				.then((res) => res.data || {});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: msg
			});
			setRecord(res);
			setGender(res?.Gender);
			setTaxPayerName(res.TaxPayerName);
			setAddress(res.TaxPayerAddr);
			setSourceOfIncome(res.IncomeSource);
			setEdit(true);
			setYear1({
				totalIncome: res.totIncomeYr1,
				totalTaxableIncome: res.totTaxIncomeYr1,
				taxPaid: res.totTaxPaidYr1,
				taxReceiptNo: res.taxReceiptYr1,
				dateOfIssuance: new Date(res.issueDateYr1)
					.toISOString()
					.split("T")[0],
				dateOfPayment: new Date(res.pymtDateYr1)
					.toISOString()
					.split("T")[0]
			});
			setYear2({
				totalIncome: res.totIncomeYr2,
				totalTaxableIncome: res.totTaxIncomeYr2,
				taxPaid: res.totTaxPaidYr2,
				taxReceiptNo: res.taxReceiptYr2,
				dateOfIssuance: new Date(res.issueDateYr2)
					.toISOString()
					.split("T")[0],
				dateOfPayment: new Date(res.pymtDateYr2)
					.toISOString()
					.split("T")[0]
			});
			setYear3({
				totalIncome: res.totIncomeYr3,
				totalTaxableIncome: res.totTaxIncomeYr3,
				taxPaid: res.totTaxPaidYr3,
				taxReceiptNo: res.taxReceiptYr3,
				dateOfIssuance: new Date(res.issueDateYr3)
					.toISOString()
					.split("T")[0],
				dateOfPayment: new Date(res.pymtDateYr3)
					.toISOString()
					.split("T")[0]
			});
			setYear4({
				totalIncome: res.totIncomeYr4 || "",
				totalTaxableIncome: res.totTaxIncomeYr4 || "",
				taxPaid: res.totTaxPaidYr4 || "",
				taxReceiptNo: res.taxReceiptYr4 || "",
				dateOfIssuance:
					new Date(res.issueDateYr4).toISOString().split("T")[0] ||
					"",
				dateOfPayment:
					new Date(res.pymtDateYr4).toISOString().split("T")[0] || ""
			});
			setShowingTable(true);
		} catch (error) {
			setEdit(false);
			if (error.response) {
				if (error.response.status === 404) setShowingTable(true);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setCheckingRecord(false);
		}
	};

	const submitRecord = async () => {
		try {
			setSaving(true);
			if (edit) {
				// eslint-disable-next-line no-unused-vars
				const { data: _, msg } = await axios
					.put(
						`/tmscertmakeup/${record.ESBN}/${record.CertCurYear}`,
						{
							certno: record.CertificateNo,
							esbn: record.ESBN,
							taxpayername: taxpayerName,
							taxpayeraddr: address,
							gender: gender,
							incsource: sourceOfIncome,
							certcuryear: record.CertCurYear,
							totincomeyr1: `${year1.totalIncome}`,
							tottaxincomeyr1: `${year1.totalTaxableIncome}`,
							tottaxpaidyr1: `${year1.taxPaid}`,
							taxreceiptyr1: `${year1.taxReceiptNo}`,
							issuedateyr1: `${year1.dateOfIssuance}`,
							pymtdateyr1: `${year1.dateOfPayment}`,
							totincomeyr2: `${year2.totalIncome}`,
							tottaxincomeyr2: `${year2.totalTaxableIncome}`,
							tottaxpaidyr2: `${year2.taxPaid}`,
							taxreceiptyr2: `${year2.taxReceiptNo}`,
							issuedateyr2: `${year2.dateOfIssuance}`,
							pymtdateyr2: `${year2.dateOfPayment}`,
							totincomeyr3: `${year3.totalIncome}`,
							tottaxincomeyr3: `${year3.totalTaxableIncome}`,
							tottaxpaidyr3: `${year3.taxPaid}`,
							taxreceiptyr3: `${year3.taxReceiptNo}`,
							issuedateyr3: `${year3.dateOfIssuance}`,
							pymtdateyr3: `${year3.dateOfPayment}`,
							totincomeyr4: `${year4.totalIncome}`,
							tottaxincomeyr4: `${year4.totalTaxableIncome}`,
							tottaxpaidyr4: `${year4.taxPaid}`,
							taxreceiptyr4: `${year4.taxReceiptNo}`,
							issuedateyr4: `${year4.dateOfIssuance}`,
							pymtdateyr4: `${year4.dateOfPayment}`,
							waiveflg: "",
							waivereason: "",
							inputter,
							deactivated: false
						}
					)
					.then((res) => res.data || {});

				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: `Edit Successful! ${msg}`
				});
				setEdit(false);
			} else {
				const certNo = await axios
					.get(`/getgeneralids/02`)
					.then((r) => r.data.Id);

				// eslint-disable-next-line no-unused-vars
				const { _, msg } = await axios
					.post(`/tmscertmakeup`, {
						certno: certNo,
						esbn: ESBN,
						taxpayername: taxpayerName,
						taxpayeraddr: address,
						gender: gender,
						incsource: sourceOfIncome,
						certcuryear: `${year}`,
						totincomeyr1: `${year1.totalIncome}`,
						tottaxincomeyr1: `${year1.totalTaxableIncome}`,
						tottaxpaidyr1: `${year1.taxPaid}`,
						taxreceiptyr1: `${year1.taxReceiptNo}`,
						issuedateyr1: `${year1.dateOfIssuance}`,
						pymtdateyr1: `${year1.dateOfPayment}`,
						totincomeyr2: `${year2.totalIncome}`,
						tottaxincomeyr2: `${year2.totalTaxableIncome}`,
						tottaxpaidyr2: `${year2.taxPaid}`,
						taxreceiptyr2: `${year2.taxReceiptNo}`,
						issuedateyr2: `${year2.dateOfIssuance}`,
						pymtdateyr2: `${year2.dateOfPayment}`,
						totincomeyr3: `${year3.totalIncome}`,
						tottaxincomeyr3: `${year3.totalTaxableIncome}`,
						tottaxpaidyr3: `${year3.taxPaid}`,
						taxreceiptyr3: `${year3.taxReceiptNo}`,
						issuedateyr3: `${year3.dateOfIssuance}`,
						pymtdateyr3: `${year3.dateOfPayment}`,
						totincomeyr4: `${year4.totalIncome}`,
						tottaxincomeyr4: `${year4.totalTaxableIncome}`,
						tottaxpaidyr4: `${year4.taxPaid}`,
						taxreceiptyr4: `${year4.taxReceiptNo}`,
						issuedateyr4: `${year4.dateOfIssuance}`,
						pymtdateyr4: `${year4.dateOfPayment}`,
						waiveflg: "",
						waivereason: "",
						inputter,
						deactivated: false
					})
					.then((res) => res.data || {});

				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: msg
				});
			}

			setESBN("");
			setYear("");
			setTaxPayerName("");
			setAddress("");
			setSourceOfIncome("");
			setYear1({
				totalIncome: "",
				totalTaxableIncome: "",
				taxPaid: "",
				taxReceiptNo: "",
				dateOfIssuance: "",
				dateOfPayment: ""
			});
			setYear2({
				totalIncome: "",
				totalTaxableIncome: "",
				taxPaid: "",
				taxReceiptNo: "",
				dateOfIssuance: "",
				dateOfPayment: ""
			});
			setYear3({
				totalIncome: "",
				totalTaxableIncome: "",
				taxPaid: "",
				taxReceiptNo: "",
				dateOfIssuance: "",
				dateOfPayment: ""
			});
			setYear4({
				totalIncome: "",
				totalTaxableIncome: "",
				taxPaid: "",
				taxReceiptNo: "",
				dateOfIssuance: "",
				dateOfPayment: ""
			});
			setShowingTable(false);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message ||
						error.response.data.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setSaving(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div className={styles.receipt__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form
				className={styles3.columned__form}
				style={{ marginBottom: "40px" }}
				onSubmit={(e) => {
					e.preventDefault();
					checkRecords();
				}}
			>
				<div className={styles3.double__inputs}>
					<div>
						<label className={styles3.input__label} htmlFor="ESBN">
							ESBN <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="ESBN"
							name="ESBN"
							className={styles3.select__input}
							value={ESBN}
							onChange={(e) => setESBN(e.target.value)}
							placeholder="eg. 54826648633"
							required
						/>
					</div>
					<div>
						<label className={styles3.input__label} htmlFor="year">
							Year <span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="year"
							value={year}
							onChange={(e) => setYear(e.target.value)}
							required
						>
							<option value="">Select</option>
							<option>2010</option>
							{new Array(new Date().getFullYear() - 2010)
								.fill(0)
								.map((_, i) => (
									<option key={i}>{2010 + i + 1}</option>
								))}
						</select>
					</div>
				</div>
				<div className={styles3.double__inputs}>
					<div>
						<label
							className={styles3.input__label}
							htmlFor="taxpayerName"
						>
							Taxpayer Name{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="taxpayerName"
							name="taxpayerName"
							className={styles3.select__input}
							value={taxpayerName}
							onChange={(e) => setTaxPayerName(e.target.value)}
							placeholder="eg. Adaobi Agu"
							required
						/>
					</div>
					<div>
						<label
							className={styles3.input__label}
							htmlFor="address"
						>
							Address <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="address"
							name="address"
							className={styles3.select__input}
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							placeholder="eg. No. 1 Lion house Independence Layout"
						/>
					</div>
				</div>
				<div className={styles3.double__inputs}>
					<div>
						<label
							htmlFor="gender"
							className={styles3.input__label}
						>
							Gender
							<span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="gender"
							value={gender}
							onChange={(e) => setGender(e.target.value)}
						>
							<option value="">---Select Gender---</option>
							<option>Male</option>
							<option>Female</option>
						</select>
					</div>

					<div>
						<label
							className={styles3.input__label}
							htmlFor="sourceOfIncome"
						>
							Source of Income{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="sourceOfIncome"
							name="sourceOfIncome"
							className={styles3.select__input}
							value={sourceOfIncome}
							onChange={(e) => setSourceOfIncome(e.target.value)}
							placeholder="eg. Business"
						/>
					</div>
				</div>
				<button
					type="submit"
					className={["primary__btn"].join(" ")}
					disabled={!taxpayerName || !year || !ESBN}
				>
					{checkingRecord ? "Checking..." : "Check Records"}
				</button>
			</form>
			{showingTable && (
				<>
					<div className={styles1.manual__entry_table}>
						<div>
							<div className={styles1.uncoloured}>Year</div>
							<div className={styles1.coloured}>Total Income</div>
							<div className={styles1.coloured}>
								Total Taxable Income
							</div>
							<div className={styles1.coloured}>Tax Paid</div>
							<div className={styles1.coloured}>
								Tax Receipt No
							</div>
							<div className={styles1.coloured}>
								Issuance Date
							</div>
							<div className={styles1.coloured}>Payment Date</div>
						</div>
						<div>
							<div>
								{edit
									? parseInt(record.CertCurYear) - 3
									: year - 3}
							</div>
							<div>
								<input
									type="number"
									className={styles3.select__input}
									value={year1.totalIncome}
									onChange={(e) =>
										setYear1({
											...year1,
											totalIncome: e.target.value
										})
									}
									placeholder="...enter year 1 total income"
									required
								/>
							</div>
							<div>
								<input
									type="number"
									className={styles3.select__input}
									value={year1.totalTaxableIncome}
									onChange={(e) =>
										setYear1({
											...year1,
											totalTaxableIncome: e.target.value
										})
									}
									placeholder="...enter year 1 total taxable income"
									required
								/>
							</div>
							<div>
								<input
									type="number"
									className={styles3.select__input}
									value={year1.taxPaid}
									onChange={(e) =>
										setYear1({
											...year1,
											taxPaid: e.target.value
										})
									}
									placeholder="...enter year 1 tax paid"
									required
								/>
							</div>
							<div>
								<input
									className={styles3.select__input}
									value={year1.taxReceiptNo}
									onChange={(e) =>
										setYear1({
											...year1,
											taxReceiptNo: e.target.value
										})
									}
									placeholder="...enter year 1 tax receipt number"
									required
								/>
							</div>
							<div>
								<input
									type="date"
									className={styles3.select__input}
									value={year1.dateOfIssuance}
									onChange={(e) =>
										setYear1({
											...year1,
											dateOfIssuance: e.target.value
										})
									}
									required
								/>
							</div>
							<div>
								<input
									type="date"
									className={styles3.select__input}
									value={year1.dateOfPayment}
									onChange={(e) =>
										setYear1({
											...year1,
											dateOfPayment: e.target.value
										})
									}
									required
								/>
							</div>
						</div>
						<div>
							<div>
								{edit
									? parseInt(record.CertCurYear) - 2
									: year - 2}
							</div>
							<div>
								<input
									type="number"
									className={styles3.select__input}
									value={year2.totalIncome}
									onChange={(e) =>
										setYear2({
											...year2,
											totalIncome: e.target.value
										})
									}
									placeholder="...enter year 2 total income"
									required
								/>
							</div>
							<div>
								<input
									type="number"
									className={styles3.select__input}
									value={year2.totalTaxableIncome}
									onChange={(e) =>
										setYear2({
											...year2,
											totalTaxableIncome: e.target.value
										})
									}
									placeholder="...enter year 2 total taxable income"
									required
								/>
							</div>
							<div>
								<input
									type="number"
									className={styles3.select__input}
									value={year2.taxPaid}
									onChange={(e) =>
										setYear2({
											...year2,
											taxPaid: e.target.value
										})
									}
									placeholder="...enter year 2 tax paid"
									required
								/>
							</div>
							<div>
								<input
									className={styles3.select__input}
									value={year2.taxReceiptNo}
									onChange={(e) =>
										setYear2({
											...year2,
											taxReceiptNo: e.target.value
										})
									}
									placeholder="...enter year 2 tax receipt number"
									required
								/>
							</div>
							<div>
								<input
									type="date"
									className={styles3.select__input}
									value={year2.dateOfIssuance}
									onChange={(e) =>
										setYear2({
											...year2,
											dateOfIssuance: e.target.value
										})
									}
									required
								/>
							</div>
							<div>
								<input
									type="date"
									className={styles3.select__input}
									value={year2.dateOfPayment}
									onChange={(e) =>
										setYear2({
											...year2,
											dateOfPayment: e.target.value
										})
									}
									required
								/>
							</div>
						</div>
						<div>
							<div>
								{edit
									? parseInt(record.CertCurYear) - 1
									: year - 1}
							</div>
							<div>
								<input
									type="number"
									className={styles3.select__input}
									value={year3.totalIncome}
									onChange={(e) =>
										setYear3({
											...year3,
											totalIncome: e.target.value
										})
									}
									placeholder="...enter year 3 total income"
									required
								/>
							</div>
							<div>
								<input
									type="number"
									className={styles3.select__input}
									value={year3.totalTaxableIncome}
									onChange={(e) =>
										setYear3({
											...year3,
											totalTaxableIncome: e.target.value
										})
									}
									placeholder="...enter year 3 total taxable income"
									required
								/>
							</div>
							<div>
								<input
									type="number"
									className={styles3.select__input}
									value={year3.taxPaid}
									onChange={(e) =>
										setYear3({
											...year3,
											taxPaid: e.target.value
										})
									}
									placeholder="...enter year 3 tax paid"
									required
								/>
							</div>
							<div>
								<input
									className={styles3.select__input}
									value={year3.taxReceiptNo}
									onChange={(e) =>
										setYear3({
											...year3,
											taxReceiptNo: e.target.value
										})
									}
									placeholder="...enter year 3 tax receipt number"
									required
								/>
							</div>
							<div>
								<input
									type="date"
									className={styles3.select__input}
									value={year3.dateOfIssuance}
									onChange={(e) =>
										setYear3({
											...year3,
											dateOfIssuance: e.target.value
										})
									}
									required
								/>
							</div>
							<div>
								<input
									type="date"
									className={styles3.select__input}
									value={year3.dateOfPayment}
									onChange={(e) =>
										setYear3({
											...year3,
											dateOfPayment: e.target.value
										})
									}
									required
								/>
							</div>
						</div>
						<div>
							<div>{edit ? record.CertCurYear : year}</div>
							<div>
								<input
									type="number"
									className={styles3.select__input}
									value={year4.totalIncome}
									onChange={(e) =>
										setYear4({
											...year4,
											totalIncome: e.target.value
										})
									}
									placeholder="...enter year 4 total income"
									required
								/>
							</div>
							<div>
								<input
									type="number"
									className={styles3.select__input}
									value={year4.totalTaxableIncome}
									onChange={(e) =>
										setYear4({
											...year4,
											totalTaxableIncome: e.target.value
										})
									}
									placeholder="...enter year 4 total taxable income"
									required
								/>
							</div>
							<div>
								<input
									type="number"
									className={styles3.select__input}
									value={year4.taxPaid}
									onChange={(e) =>
										setYear4({
											...year4,
											taxPaid: e.target.value
										})
									}
									placeholder="...enter year 4 tax paid"
									required
								/>
							</div>
							<div>
								<input
									className={styles3.select__input}
									value={year4.taxReceiptNo}
									onChange={(e) =>
										setYear4({
											...year4,
											taxReceiptNo: e.target.value
										})
									}
									placeholder="...enter year 4 tax receipt number"
									required
								/>
							</div>
							<div>
								<input
									type="date"
									className={styles3.select__input}
									value={year4.dateOfIssuance}
									onChange={(e) =>
										setYear4({
											...year4,
											dateOfIssuance: e.target.value
										})
									}
									required
								/>
							</div>
							<div>
								<input
									type="date"
									className={styles3.select__input}
									value={year4.dateOfPayment}
									onChange={(e) =>
										setYear4({
											...year4,
											dateOfPayment: e.target.value
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					<button
						className={["primary__btn", styles1.save__btn].join(
							" "
						)}
						onClick={() => submitRecord()}
						disabled={
							!ESBN ||
							!taxpayerName ||
							!address ||
							!year ||
							!gender ||
							!sourceOfIncome
						}
					>
						{saving ? "Saving..." : "Save"}
					</button>
				</>
			)}
		</div>
	);
};

export default DAManualEntryCertificate;
