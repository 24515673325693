import React, { useEffect, useState } from "react";
import axios from "axios";
import { ReactComponent as CloseIcon } from "../../../assets/Home/closeIcon.svg";

import styles from "../style.module.css";

const LookUpCertificate = () => {
	const [showTaxCalcResult, setShowTaxCalcResult] = useState(false);
	const [loading, setLoading] = useState(false);
	const [certificate, setReceiptID] = useState("");
	const [taxData, setTaxData] = useState(null);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const handleGetCertificate = async (e) => {
		e.preventDefault();
		if (!!certificate && !isNaN(certificate)) {
			try {
				setLoading(true);
				const taxResult = await axios
					.get(`taxcalculator/${certificate}`)
					.then((res) => (res.data || {}).data);
				if (taxResult) {
					setTaxData(taxResult);
					setShowTaxCalcResult(true);
					setLoading(false);
					setAlert({
						showing: true,
						type: "error",
						message: "ChargeTaxSpread found"
					});
				}
			} catch (err) {
				setLoading(false);
				console.error(err);
				setAlert({
					showing: true,
					type: "error",
					message: "Tax Calculation not successful."
				});
			}
		}
	};

	console.log(taxData);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<>
			<div className="container demo">
				<div
					className="modal right fade"
					id="lookUpCertificate"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="LookUpCertificate"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className={`modal-body ${styles.modal__body}`}>
								<div>
									<CloseIcon
										type="button"
										data-dismiss="modal"
										style={{ cursor: "pointer" }}
									/>
									<h3>Lookup Certifcate</h3>
								</div>
								{!showTaxCalcResult ? (
									<>
										<label htmlFor="certificateField">
											Certificate ID
											<span className="text-danger">
												*
											</span>
										</label>
										<div className="input-group">
											<span
												className="input-group-addon"
												id="sizing-addon1"
											>
												&#8358;
											</span>
											<input
												type="text"
												id="certificateField"
												className="form-control"
												placeholder="Certificate"
												aria-describedby="sizing-addon2"
												onChange={(e) =>
													setReceiptID(e.target.value)
												}
											/>
										</div>

										<button
											className={`btn btn-success ${styles.my_30}`}
											type="button"
											disabled={!certificate}
											onClick={handleGetCertificate}
										>
											{loading
												? "Loading..."
												: "Get Certificate"}
										</button>
									</>
								) : (
									<>
										<p>
											Instantly estimate your tax due with
											the calculator.
										</p>

										<br />
										<h4>Income-to-Tax</h4>
										<table className="table table-bordered">
											<thead>
												<tr>
													<th>Total Income</th>
													<th>₦50,000,000.00</th>
												</tr>
											</thead>
										</table>
										<br />

										<table className="table table-bordered">
											<thead>
												<tr>
													<th>
														Annual Taxable Income
													</th>
													<th>Rate</th>
													<th>
														{"Tax Calculated(₦)"}
													</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>First NGN 300,000</td>
													<td>7%</td>
													<td>₦21,000.00</td>
												</tr>
												<tr>
													<td>Next NGN 300,000</td>
													<td>11%</td>
													<td>₦21,000.00</td>
												</tr>
												<tr>
													<td>Next NGN 300,000</td>
													<td>11%</td>
													<td>₦21,000.00</td>
												</tr>
												<tr>
													<td>Next NGN 300,000</td>
													<td>11%</td>
													<td>₦21,000.00</td>
												</tr>
												<tr>
													<td>Next NGN 300,000</td>
													<td>11%</td>
													<td>₦21,000.00</td>
												</tr>
												<tr>
													<td>Above NGN 3,200,000</td>
													<td>24%</td>
													<td>₦768,000.00</td>
												</tr>
												<tr>
													<td>Overflow</td>
													<td>24%</td>
													<td>₦21,000.00</td>
												</tr>
												<tr>
													<td
														style={{
															padding: "8px"
														}}
													>
														{" "}
													</td>
													<td> </td>
													<td> </td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<th colSpan={2}>Total</th>
													<th>₦21,000.00</th>
												</tr>
											</tfoot>
										</table>
										<br />
										<table className="table table-bordered">
											<thead>
												<tr>
													<th>Income Tax</th>
													<th>₦500,000.00</th>
												</tr>
											</thead>
											<tfoot>
												<tr>
													<th>
														Total calculated annual
														Tax
													</th>
													<th>₦12,292,000.00</th>
												</tr>
												<tr>
													<th>
														Total calculated Monthly
														Tax
													</th>
													<th>₦12,292,000.00</th>
												</tr>
											</tfoot>
										</table>
										<br />
										<br />
										<div
											className={
												styles.modal__button__wrapper
											}
										>
											<button
												className={`${styles.modal__button} btn`}
											>
												Check again
											</button>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default LookUpCertificate;
