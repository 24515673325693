import { useState, useEffect } from "react";
import axios from "axios";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import styles from "../../Dashboard/ReceiptPymtRef/style.module.css";
import styles1 from "./style.module.css";
import styles3 from "../../Identity-management-individual/style.module.css";
import { formatAmount } from "../../utils";

const Match = () => {
	const inputter = JSON.parse(localStorage.getItem("user_info")).email;

	const [ESBN, setESBN] = useState("");
	const [year, setYear] = useState("");
	const [loadingBreakdown, setLoadingBreakdown] = useState(false);
	const [paymentBreakdown, setPaymentBreakdown] = useState([]);
	const [receiptNoInputValues, setReceiptNoInputValues] = useState({});
	const [checkingReceiptNo, setCheckingReceiptNo] = useState({
		status: false,
		year: ""
	});
	const [isMatching, setIsMatching] = useState({
		status: false,
		year: ""
	});
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const headers = [
		["Revenue", "Revenue", formatAmount],
		["Year", "AssessmentYear"],
		["Total Tax Due", "TotalTaxDue", formatAmount],
		["Tax Paid", "Amount", formatAmount],
		["Assessment Code", "AssessmentCode"],
		["Receipt Number", "EReceiptNo"],
		["Issue Date", "createdAt"],
		["Payment Date", "PaymentDate"]
	];

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const getPaymentBreakdown =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault();
			setLoadingBreakdown(true);
			try {
				const res = await axios
					.get(`/getcertesbn/${ESBN}/${year}`)
					.then((res) => (res.data || {}).data);

				setPaymentBreakdown(res);

				res.forEach((r) =>
					setReceiptNoInputValues((prevInputValues) => ({
						...prevInputValues,
						[r.fiscalyear]: {
							value: "",
							data: null
						}
					}))
				);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
				setPaymentBreakdown([]);
				setReceiptNoInputValues({});
			} finally {
				setLoadingBreakdown(false);
			}
		};

	const checkReceiptNumber = async (receiptNo, year) => {
		setCheckingReceiptNo({
			status: true,
			year
		});

		try {
			let res = await axios
				.get(`/gettmspayref/${receiptNo}`)
				.then((res) => (res.data || {}).data);

			setReceiptNoInputValues({
				...receiptNoInputValues,
				[year]: {
					...receiptNoInputValues[year],
					data: res
				}
			});
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}

			setReceiptNoInputValues({
				...receiptNoInputValues,
				[year]: {
					...receiptNoInputValues[year],
					data: null
				}
			});
		} finally {
			setCheckingReceiptNo({
				status: false,
				year: ""
			});
		}
	};

	const match = async (billCode, payRef, year, index) => {
		setIsMatching({
			status: true,
			year
		});

		try {
			let { msg, data } = await axios
				.post(`/regularisepay`, {
					billCode,
					payref: payRef,
					typeofBill: "1",
					userId: inputter
				})
				.then((res) => res.data || {});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: msg
			});
			setPaymentBreakdown((paymentBreakdown) => {
				return paymentBreakdown.map((b, i) =>
					index === i
						? {
								...b,
								Amount: data.taxPaid,
								EReceiptNo: data.receiptNo,
								PaymentDate: data.pymtDate
						  }
						: b
				);
			});
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setIsMatching({
				status: false,
				year: ""
			});
		}
	};

	return (
		<div className={styles.receipt__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form
				className={styles3.columned__form}
				style={{ marginBottom: "40px" }}
				onSubmit={(e) => getPaymentBreakdown()(e)}
			>
				<div className={styles3.double__inputs}>
					<div>
						<label className={styles3.input__label} htmlFor="ESBN">
							ESBN <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="ESBN"
							name="ESBN"
							className={styles3.select__input}
							value={ESBN}
							onChange={(e) => setESBN(e.target.value)}
							placeholder="eg. 54826648633"
							required
						/>
					</div>
					<div>
						<label className={styles3.input__label} htmlFor="year">
							Year <span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="year"
							value={year}
							onChange={(e) => setYear(e.target.value)}
							required
						>
							<option value="">Select</option>
							<option>2010</option>
							{new Array(new Date().getFullYear() - 2010)
								.fill(0)
								.map((_, i) => (
									<option key={i}>{2010 + i + 1}</option>
								))}
						</select>
					</div>
				</div>
				<button
					type="submit"
					className={["primary__btn", styles1.breakdown__btn].join(
						" "
					)}
					disabled={!ESBN || !year}
				>
					Get Breakdown
				</button>
			</form>
			{loadingBreakdown && <p>Loading...</p>}
			{paymentBreakdown?.length > 0 && !loadingBreakdown && (
				<>
					<div className={styles1.table__area}>
						<Table
							headers={headers}
							data={paymentBreakdown}
							total={paymentBreakdown?.length}
							full
							showingTotal={false}
						/>
						<div className={styles1.correction__inputs}>
							{paymentBreakdown.map((breakdown, i) => (
								<div key={breakdown.fiscalyear}>
									<form
										onSubmit={(e) => {
											e.preventDefault();
											checkReceiptNumber(
												receiptNoInputValues[
													breakdown.fiscalyear
												]?.value,
												breakdown.fiscalyear
											);
										}}
									>
										<input
											name={breakdown.fiscalyear}
											value={
												receiptNoInputValues[
													breakdown.fiscalyear
												]?.value || ""
											}
											onChange={(e) =>
												setReceiptNoInputValues({
													...receiptNoInputValues,
													[breakdown.fiscalyear]: {
														...receiptNoInputValues[
															breakdown.fiscalyear
														],
														value: e.target.value
													}
												})
											}
											placeholder="Enter Receipt Number"
										/>
										<button type="submit">
											{checkingReceiptNo.year ===
											breakdown.fiscalyear
												? "Checking..."
												: "Check"}
										</button>
									</form>
									{receiptNoInputValues[breakdown.fiscalyear]
										?.data &&
										!checkingReceiptNo.status && (
											<p
												className={
													styles1.receiptNo__breakdown
												}
											>
												<span>
													&#8358;&nbsp;
													{new Intl.NumberFormat().format(
														receiptNoInputValues[
															breakdown.fiscalyear
														]?.data.Amount
													)}
												</span>
												<span>
													{receiptNoInputValues[
														breakdown.fiscalyear
													]?.data.PaymentDate.slice(
														0,
														10
													)}
												</span>
												<button
													onClick={() =>
														match(
															breakdown.AssessmentCode,
															receiptNoInputValues[
																breakdown
																	.fiscalyear
															]?.value,
															breakdown.fiscalyear,
															i
														)
													}
												>
													{isMatching.year ===
													breakdown.fiscalyear
														? "Matching..."
														: "Match"}
												</button>
											</p>
										)}
								</div>
							))}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Match;
