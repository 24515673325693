import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Alert from "../../components/Alert";
import styles from "../../Identity-management-individual/style.module.css";
import style from "./style.module.css";
import Spinner from "../../components/spinner";

export const AdminFileAnnualReturn = () => {
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		yearOfReturns: "2024",
		esbn: ""
	});
	const history = useHistory();

	const years = useMemo(() => {
		const currentYear = new Date().getFullYear();
		const pastFiveYears = Array.from(
			{ length: 5 },
			(_, index) => Number(currentYear) - index
		);
		return pastFiveYears;
	}, []);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const handleProfileInArrayChange = (e) => {
		const value = e.target.value;
		const name = e.target.name;
		setFormData((prevState) => ({ ...prevState, [name]: value }));
	};

	const submit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			let res = await axios
				.get(
					`/comp/annual-returns/${formData.esbn}/${formData.yearOfReturns}`
				)
				.then((res) => res.data || {});

			setAlert({
				showing: true,
				type: "success",
				message: res.message
			});

			setTimeout(() => {
				history.push(`admin-filing/file-annual-returns`, {
					coyID: res?.data?.corporatecoyfoldid,
					esbn: formData.esbn
				});
				setLoading(false);
			}, 3000);
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error.response.data?.msg ||
					error.response.data?.errors?.[0]?.message ||
					error.response.data?.errors?.details[0]?.message ||
					error.response?.data?.message
			});
		} finally {
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);
	return (
		<form className={styles.form} onSubmit={submit}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}

			<header>
				<h2>Annual Returns Filing</h2>
				<hr />
			</header>

			<br />
			<div className={styles.double__inputs}>
				<div>
					<label className={styles.input__label} htmlFor="esbn">
						ESBN *
					</label>
					<input
						type="text"
						name="esbn"
						onChange={(e) => {
							handleProfileInArrayChange(e);
						}}
						id="esbn"
						placeholder=""
						required
						value={formData.esbn}
					/>
				</div>
			</div>
			<br />
			<div className={styles.double__inputs}>
				<div>
					<label
						className={styles.input__label}
						htmlFor="yearOfReturns"
					>
						Year of Returns
					</label>
					<select
						id="yearOfReturns"
						name="yearOfReturns"
						onChange={(e) => {
							handleProfileInArrayChange(e);
						}}
						value={formData.yearOfReturns}
						required
					>
						{years.map((year, index) => (
							<option key={`${year}${index}`}>{year}</option>
						))}
					</select>
				</div>
			</div>
			<br />
			<br />
			<footer className={style.form__footer}>
				<button
					disabled={loading || Object.values(formData).includes("")}
					type="submit"
					className={[
						"primary__btn",
						styles.primary__button,
						loading ? styles.loading__button : ""
					].join(" ")}
				>
					File Annual Returns
					{loading && <Spinner />}
				</button>
			</footer>
		</form>
	);
};
