import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import Aside from "../../components/Aside";
import Alert from "../../components/Alert";
import Logo from "../../assets/logo.svg";

import styles1 from "../Login/style.module.css";

const ResetPassword = () => {
	const history = useHistory();
	const [ESBN, setESBN] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [resetting, setResetting] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const resetPassword = async (e) => {
		e.preventDefault();
		setResetting(true);

		try {
			await axios
				.post("/auth/forgot-password", {
					anssid: ESBN,
					email,
					phoneNumber,
					flg: ESBN ? "1" : "2"
				})
				.then((res) => (res.data || {}).data);

			history.push("/login");
		} catch (error) {
			console.error(error);
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setResetting(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section className={styles1.wrapper}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<Aside showOnMobile={false} />
			<main className={styles1.auth__form__container}>
				<img src={Logo} alt="logo" />
				<h1 className={styles1.heading__text}>Reset Password</h1>
				<p className={styles1.sub__heading__text}>
					Fill-in, at least, one field. <br />
					Remember your password? <Link to="/login">Log in</Link>
				</p>
				<form className={styles1.auth__form} onSubmit={resetPassword}>
					<label htmlFor="ESBN">ESBN</label>
					<input
						id="ESBN"
						type="text"
						value={ESBN}
						onChange={(e) => setESBN(e.target.value)}
						placeholder="enter your ESBN"
					/>
					<label htmlFor="email">Email</label>
					<input
						id="email"
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="enter email linked to account"
					/>
					<label htmlFor="phoneNumber">Phone Number</label>
					<input
						id="phoneNumber"
						type="tel"
						value={phoneNumber}
						onChange={(e) => setPhoneNumber(e.target.value)}
						placeholder="enter phone number linked to account"
					/>
					<button
						type="submit"
						className={`${styles1.submit__button} primary__btn`}
						disabled={
							(!ESBN.trim() &&
								!email.trim() &&
								!phoneNumber.trim()) ||
							resetting
						}
					>
						{resetting ? "Submitting..." : "Submit"}
					</button>
				</form>
			</main>
		</section>
	);
};

export default ResetPassword;
