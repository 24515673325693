/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Spinner from "../../../components/spinner";
import styles from "../../../Identity-management-individual/style.module.css";
import styles1 from "../style.module.css";
import styles2 from "../../../Direct-assessment/Create-direct-assessment/style.module.css";
import styles3 from "../../../Identity-management-individual/style.module.css";
import Alert from "../../../components/Alert";
import Receipt from "../../ReceiptPymtRef/receipt";
import GoBackButton from "../../../components/goBackButton";

const user = JSON.parse(localStorage.getItem("user_info"));

const EditBill = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [fetching, setFetching] = useState(true);
	const [ownersesbn, setownersesbn] = useState("");
	const [BillNumber, setBillNumber] = useState("");
	const [ownersname, setownersname] = useState("");
	const [owneremail, setowneremail] = useState("");
	const [ownersaddrcity, setownersaddrcity] = useState("");
	const [accountType, setAccountType] = useState("individual");
	const [payername, setpayername] = useState("");
	const [propertyaddr, setpropertyaddr] = useState("");
	const [propertycity, setpropertycity] = useState("");
	const [appartmentno, setappartmentno] = useState("");
	const [ownershiptype, setownershiptype] = useState("");
	const [profile, setprofile] = useState("");
	const [profilecategory, setprofilecategory] = useState("");
	const [tagId, settagId] = useState("");
	const [billtype, setbilltype] = useState("");
	const [showInvoice, setShowInvoice] = useState(false);
	const [subscription, setSubcription] = useState("yearly");
	const [selectMonth, setSelectedMonth] = useState("");

	const [invoice, setInvoice] = useState("");
	const [authoriser, setauthoriser] = useState("");
	const [phoneno, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [residentialaddress, setResidentialaddress] = useState("");
	const [RegistrationFee, setRegistrationFee] = useState("");
	const [RenewalFee, setRenewalFee] = useState("");
	const [discount, setDiscount] = useState("");
	const [discountReason, setDiscountReason] = useState("");
	const [outstandingReason, setOutstandingReason] = useState("");
	const [outstand, setOutStanding] = useState("");
	const [categories, setCategories] = useState([]);
	const [validSubCategories, setValidSubCategories] = useState({
		FixedFee: [],
		RegistrationFee: [],
		RenewalFee: []
	});
	const [subCategories, setSubCategories] = useState([]);
	const [revenues, setRevenues] = useState([]);
	const [filterSubCategories, setFilteredSubCategories] = useState([]);
	const [currentBillType, setCurrentBillType] = useState("");
	const [waterConnectionStatus, setWaterConnectionStatus] =
		useState("Connected");
	const [meteringStatus, setMeteringStatus] = useState("");
	const [revenue, setRevenue] = useState("");
	const [taxoffice, setTaxOffice] = useState("");
	const [taxzone, setTaxZone] = useState("");
	const [taxlga, setTaxLGA] = useState("");
	const [incomeGroup, setIncomeGroup] = useState("");
	const [fiscalyr, setFiscalYear] = useState("");
	const [fiscalymth, setFiscalMonth] = useState("");
	const [currentReading, setCurrentReading] = useState("");
	const [previousReading, setPreviousReading] = useState("");
	const [readingDate, setReadingDate] = useState("");
	const [generalRevDesc, setGeneralRevDesc] = useState("");
	const [grandTotal, setGrandTotal] = useState("");
	const [currentMnth, setCurrentMonth] = useState({
		value: "",
		index: null
	});
	const [total, setCurrentTotal] = useState({
		value: "",
		index: null
	});
	const [subCatData, setSubCatData] = useState({ value: "", index: null });

	let { state } = useLocation();

	// const [currentPIDName, setCurrentPIDName] = useState({
	// 	value: "",
	// 	index: null
	// });
	const [landMeasurement, setLandMeasurement] = useState({
		value: "",
		index: null
	});

	const [mdaProperties, setMDAProperties] = useState({});
	const [charge, setCharge] = useState(0);
	const [areas, setAreas] = useState([]);
	const [zones, setZones] = useState([]);
	const [lgas, setLGAs] = useState([]);
	const [taxoffices, setTaxOffices] = useState([]);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const [profileDetailsArray, setProfileDetailsArray] = React.useState([
		{
			Amount: "",
			PIDName: " ",
			HouseNo: " ",
			HouseStreet: " ",
			houseArea: " ",
			HouseLGA: " ",
			HouseZone: " ",
			RevenueDescription: "",
			Profile: "",
			ProfileCategory: "",
			BillType: "",
			PropertyId: " ",
			Quantity: "1",
			monthNumber: "1",
			revenue: ""
		}
	]);

	useEffect(() => {
		const fetchCategories = async () => {
			const res = await axios
				.get(`settings/profilecategorys/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setCategories(res);
			if (res) {
				fetchSubCategories(res);
			}
		};
		fetchCategories();
	}, []);

	const fetchSubCategories = async (allCategories) => {
		const res = await axios
			.get(`settings/tarrifcommerces/${user?.mdaModuleCoy}`)
			.then((res) => (res.data || {}).data);
		const FixedFee = res?.filter((subCat) => Number(subCat?.FixedFee) > 0);
		const RegistrationFee = res?.filter(
			(subCat) => Number(subCat?.RegistrationFee) > 0
		);
		const RenewalFee = res?.filter(
			(subCat) => Number(subCat?.RenewalFee) > 0
		);
		setValidSubCategories({ FixedFee, RegistrationFee, RenewalFee });
		setSubCategories(res);
		fetchCurrentBill(allCategories, res);
	};

	const fetchCurrentBill = useCallback(
		async (allCategories, allSubCategories) => {
			setFetching(true);
			const { res, revDesc } = await axios
				.get(`billing/${state.coy}/${state.userId}`)
				.then((res) => ({
					res: (res.data || {}).data?.result,
					revDesc: (res.data || {}).data?.RevenueDescription
				}));
			setownersesbn(res?.OwnersESBN);

			setownersname(res.OwnersName);
			setResidentialaddress(res?.OwnersAddr);
			setPhoneNumber(res?.OwnersPhone);
			setEmail(res?.OwnerEmail);
			setWaterConnectionStatus(res?.WaterConnectStatus);
			setMeteringStatus(res?.MeteringStatus);
			setSubcription(res?.Year_Mth_Flg === "2" ? "monthly" : "yearly");
			setFiscalYear(res?.fiscalYear);
			setSelectedMonth(res?.fiscalMonth);
			setTaxLGA(res?.TaxLGA);
			setTaxZone(res?.TaxZone);
			setTaxOffice(res?.TaxOffice);
			setCurrentReading(res?.Current_Reading ?? 0);
			setPreviousReading(res?.Previous_Reading ?? 0);
			setReadingDate(res?.Reading_Date);
			setDiscount(res?.Discount);
			setDiscountReason(res?.DiscountReason);
			setOutStanding(res?.Outstanding);
			setOutstandingReason(res?.OutstandingReason ?? "");
			setProfileDetailsArray(res?.TaxPayerBillsDetails);
			setGeneralRevDesc(revDesc);
			setGrandTotal(res?.GrandTotal);
			setBillNumber(res?.BillNumber);

			if (res?.TaxPayerBillsDetails) {
				res?.TaxPayerBillsDetails?.forEach((billDetail) => {
					getSelectedCategories(
						null,
						billDetail?.Profile,
						allCategories,
						allSubCategories
					);
				});
				setFetching(false);
			}
		},
		[state.userId, state.coy]
	);

	const [profileDetails, setProfileDetails] = React.useState({
		Amount: "",
		PIDName: " ",
		HouseNo: " ",
		HouseStreet: " ",
		houseArea: " ",
		HouseLGA: " ",
		HouseZone: " ",
		RevenueDescription: "",
		Profile: "",
		ProfileCategory: "",
		BillType: "",
		PropertyId: " ",
		Quantity: "1",
		monthNumber: "1",
		revenue: ""
	});

	React.useEffect(() => {
		if (profileDetails.qty && currentMnth && profileDetails.Amount) {
			const calculatedCharge =
				profileDetails.Amount * profileDetails.qty * currentMnth;

			const finalCharge = calculatedCharge - discount;

			setCharge(finalCharge);
		}
	}, [profileDetailsArray, currentMnth, profileDetails.Amount, discount]);

	const invoiceModalRef = useRef();

	const handleDiscount = (value) => {
		if (parseInt(allBilledAmount) < parseInt(value)) {
			setAlert({
				showing: true,
				type: "error",
				message: "Discount cannot be higher than amount."
			});
		} else {
			setDiscount(value);
			setAlert({
				showing: false,
				type: null,
				message: ""
			});
		}
	};

	const consumptionRate = useMemo(() => {
		if (Number(currentReading) - Number(previousReading) >= 0) {
			return Number(currentReading) - Number(previousReading);
		} else {
			setAlert({
				showing: true,
				type: "error",
				message: "Current Reading must be more than previous reading"
			});
			return 0;
		}
	}, [currentReading, previousReading]);

	const allBilledAmount = useMemo(() => {
		let allAmount = profileDetailsArray.reduce(
			(accumulator, currentValue) =>
				accumulator +
				Number(currentValue.Amount) *
					Number(currentValue.Quantity) *
					Number(currentValue.monthNumber),
			0
		);
		allAmount =
			consumptionRate > 0 ? allAmount * consumptionRate : allAmount;
		const newTotalAmount =
			Number(allAmount) > 0 &&
			Number(allAmount) - Number(discount) + Number(outstand) >= 0
				? Number(allAmount) - Number(discount) + Number(outstand)
				: Number(allAmount);

		return newTotalAmount.toFixed(2);
	}, [
		profileDetailsArray,
		outstand,
		discount,
		subscription,
		consumptionRate
	]);

	const getSelectedCategories = (
		e,
		fetchedCategory,
		fetchedCategoryArray = null,
		fetchedSubCategory = null,
		index = null
	) => {
		const value = fetchedCategory ? fetchedCategory : e.target.value;
		const fetchedCat = fetchedCategoryArray || categories;
		const fetchedSubCat = fetchedSubCategory || subCategories;
		if (value) {
			const validSubCatArray = !!e?.target?.value
				? validSubCategories[profileDetailsArray[index]?.BillType]
				: fetchedSubCat;

			const currentCategoryCode = fetchedCat.filter(
				(category) => category?.Description === value
			)[0]?.Code;

			const filteredSubCategoryVal = validSubCatArray?.filter(
				(category) => category.CategoryCode === currentCategoryCode
			);

			setFilteredSubCategories(filteredSubCategoryVal);
		}
	};

	const getAmount = useCallback(() => {
		const subCatDesc = subCatData?.value;
		const currentSubCategories =
			validSubCategories[
				profileDetailsArray[subCatData?.index]?.BillType
			];

		const currentSubCategory = currentSubCategories?.filter(
			(category) => category?.Description?.trim() === subCatDesc
		)[0];

		if (currentSubCategory) {
			const { RegistrationFee, RenewalFee, FixedFee } =
				currentSubCategory;
			const allAvailableBills = { RegistrationFee, RenewalFee, FixedFee };
			const typeOfCurrentBill =
				profileDetailsArray[subCatData?.index]?.BillType;

			if (
				currentSubCategory[typeOfCurrentBill] &&
				Number(currentSubCategory[typeOfCurrentBill]) > 0
			) {
				setAlert({
					showing: false,
					type: false,
					message: ""
				});

				return subscription === "yearly"
					? currentSubCategory[typeOfCurrentBill]
					: (
							Number(currentSubCategory[typeOfCurrentBill]) / 12
						).toFixed(2);
			} else {
				currentBillType &&
					subCatData.value &&
					setAlert({
						showing: true,
						type: "error",
						message: "Tarrif doesn't exist for the sub-category"
					});
				return "";
			}
		} else {
			return 0;
		}
	}, [subCatData, currentBillType]);

	const updateAmount = (value, i) => {
		const newProfilesArray = profileDetailsArray.map((child, index) => {
			return index === Number(i) ? { ...child, Amount: value } : child;
		});
		setProfileDetailsArray(newProfilesArray);
	};

	useEffect(() => {
		updateAmount(getAmount(), subCatData?.index);
	}, [subCatData]);

	const handleProfileInArrayChange = (e, i) => {
		const value = e.target.value;
		const name = e.target.name;

		if (name === "revenue" && value !== "") {
			setRevenue(name);
		}

		const newProfilesArray = profileDetailsArray.map((child, index) => {
			return index === i ? { ...child, [name]: value } : child;
		});

		setProfileDetailsArray(newProfilesArray);
	};

	const addAnotherProfile = () => {
		setProfileDetailsArray([...profileDetailsArray, profileDetails]);
		setProfileDetails({
			...profileDetails,
			amount: "",
			PIDName: " ",
			HouseNo: " ",
			HouseStr: " ",
			houseArea: " ",
			houseLGA: " ",
			houseZone: " ",
			RevenueDescription: "",
			Profile: "",
			profilecategory: "",
			billtype: "",
			propertyid: " ",
			qty: "1",
			monthNumber: "1",
			revenue: ""
		});
	};

	function removeChild(index) {
		const newChildrenArray = [...profileDetailsArray];
		newChildrenArray.splice(index, 1);
		setProfileDetailsArray(newChildrenArray);
	}

	const getESBN = async (currentESBN) => {
		setownersesbn(currentESBN);
		if (currentESBN?.length >= 10) {
			const res = await axios
				.get(`users/anssid?anssid=${currentESBN}`)
				.then((res) => (res.data || {}).data);

			setAccountType(res.accountType);
			if (res?.accounttype === "individual") {
				setownersname(`${res.firstname} ${res.surname}`);
				setResidentialaddress(res.residentialaddress);
				setPhoneNumber(res.phonenumber);
			} else {
				setownersname(res?.name);
				setResidentialaddress(res.stateOfficeAddress);
				setPhoneNumber(res.phoneNumber);
			}
			setResidentialaddress(res.residentialaddress);
			setEmail(res.email);
		}
	};

	useEffect(() => {
		const fetchIncomeGroup = async () => {
			const res = await axios
				.get(`settings/mdacompany/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setIncomeGroup(res?.income_group);
		};
		fetchIncomeGroup();
	}, []);

	useEffect(() => {
		const fetchIncomeLines = async () => {
			const res = await axios
				.get(`settings/mdalinkedrevenue/${incomeGroup}`)
				.then((res) => (res.data || {}).data);
			setRevenues(res);
		};
		fetchIncomeLines();
	}, [incomeGroup !== ""]);

	// Area
	useEffect(() => {
		const fetchArea = async () => {
			const res = await axios
				.get(`settings/areas/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setAreas(res);
		};
		fetchArea();
	}, []);

	// Zone
	useEffect(() => {
		const fetchZones = async () => {
			const res = await axios
				.get(`settings/zones/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setZones(res);
		};
		fetchZones();
	}, []);

	// LGA
	useEffect(() => {
		const fetchLGAs = async () => {
			const res = await axios
				.get(`settings/lgass/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setLGAs(res);
		};
		fetchLGAs();
	}, []);

	// Tax Office
	useEffect(() => {
		const fetchTaxOffices = async () => {
			const res = await axios
				.get(`/settings/assesstaxoffice`)
				.then((res) => (res.data || {}).data);
			setTaxOffices(res);
		};
		fetchTaxOffices();
	}, []);

	const fetchInvoice = async (billNumber) => {
		await axios
			.get(`mdanotice/${billNumber}/${user?.mdaModuleCoy}/1`)
			.then((res) => {
				setInvoice(res?.data);
				setShowInvoice(true);
				setTimeout(() => {
					printInvoiceFile();
				}, 3000);
			});
	};

	useEffect(() => {
		if (!!invoice) {
			printInvoiceFile();
		}
	}, [invoice]);

	const printInvoiceFile = () => {
		if (!!invoice) {
			const w = window.open();
			if (w.document) {
				w.document.write(invoice);
				w.document.close();
				w.onload = () => {
					w.focus();
					w.print();
				};

				w.onafterprint = () => {
					w.close();
				};
			}
		} else {
			return;
		}
	};

	const fetchPropId = async (e, i) => {
		handleProfileInArrayChange(e, i);
		const propId = e?.target?.value;

		if (!!propId && i !== null) {
			const propty = `${propId}`.trim();
			const res = await axios
				.get(`mdapropertyprofile/${propty}/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {})?.data);

			if (res) {
				const newProfilesArray = profileDetailsArray?.map(
					(child, index) => {
						return index === Number(i)
							? {
									...child,
									PropertyId: res?.PropertyId,
									PIDName: res?.PayerName,
									HouseNo: res?.AppartmentNo,
									HouseStr: res?.HouseStreet,
									houseArea: res?.HouseArea,
									houseLGA: res?.HouseLGA,
									houseZone: ""
								}
							: child;
					}
				);
				setProfileDetailsArray(newProfilesArray);
				setMDAProperties(res);
			}
		}
	};

	const submit = async (e) => {
		e.preventDefault();
		if (revenue === "" || revenue === " ") {
			setAlert({
				showing: true,
				type: "error",
				message: "Revenue Cannot be empty"
			});
		} else {
			setAlert({
				showing: false,
				type: null,
				message: ""
			});

			setLoading(true);

			try {
				const accessToken = localStorage.getItem("access_token");
				await axios
					.put(
						`/billing/${user?.mdaModuleCoy}/${BillNumber}`,
						{
							coyid: user?.mdaModuleCoy
								? `${user?.mdaModuleCoy}`.trim()
								: "",
							billnumber: BillNumber
								? `${BillNumber}`.trim()
								: "",
							yr_mth_flg: subscription === "yearly" ? "1" : "2",
							fiscalyr: fiscalyr ? `${fiscalyr}`.trim() : "",
							fiscalmth: selectMonth
								? `${selectMonth}`.trim()
								: "   ",
							taxlga: taxlga ? `${taxlga}`.trim() : "",
							taxzone: taxzone ? `${taxzone}`.trim() : "",
							taxoffice: taxoffice ? `${taxoffice}`.trim() : "",
							owneremail: email ? `${email}`.trim() : "",
							ownersesbn: ownersesbn
								? `${ownersesbn}`.trim()
								: "",
							ownersname: !!ownersname
								? ownersname
									? `${ownersname}`.trim()
									: ""
								: "",
							ownersaddr: residentialaddress
								? `${residentialaddress}`.trim()
								: "",
							ownersaddrcity: mdaProperties?.OwnersAddrCity
								? `${mdaProperties?.OwnersAddrCity}`.trim()
								: "",
							ownersphone: phoneno ? `${phoneno}`.trim() : "",
							meteringstatus: meteringStatus.length
								? `${meteringStatus}`.trim()
								: " ",
							waterconnectstatus:
								`${waterConnectionStatus}`.trim(),
							readingdate: readingDate
								? `${readingDate}`?.trim()
								: "",
							prevreading: Number(previousReading)
								? Number(previousReading)
								: 0,
							curreading: Number(currentReading)
								? Number(currentReading)
								: 0,
							consumption: consumptionRate ? consumptionRate : 0,
							payername: !ownersesbn
								? ownersname
									? `${ownersname}`.trim()
									: ""
								: "",
							propertyaddr: mdaProperties?.PropertyAddr
								? `${mdaProperties?.PropertyAddr}`.trim()
								: "",
							propertycity: mdaProperties?.PropertyCity
								? `${mdaProperties?.PropertyCity}`.trim()
								: "",

							propertyid: "",
							ownershiptype: mdaProperties?.OwnershipType
								? `${mdaProperties?.OwnershipType}`.trim()
								: "",
							tagId: mdaProperties?.TagId
								? `${mdaProperties?.TagId}`.trim()
								: "",
							authoriser: mdaProperties?.Authoriser
								? `${mdaProperties?.Authoriser}`.trim()
								: "",
							totamt: `${
								isNaN(allBilledAmount)
									? grandTotal
									: allBilledAmount
							}`.trim(),
							discount: discount ? `${discount}`.trim() : "",
							discountreason: discountReason
								? `${discountReason}`.trim()
								: "",
							grandtot: `${
								isNaN(allBilledAmount)
									? grandTotal
									: allBilledAmount
							}`.trim(),
							outstand: outstand ? `${outstand}`.trim() : "",
							outstandingreason: outstandingReason
								? `${outstandingReason}`.trim()
								: "",
							billDetails: profileDetailsArray?.map((detail) => ({
								amount: `${detail?.Amount}`,
								PIDname: detail?.PIDName,
								houseNo: detail?.HouseNo,
								HouseStr: detail?.HouseStreet,
								houseArea: detail?.houseArea,
								houseLGA: detail?.HouseLGA,
								houseZone: detail?.HouseZone,
								revenuedescription: detail?.RevenueDescription,
								billtype: detail?.BillType,
								propertyid: detail?.PropertyId,
								qty: detail?.Quantity,
								month: detail?.monthNumber,
								revenue: detail?.revenue,
								coy: user?.mdaModuleCoy
									? `${user?.mdaModuleCoy}`.trim()
									: "",
								profile: `${detail.Profile}`,
								profilecategory:
									`${detail.ProfileCategory}`.trim(),
								deactivated: false
							})),
							// paymtstatus: "Pending",
							// procstatus: "Approved",
							inputter: user?.email
								? `${user?.email}`.trim()
								: "",
							deactivated: false
						},
						{
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						}
					)
					.then((res) => {
						setAlert({
							showing: true,
							type: "success",
							message: "Bill generated successfully"
						});
						fetchInvoice(`${BillNumber}`);
						return res?.data;
					});

				setBillNumber("");
				setownersesbn("");
				setownersname("");
				setResidentialaddress("");
				setDiscount("");
				setDiscountReason("");
				setOutstandingReason("");
				setownersaddrcity("");
				setPhoneNumber("");
				setEmail("");
				setowneremail("");
				setFiscalMonth("");
				setFiscalYear("");
				setpayername("");
				setpropertyaddr("");
				setpropertycity("");
				setownershiptype("");
				setappartmentno("");
				settagId("");
				setprofilecategory("");
				setprofile("");
				setauthoriser("");
				setbilltype("");
				setRegistrationFee("");
				setRenewalFee("");
				setOutstandingReason("");
				setRevenue("");
				setTaxZone("");
				setTaxOffice("");
				setTaxLGA("");
				setIncomeGroup("");
				setFilteredSubCategories("");
				setSubCatData("");
				setCurrentBillType("");
				setSelectedMonth("");
				setProfileDetailsArray([
					{
						amount: "",
						PIDName: " ",
						HouseNo: " ",
						HouseStr: " ",
						houseArea: " ",
						houseLGA: " ",
						houseZone: " ",
						revenuedescription: "",
						profile: "",
						profilecategory: "",
						billtype: "",
						propertyid: " ",
						qty: "1",
						month: "1",
						revenue: ""
					}
				]);
				setProfileDetails({
					amount: "",
					PIDName: " ",
					HouseNo: " ",
					HouseStr: " ",
					houseArea: " ",
					houseLGA: " ",
					houseZone: " ",
					revenuedescription: "",
					profile: "",
					profilecategory: "",
					billtype: "",
					propertyid: " ",
					qty: "1",
					month: "1",
					revenue: ""
				});
			} catch (err) {
				console.error(err);
				setAlert({
					showing: true,
					type: "error",
					message: "Bill generation not successful."
				});
			} finally {
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section>
			<GoBackButton />
			<form className={styles3.columned__form} onSubmit={submit}>
				{alert.showing && (
					<Alert text={alert.message} type={alert.type} />
				)}

				{user?.mdaModuleCoy === "04" && (
					<>
						<div
							className={styles2.radio__button_div}
							style={{ marginTop: "20px" }}
						>
							<span className={styles1.input__label}>
								Water connection Status
							</span>
							<div>
								<div>
									<input
										id="connected"
										type="radio"
										value="Connected"
										checked={
											waterConnectionStatus ===
											"Connected"
										}
										onChange={(e) => {
											setWaterConnectionStatus(
												e.target.value
											);
										}}
									/>
									<label
										className={styles3.input__label}
										htmlFor="connected"
									>
										Connected
									</label>
								</div>
								<div>
									<input
										id="notConnected"
										type="radio"
										value="Not connected"
										checked={
											waterConnectionStatus ===
											"Not connected"
										}
										onChange={(e) => {
											setWaterConnectionStatus(
												e.target.value
											);
										}}
									/>
									<label
										className={styles3.input__label}
										htmlFor="notConnected"
									>
										Not connected
									</label>
								</div>
							</div>
						</div>

						<div className={styles.select__wrapper}>
							<div>
								<label htmlFor="meteringStatus">
									Metering Status
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="meteringStatus"
									className={styles.input__medium}
									name="meteringStatus"
									onChange={(e) => {
										setMeteringStatus(e.target.value);
									}}
									value={meteringStatus}
									required
								>
									<option value="">
										--Select Meter Status--
									</option>
									<option value={"Not Metered"}>
										Not Metered
									</option>
									<option
										value={
											"Metered with Prepaid smart meter"
										}
									>
										Metered with Prepaid smart meter
									</option>
									<option
										value={"Metered with analog/bulk meter"}
									>
										Metered with analog/bulk meter
									</option>
								</select>
							</div>
						</div>

						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="currentReading"
								>
									Current Reading
								</label>
								<input
									type="number"
									name="currentReading"
									disabled={meteringStatus === "Not Metered"}
									min={0}
									onChange={(e) =>
										setCurrentReading(() =>
											Number(e.target.value) >= 0
												? e.target.value
												: 0
										)
									}
									id="currentReading"
									placeholder="Current reading"
									value={currentReading}
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="previousReading"
								>
									Previous Reading
								</label>
								<input
									type="number"
									name="previousReading"
									disabled={meteringStatus === "Not Metered"}
									min={0}
									onChange={(e) =>
										setPreviousReading((prevVal) =>
											currentReading >=
												Number(e.target.value) &&
											Number(e.target.value) >= 0
												? e.target.value
												: prevVal
										)
									}
									id="previousReading"
									placeholder="Previous reading"
									value={previousReading}
								/>
							</div>
						</div>

						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="consumption"
								>
									Consumption
								</label>
								<input
									type="number"
									name="consumption"
									id="consumption"
									placeholder="Consumption"
									value={consumptionRate}
									disabled
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="readingDate"
								>
									Current Reading Date
								</label>
								<input
									type="date"
									name="readingDate"
									id="readingDate"
									onChange={(e) =>
										setReadingDate(e.target.value)
									}
									value={readingDate}
									disabled={meteringStatus === "Not Metered"}
								/>
							</div>
						</div>
					</>
				)}
				<hr className={styles.divider} />

				<div
					className={styles2.radio__button_div}
					style={{ marginTop: "20px" }}
				>
					<span className={styles1.input__label}>
						Bill is Scheduled for
					</span>
					<div>
						<div>
							<input
								id="yearlySchedule"
								type="radio"
								value="yearly"
								checked={subscription === "yearly"}
								onChange={(e) => {
									setSubcription(e.target.value);
								}}
							/>
							<label
								className={styles3.input__label}
								htmlFor="yearlySchedule"
							>
								Yearly
							</label>
						</div>
						<div>
							<input
								id="monthlySchedule"
								type="radio"
								value="monthly"
								checked={subscription === "monthly"}
								onChange={(e) => {
									setSubcription(e.target.value);
								}}
							/>
							<label
								className={styles3.input__label}
								htmlFor="monthlySchedule"
							>
								Monthly
							</label>
						</div>
					</div>
				</div>

				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="selectyear"
						>
							Bill Year (Fiscal Year)
						</label>
						<select
							id="selectYear"
							value={fiscalyr ?? ""}
							onChange={(e) => setFiscalYear(e.target.value)}
							required
						>
							{fetching ? (
								<option value="">Loading...</option>
							) : (
								<option value="">Select</option>
							)}
							{/* <option>2013</option> */}
							{new Array((new Date().getFullYear() + 1) - 2013)
								.fill(0)
								.map((_, i) => (
									<React.Fragment
										key={`year-${2012 + i + 1}`}
									>
										<option key={i}>{2012 + i + 1}</option>
									</React.Fragment>
								))}
						</select>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="selectmonth"
						>
							Bill Month (Fiscal Month)
						</label>
						<select
							id="selectmonth"
							value={selectMonth}
							onChange={(e) => {
								setSelectedMonth(e.target.value);
							}}
							disabled={subscription === "yearly"}
						>
							{fetching ? (
								<option value="">Loading...</option>
							) : (
								[...Array(12)].map((_, index) => (
									<React.Fragment key={`month-${index}`}>
										<option
											key={index}
											value={new Intl.DateTimeFormat(
												"en",
												{
													month: "long"
												}
											).format(new Date(2000, index, 1))}
										>
											{new Intl.DateTimeFormat("en", {
												month: "long"
											}).format(new Date(2000, index, 1))}
										</option>
									</React.Fragment>
								))
							)}
						</select>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="taxlga">
							Bill from which Local Government Area(LGA)
						</label>
						<select
							id="taxlga"
							name="taxlga"
							onChange={(e) => {
								setTaxLGA(e.target.value);
							}}
							value={taxlga}
							required
						>
							{fetching ? (
								<option value="">Loading...</option>
							) : (
								<option value="">--Select Billing LGA--</option>
							)}
							{lgas.map((lgatax, index) => (
								<React.Fragment
									key={`lga-tax-${
										lgatax.Description + index
									}`}
								>
									<option
										value={lgatax.Description}
										zonecode={lgatax.Code}
										index={index}
									>
										{lgatax.Description}
									</option>
								</React.Fragment>
							))}
						</select>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="taxzone"
						>
							Bill from which Zone?
						</label>
						<select
							id="taxzone"
							name="taxzone"
							onChange={(e) => {
								setTaxZone(e.target.value);
							}}
							value={taxzone}
							required
						>
							{fetching ? (
								<option value="">Loading...</option>
							) : (
								<option value="">
									--Select Billing Zone--
								</option>
							)}
							{zones?.map((tzone, index) => (
								<React.Fragment
									key={`tax-zone-${
										tzone?.Description + index
									}`}
								>
									<option
										value={tzone.Description}
										zonecode={tzone.Code}
										index={index}
									>
										{tzone.Description}
									</option>
								</React.Fragment>
							))}
						</select>
					</div>
				</div>
				<hr className={styles.divider} />
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="ownersesbn"
						>
							ESBN
						</label>
						<input
							type="text"
							onChange={(e) => getESBN(e.target.value)}
							id="ownersesbn"
							placeholder=""
							value={fetching ? "Loading..." : ownersesbn ?? ""}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="ownersname"
						>
							Name <span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="text"
							onChange={(e) => setownersname(e.target.value)}
							id="ownersname"
							placeholder=""
							required
							value={fetching ? "Loading..." : ownersname ?? ""}
						/>
					</div>
				</div>
				<div className={styles.mda__double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="residentialaddress"
						>
							Address
						</label>
						<input
							type="text"
							onChange={(e) =>
								setResidentialaddress(e.target.value)
							}
							id="residentialaddress"
							placeholder=""
							value={
								fetching
									? "Loading..."
									: residentialaddress ?? ""
							}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="phonenumber"
						>
							Phone
						</label>
						<input
							type="text"
							onChange={(e) => setPhoneNumber(e.target.value)}
							id="phonenumber"
							placeholder=""
							value={fetching ? "Loading..." : phoneno ?? ""}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="email">
							Email
						</label>
						<input
							type="text"
							onChange={(e) => setEmail(e.target.value)}
							id="email"
							placeholder=""
							value={fetching ? "Loading..." : email ?? ""}
						/>
					</div>
				</div>

				{/* Profile ID details */}
				{profileDetailsArray.map((child, index) => (
					<React.Fragment key={`profileDetails-${index}`}>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="PropertyId"
								>
									Property ID
								</label>
								<input
									type="text"
									name="PropertyId"
									onChange={(e) => {
										// handleProfileInArrayChange(e, index);
										fetchPropId(e, index);
									}}
									id="PropertyId"
									placeholder=""
									value={
										fetching
											? "Loading..."
											: child?.PropertyId ?? ""
									}
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="PIDName"
								>
									Occupant(s) / Owner's Name
								</label>
								<input
									type="text"
									name="PIDName"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									id="PIDName"
									placeholder=""
									value={
										fetching
											? "Loading..."
											: child?.PIDName ?? ""
									}
								/>
							</div>
						</div>
						{/* <h3>Address</h3> */}
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="HouseNo"
								>
									No
								</label>
								<input
									type="text"
									name="HouseNo"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									id="HouseNo"
									placeholder=""
									//  disabled
									value={
										fetching
											? "Loading..."
											: child?.HouseNo ?? ""
									}
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="HouseStreet"
								>
									Street
								</label>
								<input
									type="text"
									name="HouseStreet"
									onChange={(e) =>
										handleProfileInArrayChange(e, index)
									}
									id="HouseStreet"
									placeholder=""
									//  disabled
									value={
										fetching
											? "Loading..."
											: child?.HouseStreet ?? ""
									}
								/>
							</div>
						</div>

						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="HouseLGA"
								>
									LGA
								</label>
								<select
									id="HouseLGA"
									className={styles.input__long}
									name="HouseLGA"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									value={child?.HouseLGA ?? ""}
								>
									{fetching ? (
										<option value="">Loading...</option>
									) : (
										<option value="">--Select LGA--</option>
									)}
									{lgas?.map((lga, index) => (
										<React.Fragment
											key={`lga-${lga.Description}-${index}`}
										>
											<option value={lga.Description}>
												{lga.Description}
											</option>
										</React.Fragment>
									))}
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="HouseZone"
								>
									Zone
								</label>
								<select
									id="HouseZone"
									className={styles.input__long}
									name="HouseZone"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									value={child?.HouseZone ?? ""}
								>
									{fetching ? (
										<option value="">Loading...</option>
									) : (
										<option value="">
											--Select Zone--
										</option>
									)}
									{zones?.map((zone, index) => (
										<React.Fragment
											key={`zone-${zone.Description}-${index}`}
										>
											<option value={zone.Description}>
												{zone.Description}
											</option>
										</React.Fragment>
									))}
								</select>
							</div>
						</div>

						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="RevenueDescription"
								>
									Further Description{" "}
								</label>
								<input
									type="text"
									name="RevenueDescription"
									onChange={(e) =>
										handleProfileInArrayChange(e, index)
									}
									id="RevenueDescription"
									placeholder=""
									//  disabled
									value={
										fetching
											? "Loading..."
											: child?.RevenueDescription ?? ""
									}
								/>
							</div>
						</div>

						<hr className={styles.divider} />

						<div className={styles.select__wrapper}>
							<div>
								<label htmlFor="BillType">
									Bill Type
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="BillType"
									className={styles.input__medium}
									name="BillType"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
										setCurrentBillType(e.target.value);
									}}
									value={child?.BillType ?? ""}
									required
								>
									{fetching ? (
										<option value="">Loading...</option>
									) : (
										<option value="">
											--Select BillType--
										</option>
									)}
									<option value={"RenewalFee"}>
										Renewal
									</option>
									<option value={"RegistrationFee"}>
										Registration
									</option>
									<option value={"FixedFee"}>
										Fixed Amount
									</option>
								</select>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="Profile">
									Category{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="Profile"
									className={styles.input__medium}
									name="Profile"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
										getSelectedCategories(
											e,
											null,
											null,
											null,
											index
										);
									}}
									value={child?.Profile ?? ""}
									required
								>
									{fetching ? (
										<option value="">Loading...</option>
									) : (
										<option value="">
											--Select Category--
										</option>
									)}
									{categories.length &&
										categories?.map((category, index) => (
											<React.Fragment
												key={`category-${category?.Coy}-${index}`}
											>
												<option
													value={`${category?.Description}`}
												>
													{category?.Description}
												</option>
											</React.Fragment>
										))}
								</select>
							</div>
							<div>
								<label htmlFor="ProfileCategory">
									Sub-Category{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="ProfileCategory"
									className={styles.input__medium}
									name="ProfileCategory"
									onChange={(e) => {
										setSubCatData({
											value: e.target.value,
											index
										});
										handleProfileInArrayChange(e, index);
									}}
									value={child?.ProfileCategory?.trim() ?? ""}
									required
								>
									{fetching ? (
										<option value="">Loading...</option>
									) : (
										<option value="">
											--Select Sub-Category--
										</option>
									)}
									{filterSubCategories.length &&
										filterSubCategories?.map(
											(sub, index) => (
												<React.Fragment
													key={`sub-category-${sub?.id}-${index}`}
												>
													<option
														value={sub?.Description?.trim()}
													>
														{sub?.Description?.trim()}
													</option>
												</React.Fragment>
											)
										)}
								</select>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Quantity"
								>
									How Many Units / Number of Occupants
								</label>
								<input
									type={fetching ? "text" : "number"}
									name="Quantity"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									id="Quantity"
									placeholder=""
									value={
										fetching
											? "Loading..."
											: child?.Quantity ?? ""
									}
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="monthNumber"
								>
									How Many Months
								</label>
								<input
									type={fetching ? "text" : "number"}
									name="monthNumber"
									disabled={subscription === "yearly"}
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									id="monthNumber"
									placeholder="How many months?"
									value={
										fetching
											? "Loading..."
											: child?.monthNumber ?? ""
									}
								/>
							</div>
						</div>

						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Amount"
								>
									{subscription === "yearly"
										? "Yearly Tarrif"
										: "Monthly Tarrif"}{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									type="text"
									name="Amount"
									onChange={(e) =>
										handleProfileInArrayChange(e, index)
									}
									id="Amount"
									required
									value={
										fetching
											? "Loading..."
											: child?.Amount ?? ""
									}
								/>
							</div>
							<div>
								<label htmlFor="revenue">
									Which Revenue is this bill Attached to?{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="revenue"
									className={styles.input__medium}
									name="revenue"
									required
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									value={child?.revenue ?? ""}
								>
									{fetching ? (
										<option value="">Loading...</option>
									) : (
										<option value="">
											--Select Revenue--
										</option>
									)}
									{revenues?.length &&
										revenues?.map((rev, index) => (
											<React.Fragment
												key={`revenue-${rev?.id}-${index}`}
											>
												<option value={`${rev?.Code}`}>
													{rev?.Description}
												</option>
											</React.Fragment>
										))}
								</select>
							</div>
						</div>

						{profileDetailsArray.length > 1 && (
							<button
								type="button"
								className={styles.add__another__child__btn}
								style={{
									color: "#F0372E",
									textAlign: "left",
									padding: 0,
									marginBottom: 10,
									marginTop: 10
								}}
								onClick={() => {
									removeChild(index);
								}}
							>
								- Remove Property {index + 1}
							</button>
						)}
						<hr className={styles.divider} />
					</React.Fragment>
				))}

				{/* <hr className={styles.divider} /> */}
				<button
					type="button"
					className={styles.add__another__child__btn}
					onClick={addAnotherProfile}
				>
					+ Add another property
				</button>
				<hr className={styles.divider} />

				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="discount"
						>
							Discount
						</label>
						<input
							type="text"
							onChange={(e) => handleDiscount(e.target.value)}
							id="discount"
							placeholder=""
							value={fetching ? "Loading..." : discount ?? ""}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="discountReason"
						>
							Reason for Discount
						</label>
						<input
							type="text"
							onChange={(e) => setDiscountReason(e.target.value)}
							id="discountReason"
							placeholder=""
							value={
								fetching ? "Loading..." : discountReason ?? ""
							}
						/>
					</div>
				</div>
				{/* femi ===> outstanding */}
				<div className={styles.mda__double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="outstanding"
						>
							Outstanding
						</label>
						<input
							type="text"
							onChange={(e) => setOutStanding(e.target.value)}
							id="outstanding"
							placeholder=""
							value={fetching ? "Loading..." : outstand ?? ""}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="outstandingReason"
						>
							Reason for Outstanding
						</label>
						<input
							type="text"
							onChange={(e) =>
								setOutstandingReason(e.target.value)
							}
							id="outstandingReason"
							placeholder=""
							value={
								fetching
									? "Loading..."
									: outstandingReason ?? ""
							}
						/>
					</div>
				</div>
				{/* femi ===> outstanding */}
				<hr className={styles.divider} />

				<p>
					<i>
						Monthly bill is reflected by dividing yearly tarriff by
						12 (months)
					</i>
				</p>
				<div className={styles.mda__double__inputs}>
					<h4 style={{ fontWeight: "bold" }}>
						Total Amount Billed:{" "}
					</h4>
					&nbsp;
					<h4 style={{ fontWeight: 300 }}>
						&#8358;
						{isNaN(allBilledAmount) ? grandTotal : allBilledAmount}
					</h4>
				</div>
				<div className={styles.mda__double__inputs}>
					<h4 style={{ fontWeight: "bold" }}>Total Amount: </h4>&nbsp;
					<h4 style={{ fontWeight: 300 }}>
						&#8358;
						{isNaN(allBilledAmount) ? grandTotal : allBilledAmount}
					</h4>
				</div>

				<hr className={styles.divider} />
				{/* total and stuffs */}
				<footer className={styles.form__footer}>
					<button
						style={{
							padding: "0 25px"
						}}
						disabled={loading}
						type="submit"
						className={[
							"primary__btn",
							styles.primary__button,
							loading ? styles.loading__button : ""
						].join(" ")}
					>
						Update bill
						{loading && <Spinner />}
					</button>
				</footer>
			</form>
			{!!invoice && <Receipt data={invoice} loading={loading} />}
		</section>
	);
};

export default EditBill;

const htmlInvoice = (htmlString) => {
	return { __html: htmlString };
};
