import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Alert from "../../components/Alert";
import AddIcon from "../../assets/UserManagement/add.svg";
import styles from "../user/style.module.css";
const coy_ANSSID = process.env.REACT_APP_ANSSID

const User = () => {
	// const filterRef = createRef();

	const [agents, setAgents] = useState(null);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const [name] = useState("");
	const [email] = useState("");
	const [phone] = useState("");

	const accessToken = localStorage.getItem("access_token");

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	async function fetchAnssidAgent(e) {
		e?.preventDefault();

		setLoading(true);

		let query = "";
		if (name) {
			query += `name=${name}&`;
		}
		if (email) {
			query += `email=${email}`;
		}
		if (phone) {
			query += `phone=${phone.replace(/^(0|((\+)*234))/, "234")}`;
		}

		try {
			const request = await axios.get(
				`/admin/usermanagement/getusersbyrole/anssid-agent?${query}`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);
			setLoading(false);

			console.log(request.data.data);
			setAgents(request.data?.data?.Users);
		} catch (error) {
			setLoading(false);
			if (error.response) {
				console.log("response", error.response.data.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.message
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		}
	}

	useEffect(() => {
		fetchAnssidAgent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const deactivate = async (id, status, index) => {
		try {
			await axios.put(
				`/admin/usermanagement/${
					status === true ? "deactivateaccount" : "activateaccount"
				}/${id}`
			);

			agents[index].isActive = !status;

			setAgents([...agents]);

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: `Agent ${
					status === true ? "Deactivated" : "activated"
				}`
			});
		} catch (error) {
			if (error.response) {
				console.log("response", error.response.data.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.message
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		}
	};

	return (
		<main className={styles.wrapper}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<header className={styles.header}>
				<div className={styles.top}>
					<p className={styles.heading__text}>{coy_ANSSID} Agent</p>
					{/* <div className={styles.filter}>
					<button
						onClick={() => { filterRef.current.classList.toggle(styles.no__display) }}
						className={styles.filter__button}>Filter</button>
					<form ref={filterRef} className={[styles.filter__form, styles.no__display].join(" ")} onSubmit={fetchAnssidAgent}>
						<div className={styles.form__control}>
							<label htmlFor="name">Name</label>
							<input type="text" id="name" placeholder="John" onChange={e => setName(e.target.value)} />
						</div>
						<div className={styles.form__control}>
							<label htmlFor="code">Email</label>
							<input type="email" id="code" placeholder="email@test.com" onChange={e => setEmail(e.target.value)} />
						</div>
						<div className={styles.form__control}>
							<label htmlFor="revenue">Phone Number</label>
							<input type="text" id="revenue" placeholder="+2348012345678" onChange={e => setPhone(e.target.value)} />
						</div>
						<footer>
							<button className={styles.submit__button} type="submit">Search</button>
						</footer>
					</form>
				</div> */}
					<span className={styles.drop__arrow}></span>
				</div>
				<div className={styles.bottom}>
					<p className={styles.heading__text}>
						{agents?.length}{" "}
						{loading
							? ""
							: agents?.length === 1
							? "Agent"
							: "Agents"}
					</p>
					<NavLink to="/create-user" className={styles.nav__link}>
						<img src={AddIcon} alt="add icon" />
						Create Agent
					</NavLink>
				</div>
			</header>
			<section>
				<table className={styles.table}>
					{loading && (
						<tbody>
							<tr>
								<td>
									<p style={{ textAlign: "center" }}>
										Loading...
									</p>
								</td>
							</tr>
						</tbody>
					)}
					{!agents?.length && !loading ? (
						<tbody>
							<tr>
								<td>
									<p style={{ textAlign: "center" }}>
										No Agents.
									</p>
								</td>
							</tr>
						</tbody>
					) : (
						<tbody>
							{agents?.map((agent, index) => (
								<tr key={index}>
									<td>
										<p>
											{agent.firstname} {agent.surname}
										</p>
										<p>{agent.email}</p>
									</td>
									<td>
										<button
											className={styles.deactive__btn}
											onClick={() =>
												deactivate(
													agent.id,
													agent.isActive,
													index
												)
											}
										>
											{agent.isActive
												? "Deactivate"
												: "Activate"}
										</button>
										<button className={styles.delete__btn}>
											Delete
										</button>
									</td>
								</tr>
							))}
						</tbody>
					)}
				</table>
			</section>
		</main>
	);
};

export default User;
