/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";

import Spinner from "../../components/spinner";
import Alert from "../../components/Alert";
import styles from "../../Identity-management-individual/style.module.css";
import style from "../user/style.module.css";

const CreateClaim = () => {
	const [values, setValues] = useState({
		name: "",
		route: "",
		method: ""
	});
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const accessToken = localStorage.access_token;
	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;

		setValues({
			...values,
			[name]: value
		});
	};

	const submit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			await axios.post(
				`/auth/claim`,
				{
					...values
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);
			setLoading(false);

			setValues({
				name: "",
				route: "",
				method: ""
			});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: "Claim Created!"
			});
		} catch (error) {
			setLoading(false);
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.[0] ||
						error.response.data?.message
				});
			} else {
				console.log("Error", error.message);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	return (
		<main className={style.wrapper}>
			<form className={styles.columned__form} onSubmit={submit}>
				{alert.showing && (
					<Alert text={alert.message} type={alert.type} />
				)}
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="name">
							Name <span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="text"
							onChange={handleChange}
							id="name"
							name="name"
							placeholder=""
							required
							value={values.name}
						/>
					</div>
					<div>
						<label className={styles.input__label} htmlFor="route">
							Routes <span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="text"
							onChange={handleChange}
							id="route"
							name="route"
							placeholder=""
							required
							value={values.route}
						/>
					</div>
					<div>
						<label className={styles.input__label} htmlFor="method">
							Method <span style={{ color: "red" }}>*</span>
						</label>
						<select
							name="method"
							id="method"
							className={styles.select__input}
							required
							value={values.method}
							onChange={handleChange}
						>
							<option value="">--Select HTTP Method--</option>
							<option>GET</option>
							<option>POST</option>
							<option>PUT</option>
							<option>DELETE</option>
						</select>
					</div>
				</div>
				<footer className={styles.form__footer}>
					<button
						disabled={
							loading ||
							!values.name ||
							!values.route ||
							!values.method
						}
						type="submit"
						className={[
							"primary__btn",
							styles.primary__button,
							loading ? styles.loading__button : ""
						].join(" ")}
					>
						Save
						{loading && <Spinner />}
					</button>
				</footer>
			</form>
		</main>
	);
};

export default CreateClaim;
