import { useState, useEffect, createRef } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import Modal from "../../components/Modal/regular";
import downloadIcon from "../../assets/whiteDownloadIcon.svg";
import styles from "./style.module.css";
import styles1 from "../../Dashboard/MDAs/style.module.css";

const ManualDirectAssessmentCertificate = () => {
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [ESBN, setESBN] = useState("");
	const [name, setName] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [viewModalShowing, setViewModalShowing] = useState(false);
	const [reviewedCertDetails, setReviewedCertDetails] = useState(null);
	const [approvalCertDetails, setApprovalCertDetails] = useState(null);
	const [loadingCert, setLoadingCert] = useState(false);
	const [certificate, setCertificate] = useState(null);
	const [sendingForApproval, setSendingForApproval] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [sendingToMail, setSendingToMail] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const filterRef = createRef();

	const headers = [
		["ESBN", "ESBN"],
		["Name", "TaxPayerName"],
		["Address", "TaxPayerAddr"],
		["Year", "CertCurYear"],
		["Certificate Type", "Type_of_Cert"],
		["Amount Paid", "Total_Amt_Paid"],
		["Generated By", "Inputter"],
		["Request Date", "InputterTime", (data) => data?.split(" ")[0]],
		["Status", "ApproveFlg", getStatusText],
		[
			"Denial Reason",
			"RejectReason",
			(data) => (!data?.trim() ? null : data)
		],
		[
			"Approved By",
			"Approval1_Name1",
			(data) => (!data?.trim() ? null : data)
		],
		[
			"Approval Date",
			"AuthoriserTime",
			(data) => (data ? data?.split(" ")[0] : null)
		]
	];

	function getStatusText(approveRejectFlag) {
		if (approveRejectFlag === "0") {
			return "Pending";
		} else if (approveRejectFlag === "3") {
			return "Approved";
		} else {
			return "Denied";
		}
	}

	const getCertificates =
		({ currentPage = 1, pageSize = 20, download = false } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (!filterRef.current?.classList.contains(styles1.no__display)) {
				filterRef.current?.classList.toggle(styles1.no__display);
			}

			let query = `page=${currentPage}&pageSize=${pageSize}&`;

			if (ESBN) {
				query += `esbn=${ESBN}&`;
			}

			if (startDate) {
				query += `startdate=${startDate}&`;
			}

			if (endDate) {
				query += `enddate=${endDate}&`;
			}

			if (name) {
				query += `Name=${name}&`;
			}

			try {
				let { data, total } = await axios
					.get(`/getallmanualcerts?${query}`)
					.then((res) => res.data.data);
				setData(data);
				setTotal(total);

				return data;
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setLoading(false);
			}
		};

	const viewCertificate = async (ESBN, year, viewMode) => {
		if (viewMode === 1) {
			setLoadingCert(true);
			setViewModalShowing(true);
		} else if (viewMode === 2) {
			setDownloading(true);
		} else {
			setSendingToMail(true);
		}

		try {
			const data = await axios
				.get(`getcerts/${ESBN}/${year}/${viewMode}`, {
					responseType: viewMode === 2 ? "blob" : "json"
				})
				.then((res) => res.data);

			if (viewMode === 1) {
				setCertificate(data.html);
				setApprovalCertDetails(data.data);
			} else if (viewMode === 2) {
				const blobURL = URL.createObjectURL(data);

				const element = document.createElement("a");

				element.href = blobURL;
				element.download = `Tax Clearance Certificate ${year}.pdf`;
				document.body.appendChild(element);
				element.click();

				document.body.removeChild(element);

				URL.revokeObjectURL(blobURL);
			} else {
				setViewModalShowing(false);
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: data.msg
				});
			}
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				console.error(error.message);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setLoadingCert(false);
			setDownloading(false);
			setSendingToMail(false);
		}
	};

	const requestForApproval = async () => {
		setSendingForApproval(true);

		try {
			const res = await axios.post(`/manualcert4approval`, {
				inputter: userInfo?.email,
				...approvalCertDetails
			});

			setViewModalShowing(false);
			setAlert((alert) => ({
				...alert,
				showing: true,
				type: "success",
				message: res.data.msg
			}));
			setApprovalCertDetails(null);
		} catch (error) {
			if (error.response) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message ||
						error.response?.data.err.message
				}));
			} else if (error.request) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.request
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		} finally {
			setSendingForApproval(false);
		}
	};

	const printCertificate = () => {
		const w = window.open();
		w.document.write(certificate);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		w.onafterprint = () => {
			w.close();
		};
	};

	useEffect(() => {
		getCertificates()();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clearFilters = () => {
		setESBN("");
		setName("");
		setStartDate("");
		setEndDate("");

		filterRef.current.classList.toggle(styles1.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getCertificates()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{viewModalShowing && (
				<Modal toggle={setViewModalShowing} showing={viewModalShowing}>
					{loadingCert ? (
						<p style={{ textAlign: "center" }}>Loading...</p>
					) : (
						<>
							<div className={styles.cert__btns}>
								{reviewedCertDetails.status === "3" && (
									<>
										<button
											className={`tertiary__btn ${styles.download__btn}`}
											style={{ marginBottom: "20px" }}
											onClick={() => printCertificate()}
										>
											Print
										</button>
										<button
											className={`secondary__btn ${styles.download__btn}`}
											style={{ marginBottom: "20px" }}
											onClick={() =>
												viewCertificate(
													reviewedCertDetails.ESBN,
													reviewedCertDetails.startYear,
													2
												)
											}
										>
											<img
												src={downloadIcon}
												alt="download icon"
												style={{ marginRight: "8px" }}
											/>
											{downloading
												? "Downloading..."
												: "Download"}
										</button>
										<button
											className={styles.to__mail_btn}
											style={{ marginBottom: "20px" }}
											onClick={() =>
												viewCertificate(
													reviewedCertDetails.ESBN,
													reviewedCertDetails.startYear,
													3
												)
											}
										>
											{sendingToMail
												? "Sending..."
												: "Send to Email"}
										</button>
									</>
								)}
								{reviewedCertDetails.status === "0" && (
									<button
										className={`primary__btn ${styles.request__btn}`}
										onClick={() => requestForApproval()}
									>
										{sendingForApproval
											? "Requesting..."
											: "Request for Approval"}
									</button>
								)}
							</div>
							<div
								dangerouslySetInnerHTML={{
									__html: certificate
								}}
							></div>
						</>
					)}
				</Modal>
			)}
			<div className={styles1.actions__div}>
				<button
					className="primary__btn"
					onClick={() => {
						history.push(
							"/certificate/generate-da-manual-entry-certificate"
						);
					}}
					style={{ padding: "0 16px" }}
				>
					Generate Manual Certificate
				</button>
				<div className={styles1.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles1.no__display
							);
						}}
						className={styles1.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles1.filter__div,
							styles1.no__display
						].join(" ")}
					>
						<div className={styles1.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles1.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles1.clear__filter_fields}
							onClick={clearFilters}
							disabled={!ESBN && !startDate && !endDate && !name}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="ESBN">ESBN</label>
								<input
									name="ESBN"
									id="ESBN"
									type="text"
									value={ESBN}
									onChange={(e) => setESBN(e.target.value)}
									placeholder="Enter ESBN"
								/>
							</div>
						</form>
						<p>By Taxpayer</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="name">Name</label>
								<input
									name="name"
									id="name"
									type="text"
									value={name}
									onChange={(e) => setName(e.target.value)}
									placeholder="Eg Ada Agu"
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								styles1.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getCertificates()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			{loading && !data ? (
				<p style={{ textAlign: "center" }}>Loading...</p>
			) : (
				<div className={styles.table__wrapper}>
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getCertificates}
						onView={(data) => {
							viewCertificate(data.ESBN, data.CertCurYear, 1);
							setReviewedCertDetails({
								ESBN: data.ESBN,
								startYear: data.CertCurYear,
								status: data.ApproveFlg
							});
						}}
					/>
				</div>
			)}
		</section>
	);
};

export default ManualDirectAssessmentCertificate;
