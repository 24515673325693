import { useEffect } from "react";
import style from "./style.module.css";

const useIntersectionObserver = () => {
	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add(style.inView);
				} else {
					entry.target.classList.remove(style.inView);
				}
			});
		});

		const allAnimatedElements = document.querySelectorAll(
			`.${style.animateHomePage}`
		);
		allAnimatedElements.forEach((element) => observer.observe(element));

		// Clean up the observer on component unmount
		return () => {
			allAnimatedElements.forEach((element) =>
				observer.unobserve(element)
			);
		};
	}, []);
};

export default useIntersectionObserver;
