/* eslint-disable no-unused-vars */
import React, { createRef } from "react";
import axios from "axios";
import { Line, Bar, Pie } from "react-chartjs-2";
import Alert from "../../components/Alert";
import { formatAmount } from "../../utils";
import styles from "./style.module.css";
const coy_ANSSID = process.env.REACT_APP_ANSSID;

const accessToken = localStorage.getItem("access_token");

const getCurrentWeek = (datestring) => {
	//define a date object variable that will take the current system date
	let todaydate = new Date(datestring);
	//find the year of the current date
	var oneJan = new Date(todaydate.getFullYear(), 0, 1);

	// calculating number of days in given year before a given date
	var numberOfDays = Math.floor((todaydate - oneJan) / (24 * 60 * 60 * 1000));

	let result = numberOfDays / 7;
	if (result.toString().includes(".")) {
		let split = result.toString().split(".");

		if (parseInt(split[1][0]) > 1) result = Math.ceil(result);
		else result = Math.round(result);
	}

	return result;
};

function generateWeeks() {
	const weeks = [];

	for (let i = 1; i <= getCurrentWeek(new Date()) + 1; i++) {
		weeks.push(`Week ${i}`);
	}

	return weeks;
}

const getMax = (arr = []) => {
	const max = Math.max.apply(Math, arr);
	if (isNaN(max) || !Number.isFinite(max)) return 0;

	return formatAmount(max);
};

const getMin = (arr = []) => {
	const min = Math.min.apply(Math, arr);
	if (isNaN(min) || !Number.isFinite(min)) return 0;

	return formatAmount(min);
};

const getSum = (arr = []) => {
	const sum = arr.reduce((a, b) => a + b, 0);
	if (isNaN(sum) || !Number.isFinite(sum)) return 0;

	return formatAmount(sum);
};

const getTop = (arr = {}, max) => {
	for (const key in arr || {}) {
		if (formatAmount(arr[key].top.Amount) === max) {
			return arr[key].top.Description;
		}
	}
};

const getBottom = (arr = {}, min) => {
	for (const key in arr || {}) {
		if (formatAmount(arr[key].bottom.Amount) === min) {
			return arr[key].bottom.Description;
		}
	}
};

const Summary = () => {
	const user = JSON.parse(localStorage.getItem("user_info"));
	const [dailyEnrollments, setDailyEnrollments] = React.useState([]);
	const [weeklyEnrollments, setWeeklyEnrollments] = React.useState([]);
	React.useState([]);
	const [mdaBreakdown, setMdaBreakdown] = React.useState({});
	const [anssidBreakdown, setAnssidBreakdown] = React.useState({});
	const [alert, setAlert] = React.useState({
		showing: false,
		type: null,
		message: ""
	});
	const categorychk = /\bcategory\b/i;
	const mdachk = /\bmda\b/i;

	React.useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const dailyLabels = ["SUN", "MON", "TUE", "WED", "THUR", "FRI", "SAT"];
	const dailyLabelsAlt = ["0", "1", "2", "3", "4", "5", "6"];
	const weeklyLabels = generateWeeks();
	const monthlyLabels = [
		"JAN",
		"FEB",
		"MAR",
		"APR",
		"MAY",
		"JUN",
		"JUL",
		"AUG",
		"SEPT",
		"OCT",
		"NOV",
		"DEC"
	];
	const monthlyLabelsAlt = [
		"0",
		"1",
		"2",
		"3",
		"4",
		"5",
		"6",
		"7",
		"8",
		"9",
		"10",
		"11"
	];
	const quarterlyLabels = ["Q1", "Q2", "Q3", "Q4"];

	const data = (values, labels, header, colors) => {
		return {
			labels: labels,
			datasets: [
				{
					label: header,
					data: values,
					fill: true,
					backgroundColor: colors,
					// borderColor: "#6204DC"
					// color: '#0242C2',
					borderWidth: 1
				}
			]
		};
	};

	const options = {
		scales: {
			xAxes: [
				{
					gridLines: {
						display: false,
						drawOnChartArea: false,
						drawTicks: false
					}
					// display: false, // this will hide vertical lines
				}
			],
			yAxes: [
				{
					gridLines: {
						display: false,
						drawOnChartArea: false,
						drawTicks: false
					},
					display: false // this will hide vertical lines
				}
			]
		}
	};

	const handleErrors = (error) => {
		if (error.response) {
			console.log("response", error.response.data.message);
			setAlert((alert) => ({
				...alert,
				showing: true,
				type: "error",
				message: error.response.data.message
			}));
		} else {
			console.log("Error", error.message);
			setAlert((alert) => ({
				...alert,
				showing: true,
				type: "error",
				message: error.message
			}));
		}
	};

	const getReport = (url) => {
		return axios
			.get(url, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			})
			.then((res) => (res.data || {}).data);
	};

	const getMdaCollections = async (mdaCode) => {
		try {
			const data = await getReport(
				`/admin/reports/specialreportsmda?mdacode=${mdaCode}`
			);
			setMdaBreakdown(data);
		} catch (error) {
			console.error(error);
			handleErrors(error);
		}
	};

	const getCategoryCollections = async (categoryCode) => {
		try {
			const data = await getReport(
				`/admin/reports/specialreportscat?categry=${categoryCode}`
			);
			setMdaBreakdown(data);
		} catch (error) {
			console.error(error);
			handleErrors(error);
		}
	};

	const getAnssidBreakdown = async (email) => {
		try {
			const data = await getReport(
				`/admin/reports/specialreportsuser?email=${email}`
			);
			setAnssidBreakdown(data);
		} catch (error) {
			console.error(error);
			handleErrors(error);
		}
	};

	const getSummaries = () => {
		if (user?.account_type === "mda" || mdachk.test(user?.account_type))
			getMdaCollections(user.mdaCode);
		if (
			user?.account_type === "category" ||
			categorychk.test(user?.account_type)
		)
			getCategoryCollections(user.categoryCode);

		if (user?.account_type === "individual") getAnssidBreakdown(user.email);
	};

	React.useEffect(() => {
		getSummaries();

		//refresh every 5 mins
		const interval = setInterval(() => getSummaries(), 300000);

		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{/* <main ref={dailyRef} className={styles.wrapper}> */}
			<div className={styles.stats}>
				{(user?.account_type === "mda" ||
					user?.account_type === "category" ||
					categorychk.test(user?.account_type)) && (
					<div className={styles.top__stats}>
						<div className={styles.stat}>
							<p>MDA's - Daily</p>
							<p>
								&#8358;{" "}
								{formatAmount(mdaBreakdown?.todaysRevenue)}
							</p>
							<div>
								<Bar
									data={data(
										(() => {
											let weekData = {};
											mdaBreakdown?.weekRevenueBrkDn?.forEach(
												(each) => {
													if (each.dayz === "Sun") {
														return (weekData.SUN =
															parseFloat(
																each.Amount
															));
													} else if (
														each.dayz === "Mon"
													) {
														return (weekData.MON =
															parseFloat(
																each.Amount
															));
													} else if (
														each.dayz === "Tue"
													) {
														return (weekData.TUE =
															parseFloat(
																each.Amount
															));
													} else if (
														each.dayz === "Wed"
													) {
														return (weekData.WED =
															parseFloat(
																each.Amount
															));
													} else if (
														each.dayz === "Thu"
													) {
														return (weekData.THUR =
															parseFloat(
																each.Amount
															));
													} else if (
														each.dayz === "Fri"
													) {
														return (weekData.FRI =
															parseFloat(
																each.Amount
															));
													} else if (
														each.dayz === "Sat"
													) {
														return (weekData.SAT =
															parseFloat(
																each.Amount
															));
													}
													return null;
												}
											);

											return dailyLabels.map(
												(day) =>
													parseFloat(weekData[day]) ||
													0
											);
										})(),
										dailyLabels,
										"",
										["#D73E5B", "#00A3FF"]
									)}
									options={options}
								/>
							</div>
						</div>
						<div className={styles.stat}>
							<p>MDA's - Weekly</p>
							<p>
								{/* {
								weeklyTopMdaCollections[
									getCurrentWeek(new Date())
								]?.top
							} */}
								&#8358;{" "}
								{formatAmount(mdaBreakdown?.weekRevenue)}
							</p>
							<div>
								<Line
									data={data(
										mdaBreakdown?.yearsRevenuebyWeek?.map(
											(each) => parseFloat(each.Amount)
										),
										weeklyLabels,
										"Total Collections",
										[
											"#3ABDA5",
											"#2F9986",
											"#267C6C",
											"#1F6458"
										]
									)}
									options={options}
								/>
							</div>
						</div>
					</div>
				)}
				{(user?.account_type === "mda" ||
					user?.account_type === "category" ||
					categorychk.test(user?.account_type)) && (
					<div className={styles.chart__card}>
						<p>MDA's Monthly</p>
						{/* <p>{getSum(monthlyCollections)}</p> */}
						<p>
							&#8358;{" "}
							{formatAmount(mdaBreakdown?.currMonthsRevenue)}
						</p>
						<div>
							<Bar
								data={data(
									mdaBreakdown?.revenueMth?.map((mth) =>
										parseFloat(mth.Amount)
									),
									monthlyLabels,
									"",
									["#3ABDA5", "#6204DC"]
								)}
								options={options}
							/>
						</div>
					</div>
				)}
				{user?.roles[0]?.Level === 1 && (
					<div className={styles.top__stats}>
						<div className={styles.stat}>
							<p>Individual {coy_ANSSID} - Daily</p>
							<p>{dailyEnrollments[new Date().getDay()]}</p>
							<div>
								<Bar
									data={data(
										dailyEnrollments,
										dailyLabels,
										"",
										["#D73E5B", "#00A3FF"]
									)}
									options={options}
								/>
							</div>
						</div>
						<div className={styles.stat}>
							<p>Individual {coy_ANSSID} - Weekly</p>
							<p>
								{weeklyEnrollments[getCurrentWeek(new Date())]}
							</p>
							<div>
								<Line
									data={data(
										weeklyEnrollments,
										weeklyLabels,
										"Total Collections",
										[
											"#3ABDA5",
											"#2F9986",
											"#267C6C",
											"#1F6458"
										]
									)}
									options={options}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Summary;
