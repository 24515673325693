/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "../../Identity-management-individual/style.module.css";
import Spinner from "../../components/spinner";
import axios from "axios";
import Alert from "../../components/Alert";
import PAYEEmodule from "../../Dashboard/PAYE/Paye-Record/style.module.css";
import backArrow from "../../assets/arrowLeft.svg";

const CreateMulticompany = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const user = JSON.parse(localStorage.getItem("user_info"));
	const accessToken = localStorage.getItem("access_token");
	const inputter = user.email;
	//MDA Profile

	const [payername, setpayername] = useState("");
	const [propertyaddr, setpropertyaddr] = useState("");
	const [propertycity, setpropertycity] = useState("");
	const [appartmentno, setappartmentno] = useState("");
	const [TagId, setTagId] = useState("");
	//Owners profile
	const [ownersesbn, setownersesbn] = useState(" ");
	const [OwnersName, setOwnersName] = useState("");
	const [ownersaddr, setownersaddr] = useState("");
	const [ownershiptype, setownershiptype] = useState(" ");
	const [ownersaddrcity, setownersaddrcity] = useState("");
	const [ownersphone, setownersphone] = useState("");
	const [owneremail, setowneremail] = useState("");
	const [authoriser, setAuthoriser] = useState("");
	const [Deactivate, setDeactivate] = useState("");
	const [assesscode, setAssessmentCode] = useState("");
	const [lgas, setLGAs] = useState([]);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const pIDRefForUserDisplay = useRef(null);
	const history = useHistory();

	const generateProfileID = async (e) => {
		e.preventDefault();
		setLoading(true);
		await axios
			.get(`/getgeneralids/03`)
			.then((res) => {
				if (res?.data?.Id) {
					generatePropertyID(res?.data?.Id);
				}
			})
			.catch(() =>
				setAlert({
					showing: true,
					type: error,
					message:
						error?.data?.message ??
						"Profile ID not generated successfully"
				})
			)
			.finally(() => setLoading(false));
	};

	const generatePropertyID = async (profileID) => {
		setLoading(true);
		await axios
			.get(`/getgeneralids/04`)
			.then((res) => {
				if (res?.data?.Id) {
					pIDRefForUserDisplay.current = res?.data?.Id;
					submit(profileID, res?.data?.Id);
				}
			})
			.catch(() => {
				setAlert({
					showing: true,
					type: error,
					message:
						error?.data?.message ??
						"Property ID not generated successfully"
				});
				setLoading(false);
			});
	};

	const submit = async (profileID, propID) => {
		setLoading(true);

		try {
			await axios
				.post(
					`/mdaprofile`,
					{
						coy: `${user?.mdaModuleCoy}`.trim(),
						profileid: profileID,
						propertyid: propID,
						payername: payername,
						propertyaddr: propertyaddr,
						propertycity: propertycity,
						ownershiptype: ownershiptype,
						appartmentno: appartmentno,
						tagid: "",
						ownersesbn: ownersesbn,
						OwnersName: OwnersName,
						ownersaddr: ownersaddr,
						ownersaddrcity: ownersaddrcity,
						ownersphone: ownersphone,
						owneremail: owneremail,
						inputter: inputter,
						authoriser: authoriser,
						deactivated: false
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => {
					if (res?.data?.data?.id) {
						setAlert({
							showing: true,
							type: "success",
							message: "Property profile created successfully"
						});
						return res.data.data;
					} else {
						setAlert({
							showing: true,
							type: "error",
							message: "Property profile creation unsuccessful."
						});
					}
				});

			setpayername("");
			setpropertyaddr("");
			setpropertycity("");
			setownershiptype("");
			setappartmentno("");
			setownersaddrcity("");
			setTagId("");
			setowneremail("");
		} catch (err) {
			setAlert({
				showing: true,
				type: "error",
				message: "Property profile creation unsuccessful."
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const fetchLGAs = async () => {
			const res = await axios
				.get(`settings/lgass/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setLGAs(res);
		};
		fetchLGAs();
	}, [user?.mdaModuleCoy]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);
	return (
		<>
			<section>
				<button
					className={`${PAYEEmodule.review_header_btn} ${PAYEEmodule.customBackButton} ${styles.back__btn}`}
					onClick={() => history.push("/multi-company/mdaprofile")}
					style={{ marginLeft: "-4px", marginTop: "5px" }}
				>
					<img
						src={backArrow}
						alt="backArrow"
						className={PAYEEmodule.backArrow}
					/>
					<span className={PAYEEmodule.backText}>Back</span>
				</button>
				<form className={styles.form} onSubmit={generateProfileID}>
					{alert.showing && (
						<Alert text={alert.message} type={alert.type} />
					)}
					{pIDRefForUserDisplay?.current && (
						<>
							<h5>Profiled Property ID:</h5>{" "}
							<h3 className="text-success" style={{ margin: 0 }}>
								{pIDRefForUserDisplay?.current ?? "N/A"}
							</h3>
						</>
					)}
					<hr />
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="payername"
							>
								Payer Name
							</label>
							<input
								type="text"
								onChange={(e) => setpayername(e.target.value)}
								id="payername"
								placeholder=""
								value={payername}
							/>
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="propertyaddr"
							>
								Property Street Name
							</label>
							<input
								type="text"
								onChange={(e) =>
									setpropertyaddr(e.target.value)
								}
								id="propertyaddr"
								placeholder=""
								value={propertyaddr}
							/>
						</div>
					</div>

					<br />
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="appartmentno"
							>
								{/* Description <span style={{ color: "red" }}>*</span> */}
								House Number
							</label>
							<input
								type="text"
								onChange={(e) =>
									setappartmentno(e.target.value)
								}
								id="appartmentno"
								placeholder="e.g. No 1, Flat 6"
								// required
								value={appartmentno}
							/>
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="houseLGA"
							>
								{/* L.G.A <span style={{ color: "red" }}>*</span> */}
								L.G.A
							</label>
							<select
								id="houseLGA"
								// className={styles.input__long}
								name="houseLGA"
								onChange={(e) =>
									setpropertycity(e.target.value)
								}
								value={propertycity}
								// required
							>
								<option value="">--Select LGA--</option>
								{lgas?.map((lga, index) => (
									<React.Fragment
										key={`${lga.Description}${index}`}
									>
										<option value={lga.Description}>
											{lga.Description}
										</option>
									</React.Fragment>
								))}
							</select>
						</div>
					</div>

					<br />
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="ownersaddrcity"
							>
								Property City
							</label>
							<input
								type="text"
								onChange={(e) =>
									setownersaddrcity(e.target.value)
								}
								id="ownersaddrcity"
								placeholder="e.g Nsukka "
								value={ownersaddrcity}
							/>
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="ownershiptype"
							>
								Ownership Type
							</label>
							<select
								id="ownershiptype"
								// className={styles.input__long}
								name="ownershiptype"
								onChange={(e) =>
									setownershiptype(e.target.value)
								}
								value={ownershiptype}
								// required
							>
								<option value="">
									--Select Ownership Type--
								</option>
								<option value={"Individual"}>Individual</option>
								<option value={"Corporate"}>Corporate</option>
							</select>
						</div>

						{/* <div>
					<label
						className={styles.input__label}
						htmlFor="ownersesbn"
					>
						Owner's ESBN <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={(e) => setownersesbn(e.target.value)}
						id="ownersesbn"
						placeholder=""
						required
						value={ownersesbn}
					/>
				</div> */}
					</div>

					{/* <div className={styles.double__inputs}>
				<div>
					<label
						className={styles.input__label}
						htmlFor="OwnersName"
					>
						Owner's Name <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={(e) => setOwnersName(e.target.value)}
						id="OwnersName"
						placeholder=""
						required
						value={OwnersName}
					/>
				</div>
				<div>
					<label
						className={styles.input__label}
						htmlFor="ownersaddr"
					>
						Owner's Address <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={(e) => setownersaddr(e.target.value)}
						id="ownersaddr"
						placeholder=""
						required
						value={ownersaddr}
					/>
				</div>
			</div>
			<div className={styles.double__inputs}>
				<div>
					<label
						className={styles.input__label}
						htmlFor="ownersaddrcity"
					>
						Owner's Address City <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={(e) => setownersaddrcity(e.target.value)}
						id="ownersaddrcity"
						placeholder=""
						required
						value={ownersaddrcity}
					/>
				</div>
				<div>
					<label
						className={styles.input__label}
						htmlFor="ownersphone"
					>
						Owner's Phone <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={(e) => setownersphone(e.target.value)}
						id="ownersphone"
						placeholder=""
						required
						value={ownersphone}
					/>
				</div>
			</div>
			<div className={styles.double__inputs}>
				<div>
					<label
						className={styles.input__label}
						htmlFor="owneremail"
					>
						Owner's Email <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={(e) => setowneremail(e.target.value)}
						id="owneremail"
						placeholder=""
						required
						value={owneremail}
					/>
				</div>
				<div>
					<label
						className={styles.input__label}
						htmlFor="authoriser"
					>
						Authoriser <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={(e) => setAuthoriser(e.target.value)}
						id="authoriser"
						placeholder=""
						required
						value={authoriser}
					/>
				</div>
			</div> */}

					<hr className={styles.divider} />

					<footer className={styles.form__footer}>
						<button
							disabled={loading}
							style={{
								padding: "0 25px"
							}}
							type="submit"
							className={[
								"primary__btn",
								styles.primary__button,
								loading ? styles.loading__button : ""
							].join(" ")}
						>
							Save
							{loading && <Spinner />}
						</button>
					</footer>
				</form>
			</section>
		</>
	);
};

export default CreateMulticompany;
