import styles from "./styles.module.css";

const RegularModal = ({ showing, toggle = () => {}, children }) => {
	return (
		<>
			<div className={styles.regular__children}>{children}</div>
			<div
				className={styles.modal}
				onClick={() => toggle(!showing)}
			></div>
		</>
	);
};

export default RegularModal;
