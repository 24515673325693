import { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Alert from "../../components/Alert";
import AddIcon from "../../assets/UserManagement/add.svg";
import styles from "../user/style.module.css";

const Claim = () => {
	const [claims, setClaims] = useState(null);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const accessToken = localStorage.getItem("access_token");

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	useEffect(() => {
		async function fetchClaims() {
			setLoading(true);

			try {
				const request = await axios.get(`/auth/claim`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				});
				setLoading(false);

				setClaims(request?.data?.data?.claims);
			} catch (error) {
				setLoading(false);
				if (error.response) {
					console.log("response", error.response.data.message);
					setAlert((alert) => ({
						...alert,
						showing: true,
						type: "error",
						message: error.response.data.message
					}));
				} else {
					console.log("Error", error.message);
					setAlert((alert) => ({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					}));
				}
			}
		}

		fetchClaims();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<main className={styles.wrapper}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<header className={styles.header}>
				<div className={styles.top}>
					<p className={styles.heading__text}>Claims</p>
					<span className={styles.drop__arrow}></span>
				</div>
				<div className={styles.bottom}>
					<p className={styles.heading__text}>
						{claims?.length}{" "}
						{loading
							? ""
							: claims?.length === 1
							? "Claim"
							: "Claims"}
					</p>
					<NavLink to="/create-claim" className={styles.nav__link}>
						<img src={AddIcon} alt="add icon" /> Create Claim
					</NavLink>
				</div>
			</header>
			<section>
				<table className={styles.table}>
					{loading && (
						<tbody>
							<tr>
								<td>
									<p style={{ textAlign: "center" }}>
										Loading...
									</p>
								</td>
							</tr>
						</tbody>
					)}
					{!claims?.length && !loading ? (
						<tbody>
							<tr>
								<td>
									<p style={{ textAlign: "center" }}>
										No Claims.
									</p>
								</td>
							</tr>
						</tbody>
					) : (
						<tbody>
							{claims?.map((claim) => (
								<tr key={claim.id}>
									<td>
										<p>Name</p>
										<p>{claim.Name}</p>
									</td>
									<td>
										<p>Route</p>
										<p>{claim.Route}</p>
									</td>
									<td>
										<p>Method</p>
										<p>{claim.Method}</p>
									</td>
								</tr>
							))}
						</tbody>
					)}
				</table>
			</section>
		</main>
	);
};

export default Claim;
