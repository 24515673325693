import React, { useState } from "react";
import Spinner from "../../components/spinner";
import styles from "../../Identity-management-individual/style.module.css";
import axios from "axios";

const CreateMDA = () => {
	const [values, setValues] = useState({
		mdaCode: "",
		mdaShortName: "",
		name: "",
		revenueCode: "",
		revenueSourceName: "",
		amount: 0,
		category: "",
		billingGroup: "",
		handlingSystem: ""
	});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	function handleChange(e) {
		const name = e.target.id || e.target.name;
		const value = e.target.value;

		setValues({
			...values,
			[name]: value
		});
	}

	const submitMDAData = async (e) => {
		e.preventDefault();
		setLoading(true);
		setError("");

		try {
			const accessToken = localStorage.getItem("access_token");
			await axios
				.post(
					`/admin/mda`,
					{
						...values
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data);
		} catch (err) {
			console.error(err);
			setError(
				"MDA could not be created. Please verify your details and try again."
			);
		} finally {
			setLoading(false);
		}
	};

	return (
		<form className={styles.columned__form} onSubmit={submitMDAData}>
			{error && <div className="error">{error}</div>}
			<div className={styles.double__inputs}>
				<div>
					<label className={styles.input__label} htmlFor="mdaCode">
						MDA Code <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={handleChange}
						id="mdaCode"
						placeholder=""
						required
						value={values.mdaCode}
					/>
				</div>
				<div>
					<label
						className={styles.input__label}
						htmlFor="mdaShortName"
					>
						MDA Acronym <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={handleChange}
						id="mdaShortName"
						placeholder=""
						required
						value={values.mdaShortName}
					/>
				</div>
				<div>
					<label className={styles.input__label} htmlFor="name">
						MDA Name <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={handleChange}
						id="name"
						placeholder=""
						required
						value={values.name}
					/>
				</div>
			</div>
			<hr className={styles.divider} />
			<div className={styles.double__inputs}>
				<div>
					<label
						className={styles.input__label}
						htmlFor="revenueCode"
					>
						Revenue Code <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={handleChange}
						id="revenueCode"
						placeholder=""
						required
						value={values.revenueCode}
					/>
				</div>
				<div>
					<label
						className={styles.input__label}
						htmlFor="revenueSourceName"
					>
						Revenue Source Name{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={handleChange}
						id="revenueSourceName"
						placeholder=""
						required
						value={values.revenueSourceName}
					/>
				</div>
				<div>
					<label className={styles.input__label} htmlFor="amount">
						Amount <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="number"
						onChange={handleChange}
						id="amount"
						placeholder=""
						required
						min={0}
						value={values.amount}
					/>
				</div>
			</div>
			<hr className={styles.divider} />
			<div className={styles.double__inputs}>
				<div>
					<label className={styles.input__label} htmlFor="category">
						Category <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={handleChange}
						id="category"
						placeholder=""
						required
						value={values.category}
					/>
				</div>
				<div>
					<label
						className={styles.input__label}
						htmlFor="billingGroup"
					>
						Billing Group <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={handleChange}
						id="billingGroup"
						placeholder=""
						required
						value={values.billingGroup}
					/>
				</div>
				<div>
					<label
						className={styles.input__label}
						htmlFor="handlingSystem"
					>
						Handling System <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={handleChange}
						id="handlingSystem"
						placeholder=""
						required
						value={values.handlingSystem}
					/>
				</div>
			</div>
			<footer className={styles.form__footer}>
				<button
					disabled={loading}
					type="submit"
					className={[
						"primary__btn",
						styles.primary__button,
						loading ? styles.loading__button : ""
					].join(" ")}
				>
					Save
					{loading && <Spinner />}
				</button>
			</footer>
		</form>
	);
};

export default CreateMDA;
