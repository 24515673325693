import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styles from "./style.module.css";
import style from "../style.module.css";
import downloadIcon from "../../../assets/downloadFilingIcon.svg";
import template from "../../../assets/Annual-Filing-Template.xlsx";
import Table from "../../../components/Table";
import Alert from "../../../components/Alert";
import trashIcon from "../../../assets/Filing/trashIcon.svg";

const userData = JSON.parse(localStorage.getItem("user_info"));

export const StepOne = ({
	updateTab = () => {},
	updateFilingData = () => {},
	filingData = null
}) => {
	const [loading, setLoading] = useState(false);
	const [employees, setEmployees] = useState(filingData?.employees ?? []);
	const [employeesSheet, setEmployeesSheet] = useState(null);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	let { state } = useLocation();
	const employeesSheetRef = useRef(null);

	const headers = [
		["Employee Name", "fullName"],
		["Employee ESBN", "eSBN"],
		["Months in Service", "monthsInService"],
		["Total Gross Salary (₦)", "annualGrossSalary"],
		["Total Deductions (₦)", "annualCalcTotalRelief"],
		["Annual Tax Due (₦)", "annualCalcTaxDue"]
	];

	const uploadEmployeeSheet = async () => {
		if (employeesSheet) {
			const formData = new FormData();
			formData.append(
				"file",
				employeesSheet,
				"Annual Filing Template.xlsx"
			);
			formData.append("coyid", state?.coyID || userData?.id);

			try {
				const accessToken = localStorage.getItem("access_token");
				let data = await axios
					.post(`company/annual-returns/staff`, formData, {
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					})
					.then((res) => res.data || { data: {} });

				if (!data || !data?.data) {
					// handle errors
					return null;
				}
				setEmployees(data?.data?.employees);
				updateFilingData({
					...filingData,
					...data?.data
				});
				setAlert({
					showing: true,
					type: "success",
					message:
						data?.description?.description.toUpperCase() ??
						"Upload successful!"
				});
			} catch (err) {
				setAlert({
					showing: true,
					type: "error",
					message:
						err?.response?.data?.message ?? "Upload Unsuccessful!"
				});
				console.error({ err });
			} finally {
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles.wrapper}>
				<p>Step 1: Upload Employees</p>

				<div className={`bg-warning ${styles.notice__left}`}>
					<ul>
						<li>
							This form allows you download the employee Excel/CSV
							Template
						</li>
						<li>
							Fill in the details of your employee enrollments and
							upload the Excel/CSV to start the process of your
							Annual returns.
						</li>
						<li>
							Note that a maximum of 2,000 records are allowed per
							Excel/CSV batch.
						</li>
						<li>
							Make sure your internet connection is fast and
							reliable to avoid disruption.
						</li>

						<li>
							REMEMBER TO FILL ALL COLUMNS THAT ARE COMPULSORY OR
							THE UPLOADS FAILS.
						</li>

						<li>Email info@irs.en.gov.ng if you have an issue.</li>
					</ul>
				</div>
				<br />

				<div className={`row ${styles.uploads}`}>
					<div className={`col-md-6`}>
						<p>
							<strong>
								Download excel template to upload employees
							</strong>
						</p>
						<a
							href={template}
							className={`btn btn-success ${styles.downloads__button}`}
							download
						>
							<img src={downloadIcon} alt="download" />
							<p>Download Template</p>
						</a>
					</div>

					<div className={`col-md-6`}>
						<p>
							<strong>
								Upload Excel Sheet (Use Template Provided)
							</strong>{" "}
							<span style={{ color: "red" }}>*</span>
						</p>
						<span className={style.flex__left}>
							<input
								type="file"
								id="file2-btn"
								style={{ display: "none" }}
								ref={employeesSheetRef}
								accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
								onChange={(e) =>
									setEmployeesSheet(e.target.files[0])
								}
							/>
							<label
								htmlFor="file2-btn"
								className={`text-success`}
							>
								<p>Choose File</p>
							</label>
							<span className={styles.ml_1}>
								<p>{employeesSheet?.name}</p>
							</span>
							<button
								className={`btn btn-success ${styles.downloads__button} ${styles.ml_1}`}
								onClick={() => {
									uploadEmployeeSheet();
									setLoading(true);
								}}
								disabled={!employeesSheet?.name}
							>
								{loading ? "Submitting..." : "Submit"}
							</button>
							{employeesSheet?.name && (
								<span className={styles.ml_1}>
									<img
										src={trashIcon}
										alt="delete"
										className={styles.delete_icon}
										onClick={() => {
											setEmployeesSheet(null);
											employeesSheetRef.current.value =
												null;
										}}
									/>
								</span>
							)}
						</span>
					</div>
				</div>
				<br />
				<div className={`bg-info ${styles.info}`}>
					<p>
						PLEASE KINDLY REQUEST ALL YOUR STAFF TO OBTAIN THEIR
						ESBN FROM{" "}
						<a href="https://irs.en.gov.ng/individual-enrollment/self-enrollment/new">
							https://irs.en.gov.ng/individual-enrollment/self-enrollment/new
						</a>
						<br />
						BASIC TRANSPORT, RENT are compulsory to fill in the
						template. <br />
						NOTE THAT SUPPORTING DOCUMENTS WOULD BE REQUIRED IF
						ANNUAL NHIS, NHF & PENSION HAVE VALUES
					</p>
				</div>
				<br />
				<br />
				<h5 className={styles.entries__title}>
					Employee entries preview
				</h5>
				<br />

				<Table
					headers={headers}
					data={employees}
					full
					pageSize={20}
					total={employees?.length}
					// onPageChanged={getProfile}
				/>
				<br />
				<br />
				<footer className={style.flex__right}>
					<button
						className={`btn btn-success ${styles.downloads__button}`}
						disabled={!employees?.length}
						onClick={() => updateTab(2)}
					>
						<p>Next</p>
					</button>
				</footer>
				<br />
			</div>
		</>
	);
};
