/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Spinner from "../../../components/spinner";
// import styles from "./style.module.css";
import styles from "../../../Identity-management-individual/style.module.css";

// import styles2 from "../MDAs/style.module.css";
// import styles3 from "../../../Identity-management-individual/style.module.css";
import Table from "../../../components/Table";
import { useDebounce } from "../hooks";
import Alert from "../../../components/Alert";
import Receipt from "../../ReceiptPymtRef/receipt";
import styles1 from "./style.module.css";
import styles2 from "../../../Direct-assessment/Create-direct-assessment/style.module.css";
import styles3 from "../../../Identity-management-individual/style.module.css";

const user = JSON.parse(localStorage.getItem("user_info"));

const EditBill = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	// const [ownersesbn, setownersesbn] = useState("");
	const [BillNumber, setBillNumber] = useState("");
	// const [ownersname, setownersname] = useState("");
	const [owneremail, setowneremail] = useState("");
	const [ownersphone, setownersphone] = useState("");
	const [ownersaddr, setownersaddr] = useState("");
	const [ownersaddrcity, setownersaddrcity] = useState("");
	const [accountType, setAccountType] = useState("individual");
	const [payername, setpayername] = useState("");
	const [propertyaddr, setpropertyaddr] = useState("");
	const [propertycity, setpropertycity] = useState("");
	const [appartmentno, setappartmentno] = useState("");
	const [shopnumber, setShopNumber] = useState("");
	const [outstandingReason, setOutstandingReason] = useState("");
	const [ownershiptype, setownershiptype] = useState("");
	const [profile, setprofile] = useState("");
	const [profilecategory, setprofilecategory] = useState("");
	const [tagId, settagId] = useState("");
	const [billtype, setbilltype] = useState("");
	const [invoice, setInvoice] = useState("");

	const [authoriser, setauthoriser] = useState("");
	const [phoneno, setPhoneNumber] = useState("");
	const [assesscode, setAssessmentCode] = useState("");
	const [email, setEmail] = useState("");
	const [RegistrationFee, setRegistrationFee] = useState("");
	const [RenewalFee, setRenewalFee] = useState("");
	const [discount, setDiscount] = useState("");
	const [discountReason, setDiscountReason] = useState("");
	const [incomeGroup, setIncomeGroup] = useState("");
	const [outstand, setOutStanding] = useState("");
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [filterSubCategories, setFilteredSubCategories] = useState([]);
	const [currentBillType, setCurrentBillType] = useState("");
	const [allBillsFromServer, setAllBillsFromServer] = useState([]);
	const [areas, setAreas] = useState([]);
	const [subCatData, setSubCatData] = useState({ value: "", index: null });
	const [currentPropertyID, setCurrentPropertyID] = useState({
		value: "",
		index: null
	});
	const [landMeasurement, setLandMeasurement] = useState({
		value: "",
		index: null
	});
	const [total, setCurrentTotal] = useState({
		value: "",
		index: null
	});
	const [mdaProperties, setMDAProperties] = useState({});
	const [subscription, setSubcription] = useState("yearly");
	const [fiscalyr, setFiscalYear] = useState("");
	const [selectMonth, setSelectedMonth] = useState("");
	const [taxoffice, setTaxOffice] = useState("");
	const [taxzone, setTaxZone] = useState("");
	const [taxlga, setTaxLGA] = useState("");
	const [billno, setBillNo] = useState("");
	const [residentialaddress, setResidentialaddress] = useState("");
	const [ownersesbn, setownersesbn] = useState("");
	const [revenue, setRevenue] = useState("");
	const [zones, setZones] = useState([]);
	const [lgas, setLGAs] = useState([]);
	const [taxoffices, setTaxOffices] = useState([]);
	const [revenues, setRevenues] = useState([]);
	const [ownersname, setownersname] = useState("");
	const [charge, setCharge] = useState(0);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const [currentMnth, setCurrentMonth] = useState({
		value: "",
		index: null
	});

	const [profileDetailsArray, setProfileDetailsArray] = React.useState([
		{
			amount: "",
			PIDname: " ",
			houseNo: " ",
			HouseStr: " ",
			houseArea: " ",
			houseLGA: " ",
			houseZone: " ",
			revenuedescription: "",
			profile: "",
			profilecategory: "",
			billtype: "",
			propertyid: " ",
			qty: "1",
			month: "1",
			revenue: ""
		}
	]);
	const [profileDetails, setProfileDetails] = React.useState({
		amount: "",
		PIDname: " ",
		houseNo: " ",
		HouseStr: " ",
		houseArea: " ",
		houseLGA: " ",
		houseZone: " ",
		revenuedescription: "",
		profile: "",
		profilecategory: "",
		billtype: "",
		propertyid: " ",
		qty: "1",
		month: "1",
		revenue: ""
	});

	let { state } = useLocation();
	const myBillNo = state.userId;
	const myCoyz = state.coy;

	const handleDiscount = (value) => {
		if (allBilledAmount < value) {
			setAlert({
				showing: true,
				type: "error",
				message: "Discount cannot be higher than amount."
			});
		} else {
			setDiscount(value);
			setAlert({
				showing: false,
				type: null,
				message: ""
			});
		}
	};

	const allBilledAmount = useMemo(() => {
		const allAmount = profileDetailsArray.reduce(
			(accumulator, currentValue) =>
				accumulator +
				Number(currentValue.amount) *
					Number(currentValue.qty) *
					Number(currentValue.month),
			0
		);
		const newTotalAmount =
			Number(allAmount) > 0 &&
			Number(allAmount) - Number(discount) + Number(outstand) >= 0
				? Number(allAmount) - Number(discount) + Number(outstand)
				: Number(allAmount);
		// console.log(`outstand ${outstand} |discount ${discount} |amount: ${amount} | qty: ${qty} | month: ${month}`)
		// return subscription === 'yearly' ? newTotalAmount.toFixed(2) : (newTotalAmount/12).toFixed(2) ;
		return newTotalAmount.toFixed(2);
	}, [profileDetailsArray, outstand, discount, subscription]);

	const getSelectedCategories = (e) => {
		const value = e.target.value;
		if (value?.split("/")) {
			const [company, code] = value?.split("/");
			const filteredSubCategory = subCategories.filter(
				(category) =>
					category.CategoryCode === code && category.Coy === company
			);
			setFilteredSubCategories(filteredSubCategory);
		} else {
			setFilteredSubCategories(value);
		}
	};

	const getSelectedSubCat = (e, i) => {
		handleProfileInArrayChange(e, i);
	};

	const getAmount = useMemo(() => {
		const [RegistrationFee, RenewalFee, FixedFee, i] =
			subCatData.value.split("/");
		console.log({ RegistrationFee, RenewalFee, FixedFee });

		const allAvailableBills = { RegistrationFee, RenewalFee, FixedFee };

		if (
			allAvailableBills[currentBillType] &&
			Number(allAvailableBills[currentBillType]) > 0
		) {
			setError("");
			return allAvailableBills[currentBillType];
		} else {
			currentBillType &&
				subCatData.value &&
				setError("Tarrif doesn't exist for the sub-category");
			return "";
		}
	}, [subCatData, currentBillType]);

	const updateAmount = (value, i) => {
		const newProfilesArray = profileDetailsArray.map((child, index) => {
			return index === Number(i) ? { ...child, amount: value } : child;
		});
		setProfileDetailsArray(newProfilesArray);
	};

	const handLandMeasurement = (amount, i) => {
		if (landMeasurement.index >= 0) {
			if (landMeasurement.index === i) {
				const landMeasurementAmount = landMeasurement.value * amount;
				updateAmount(landMeasurementAmount, i);
			} else {
				updateAmount(amount, i);
			}
		} else {
			updateAmount(amount, i);
		}
	};

	React.useEffect(() => {
		if (profileDetails.qty && currentMnth && profileDetails.amount) {
			const calculatedCharge =
				profileDetails.amount * profileDetails.qty * currentMnth;

			const finalCharge = calculatedCharge - discount;

			setCharge(finalCharge);
		}
	}, [profileDetailsArray, currentMnth, profileDetails.amount, discount]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	useEffect(() => {
		const i = subCatData.index;

		handLandMeasurement(getAmount, i);
	}, [getAmount, subCatData, landMeasurement]);

	const handleProfileInArrayChange = (e, i) => {
		const value = e.target.value;
		const name = e.target.name;

		const newProfilesArray = profileDetailsArray.map((child, index) => {
			return index === i ? { ...child, [name]: value } : child;
		});

		setProfileDetailsArray(newProfilesArray);
	};

	const addAnotherProfile = () => {
		setProfileDetailsArray([...profileDetailsArray, profileDetails]);
		setSubCatData({ value: "", index: null });
		setCurrentBillType("");
		setProfileDetails({
			...profileDetails,
			amount: "",
			PIDname: " ",
			houseNo: " ",
			HouseStr: " ",
			houseArea: " ",
			houseLGA: " ",
			houseZone: " ",
			revenuedescription: "",
			profile: "",
			profilecategory: "",
			billtype: "",
			propertyid: " ",
			qty: "1",
			month: "1",
			revenue: ""
		});
	};

	function removeChild(index) {
		const newChildrenArray = [...profileDetailsArray];
		newChildrenArray.splice(index, 1);
		setProfileDetailsArray(newChildrenArray);
	}

	const fetchAllBillsFromServer = useCallback(async () => {
		setLoading(true);
		await axios
			.get(`billing/${state.coy}/${state.userId}`)
			.then((res) => {
				console.log(
					"res[fetchAllBillsFromServer] => ",
					res.data?.data?.result
				);
				setownersesbn(res.data?.data?.result?.OwnersESBN);
				setownersname(
					res.data?.data?.result?.OwnersName ?? res.PayerName
				);
				setResidentialaddress(res.data?.data?.result?.OwnersAddr);
				setPhoneNumber(res.data?.data?.result?.OwnersPhone);
				setEmail(res.data?.data?.result?.OwnerEmail);
				setDiscount(res.data?.data?.result?.Discount);
				setOutStanding(res.data?.data?.result?.Outstanding);
				//---
				setOutstandingReason(res.data?.data?.result?.outstandingReason);
				setTaxOffice(res.data?.data?.result?.TaxOffice);
				setTaxZone(res.data?.data?.result?.TaxZone);
				setTaxLGA(res.data?.data?.result?.TaxLGA);
				setRevenue(res.data?.data?.result?.Outstanding);
				setShopNumber(res.data?.data?.result?.TagId);
				setDiscountReason(res.data?.data?.result?.DiscountReason);
				setSubcription(
					res.data?.data?.result?.Year_Mth_Flg === "1"
						? "yearly"
						: "monthly"
				);
				setSelectedMonth(res.data?.data?.result?.fiscalMonth);
				setFiscalYear(res.data?.data?.result?.fiscalYear);
				setBillNo(res.data?.data?.result?.BillNumber);

				//---
				const newTaxPayerBillsDetails =
					res.data?.data?.result?.TaxPayerBillsDetails?.map(
						(child) => ({
							amount: child?.Amount,
							houseNo: child?.HouseNo,
							billtype: child?.BillType,
							coy: child?.Coy,
							deactivated: child?.Deactivated,
							houseArea: child?.HouseArea,
							houseLGA: child?.HouseLGA,
							HouseStr: child?.HouseStreet,
							houseZone: child?.HouseZone,
							profile: child?.Profile,
							profilecategory: child?.ProfileCategory,
							propertyid: child?.PropertyId,
							revenuedescription: child?.RevenueDescription,
							landmeasure: child?.LandMeasurement,
							revenue: child?.RevenueCode,
							month: child?.monthNumber,
							qty: child?.Quantity
						})
					);

				console.log(
					"newTaxPayerBillsDetails  => ",
					newTaxPayerBillsDetails
				);
				// allBilledAmount()()
				setProfileDetailsArray(newTaxPayerBillsDetails);
				setAllBillsFromServer(res.data?.data?.result);
			})
			.catch((error) => console.log({ error }))
			.finally(() => setLoading(false));
	}, [myBillNo, myCoyz]);

	useEffect(() => {
		fetchAllBillsFromServer();
	}, [fetchAllBillsFromServer]);

	// console.log({ allBillsFromServer })
	useEffect(() => {
		const getESBN = async () => {
			const res = await axios
				.get(`users/anssid?anssid=${ownersesbn}`)
				.then((res) => (res.data || {}).data);

			setAccountType(res.accountType);
			setownersname(`${res.firstname} ${res.surname}`);
			setResidentialaddress(res.residentialaddress);
			setPhoneNumber(res.phonenumber);
			setEmail(res.email);
		};
		if (ownersesbn?.length >= 10) getESBN();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownersesbn]);

	useEffect(() => {
		const fetchCategories = async () => {
			const res = await axios
				.get(`settings/profilecategorys/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setCategories(res);
		};
		fetchCategories();
	}, []);

	useEffect(() => {
		const fetchSubCategories = async () => {
			const res = await axios
				.get(`settings/tarrifcommerces/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setSubCategories(res);
		};
		fetchSubCategories();
	}, []);

	// useEffect(() => {
	// }, [categories, subCategories]);

	useEffect(() => {
		const fetchIncomeGroup = async () => {
			const res = await axios
				.get(`settings/mdacompany/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			// console.log("res[IncomeGroup] => ", res);
			setIncomeGroup(res?.income_group);
		};
		fetchIncomeGroup();
	}, []);

	// Area
	useEffect(() => {
		const fetchArea = async () => {
			const res = await axios
				.get(`settings/areas/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setAreas(res);
		};
		fetchArea();
	}, []);

	// Zone
	useEffect(() => {
		const fetchZones = async () => {
			const res = await axios
				.get(`settings/zones/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setZones(res);
		};
		fetchZones();
	}, []);

	// Tax Office
	useEffect(() => {
		const fetchTaxOffices = async () => {
			const res = await axios
				.get(`/settings/assesstaxoffice`)
				.then((res) => (res.data || {}).data);
			setTaxOffices(res);
		};
		fetchTaxOffices();
	}, []);

	// LGA
	useEffect(() => {
		const fetchLGAs = async () => {
			const res = await axios
				.get(`settings/lgass/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setLGAs(res);
		};
		fetchLGAs();
	}, []);

	useEffect(() => {
		const fetchIncomeLines = async () => {
			const res = await axios
				.get(`settings/mdalinkedrevenue/${incomeGroup}`)
				.then((res) => (res.data || {}).data);
			// console.log("res[setRevenues] => ", res);
			setRevenues(res);
		};
		fetchIncomeLines();
	}, [incomeGroup !== ""]);

	useEffect(() => {
		const fetchPropId = async (propId, i) => {
			const res = await axios
				.get(`mdapropertyprofile/${propId}/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);

			const newProfilesArray = profileDetailsArray.map((child, index) => {
				return index === Number(i)
					? {
							...child,
							houseNo: res.AppartmentNo,
							HouseStr: res.PropertyAddr,
							houseArea: "",
							houseLGA: "",
							houseZone: ""
						}
					: child;
			});
			setProfileDetailsArray(newProfilesArray);
			setMDAProperties(res);
		};
		if (!!currentPropertyID.value && currentPropertyID.index !== null) {
			fetchPropId(currentPropertyID.value, currentPropertyID.index);
		}
	}, [currentPropertyID]);

	const generateAssessmentCode = async (e) => {
		e.preventDefault();
		setLoading(true);
		await axios
			.get(`gettmsids/${user?.mdaModuleCoy}`)
			.then((res) => {
				console.log(res.data, "genAssCode");
				if (res.data.Id) {
					submit(res.data.Id);
				}
			})
			.catch((error) => {
				console.log({ error });
			});
	};

	const submit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const accessToken = localStorage.getItem("access_token");
			console.log("allBillsFromServer [submit]  => ", allBillsFromServer);
			console.log(
				"profileDetailsArray [submit]  => ",
				profileDetailsArray
			);

			const res = await axios
				.put(
					`/billing/${user?.mdaModuleCoy}/${billno}`,
					{
						coyid: user?.mdaModuleCoy
							? `${user?.mdaModuleCoy}`.trim()
							: "",
						billnumber: billno,
						yr_mth_flg: subscription === "yearly" ? "1" : "2",
						fiscalyr: fiscalyr ? `${fiscalyr}`.trim() : "",
						fiscalmth: selectMonth ? `${selectMonth}`.trim() : "",
						taxlga: taxlga ? `${taxlga}`.trim() : "",
						taxzone: taxzone ? `${taxzone}`.trim() : "",
						taxoffice: taxoffice ? `${taxoffice}`.trim() : "",
						owneremail: email ? `${email}`.trim() : "",
						ownersesbn: ownersesbn ? `${ownersesbn}`.trim() : "",
						ownersname: !!ownersesbn
							? ownersname
								? `${ownersname}`.trim()
								: ""
							: "",
						ownersaddr: residentialaddress
							? `${residentialaddress}`.trim()
							: "",
						ownersaddrcity: mdaProperties?.OwnersAddrCity
							? `${mdaProperties?.OwnersAddrCity}`.trim()
							: "",
						ownersphone: phoneno ? `${phoneno}`.trim() : "",
						payername: !ownersesbn
							? ownersname
								? `${ownersname}`.trim()
								: ""
							: "",
						propertyaddr: mdaProperties?.PropertyAddr
							? `${mdaProperties?.PropertyAddr}`.trim()
							: "",
						propertycity: mdaProperties?.PropertyCity
							? `${mdaProperties?.PropertyCity}`.trim()
							: "",

						propertyid: "",
						ownershiptype: mdaProperties?.OwnershipType
							? `${mdaProperties?.OwnershipType}`.trim()
							: "",
						tagId: shopnumber // used to hold shop number for new haven
							? `${shopnumber}`.trim()
							: "",
						// tagId: mdaProperties?.TagId
						// 	? `${mdaProperties?.TagId}`.trim()
						// 	: "",
						authoriser: mdaProperties?.Authoriser
							? `${mdaProperties?.Authoriser}`.trim()
							: "",
						totamt: allBilledAmount
							? `${allBilledAmount}`.trim()
							: "",
						discount: discount ? `${discount}`.trim() : "",
						discountreason: discountReason
							? `${discountReason}`.trim()
							: "",
						grandtot: allBilledAmount
							? `${allBilledAmount}`.trim()
							: "",
						outstand: outstand ? `${outstand}`.trim() : "",
						outstandingreason: outstandingReason
							? `${outstandingReason}`.trim()
							: "",
						billDetails: profileDetailsArray?.map((detail) => ({
							...detail,
							coy: user?.mdaModuleCoy
								? `${user?.mdaModuleCoy}`.trim()
								: "",
							profile: `${detail.profile.split("/")[2]}`,
							profilecategory: `${
								detail.profilecategory.split("+")[0]
							}`.trim(),
							deactivated: false
						})),
						paymtstatus: "Pending",
						procstatus: "Approved",
						inputter: user?.email ? `${user?.email}`.trim() : "",
						deactivated: false
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data);
			console.log("response[res]  => ", res);

			setLoading(false);
			setAlert((alert) => ({
				...alert,
				showing: true,
				type: "success",
				message: `${billno} was updated successfully`
			}));
		} catch (err) {
			console.error(err);
			setError(
				"MDA could not be created. Please verify your details and try again."
			);
			setAlert((alert) => ({
				...alert,
				showing: true,
				type: "error",
				message:
					error.response.data?.msg ||
					error.response.data?.errors?.[0] ||
					error.response.data?.errors?.[0]?.message ||
					error.response.data?.errors?.details[0]?.message ||
					error.response.message
			}));
		} finally {
			setLoading(false);
		}
	};

	// console.log({ profileDetailsArray, allBillsFromServer });
	return (
		<>
			{/* <form className={styles.form} onSubmit={generateAssessmentCode}> */}
			<form className={styles3.columned__form} onSubmit={submit}>
				{alert.showing && (
					<Alert text={alert.message} type={alert.type} />
				)}

				{/* femi----- */}
				<div className={styles2.radio__button_div}>
					<span className={styles1.input__label}>
						Bill is Scheduled for
					</span>
					<div>
						<div>
							<input
								id="yearlySchedule"
								type="radio"
								value="yearly"
								checked={subscription === "yearly"}
								onChange={(e) => {
									setSubcription(e.target.value);
								}}
							/>
							<label
								className={styles3.input__label}
								htmlFor="yearlySchedule"
							>
								Yearly
							</label>
						</div>
						<div>
							<input
								id="monthlySchedule"
								type="radio"
								value="monthly"
								checked={subscription === "monthly"}
								onChange={(e) => {
									setSubcription(e.target.value);
								}}
							/>
							<label
								className={styles3.input__label}
								htmlFor="monthlySchedule"
							>
								Monthly
							</label>
						</div>
					</div>
				</div>

				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="selectyear"
						>
							Bill Year (Fiscal Year)
						</label>
						<select
							id="selectYear"
							value={fiscalyr}
							onChange={(e) => setFiscalYear(e.target.value)}
							// disabled={historyState?.view}
							required
						>
							<option value="">Select</option>
							<option>2013</option>
							{new Array((new Date().getFullYear() + 1) - 2013)
								.fill(0)
								.map((_, i) => (
									<option key={i}>{2013 + i + 1}</option>
								))}
						</select>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="selectmonth"
						>
							Bill Month (Fiscal Month)
						</label>
						<select
							id="selectmonth"
							value={selectMonth}
							onChange={(e) => {
								setSelectedMonth(e.target.value);
							}}
							disabled={subscription === "yearly"}
						>
							{[...Array(12)].map((_, index) => (
								<option
									key={index}
									value={new Intl.DateTimeFormat("en", {
										month: "long"
									}).format(new Date(2000, index, 1))}
								>
									{new Intl.DateTimeFormat("en", {
										month: "long"
									}).format(new Date(2000, index, 1))}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="taxlga">
							Bill from which Local Government Area(LGA)
						</label>
						<select
							id="taxlga"
							name="taxlga"
							onChange={(e) => {
								setTaxLGA(e.target.value);
							}}
							value={taxlga}
							required
						>
							<option value="">--Select Billing LGA--</option>
							{lgas.map((lgatax, index) => (
								<option
									value={lgatax.Description}
									zonecode={lgatax.Code}
									index={index}
								>
									{lgatax.Description}
								</option>
							))}
						</select>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="taxzone"
						>
							Bill from which Zone?
						</label>
						<select
							id="taxzone"
							name="taxzone"
							onChange={(e) => {
								setTaxZone(e.target.value);
							}}
							value={taxzone}
							required
						>
							<option value="">--Select Billing Zone--</option>
							{zones?.map((tzone, index) => (
								<option
									value={tzone.Description}
									zonecode={tzone.Code}
									index={index}
								>
									{tzone.Description}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="taxoffice"
						>
							Bill from which Tax Office?
						</label>
						<select
							id="taxoffice"
							name="taxoffice"
							onChange={(e) => {
								setTaxOffice(e.target.value);
							}}
							value={taxoffice}
							required
						>
							<option value="">
								--Select Billing Tax Office--
							</option>
							{taxoffices.map((tax, index) => (
								<option
									value={tax.Description}
									taxcode={tax.Code}
									index={index}
								>
									{tax.Description}
								</option>
							))}
						</select>
					</div>
				</div>
				<hr className={styles.divider} />
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="ownersesbn"
						>
							ESBN
						</label>
						<input
							type="text"
							onChange={(e) => setownersesbn(e.target.value)}
							id="ownersesbn"
							placeholder=""
							value={ownersesbn}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="ownersname"
						>
							Shop Owner's Name{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="text"
							onChange={(e) => setownersname(e.target.value)}
							id="ownersname"
							placeholder=""
							required
							//  disabled
							value={ownersname}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="residentialaddress"
						>
							Line of Business
						</label>
						<input
							type="text"
							onChange={(e) =>
								setResidentialaddress(e.target.value)
							}
							id="residentialaddress"
							placeholder=""
							//  disabled
							value={residentialaddress}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="shopnumber"
						>
							Shop Number
						</label>
						<input
							type="text"
							onChange={(e) => setShopNumber(e.target.value)}
							id="shopnumber"
							placeholder=""
							//  disabled
							value={shopnumber}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="phonenumber"
						>
							Phone
						</label>
						<input
							type="text"
							onChange={(e) => setPhoneNumber(e.target.value)}
							id="phonenumber"
							placeholder=""
							//  disabled
							value={phoneno}
						/>
					</div>
					<div>
						<label className={styles.input__label} htmlFor="email">
							Email
						</label>
						<input
							type="text"
							onChange={(e) => setEmail(e.target.value)}
							id="email"
							placeholder=""
							//  disabled
							value={email}
						/>
					</div>
				</div>
				<hr className={styles.divider} />
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="discount"
						>
							Discount
						</label>
						<input
							type="text"
							onChange={(e) => handleDiscount(e.target.value)}
							id="discount"
							placeholder=""
							value={discount}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="discountReason"
						>
							Reason for Discount
						</label>
						<input
							type="text"
							onChange={(e) => setDiscountReason(e.target.value)}
							id="discountReason"
							placeholder=""
							value={discountReason}
						/>
					</div>
				</div>
				{/* femi ===> outstanding */}
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="outstanding"
						>
							Outstanding
						</label>
						<input
							type="text"
							onChange={(e) => setOutStanding(e.target.value)}
							id="outstanding"
							placeholder=""
							value={outstand}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="outstandingReason"
						>
							Reason for Outstanding
						</label>
						<input
							type="text"
							onChange={(e) =>
								setOutstandingReason(e.target.value)
							}
							id="outstandingReason"
							placeholder=""
							value={outstandingReason}
						/>
					</div>
				</div>
				{/* femi ===> outstanding */}
				<hr className={styles.divider} />

				{/* Profile ID details */}
				{profileDetailsArray.map((child, index) => (
					<React.Fragment key={`profile${index}`}>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="revenuedescription"
								>
									Property ID
								</label>
								<input
									type="text"
									name="propertyid"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
										setCurrentPropertyID({
											value: e.target.value,
											index
										});
									}}
									id="propertyid"
									placeholder=""
									value={child.propertyid}
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="PIDname"
								>
									Occupant(s) / Owner's Name
								</label>
								<input
									type="text"
									name="PIDname"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									id="PIDname"
									placeholder=""
									value={child.PIDname}
								/>
							</div>
						</div>
						<h3>Address</h3>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="houseNo"
								>
									No
								</label>
								<input
									type="text"
									name="houseNo"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									id="houseNo"
									placeholder=""
									//  disabled
									value={child.houseNo}
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="HouseStr"
								>
									Street
								</label>
								<input
									type="text"
									name="HouseStr"
									onChange={(e) =>
										handleProfileInArrayChange(e, index)
									}
									id="HouseStr"
									placeholder=""
									//  disabled
									value={child.HouseStr}
								/>
							</div>
						</div>

						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="houseLGA"
								>
									LGA
								</label>
								<select
									id="houseLGA"
									className={styles.input__long}
									name="houseLGA"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									value={child.houseLGA}
									required
								>
									<option>--Select LGA--</option>
									{lgas?.map((lga, index) => (
										<React.Fragment
											key={`${lga.Description}${index}`}
										>
											<option value={lga.Description}>
												{lga.Description}
											</option>
										</React.Fragment>
									))}
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="houseZone"
								>
									Zone
								</label>
								<select
									id="houseZone"
									className={styles.input__long}
									name="houseZone"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									value={child.houseZone}
									required
								>
									<option>--Select Zone--</option>
									{zones?.map((zone, index) => (
										<React.Fragment
											key={`${zone.Description}${index}`}
										>
											<option value={zone.Description}>
												{zone.Description}
											</option>
										</React.Fragment>
									))}
								</select>
							</div>
						</div>

						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="revenuedescription"
								>
									Further Description{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									type="text"
									name="revenuedescription"
									onChange={(e) =>
										handleProfileInArrayChange(e, index)
									}
									id="revenuedescription"
									placeholder=""
									required
									//  disabled
									value={child.revenuedescription}
								/>
							</div>
						</div>

						<hr className={styles.divider} />

						<div className={styles.select__wrapper}>
							<div>
								<label htmlFor="billtype">
									Bill Type
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="billtype"
									className={styles.input__medium}
									name="billtype"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
										setCurrentBillType(e.target.value);
									}}
									value={child.billtype}
									required
								>
									<option>--Select BillType--</option>
									<option value={"RenewalFee"}>
										Renewal
									</option>
									<option value={"RegistrationFee"}>
										Registration
									</option>
									<option value={"FixedFee"}>
										Fixed Amount
									</option>
								</select>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="profile">
									Category{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="profile"
									className={styles.input__medium}
									name="profile"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
										getSelectedCategories(e);
									}}
									value={child.profile}
									required
								>
									<option>--Select Category--</option>
									{categories.length &&
										categories?.map((category, index) => (
											<React.Fragment
												key={`${category?.Coy}${index}`}
											>
												<option
													value={`${category?.Coy}/${category?.Code}/${category?.Description}`}
												>
													{category?.Description}
												</option>
											</React.Fragment>
										))}
								</select>
							</div>
							<div>
								<label htmlFor="profilecategory">
									Sub-Category{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="profilecategory"
									className={styles.input__medium}
									name="profilecategory"
									onChange={(e) => {
										setSubCatData({
											value: e.target.value.split("+")[1],
											index
										});
										handleProfileInArrayChange(e, index);
									}}
									value={child.profilecategory}
									required
								>
									<option>--Select Sub-Category--</option>
									{filterSubCategories.length &&
										filterSubCategories?.map(
											(sub, index) => (
												<React.Fragment
													key={sub?.id + index}
												>
													<option
														value={
															sub?.Description +
															"+" +
															sub?.RegistrationFee +
															"/" +
															sub?.RenewalFee +
															"/" +
															sub?.FixedFee +
															"/" +
															index
														}
													>
														{sub?.Description}
													</option>
												</React.Fragment>
											)
										)}
								</select>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="qty"
								>
									How Many Units / Number of Occupants
								</label>
								<input
									type="number"
									name="qty"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									id="qty"
									placeholder="Quantity "
									value={child.qty}
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="month"
								>
									How Many Months
								</label>
								<input
									type="number"
									name="month"
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									id="month"
									placeholder="How many months?"
									value={child.month}
								/>
							</div>
						</div>
						{child.profilecategory.includes("cubic meter") && (
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="landmeasure"
									>
										Land Measurement{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										name="landmeasure"
										onChange={(e) => {
											handleProfileInArrayChange(
												e,
												index
											);
											setCurrentTotal(
												Number(child.qty) *
													Number(child.currentMnth) *
													Number(child.amount)
											);
										}}
										id="landmeasure"
										required
										value={child.landmeasure}
									/>
								</div>
							</div>
						)}

						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="amount"
								>
									{subscription === "yearly"
										? "Yearly Tarrif"
										: "Monthly Tarrif"}{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									type="text"
									name="amount"
									onChange={(e) =>
										handleProfileInArrayChange(e, index)
									}
									id="amount"
									required
									// value={child.amount}
									value={child.amount}
								/>
							</div>
							<div>
								<label htmlFor="revenue">
									Which Revenue is this bill Attached to?{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="revenue"
									className={styles.input__medium}
									name="revenue"
									required
									onChange={(e) => {
										handleProfileInArrayChange(e, index);
									}}
									value={child.revenue}
								>
									<option>--Select Revenue--</option>
									{revenues?.length &&
										revenues?.map((rev, index) => (
											<React.Fragment
												key={`${rev?.id}${index}`}
											>
												<option value={`${rev?.Code}`}>
													{rev?.Description}
												</option>
											</React.Fragment>
										))}
								</select>
							</div>
						</div>
						{profileDetailsArray.length > 1 && (
							<button
								type="button"
								className={styles.add__another__child__btn}
								style={{
									color: "#F0372E",
									textAlign: "left",
									padding: 0,
									marginBottom: 10,
									marginTop: 10
								}}
								onClick={() => {
									removeChild(index);
								}}
							>
								- Remove Property {index + 1}
							</button>
						)}
						<hr className={styles.divider} />
					</React.Fragment>
				))}

				{/* <hr className={styles.divider} /> */}
				<button
					type="button"
					className={styles.add__another__child__btn}
					onClick={addAnotherProfile}
				>
					+ Add another property
				</button>
				<hr className={styles.divider} />
				<p>
					<i>
						Monthly bill is reflected by dividing yearly tarriff by
						12 (months)
					</i>
				</p>
				<div className={styles.double__inputs}>
					<h4 style={{ fontWeight: "bold" }}>
						Total Amount Billed:{" "}
					</h4>
					&nbsp;
					<h4 style={{ fontWeight: 300 }}>
						&#8358;{allBilledAmount}
					</h4>
				</div>
				<div className={styles.double__inputs}>
					<h4 style={{ fontWeight: "bold" }}>Total Amount: </h4>&nbsp;
					<h4 style={{ fontWeight: 300 }}>
						&#8358;{allBilledAmount}
					</h4>
				</div>

				<hr className={styles.divider} />
				{/* total and stuffs */}
				<footer className={styles.form__footer}>
					<button
						disabled={loading}
						type="submit"
						className={[
							"primary__btn",
							styles.primary__button,
							loading ? styles.loading__button : ""
						].join(" ")}
					>
						Generate bill
						{loading && <Spinner />}
					</button>
				</footer>
			</form>
			{!!invoice && <Receipt data={invoice} loading={loading} />}
		</>
	);
};

export default EditBill;
