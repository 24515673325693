import React, { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import styles from "./style.module.css";
import arrowRightIcon from "../../../assets/arrowRightIcon.svg";
import arrowLeftIcon from "../../../assets/arrowLeftIcon.svg";
import style from "../../../Identity-management-individual/style.module.css";
import style1 from "../../AnnualReturns/style.module.css";
import Table from "../../../components/Table";
import Alert from "../../../components/Alert";
import trashIcon from "../../../assets/Filing/trashIcon.svg";

const dateFiled = new Date().toLocaleDateString();

export const StepTwo = ({
	updateTab = () => {},
	updateFilingData = () => {},
	filingData = null
}) => {
	const [loading, setLoading] = useState(false);
	const [authority, setAuthority] = useState({
		signature: filingData?.authority?.signature ?? null,
		signatory: filingData?.authority?.signatory ?? "",
		designation: filingData?.authority?.designation ?? "",

		email: filingData?.authority?.email ?? "",
		phone: filingData?.authority?.phone ?? "",
		dateFiled: filingData?.authority?.dateFiled ?? dateFiled
	});

	const signatureRef = useRef(null);

	const [billNumber, setBillNumber] = useState({
		bill: filingData?.billNumberData?.bill ?? "",
		data: filingData?.billNumberData?.data ?? []
	});

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const headers = [
		["Reciept Number", "recieptNo"],
		["Bill Number", "BillNumber"],
		["Ammount (₦)", "GrandTotal"],
		["Payment Date", "paymentDate"],
		["Revenue Source", "revenueResource"]
	];

	const buttonState = useMemo(() => {
		if (billNumber.data.length) {
			if (
				!authority.signature ||
				!authority.signatory ||
				!authority.designation
			) {
				return true;
			}
		} else {
			return false;
		}
	}, [authority, billNumber]);

	const fetchBillNumbers = async () => {
		setLoading(true);
		try {
			const accessToken = localStorage.getItem("access_token");
			let data = await axios
				.get(`billingno/${billNumber?.bill}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => res.data || { data: {} });

			if (!data || !data?.data) {
				// handle errors
				return null;
			}

			setLoading(false);
			setBillNumber((prev) => ({
				...prev,
				data: [...prev.data, ...data.data]
			}));

			updateFilingData({
				...filingData,
				billNumberData: {
					bill: billNumber.bill,
					data: [
						...(filingData?.billNumberData?.data || []),
						...data.data
					]
				}
			});

			setAlert({
				showing: true,
				type: "success",
				message:
					data?.description?.description.toUpperCase() ??
					"Bill Number fetched successful!"
			});
		} catch (err) {
			setAlert({
				showing: true,
				type: "error",
				message:
					err?.response?.data?.message ??
					"Bill Number fetch Unsuccessful!"
			});
		} finally {
			// setLoading(false);
		}
	};

	const deleteItem = (item) => {
		if (billNumber?.data) {
			const filteredData = billNumber?.data?.filter(
				(singleBill) => singleBill?.id !== item
			);
			setBillNumber((prev) => ({
				...prev,
				data: filteredData
			}));
		}
	};

	useEffect(() => {
		if (authority.signature && !Object.values(authority).includes("")) {
			updateFilingData({
				...filingData,
				authority: authority
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authority]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles.wrapper}>
				<p>Step 2: Upload Authority/Assessment</p>

				<div className={`row ${styles.authority}`}>
					<div className={`col-md-5`}>
						<p>
							<strong>
								Upload Signature (Authorised Signature)
								<span style={{ color: "red" }}> *</span>
							</strong>
						</p>

						{/* <span></span> */}
						<span className={style1.flex__left}>
							<input
								type="file"
								id="signature-btn"
								style={{ display: "none" }}
								accept="image/png, image/jpeg"
								ref={signatureRef}
								onChange={(e) => {
									setAuthority((prev) => ({
										...prev,
										signature: e.target.files[0]
									}));
								}}
							/>
							<label
								htmlFor="signature-btn"
								className={`btn btn-success ${styles.downloads__button}`}
							>
								<p>Choose File</p>
							</label>
							<span className={styles.ml_1}>
								<p>{authority?.signature?.name}</p>
							</span>
							{authority?.signature?.name && (
								<span className={styles.ml_1}>
									<img
										src={trashIcon}
										alt="delete"
										className={styles.delete_icon}
										onClick={() => {
											setAuthority((prev) => ({
												...prev,
												signature: null
											}));
											signatureRef.current.value = null;
										}}
									/>
								</span>
							)}
						</span>
					</div>

					<div className={`col-md-7`}>
						<div className={style.double__inputs}>
							<div>
								<label
									className={style.input__label}
									htmlFor="signatory"
								>
									Name of Signatory{" "}
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="text"
									onChange={(e) => {
										setAuthority((prev) => ({
											...prev,
											signatory: e.target.value
										}));
									}}
									id="signatory"
									placeholder="enter the name of signatory"
									required
									value={authority?.signatory}
								/>
							</div>
							<div>
								<label
									className={style.input__label}
									htmlFor="designation"
								>
									Designation{" "}
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="text"
									onChange={(e) => {
										setAuthority((prev) => ({
											...prev,
											designation: e.target.value
										}));
									}}
									id="designation"
									placeholder="enter designation"
									required
									value={authority?.designation}
								/>
							</div>
						</div>
						<br />
						<div className={style.double__inputs}>
							<div>
								<label
									className={style.input__label}
									htmlFor="email"
								>
									Email{" "}
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="text"
									onChange={(e) => {
										setAuthority((prev) => ({
											...prev,
											email: e.target.value
										}));
									}}
									id="email"
									placeholder="enter email"
									required
									value={authority?.email}
								/>
							</div>
							<div>
								<label
									className={style.input__label}
									htmlFor="phone"
								>
									Phone{" "}
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="text"
									onChange={(e) => {
										setAuthority((prev) => ({
											...prev,
											phone: e.target.value
										}));
									}}
									id="phone"
									placeholder="enter phone"
									required
									value={authority?.phone}
								/>
							</div>
						</div>
					</div>
				</div>
				<br />
				<br />
				<br />
				<div className={`bg-warning ${styles.notice__center}`}>
					<p>
						Enter Bill Number for each remittance made in{" "}
						{new Date().getFullYear() - 1} (Payee and direct
						assessments bills numbers only)
					</p>
				</div>
				<br />
				<br />
				<div className={style1.flex__left}>
					<input
						type="text"
						onChange={(e) =>
							setBillNumber((prev) => ({
								...prev,
								bill: e.target.value
							}))
						}
						id="billNumber"
						placeholder="Bill Number"
						required
						value={billNumber.bill}
					/>
					<button
						className={`btn btn-success ${styles.downloads__button} ${styles.ml_1}`}
						onClick={() => fetchBillNumbers()}
					>
						<p>{loading ? "Adding..." : "Add"}</p>
					</button>
				</div>
				<br />

				<Table
					headers={headers}
					data={billNumber?.data || []}
					full
					pageSize={20}
					total={billNumber?.data?.length}
					onDelete={(record) => deleteItem(record.id)}
				/>
				<br />
				<br />
				<footer className={style.form__footer}>
					<button
						disabled={loading}
						type="submit"
						className={`btn text-success ${styles.border__button__green}`}
						onClick={() => updateTab(1)}
					>
						<img src={arrowLeftIcon} alt="download" />
						Go Back
					</button>
					<button
						disabled={buttonState}
						type="submit"
						className={["primary__btn", style.primary__button].join(
							" "
						)}
						onClick={() => updateTab(3)}
					>
						Next
						<img src={arrowRightIcon} alt="Next" />
					</button>
				</footer>
				<br />
			</div>
		</>
	);
};
