import Table from "../../components/Table";
import styles from "../MDAs/style.module.css";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

import Modal from "../../components/Modal";

const JBTs = () => {
	const [fullTableShowing, toggleFullTable] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const headers = [
		["JTB Tin", "jtbTin"],
		["TCC No", "tccNo"],
		["Tax Period", "taxPeriod"],
		["Turn Over", "turnOver"],
		["Accessible Profit", "accessibleProfit"],
		["Total Profit", "totalProfit"],
		["Tax Payable", "taxPayable"],
		["Tax Paid", "taxPaid"],
		["Tax Authority", "taxAuthority"],
		["Tax Office", "taxOffice"]
	];

	const getJTBs = async () => {
		setLoading(true);
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios.get(`/admin/jtb`, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}).then(res => (res.data || {}).data);

			if (info) {
				console.log(info);
				setData(info);
			}

		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getJTBs();
	}, []);

	return (
		<div className={styles.MDA__page}>
			<div className={styles.actions__div}>
				<button onClick={() => toggleFullTable(!fullTableShowing)}>
					View Entire Table
				</button>
				<NavLink to="/create-jtb">
					Create JTB
				</NavLink>
			</div>
			<div className={styles.table__div}>
				<Table headers={headers} data={data} />
			</div>
			{fullTableShowing && (
				<Modal showing={fullTableShowing} toggle={toggleFullTable}>
					<Table headers={headers} data={data} full />
				</Modal>
			)}
		</div>
	);
};

export default JBTs;
