import style from "./style.module.css";
import Subscription from "../../Home/subscription/index";
import Footer from "../footer";
import NavBar from "../nav-bar";
import greenArrowUp from "../../assets/Home/greenArrowUp.svg";
import greenArrowDown from "../../assets/Home/greenArrowDown.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const frequentQuestions = [
	{
		Question: "What is the full meaning of ESBN?",
		Answer: "ESBN means Enugu State Social Benefit Number.",
		partition: " "
	},
	{
		Question: "How do I sign Up to get my ESBN? ",
		Answer: {
			optionFlow: "There are two options available:",
			optionTitle1: "i)	Self-Service Option",
			optionStep1: (
				<>
					Log in to the URL address &nbsp;
					<Link to="https://irs.en.gov.ng/home">
						https://irs.en.gov.ng/home
					</Link>
				</>
			),
			optionStep2: "Scroll down to click on “Register now” to Signup",
			optionStep3:
				"Obtain OTP to verify with either your phone number or e-mail",
			optionStep4: "Then log in to complete the registration process",
			optionStep5: "ESBN is then sent to you as SMS and email.",
			optionStep6: "",
			option2Title: "ii)	Tax Office Registration Option",
			option2Step1:
				"Visit any of the tax offices in the state closest to you and ESBN will be issued to you via the system instantly"
		}
	},
	{
		Question: "How do I retrieve my ESBN if forgotten? ",
		Answer: "To get your ESBN, click on Retrieve ESBN, search with your Name, Email and Phone number, the system will produce for you your ESBN. ",
		partition: " "
	},
	{
		Question: "Can one make payment without ESBN?",
		Answer: "Yes, but this will soon be stopped as all payments should be made with the ESBN to ensure proper tracking and the preparation of consolidated position where necessary.",
		partition: " "
	},
	{
		Question: "Can the Old ESBN be used for payment?",
		Answer: "Yes, there is no segregation in the numbers as all existing ones have been uploaded in the system while only new ones are issued for those who are yet to have the unique number.",
		partition: " "
	},
	{
		Question: "How do I pay for Tax and other government levies?",
		Answer: {
			optionTitle1: "Method 1: Self-service :",
			optionStep1: (
				<>
					<Link to="https://irs.en.gov.ng/home" target="_blank">
						https://irs.en.gov.ng/home
					</Link>
				</>
			),
			optionStep2:
				"Click 'Pay with' and choose your preferred payment gateway (e.g., Remita, Flutterwave, Interswitch QTB, e-Transact, or UP).			",
			optionStep3: " Enter the Bill Number as seen on your invoice.",
			optionStep4:
				" Click the 'Pay now' button or specify the amount you intend to pay, then click 'Pay now'.	",
			optionStep5:
				" Follow the instructions to select your preferred method of payment (e.g., Cards, Bank Transfer, USSD, Bank branch, etc.). Complete the payment process.",
			optionStep6:
				"Click the 'I92ve made the payment' button if it is available. Note that some payment gateways might not have this option.",
			option2Title: "Method 2: Payment at Bank",
			option2Step1: "Go to your bank and present the bill to the teller.",
			option2Step2:
				"Ensure the teller enters the bill number and the amount you wish to pay.",
			option2Step3: "Obtain your payment receipt from the teller."
		},
		partition: " "
	},
	{
		Question: "What is the full meaning of PAYE?",
		Answer: "PAYE stands for Pay As You Earn.",
		partition: " "
	},
	{
		Question: "Which people should be obliged to pay PAYE?",
		Answer: "Only staff of Organizations pays PAYE and it is so called because taxes are deducted at source, to be remitted by the organization to the IGR account of the government .",
		partition: " "
	},
	{
		Question:
			"How does one file in PAYE records without going to the board of internal revenue?",
		Answer: (
			<>
				The system offers organizations a self-service option through
				which PAYE records are filed. Organizations are therefore,
				encouraged to know their log-in details on the
				<Link to="https://irs.en.gov.ng/login">
					https://irs.en.gov.ng/login
				</Link>
			</>
		),
		partition: " "
	},
	{
		Question: "Can one make payment by installment?",
		Answer: "Yes, installment payments are allowed and that is one of the benefits of making payments with the use of ESBN and invoice number for proper tracking ",
		partition: " "
	},

	{
		Question:
			"Between Bill Number and ESBN, which should be used for payment?",
		Answer: "Both are important depending on how the bill has been prepared. So it is either both are present or one to be utilized for the purpose of making the payment.",
		partition: " "
	},

	{
		Question: "Why the need for Consolidated Demand Notice?",
		Answer: "Consolidatd Demand Notice is needed in order to avoid multiple bill numbers and to lessen the burden of customers having to go to different Ministries or Agencies to get demand notice for payment. This consolidated demand notice can be issued from any Ministry.",
		partition: " "
	},
	{
		Question:
			"As regards consolidated demand notice, how does one pay to enable effective split to the involved MDAs?",
		Answer: "After payment through the self service, it automatically splits to the different MDAs involved in the bill.",
		partition: " "
	},
	{
		Question: "What is the full meaning of TCC?",
		Answer: "Tax Clearance Certificate.",
		partition: " "
	},
	{
		Question:
			"How can one make additional assessment to enable TCC preparation?",
		Answer: "For additional assessment, click on the additional assessment port and add the previous assessment number to generate an additional assessment for the tax payer.",
		partition: " "
	},

	{
		Question: "How does one know the correct  amount to pay when billed?",
		Answer: "The assessment print out displays the amount to be paid. Also for self service, the bill number displays the amount in the bill.",
		partition: " "
	},

	{
		Question:
			"Can one have the break down of all the tax imposed on tax payers in the state?",
		Answer: "All taxes paid in Enugu State are stipulated by law. There are no taxes collected that is outside the law.",
		partition: " "
	},
	{
		Question: "Can we print receipt on our own?",
		Answer: "For now, No, However, the module for this will be available soon.",
		partition: " "
	},

	{
		Question: "Can we pay for current year without paying for other years?",
		Answer: "Yes. Although for the issuance of TCC, the previous 3 years are to be assessed and paid for.",
		partition: " "
	},
	{
		Question: "If we over pay for tax, can it be reversed?",
		Answer: "Yes, it can be reversed but it may take a while.",
		partition: " "
	},

	{
		Question: "My bills do not show the signatures",
		Answer: "It is either there is a weak internet connection or the inputer was not created to work within the MDA",
		partition: " "
	},

	{
		Question:
			"How does one report issues directly online without coming to the board?",
		Answer: "The support phone number and email is displayed on the irs site which is esirs@enugustate.gov.ng and 08075625555.",
		partition: " "
	}
];

const FaqPage = () => {
	const [selected, setSelected] = useState(null);
	const toggle = (i) => {
		if (selected === i) {
			return setSelected(null);
		}

		setSelected(i);
	};

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<main>
			<NavBar />
			<section className={style.heroSection}>
				<div className={style.heroContent}>
					<h1 className={style.desktop}>
						Frequently Asked Questions <br /> (FAQ){" "}
					</h1>
					<h1 className={style.mobile}>
						Frequently Asked <br /> Questions (FAQ)
					</h1>
				</div>
			</section>
			<section className={style.accordion}>
				<h1 className={style.heading}>FAQ</h1>
				{frequentQuestions.map((item, i) => (
					<div className={style.item} key={i}>
						<div className={style.title} onClick={() => toggle(i)}>
							<h2> {item.Question}</h2>
							{selected === i ? (
								<img src={greenArrowUp} alt="faqArrowUp" />
							) : (
								<img src={greenArrowDown} alt="faqArrowDown" />
							)}
						</div>
						<div
							className={`${style.content} ${
								selected === i ? style.contentShow : ""
							}`}
						>
							{typeof item.Answer === "object" ? (
								<div>
									<p>{item.Answer.step1}</p>
									<p>{item.Answer.step2}</p>
									<p>{item.Answer.step3}</p>
									<p>{item.Answer.step4}</p>
									<p>{item.Answer.step5}</p>
									<p>{item.Answer.optionFlow}</p>
									<p className={style.optionTitle}>
										{item.Answer.optionTitle1}
									</p>
									<p className={style.optionSteps}>
										1.
										<strong>
											Visit the Enugu State IRS Homepage:
										</strong>
										{item.Answer.optionStep1}
									</p>
									<p className={style.optionSteps}>
										2.{" "}
										<strong>Select Payment Gateway:</strong>
										{item.Answer.optionStep2}
									</p>
									<p className={style.optionSteps}>
										3.<strong>Enter Bill Details:</strong>
										{item.Answer.optionStep3}
									</p>
									<p className={style.optionSteps}>
										4.
										<strong>
											Specify Payment Amount:{" "}
										</strong>
										{item.Answer.optionStep4}
									</p>
									<p className={style.optionSteps}>
										5.{" "}
										<strong>Complete the Payment: </strong>
										{item.Answer.optionStep5}
									</p>
									<p className={style.optionSteps}>
										6.
										<strong>
											Confirm Payment (if available):{" "}
										</strong>
										{item.Answer.optionStep6}
									</p>
									<p className={style.optionTitle}>
										{item.Answer.option2Title}
									</p>
									<p className={style.optionSteps}>
										1.<strong>Visit Your Bank: </strong>
										{item.Answer.option2Step1}
									</p>
									<p className={style.optionSteps}>
										2.{" "}
										<strong>
											Provide Payment Details:{" "}
										</strong>
										{item.Answer.option2Step2}
									</p>
									<p className={style.optionSteps}>
										3.<strong> Obtain Receipt: </strong>{" "}
										{item.Answer.option2Step3}
									</p>
								</div>
							) : (
								<p>{item.Answer}</p>
							)}
						</div>
						{i !== frequentQuestions.length - 1 && (
							<div className={style.partition}></div>
						)}
					</div>
				))}
			</section>
			<Subscription />
			<Footer />
		</main>
	);
};

export default FaqPage;
