import styles from './style.module.css';

const Receipt = ({ data, loading }) => {
	return loading ? (
		<p>Loading...</p>
	) : (
		<div dangerouslySetInnerHTML={{ __html: data }} className={styles.receipt__view}></div>
	);
};

export default Receipt;
