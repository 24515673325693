import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import Spinner from "../../src/components/spinner";
import styles1 from "../Identity-management-individual/style.module.css";
import { default as style, default as styles } from "./style.module.css";
import Alert from "../components/Alert";
import BankData from "../banks.json";
import constants from "../constants";

axios.defaults.base1URL = constants.BASE1_URL;

const Addnew = () => {
	const token = localStorage.getItem("access_token");
	const [loading, setLoading] = useState(false);
	const [esbn, setESBN] = useState("");
	const [customerName, setCusName] = useState("");
	const [bank, setBank] = useState("");
	const [billNumber, setBillNumber] = useState("");
	const [paymentMethod, setPayMth] = useState("");
	const [totalAmountPaid, setTAmount] = useState("");
	const [paymentDate, setPaymentDate] = useState(new Date());
	const [depositorsPhone, setDepositorsPhone] = useState("");
	const [depositorsRcNumber, setDepositorsRcNumber] = useState("");
	const [depositSlipNumber, setDepositSlipNumber] = useState("");
	const [paymentRefNumber, setPaymentRefNumber] = useState("");
	const [receiptNumber, setReceiptNumber] = useState("");
	const [address, setAddress] = useState("");
	const [getrev, setRevhead] = useState([]);
	const [getmdas, setMdahead] = useState([]);
	const [revenue, setRevenue] = useState("");
	const [mda, setMdas] = useState("");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	let history = useHistory();

	//send submit inputs
	const PostData = async (e) => {
		e.preventDefault();
		setLoading(true);

		const ensureNotEmpty = (str) => (str.trim() === "" ? " " : str.trim());

		try {
			const res = await axios.post(
				`${constants.BASE1_URL}/manualRecon/createRecords`,
				{
					esbn: ensureNotEmpty(esbn),
					customerName: ensureNotEmpty(customerName),
					address,
					mda,
					paymentDate,
					paymentMethod,
					paymentRefNumber: ensureNotEmpty(paymentRefNumber),
					branchName: bank,
					depositorsPhone: ensureNotEmpty(depositorsPhone),
					depositorsRcNumber: ensureNotEmpty(depositorsRcNumber),
					totalAmountPaid,
					revenue,
					depositSlipNumber: ensureNotEmpty(depositSlipNumber),
					receiptNumber,
					billNumber: ensureNotEmpty(billNumber)
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`
					}
				}
			);

			if (res.status === 201) {
				history.push("/Maker-checker/inputter");
			}
		} catch (error) {
			// Handle errors here
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message:
					error.response?.data?.msg ||
					error.response.data?.errors?.[0] ||
					error.response.data?.errors?.[0]?.message ||
					error.response.data?.errors?.details[0]?.message ||
					error.response.message
			});
		} finally {
			setLoading(false);
		}
	};
	//fetch esbn for customer name
	useEffect(() => {
		const getESBN = async () => {
			const res = await axios
				.get(`users/anssid?anssid=${esbn}`)
				.then((res) => (res.data || {}).data);

			if (res.accounttype === "individual") {
				setCusName(`${res.firstname} ${res.surname}`);
			} else {
				setCusName(res.name);
			}
		};
		if (esbn?.length >= 10) getESBN();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [esbn]);

	useEffect(() => {
		const getBillAmount = async () => {
			// Make an API call
			axios
				.get(`/billingno/${billNumber}`)
				.then((res) => {
					// Assuming res.data.data[0].TotalAmtBilled is the property you want to convert to a string
					const totalAmtBilledString = String(
						res.data.data[0].TotalAmtBilled
					);

					// Set the state with the string value
					setTAmount(totalAmtBilledString);

					// ... other logic
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		};
		if (billNumber?.length >= 18) getBillAmount();
	}, [billNumber]);

	const getRevenueHeads = async () => {
		const res = await axios
			.get(`users/mdarevenue`)
			.then((res) => (res.data || {}).data);
		setRevhead(res);
	};
	useEffect(() => {
		getRevenueHeads();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const RevenueHead = async () => {
		const res = await axios
			.get(`/settings/mdalinkedrevenue/${mda}`)
			.then((res) => (res.data || {}).data);
		setRevhead(res);
	};

	useEffect(() => {
		RevenueHead();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mda]);

	const Mdas = async () => {
		try {
			const res = await axios.get(`/settings/allmdas`);
			const data = res.data?.data;
			setMdahead(data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		}
	};

	useEffect(() => {
		Mdas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form className={[styles1.columned__form, styles.main].join(" ")}>
				<div className={styles1.double__inputs}>
					<div>
						<label className={styles1.input__label} htmlFor="esbn">
							ESBN
						</label>
						<input
							name="esbn"
							type="text"
							id="esbn"
							value={esbn}
							onChange={(e) => setESBN(e.target.value)}
							placeholder="eg. 00954848493"
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="customerName"
						>
							Customer <span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="customerName"
							type="text"
							id="customerName"
							value={customerName}
							onChange={(e) => setCusName(e.target.value)}
							placeholder="eg. John Doe"
						/>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="billNumber"
						>
							Bill Number
						</label>
						<input
							name="billNumber"
							type="text"
							id="billNumber"
							value={billNumber}
							onChange={(e) => setBillNumber(e.target.value)}
							placeholder="eg. EN20231000000071EN"
						/>
					</div>
					<div>
						<label className={styles1.input__label} htmlFor="bank">
							Bank<span style={{ color: "red" }}>*</span>
						</label>
						<select
							value={bank}
							onChange={(e) => setBank(e.target.value)}
						>
							<option value={""}>--Select Bank--</option>
							{BankData &&
								BankData.map((bank) => (
									<option value={bank.name}>
										{bank.name}
									</option>
								))}
						</select>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="address"
						>
							Address <span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="address"
							type="text"
							id="address"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							placeholder="eg. New Haven "
						/>
					</div>
				</div>
				<hr />
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="depositorsPhone"
						>
							Depositor’s Phone No.{" "}
						</label>
						<input
							name="depositorsPhone"
							type="text"
							id="depositorsPhone"
							value={depositorsPhone}
							onChange={(e) => setDepositorsPhone(e.target.value)}
							placeholder="Enter Phone Number"
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="depositSlipNumber"
						>
							Deposit Slip No.
						</label>
						<input
							name="depositSlipNumber"
							type="text"
							id="depositSlipNumber"
							value={depositSlipNumber}
							onChange={(e) =>
								setDepositSlipNumber(e.target.value)
							}
							placeholder="Enter Deposit Slip Number"
						/>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label className={styles1.input__label} htmlFor="esbn">
							Depositor’s RC-No{" "}
						</label>
						<input
							name="esbn"
							type="text"
							id="esbn"
							value={depositorsRcNumber}
							onChange={(e) =>
								setDepositorsRcNumber(e.target.value)
							}
							placeholder=" Enter Depositor's Rc number "
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="paymentRef"
						>
							Payment Ref No.
						</label>
						<input
							name="paymentRef"
							type="text"
							id="paymentRef"
							value={paymentRefNumber}
							onChange={(e) =>
								setPaymentRefNumber(e.target.value)
							}
							placeholder=" Enter payment Ref No"
						/>
					</div>
				</div>
				<hr />
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="Total paid"
						>
							Total Amount Paid{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="Total Paid"
							type="number"
							id="Total paid"
							value={totalAmountPaid}
							onChange={(e) => setTAmount(e.target.value)}
							placeholder="Amount"
						/>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="receiptNumber"
						>
							Receipt No<span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="receiptNumber"
							type="text"
							id="receiptNumber"
							value={receiptNumber}
							onChange={(e) => setReceiptNumber(e.target.value)}
						/>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="paymentMethod"
						>
							Payment Method{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="paymentMethod"
							value={paymentMethod}
							onChange={(e) => setPayMth(e.target.value)}
						>
							<option value="">--select-payment-method--</option>
							<option>Cash</option>
							<option>Transfer</option>
						</select>
					</div>
					<div>
						<label
							className={styles1.input__label}
							htmlFor="PaymentDate"
						>
							Payment Date
						</label>
						<ReactDatePicker
							id="PaymentDate"
							onChange={(Date) => setPaymentDate(Date)}
							selected={paymentDate}
						/>
					</div>
				</div>
				<div className={styles1.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="mda">
							MDAS <span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="mda"
							className={styles.input__medium}
							name="mda"
							onChange={(e) => {
								setMdas(e.target.value);
							}}
							value={mda}
							required
						>
							<option value="">
								{getmdas ? "--Select MDA --" : "Loading..."}
							</option>
							{getmdas &&
								getmdas.map((getMdas) => (
									<option value={getMdas.Code}>
										{getMdas.Description}
									</option>
								))}
						</select>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="revenue"
						>
							Revenue <span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="revenue"
							className={styles.input__medium}
							name="revenue"
							onChange={(e) => {
								setRevenue(e.target.value);
							}}
							value={revenue}
							required
						>
							<option value="">
								{getrev
									? "--Select Revenue head--"
									: "Loading..."}
							</option>
							{getrev &&
								getrev.map((getRev) => (
									<option value={getRev.Code}>
										{getRev.Description}
									</option>
								))}
						</select>
					</div>
				</div>

				<div>
					<button
						disabled={
							!bank ||
							!receiptNumber ||
							!mda ||
							!revenue ||
							!totalAmountPaid
						}
						onClick={PostData}
						className={["primary__btn", style.btn].join(" ")}
					>
						Submit
						{loading && <Spinner />}
					</button>
				</div>
			</form>
		</>
	);
};

export default Addnew;
