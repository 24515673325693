import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Table from "../../components/Table";
import styles from "../MDAs/style.module.css";
import authorizeStyles from "./style.module.css";
// const coy_ANSSID = process.env.REACT_APP_ANSSID

const Authorize = () => {
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [name, setName] = useState("");
	const [filter, setFilter] = useState("name");

	const headers = useMemo(
		() => [
			["Individual's Name", "NAME"],
			["Email Address", "Email_Address"],
			["Phone Number", "PhoneNumber"],
			["Taxpayer Type", "Account_Type"]
		],
		[]
	);

	const getUnauthorized =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			let query = `page=${currentPage}&pageSize=20&`;

			try {
				const accessToken = localStorage.getItem("access_token");
				const info = await axios
					.get(`/anssidSelfServiceSearch?${query}&isActive=0`, {
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					})
					.then((res) => res.data);

				if (info) {
					setData(info?.data);
					setTotal(info?.total);
				}
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		};

	const search = (e) => {
		e.preventDefault();
		alert("doesn't work yet");
	};

	useEffect(() => {
		getUnauthorized()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.MDA__page}>
			<form className={authorizeStyles.search} onSubmit={search}>
				<input
					type="text"
					value={name}
					onChange={(e) => setName(e.target.value)}
					placeholder="Individual Name"
				/>
				<select
					value={filter}
					onChange={(e) => setFilter(e.target.value)}
				>
					<option value="name">Name</option>
				</select>
				<button type="submit" disabled={!filter}>
					Search
				</button>
			</form>
			<div className={styles.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getUnauthorized}
						onView={(data) => {
							history.push(
								`/individual-details?userId=${data.id}`,
								{ userId: data.id, authorize: true }
							);
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default Authorize;
