import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import axios from "axios";
import styles from "./style.module.css";
import backArrow from "../../assets/ANSSID/backArrow.svg";
import coatOfArm from "../../assets/coatOfArm.svg";
import anssidLogo from "../../assets/anssidLogo.svg";
import AnambraLogo from "../../assets/anambraLogo.svg";
import PrintStamp from "../../assets/ANSSID/detailsPrintStamp.svg";
const coy_ANSSID = process.env.REACT_APP_ANSSID;

const IndividualAuditDetails = () => {
	const printableDetailsRef = useRef();
	const history = useHistory();
	const location = useLocation();
	const [details, setDetails] = useState(null);
	const [loading, setLoading] = useState(false);

	async function fetchDetails() {
		try {
			const accessToken = localStorage.getItem("access_token");
			let { data } = await axios
				.get(`/anssid?anssid=${location.state.anssid}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => res.data);

			if (!data) {
				// handle errors
				return null;
			}

			setDetails(data);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div style={{ display: "none" }}>
				<div
					className={styles.print__details}
					ref={printableDetailsRef}
				>
					<div className={styles.print__details__header}>
						<img src={coatOfArm} alt="coat of arm" />
						<img src={anssidLogo} alt="logo" />
						<img src={AnambraLogo} alt="State logo" />
					</div>
					<main className={styles.print__main}>
						<div className={styles.print__left}>
							<div className={styles.main__top}>
								<div>
									<h2>Biometrics</h2>
									<img
										src={details?.biometricPhoto}
										alt="biometrics"
									/>
								</div>
								<div>
									<h2>{coy_ANSSID} Details</h2>
									<div>{details?.anssid || "N/A"}</div>
									<div>
										{(details?.createdAt &&
											new Date(
												details?.createdAt
											).toLocaleDateString("en-GB", {
												dateStyle: "short"
											})) ||
											"N/A"}
									</div>
									<div>{details?.status || "N/A"}</div>
								</div>
							</div>
							<h2>Personal Details</h2>
							<div className={styles.deets}>
								Title<span>{details?.title || "N/A"}</span>
							</div>
							<div className={styles.deets}>
								Firstname
								<span>{details?.firstname}</span>
							</div>
							<div className={styles.deets}>
								Middlename
								<span>{details?.middlename || "N/A"}</span>
							</div>
							<div className={styles.deets}>
								Surname
								<span>{details?.surname || "N/A"}</span>
							</div>
							<div className={styles.deets}>
								Mother's maiden name
								<span>
									{details?.mothermaidianname || "N/A"}
								</span>
							</div>
							<div className={styles.deets}>
								<div className={styles.deets}>
									Date of birth
									<span>
										{(details &&
											new Date(
												details?.dateofbirth
											).toLocaleString("en-GB", {
												dateStyle: "short"
											})) ||
											"N/A"}
									</span>
								</div>{" "}
								Gender
								<span>{details?.sex || "N/A"}</span>
							</div>

							<div className={styles.deets}>
								Phone No
								<span>{details?.phonenumber || "N/A"}</span>
							</div>
							<div className={styles.deets}>
								Email Address
								<span>{details?.email || "N/A"}</span>
							</div>
						</div>
						<div className={styles.print__right}>
							<img src={PrintStamp} alt="Stamp" />
						</div>
					</main>
				</div>
			</div>
			<div className={styles.top}>
				<button onClick={() => history.goBack(-1)}>
					<img src={backArrow} alt="back arrow" /> <span>Back</span>
				</button>
				<ReactToPrint
					trigger={() => <button>Print Details</button>}
					content={() => printableDetailsRef.current}
				/>
			</div>
			<h1 className={styles.header}>Individual Details</h1>
			<section className={styles.details}>
				<div className={styles.left}>
					<div className={styles.details__block}>
						<h2>{coy_ANSSID} Details</h2>
						<div>
							{coy_ANSSID}{" "}
							<span>{loading ? "..." : details?.anssid}</span>
						</div>
						<div>
							Registration Date{" "}
							<span>
								{loading
									? "..."
									: (details?.createdAt &&
											new Date(
												details?.createdAt
											).toLocaleDateString("en-GB", {
												dateStyle: "full"
											})) ||
										"N/A"}
							</span>
						</div>
						<div>
							Created By{" "}
							<span>
								{loading ? "..." : details?.createdBy || "N/A"}
							</span>
						</div>
						<div>
							Modified By{" "}
							<span>
								{loading ? "..." : details?.modifiedBy || "N/A"}
							</span>
						</div>
						<div>
							Status{" "}
							<span>
								{loading ? "..." : details?.status || "N/A"}
							</span>
						</div>
					</div>
					<div className={styles.details__block}>
						<h2>Personal Details</h2>
						<div>
							Title{" "}
							<span>{loading ? "..." : details?.title}</span>
						</div>
						<div>
							Firstname{" "}
							<span>{loading ? "..." : details?.firstname}</span>
						</div>
						<div>
							Middlename{" "}
							<span>{loading ? "..." : details?.middlename}</span>
						</div>
						<div>
							Surname{" "}
							<span>{loading ? "..." : details?.surname}</span>
						</div>
						<div>
							Mother's Maiden name{" "}
							<span>
								{loading ? "..." : details?.mothermaidianname}
							</span>
						</div>
						<div>
							Gender <span>{loading ? "..." : details?.sex}</span>
						</div>
						<div>
							Date of birth{" "}
							<span>
								{loading
									? "..."
									: details &&
										new Date(
											details?.dateofbirth
										).toLocaleString("en-GB", {
											dateStyle: "full"
										})}
							</span>
						</div>
						<div>
							Phone no{" "}
							<span>
								{loading ? "..." : details?.phonenumber}
							</span>
						</div>
						<div>
							Email Address{" "}
							<span>{loading ? "..." : details?.email}</span>
						</div>
					</div>
					<div className={styles.details__block}>
						<h2>Other Information</h2>
						<div>
							Nationality{" "}
							<span>
								{loading ? "..." : details?.nationality}
							</span>
						</div>
						<div>
							State of Origin{" "}
							<span>
								{loading ? "..." : details?.stateoforigin}
							</span>
						</div>
						<div>
							LGA of Origin{" "}
							<span>
								{loading ? "..." : details?.lgaoforigin}
							</span>
						</div>
						<div>
							Residential Address{" "}
							<span>
								{loading ? "..." : details?.residentialaddress}
							</span>
						</div>
						<div>
							State of Residence{" "}
							<span>
								{loading
									? "..."
									: details?.stateofresidence || "N/A"}
							</span>
						</div>
						<div>
							Local Govt Area of Residence{" "}
							<span>
								{loading ? "..." : details?.lgaofresidence}
							</span>
						</div>
						<div>
							Town of Residence{" "}
							<span>
								{loading
									? "..."
									: details?.townofresidence || "N/A"}
							</span>
						</div>
						<div>
							City of Residence{" "}
							<span>
								{loading
									? "..."
									: details?.cityofresidence || "N/A"}
							</span>
						</div>
						<div>
							BVN{" "}
							<span>
								{loading ? "..." : details?.bvn || "N/A"}
							</span>
						</div>
						<div>
							NIN{" "}
							<span>
								{loading ? "..." : details?.nin || "N/A"}
							</span>
						</div>
					</div>
					<div className={styles.details__block}>
						<h2>Family Information</h2>
						<div>
							Marital Status{" "}
							<span>
								{loading
									? "..."
									: details?.maritalstatus || "N/A"}
							</span>
						</div>
						<div>
							Children{" "}
							<span>
								{loading ? "..." : details?.children.length}
							</span>
						</div>
					</div>
					<div className={styles.details__block}>
						<h2>Employment Information</h2>
						<div>
							Occupation{" "}
							<span>
								{loading
									? "..."
									: details?.occupationtype || "N/A"}
							</span>
						</div>
						<div>
							Employer's name{" "}
							<span>
								{loading
									? "..."
									: details?.employername || "N/A"}
							</span>
						</div>
						<div>
							City/Town of Employer’s Address{" "}
							<span>
								{loading
									? "..."
									: details?.employercity || "N/A"}
							</span>
						</div>
						<div>
							Personal Monthly Income Range{" "}
							<span>
								{loading
									? "..."
									: details?.salaryrange || "N/A"}
							</span>
						</div>
					</div>
					<div className={styles.details__block}>
						<h2>Proof of Identity</h2>
						<div>
							Voter's Card{" "}
							<span>
								{loading
									? "..."
									: details?.votersCardCode || "N/A"}
							</span>
						</div>
						<div>
							Card number{" "}
							<span>
								{loading ? "..." : details?.cardnumber || "N/A"}
							</span>
						</div>
					</div>
				</div>
				<div className={styles.right}>
					<h2>Biometrics</h2>
					<img
						src={details?.biometricPhoto}
						alt="biometric"
						className={styles.biopic}
					/>
				</div>
			</section>
			<ReactToPrint
				trigger={() => (
					<button
						className={`primary__btn ${styles.mobile__print_btn}`}
					>
						Print Details
					</button>
				)}
				content={() => printableDetailsRef.current}
			/>
		</div>
	);
};

export default IndividualAuditDetails;
