import Table from "../../components/Table";
import styles from "../MDAs/style.module.css";
import { createRef, useEffect, useState } from "react";
// import { isValid } from "date-fns";
import axios from "axios";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";

import Modal from "../../components/Modal";
import { exportExcel, formatAmount, remapObj } from "../../utils";

const BillingGroupAnalytics = () => {
	const [fullTableShowing, toggleFullTable] = useState(false);
	const [showPeriodModal, togglePeriodModal] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const [lgaCode, setLgaCode] = useState("");
	const [revenueCode, setRevenueCode] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState("");
	const [sortOrder, setSortOrder] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);

	const [total, setTotal] = useState(0);

	const filterRef = createRef();

	const [period, setPeriod] = useState([
		{
			key: "selection"
		}
	]);

	const headers = [
		["MDA Code", "MDACode"],
		["MDA Description", "MDADescription"],
		["Revenue Code", "RevenueCode"],
		["Revenue Description", "RevenueDescription"],
		["Billing Group Code", "BillGroupCode"],
		["Billing Group Description", "BillGroupDescription"],
		["Amount (NGN)", "Amount", formatAmount]
	];

	const _getAnalytics = ({ currentPage = 1, pageSize = 20 }) => {
		let query = `page=${currentPage}&pageSize=${pageSize}&`;

		if (lgaCode) {
			query += `lgaCode=${lgaCode}&`;
		}

		if (revenueCode) {
			query += `revenueCode=${revenueCode}&`;
		}

		if (minAmount) {
			query += `minAmount=${minAmount}&`;
		}

		if (maxAmount) {
			query += `maxAmount=${maxAmount}&`;
		}

		if (sortOrder) {
			query += `sortOrder=${sortOrder}&`;
		}

		// if (isValid(period[0].startDate)) {
		// 	query += `startDate=${new Date(period[0].startDate).getTime()}&`;
		// }
		// if (isValid(period[0].endDate)) {
		// 	query += `endDate=${new Date(period[0].endDate).getTime()}&`;
		// }

		return axios
			.get(`/admin/billgroupcollections?${query}`)
			.then((res) => (res.data || {}).data);
	};

	const exportAnalytics = async () => {
		const info = await _getAnalytics({ currentPage: 1, pageSize: total });
		if (!info.data) return;
		exportExcel(
			remapObj(info.data, headers),
			headers,
			`Billing Group Report - ${new Date().toLocaleString()}.xlsx`
		);
	};

	const getAnalytics =
		({ currentPage = 1, pageSize = 20 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			try {
				const info = await _getAnalytics({ currentPage, pageSize });

				if (info) {
					setData(info.data);
					setTotal(info.total);
				}
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		};

	const clearFilters = () => {
		setLgaCode("");
		setRevenueCode("");
		setMinAmount("");
		setMaxAmount("");
		setSortOrder("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getAnalytics()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getAnalytics()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.MDA__page}>
			<div className={styles.actions__div}>
				<button onClick={() => toggleFullTable(!fullTableShowing)}>
					View Entire Table
				</button>
				<button
					className={["primary__btn", styles.download__button].join(
						" "
					)}
					onClick={(e) => {
						exportAnalytics();
					}}
				>
					Download Report
				</button>
				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!lgaCode &&
								!revenueCode &&
								!minAmount &&
								!maxAmount &&
								!sortOrder
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="lga_code">LGA Code</label>
								<input
									name="lga_code"
									id="lga_code"
									type="text"
									value={lgaCode}
									onChange={(e) => {
										setLgaCode(e.target.value);
									}}
									placeholder="LGA Code"
								/>
							</div>
							<div>
								<label htmlFor="revenue_code">
									Revenue Code
								</label>
								<input
									name="revenue_code"
									id="revenue_code"
									type="text"
									value={revenueCode}
									onChange={(e) => {
										setRevenueCode(e.target.value);
									}}
									placeholder="Revenue Code"
								/>
							</div>
						</form>
						<p>By Amount</p>
						<form>
							<div>
								<label htmlFor="min_amount">Min Amount</label>
								<input
									name="min_amount"
									id="min_amount"
									type="number"
									value={minAmount}
									onChange={(e) => {
										setMinAmount(e.target.value);
									}}
									placeholder="100,000"
								/>
							</div>
							<div>
								<label htmlFor="max_amount">Max Amount</label>
								<input
									name="max_amount"
									id="max_amount"
									type="number"
									value={maxAmount}
									onChange={(e) => {
										setMaxAmount(e.target.value);
									}}
									placeholder="2,000,000"
								/>
							</div>
						</form>
						<p>Sort Taxpayer Name Alphabetically</p>
						<form className={styles.radio__form}>
							<div>
								<input
									name={sortOrder}
									id="a_to_z"
									type="radio"
									value="a-to-z"
									onChange={(e) =>
										setSortOrder(e.target.value)
									}
								/>
								<label htmlFor="a_to_z">A to Z</label>
							</div>
							<div>
								<input
									name={sortOrder}
									id="z_to_a"
									type="radio"
									value="z-to-a"
									onChange={(e) =>
										setSortOrder(e.target.value)
									}
								/>
								<label htmlFor="z_to_a">Z to A</label>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							type="submit"
							onClick={getAnalytics}
						>
							Search
						</button>
					</div>
				</div>
				{/* <button onClick={() => { togglePeriodModal(!showPeriodModal) }}>
          Select Period
				</button> */}
				{showPeriodModal && (
					<Modal
						showing={showPeriodModal}
						toggle={togglePeriodModal}
						small
						style={{
							width: "60%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center"
						}}
					>
						<DateRangePicker
							showDate={new Date()}
							onChange={(item) => setPeriod([item.selection])}
							showSelectionPreview={true}
							moveRangeOnFirstSelection={false}
							months={2}
							ranges={period}
							direction="horizontal"
						/>
						<footer
							style={{
								display: "flex",
								width: "80%",
								alignItems: "flex-end"
							}}
						>
							<button
								className={styles.submit__button}
								type="submit"
								onClick={(e) => {
									getAnalytics()(e);
									togglePeriodModal(false);
								}}
							>
								Search
							</button>
						</footer>
					</Modal>
				)}
			</div>
			<div className={styles.table__div}>
				<Table headers={headers} data={data} />
			</div>
			{fullTableShowing && (
				<Modal showing={fullTableShowing} toggle={toggleFullTable}>
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getAnalytics}
					/>
				</Modal>
			)}
		</div>
	);
};

export default BillingGroupAnalytics;
