import Logo from "../../assets/logo.svg";
import LaptopMan from "../../assets/Signup/laptopMan.svg";
import styles from "./style.module.css";

const Aside = ({ showOnMobile = true }) => {
	return (
		<aside
			className={`${styles.aside} ${!showOnMobile && styles.no__display}`}
		>
			<img src={Logo} alt="logo" />
			<p>
				Obtain your ESBN today and simplify the payment of all your
				taxes in Enugu State.
			</p>
			<img
				src={LaptopMan}
				alt="a man sitting with his laptop on... his lap."
			/>
		</aside>
	);
};

export default Aside;
