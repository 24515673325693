import style from "./style.module.css";
import Subscription from "../../Home/subscription/index";
import Footer from "../footer";
import NavBar from "../nav-bar";
import downloadLaw from "../../assets/Home/downloadLaw.svg";
import amendmentLaw from "../../assets/Home/amendmentLaw.svg";
import PersonalLaw from "../../assets/Home/PersonalLaw.svg";
import landUseLaw from "../../assets/Home/landUseLaw.svg";
import financialLaw from "../../assets/Home/financialLaw.svg";
import { useEffect } from "react";

const legislationCards = [
	{
		id: 1,
		title: "Personal Income Tax Act",
		link: "Download",
		image: PersonalLaw,
		downloadBtn: downloadLaw,
		document: "PersonalIncomeTaxAct",
		pdfUrl: "https://storage.googleapis.com/enugu-state-static/Personal-Income-Tax-Act.pdf"
	},
	{
		id: 2,
		title: "Financial Law of Enugu State",
		link: "Download",
		image: financialLaw,
		downloadBtn: downloadLaw,
		document: "FinanceLawofEnuguState",
		pdfUrl: "https://storage.googleapis.com/enugu-state-static/Finance%20Law%20of%20Enugu%20State.pdf"
	},
	{
		id: 3,
		title: "Amendment of the Financial Law 2016",
		link: "Download",
		image: amendmentLaw,
		downloadBtn: downloadLaw,
		document: "AmendmenttotheFinanceLaw2016",
		pdfUrl: "https://storage.googleapis.com/enugu-state-static/Amendment%20to%20the%20Finance%20Law%202016.pdf"
	},
	{
		id: 4,
		title: "Land Use Charge Law ",
		link: "Download",
		image: landUseLaw,
		downloadBtn: downloadLaw,
		document: "LandUseChargeLaw",
		pdfUrl: "https://storage.googleapis.com/enugu-state-static/Land%20Use%20Charge%20Law.pdf"
	}
];

const TaxLegislation = () => {
	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<main>
			<NavBar />
			<section className={style.heroSection}>
				<h2>Tax Legislation </h2>
				<h3 className={style.desktop}>
					Find relevant Tax Laws from the Federal and State levels, as
					well <br /> as publication and Decided Cases.{" "}
				</h3>
				<h3 className={style.mobile}>
					Find relevant Tax Laws from the Federal and State <br />
					levels, as well as publication and Decided Cases.{" "}
				</h3>
			</section>
			<section className={style.legislationLawsContainer}>
				{legislationCards.map((law, index) => (
					<div key={law.id} className={style.legislationCards}>
						<img
							src={law.image}
							alt="Law Documents"
							className={style.legislationImg}
						/>
						<h2>{law.title}</h2>
						<a href={law.pdfUrl} target="_blank" rel="noreferrer">
							<img
								src={law.downloadBtn}
								alt="downloadBtn"
								className={style.legislationBtn}
							/>
						</a>
					</div>
				))}
			</section>
			<Subscription />
			<Footer />
		</main>
	);
};

export default TaxLegislation;
