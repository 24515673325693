import { useState, useEffect, createRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Table from "../../../components/Table2";
import styles from "./style.module.css";
import styles1 from "../../MDAs/style.module.css";
import anssidStyles from "../../StateTIN/style.module.css";

const PayeRecords = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [finished, setFinished] = useState("0");
	const [ESBN, setESBN] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [BillNumber, setBillNumber] = useState("");
	const [total, setTotal] = useState(0);
	const [data, setData] = useState([]);
	const [paymentStatus, setPaymentStatus] = useState("");
	const [processStatus, setProcessStatus] = useState("");
	const [filler, setFiller] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const filterRef = createRef();
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const getAssessmentRecords =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (!filterRef.current?.classList.contains(styles1.no__display)) {
				filterRef.current?.classList.toggle(styles1.no__display);
			}

			let query = `page=${currentPage}&pageSize=20&`;
			if (ESBN) {
				query += `ESBN=${ESBN}&`;
			}

			if (companyName) {
				query += `Name=${companyName}&`;
			}

			if (BillNumber) {
				query += `billNo=${BillNumber}&`;
			}

			if (filler) {
				query += `fileMode=${filler}&`;
			}

			if (paymentStatus) {
				query += `PaymentStatus=${paymentStatus}&`;
			}

			if (processStatus) {
				query += `proStatus=${processStatus}&`;
			}

			if (minAmount) {
				query += `minAmt=${minAmount}&`;
			}

			if (maxAmount) {
				query += `maxAmt=${maxAmount}&`;
			}

			if (startDate) {
				query += `startdate=${startDate}&`;
			}

			if (endDate) {
				query += `enddate=${endDate}&`;
			}

			try {
				let { data, total } = await axios
					.get(`/dareports/tmspayedisplayreviewer?${query}`)
					.then((res) => res.data || { data: {} });

				if (!data) {
					return null;
				}

				setData(data);
				setTotal(total);
				setLoading(false);

				return data;
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.message ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			} finally {
				setFinished("1");
				setLoading(false);
			}
		};

	useEffect(() => {
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getAssessmentRecords()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finished === "1"]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const getStatusColor = (status) => {
		switch (status) {
			case "Approved":
				return "#2F9959";
			case "Pending Review":
				return "brown";
			case "PAID":
				return "#2F9959";
			case "Revoke":
				return "#D73E3E";
			case "Cancel":
				return "#D73E3E";
			case "Pending":
				return "#F99601";
			case "Pending Approval":
				return "#F99601";
			default:
				return "black";
		}
	};

	const processStatusMap = {
		0: "Pending Review",
		1: "Cancel",
		2: "Decline",
		3: "Approved",
		4: "Revoke",
		5: "Pending Approval",
		6: "Delete"
	};

	const getModeOfFilingText = (modeOfFiling) => {
		if (modeOfFiling === "0") {
			return "Self-Service";
		} else if (modeOfFiling === "1") {
			return "Admin";
		} else {
			return "";
		}
	};

	const modifiedData = data.map((item) => {
		const ProcessStatusFlag = processStatusMap[item.ApproveFlg] || "";
		const ModeOfFilingFlag = getModeOfFilingText(item.ModeOfFiling) || "";

		return {
			...item,
			ProcessStatus: (
				<span style={{ color: getStatusColor(ProcessStatusFlag) }}>
					{ProcessStatusFlag}
				</span>
			),
			PaymentStatus: (
				<span style={{ color: getStatusColor(item.PaymentStatus) }}>
					{item.PaymentStatus}
				</span>
			),
			ModeOfFiling: <span>{ModeOfFilingFlag}</span>
		};
	});

	const clearFilters = () => {
		setESBN("");
		setCompanyName("");
		setBillNumber("");
		setFiller("");
		setPaymentStatus("");
		setProcessStatus("");
		setMinAmount("");
		setMaxAmount("");
		setStartDate("");
		setEndDate("");

		filterRef.current.classList.toggle(styles1.no__display);
		setFiltersCleared(true);
	};

	const headers = [
		[`ESBN`, "CoyESBN"],
		["Company Name", "CoyName"],
		["Bill Number", "BillNumber"],
		["Type of Assessment", "PayeType"],
		["Fiscal Year ", "fiscalYear"],
		["Duration", "PayTypeMode"],
		["Total Num of Staff", "totStaffNo"],
		["Total Monthly charge", "totMthAmtBilled"],
		["Generated by ", "ModeOfFiling"],
		["Payment Status", "PaymentStatus"],
		["Process Status", "ProcessStatus"]
	];

	return (
		<main>
			<div className={`styles1.action__btns ${styles.header__btns}`}>
				<button
					className={`primary__btn ${anssidStyles.create__btn}`}
					onClick={() => history.push("/PAYE-Assessment")}
				>
					File for PAYE{" "}
				</button>
				<div className={styles1.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles1.no__display
							);
						}}
						className={`${styles1.filter__button} ${styles.filter__button}`}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles1.filter__div,
							styles1.no__display
						].join(" ")}
					>
						<div className={styles1.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles1.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles1.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!ESBN &&
								!companyName &&
								!BillNumber &&
								!paymentStatus &&
								!processStatus &&
								!filler &&
								!minAmount &&
								!maxAmount &&
								!startDate &&
								!endDate
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="ESBN">ESBN</label>
								<input
									name="ESBN"
									id="ESBN"
									type="text"
									value={ESBN}
									onChange={(e) => setESBN(e.target.value)}
									placeholder="Enter ESBN"
								/>
							</div>
							<div>
								<label htmlFor="BillNumber">
									Company Bill Number
								</label>
								<input
									name="BillNumber"
									id="BillNumber"
									type="text"
									value={BillNumber}
									onChange={(e) =>
										setBillNumber(e.target.value)
									}
									placeholder="Eg.12345678"
								/>
							</div>
						</form>
						<p>By Company Name</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="companyName">
									Company Name
								</label>
								<input
									name="companyName"
									id="companyName"
									type="text"
									value={companyName}
									onChange={(e) =>
										setCompanyName(e.target.value)
									}
									placeholder="ABC Nig. Ltd "
								/>
							</div>
							<div>
								<label htmlFor="Filler">Filler</label>
								<input
									name="Filler"
									id="Filler"
									type="text"
									value={filler}
									onChange={(e) => setFiller(e.target.value)}
									placeholder="Admin"
								/>
							</div>
						</form>

						<p>By Company Status</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="paymentStatus">
									Payment Status
								</label>
								<select
									name="paymentStatus"
									id="paymentStatus"
									value={paymentStatus}
									onChange={(e) =>
										setPaymentStatus(e.target.value)
									}
								>
									<option value="">Select</option>
									<option>Pending</option>
									<option>PAID</option>
								</select>
							</div>
							<div>
								<label htmlFor="processStatus">
									Process Status
								</label>
								<select
									name="processStatus"
									id="processStatus"
									value={processStatus}
									onChange={(e) =>
										setProcessStatus(e.target.value)
									}
								>
									<option value="">Select</option>
									<option>Pending</option>
									<option>Pending Approval </option>
									<option>Approved</option>
									<option>Decline</option>
									<option>Revoke</option>
									<option>Cancel</option>
								</select>
							</div>
						</form>
						<p>By Amount Paid</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="minAmountPaid">
									Min Amount{" "}
								</label>
								<input
									name=""
									id="minAmountPaid"
									type="number"
									value={minAmount}
									onChange={(e) =>
										setMinAmount(e.target.value)
									}
									placeholder="Eg.100,000"
								/>
							</div>
							<div>
								<label htmlFor="maxAmountPaid">
									Max Amount
								</label>
								<input
									name="	"
									id="maxAmountPaid"
									type="number"
									value={maxAmount}
									onChange={(e) =>
										setMaxAmount(e.target.value)
									}
									placeholder="Eg.200,000"
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>

						<button
							className={[
								styles1.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getAssessmentRecords()}
						>
							Search
						</button>
					</div>
				</div>
			</div>

			<div style={{ margin: "0px" }}>
				<div className={styles1.table__div}>
					{loading && !data ? (
						<p style={{ textAlign: "center" }}>Loading...</p>
					) : (
						<Table
							headers={headers}
							data={modifiedData}
							full
							pageSize={20}
							total={total}
							onPageChanged={getAssessmentRecords}
							onView={(data) => {
								history.push(`/Paye-Review`, {
									...data,
									ProcessStatus:
										processStatusMap[data.ApproveFlg],
									PaymentStatus:
										processStatusMap[data.PaymentStatus],
									ModeOfFiling:
										getModeOfFilingText[data.ModeOfFiling],
									view: true
								});
							}}
						/>
					)}
				</div>
			</div>
		</main>
	);
};

export default PayeRecords;
