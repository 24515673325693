/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import axios from "axios";
import Spinner from "../../components/spinner";
import Alert from "../../components/Alert";
import styles from "../../Identity-management-individual/style.module.css";
import style from "./style.module.css";
import backArrow from "../../assets/arrowLeft.svg";
import PAYEEmodule from "../../Dashboard/PAYE/Paye-Record/style.module.css";

function formatDate(date) {
	const [d] = new Date(date).toISOString().split("T");
	return d;
}

let userInfo = localStorage.getItem("user_info");
if (userInfo) {
	userInfo = JSON.parse(userInfo);
}

const CreateUser = () => {
	const history = useHistory();
	const { state: locationState = {} } = useLocation();

	// const { user: lUser = {} } = locationState;
	const lUser = locationState?.user;

	const [values, setValues] = useState({
		tin: lUser?.tin || "",
		tinDoB: lUser?.tinDoB || "",
		firstname: lUser?.firstname || "",
		middlename: lUser?.middlename || "",
		lastname: lUser?.surname || "",
		email: lUser?.email || "",
		phone: lUser?.phonenumber || "",
		mdaCode: lUser?.mdaCode || "",
		categoryCode: lUser?.categoryCode || "",
		anssidAgent: "",
		accountType: locationState?.fromIndividual
			? "individual"
			: lUser?.role || ""
	});

	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const [roles, setRoles] = useState([]);
	const [agents, setAgents] = useState([]);
	const [mdas, setMdas] = useState([]);
	const [categories, setCategories] = useState([]);

	const [tin, setTIN] = useState("");
	const [tinDoB, setTINDoB] = useState("");
	const [tinError, setTinError] = useState("");
	const [error, setError] = useState();

	const getRoles = async () => {
		const accessToken = localStorage.access_token;

		const request = await axios.get(`/auth/role`, {
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		});

		let r = request?.data?.data?.roles;
		if (locationState?.taxpayers) {
			const role = userInfo?.roles?.[0]?.Name;
			const iTest = /individual/i.test(role);
			const cTest = /corporate/i.test(role);

			const regexp =
				iTest && cTest
					? /^(individual)|(corporate)$/i
					: iTest
						? /^individual$/i
						: cTest
							? /^corporate$/i
							: /^(individual)|(corporate)$/i;
			r = r?.filter((rl) => regexp.test(rl.Name));
		} else {
			r = r?.filter((rl) => !/^(individual)|(corporate)$/i.test(rl.Name));
		}
		setRoles(r);
	};

	const getAnssidAgents = async () => {
		const accessToken = localStorage.access_token;

		const request = await axios.get(
			`/admin/usermanagement/getanssidagents`,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
		);

		setAgents(request?.data?.data);
	};

	const getMDAs = async () => {
		const accessToken = localStorage.access_token;

		const request = await axios.get(`/settings/mdasetup`, {
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		});

		setMdas(request?.data?.data);
	};

	const getCategories = async () => {
		const accessToken = localStorage.access_token;

		const request = await axios.get(`/settings/category`, {
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		});

		setCategories(request?.data?.data);
	};

	useEffect(() => {
		getRoles();
		getAnssidAgents();
		getMDAs();
		getCategories();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			10000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;

		setValues({
			...values,
			[name]: value
		});
	};

	const jtbChecks = async (e) => {
		//e.preventDefault();
		setError("");

		const chk = e;
		setTinError("");
		setTINDoB("");

		if (chk !== "") {
			try {
				// setTINname("")		// clear textbox
				let urlPrefix = "";

				const accessToken = localStorage.getItem("access_token");
				await axios
					.post(
						`${urlPrefix}/txn/jtb/verifyInd`,
						{
							tin: chk,
							userId: ""
						},
						{
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						}
					)
					.then((res) => {
						console.log(res);
						if (res?.data?.ResponseCode === "004") {
							setTinError(
								"TIN " + res?.data?.ResponseDescription
							);
							setError("TIN " + res?.data?.ResponseDescription);
						} else if (res?.data?.ResponseCode === "001") {
							//---
							const dg = res?.data?.Taxpayer?.date_of_birth;
							var kl = dg.split("-");
							var nDate = "";
							if (kl[2].length === 4) {
								nDate = kl[2] + "-" + kl[1] + "-" + kl[0];
							}
							//---

							setTinError("");
							setError("");
							setTINDoB(formatDate(new Date(nDate)));

							setValues({
								...values,
								firstname: res?.data?.Taxpayer?.first_name,
								lastname: res?.data?.Taxpayer?.last_name,
								email: res?.data?.Taxpayer?.email,
								phone: res?.data?.Taxpayer?.phone_no
							});
						}
					});
			} catch (err) {
				let message = "";
				if (err.response) {
					message =
						err.response.data.errors?.details[0].message ||
						err.response.data?.error ||
						err.response.data?.errors;
				} else {
					message = err.message;
				}
				setError(
					message ||
						"Something goes wrong. Unable to submit your request. "
				);
			}
		}
	};

	const submit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const accessToken = localStorage.access_token;

		try {
			const { url, method } = locationState?.edit
				? {
						url: `/admin/usermanagement/updateUser/${locationState.user?.id}`,
						method: "put"
					}
				: { url: `/admin/usermanagement/adduser`, method: "post" };
			const res = await axios[method](
				url,
				{
					firstname: values.firstname,
					middlename: values.middlename,
					lastname: values.lastname,
					phonenumber: values.phone,
					email: values.email,
					roles: values.accountType,
					accounttype: values.accountType,
					mdaCode: values.mdaCode,
					categoryCode: values.categoryCode,
					ssActive: locationState.ssActive,
					tin: tin,
					tinDoB: tinDoB
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			).then((res) => (res.data || {}).data);
			setLoading(false);

			if (locationState?.taxpayers) {
				const {
					firstname,
					lastname,
					middlename,
					email,
					phone,
					accountType
				} = values;

				history.push(
					`/${values.accountType}-identity-management?userId=${res.id}`,
					{
						userId: res.id,
						firstname,
						lastname,
						middlename,
						email,
						phone,
						tin,
						tinDoB,
						accountType
					}
				);
			}

			setValues({
				...values,
				firstname: "",
				middlename: "",
				lastname: "",
				email: "",
				phone: "",
				mdaCode: "",
				categoryCode: "",
				anssidAgent: "",
				accountType: "",
				tin: "",
				tinDoB: ""
			});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: "User Created!"
			});
		} catch (error) {
			setLoading(false);
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors?.[0] ||
						error.response.data?.message
				});
			} else {
				console.log("Error", error.message);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	return (
		<main className={style.wrapper}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form className={styles.columned__form} onSubmit={submit}>
				{locationState?.taxpayers && (
					<>
						<div>
							<button
								className={`${PAYEEmodule.review_header_btn} ${PAYEEmodule.customBackButton}`}
								onClick={() =>
									history.push("/statetin/individual")
								}
							>
								<img
									src={backArrow}
									alt="backArrow"
									className={PAYEEmodule.backArrow}
								/>
								<span className={PAYEEmodule.backText}>
									Back
								</span>
							</button>
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="tin"
								style={{ marginTop: "15px" }}
							>
								Tax Identification Number(TIN){" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								id="tin"
								name="tin"
								type="text"
								placeholder="Input your TIN"
								onChange={(e) => {
									setTIN(e.target.value);
									jtbChecks(e.target.value);
								}}
								value={tin}
								// required
							/>
						</div>
						<hr className={styles.divider} />
					</>
				)}
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="firstname"
						>
							First Name <span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="firstname"
							type="text"
							onChange={handleChange}
							id="firstname"
							placeholder="eg Jane"
							required
							value={values.firstname}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="middlename"
						>
							Middle Name
						</label>
						<input
							name="middlename"
							type="text"
							onChange={handleChange}
							id="middlename"
							placeholder="eg Chinenye"
							value={values.middlename}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="lastname"
						>
							Surname <span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="lastname"
							type="text"
							onChange={handleChange}
							id="lastname"
							placeholder="Okafor"
							required
							value={values.lastname}
						/>
					</div>
				</div>
				<hr className={styles.divider} />
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="accessibleProfit"
						>
							Email Address{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="email"
							type="email"
							onChange={handleChange}
							id="email"
							placeholder="eg John.doe@gmail.com"
							required
							value={values.email}
						/>
					</div>
					<div>
						<label className={styles.input__label} htmlFor="phone">
							Phone Number <span style={{ color: "red" }}>*</span>
						</label>
						<input
							name="phone"
							type="text"
							onChange={handleChange}
							id="phone"
							placeholder="+234-8030005000"
							required
							value={values.phone}
						/>
					</div>
				</div>
				{!locationState?.taxpayers && (
					<>
						<hr className={styles.divider} />
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="mdaCode"
								>
									MDA
								</label>

								<select
									name="mdaCode"
									id="mdaCode"
									className={styles.select__input}
									// required
									value={values.mdaCode}
									onChange={handleChange}
								>
									<option value="">--Select MDA--</option>
									{mdas.map((m) => (
										<option key={m.id} value={m.Code}>
											{m.Description}
										</option>
									))}
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="categoryCode"
								>
									Category
								</label>
								<select
									name="categoryCode"
									id="categoryCode"
									className={styles.select__input}
									// required
									value={values.categoryCode}
									onChange={handleChange}
								>
									<option value="">
										--Select Category--
									</option>
									{categories.map((cat) => (
										<option key={cat.id} value={cat.id}>
											{cat.Description}
										</option>
									))}
								</select>
							</div>
						</div>
					</>
				)}
				<hr className={styles.divider} />
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="accountType"
						>
							Role <span style={{ color: "red" }}>*</span>
						</label>
						<select
							name="accountType"
							id="accountType"
							className={styles.select__input}
							required
							value={values.accountType}
							onChange={handleChange}
							disabled={locationState?.fromIndividual}
						>
							<option value="">--Select Role--</option>
							{roles.map((role) => (
								<option value={role.Name}>{role.Name}</option>
							))}
						</select>
					</div>
				</div>
				<footer className={styles.form__footer}>
					<button
						disabled={loading}
						type="submit"
						className={[
							"primary__btn",
							styles.primary__button,
							loading ? styles.loading__button : ""
						].join(" ")}
					>
						Save
						{loading && <Spinner />}
					</button>
				</footer>
			</form>
		</main>
	);
};

export default CreateUser;
