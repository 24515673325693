/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./style.module.css";
import styles1 from "../Identity-management-individual/style.module.css";
import style from "./style.module.css";
import axios from "axios";
import Alert from "../components/Alert";
import { RevenueHeads } from "./components/revenueHeads";
import Modal from "../components/Modal";
import UnifiedPayment from "../assets/Home/up-logo.png";

const coylogo = process.env.REACT_APP_COYNAME;

let imgpath = "../assets/Home/" + process.env.REACT_APP_COYLOGO;

const Unifiedpayment = () => {
	const [BillNumber, setBillNumber] = useState("");
	const [billNumberDetails, setBillNumberDetails] = useState(null);
	const [ownersesbn, setownersesbn] = useState("");
	const [amount, setAmount] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [revenue, setRevenue] = useState("");
	const [revenueDeets, setRevenueDeets] = useState({
		description: "",
		code: ""
	});
	const [ESBNDetails, setESBNDetails] = useState(null);
	const [isConfirmingDetails, setIsConfirmingDetails] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showAlert, setShowAlert] = useState(true);
	const [showESBN, toggleShowESBN] = useState("esbn");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	//Alert message when the page is loaded
	const CustomAlert = ({ message, onClose }) => {
		return (
			<div className={[style.custom__alert].join("")}>
				<div className="alert-content">
					<p>{message}</p>
					<button onClick={onClose} className={[style.btn]}>
						OK
					</button>
				</div>
			</div>
		);
	};

	const handleCloseAlert = () => {
		setShowAlert(false);
	};

	const history = useHistory();
	function randomReference() {
		var length = 10;
		var chars =
			"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
		var result = "";
		for (var i = length; i > 0; --i)
			result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	}

	function paymentCallback(response) {
		console.log(response);

		const requestData = {
			pymtgateway: "PAYDIRECT",
			billno: BillNumber,
			billerfoneno: ESBNDetails?.phonenumber,
			billeremail: ESBNDetails?.email,
			amt: amount,
			appramt: amount,
			bpresp: "",
			bptrxref: " ",
			cardno: " ",
			description: "Approved by Financial Institution",
			payref: "FBN|WEB|MX19329|03-11-2023|474560954|654074",
			rechpin: " ",
			mac: "",
			apiresponse: "00",
			retref: "000106923853",
			txnref: "000106923853"
		};

		// API endpoint
		const apiEndpoint = `https://alpha.norlics.ng/api/taxpaid/`;

		// Create a request object with the appropriate method, headers, and body
		const request = new Request(apiEndpoint, {
			method: "POST",
			headers: {
				"Content-Type": "accesstoken"
			},
			body: JSON.stringify(requestData)
		});

		// Send the POST request
		fetch(request)
			.then((response) => {
				if (response.ok) {
					console.log("Data sent successfully");
					// Handle success if needed
				} else {
					console.error("Failed to send data to the API");
					// Handle errors if needed
				}
			})
			.catch((error) => {
				console.error("An error occurred:", error);
				// Handle network errors or other issues if needed
			});
	}

	useEffect(() => {
		const getESBN = async () => {
			const res = await axios
				.get(`users/anssid?anssid=${ownersesbn}`)
				.then((res) => (res.data || {}).data);

			if (res.accounttype === "individual") {
				setName(`${res.firstname} ${res.surname}`);
			} else {
				setName(res.name);
			}
		};
		if (ownersesbn?.length >= 10) getESBN();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownersesbn]);

	useEffect(() => {
		const getBillAmount = async () => {
			await axios
				.get(`/billingno/${BillNumber}`)
				.then((res) => {
					if (res?.data?.data[0]?.GrandTotal) {
						setAmount(res.data.data[0].GrandTotal);
					}
				})
				.catch((error) => {
					if (error.response) {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message:
								error.response.data?.errors?.[0] ||
								error.response.data?.msg
						});
					}
				});
		};
		if (BillNumber?.length >= 18) getBillAmount();
	}, [BillNumber]);

	useEffect(() => {
		const revenueDetails = revenue.split("/");
		setRevenueDeets({
			description: revenueDetails[0],
			code: revenueDetails[1]
		});
	}, [revenue]);

	const handlePostData = async () => {
		setLoading(true);

		const url = `https://test.payarena.com/Aggregator`; // Replace with your actual API endpoint
		const data = {
			id: "PAYARENA",
			description: "TEST",
			amount: amount,
			fee: 0,
			currency: "566",
			returnUrl: "http://localhost:3000",
			secretKey: "CA24AC34952FB7BFC293F6451A5AAB82737CF9170E49F552",
			scheme: "",
			vendorId: "",
			parameter: "",
			count: 0,
			CustomerName: name,
			Email: email ? email : ESBNDetails?.email
		};

		try {
			const response = await axios.post(url, data);

			const transactionId = response.data;

			const redirectURL = `https://test.payarena.com:8080/${transactionId}`;

			window.location.href = redirectURL;

			if (transactionId) {
				const getCode = await axios.get(redirectURL);
			}

			if (response.ok) {
			} else {
				// Handle errors
				console.error(
					"Failed to post data:",
					response.status,
					response.statusText
				);
			}
		} catch (error) {
			console.error("Error:", error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<center>
				{showAlert && (
					<CustomAlert
						message="Dear Customer, on this platform attracts 2% of bill amount or minimum of N100"
						onClose={handleCloseAlert}
					/>
				)}
			</center>
			<main className={[style.main].join("")}>
				<div>
					<center>
						<div
							className={[style.unifiedpayment__header].join("")}
						>
							<img
								src={UnifiedPayment}
								alt="interswitch payment"
							/>
						</div>
					</center>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-start",
							padding: "0px 30px"
						}}
					>
						<div className={style.esbn__selector}>
							<input
								type="radio"
								id="esbn"
								name="esbn"
								value="esbn"
								onChange={(e) => toggleShowESBN(e.target.value)}
								checked={showESBN === "esbn"}
							/>
							<label for="esbn">Pay with ESBN</label>
						</div>

						<div className={style.esbn__selector}>
							<input
								type="radio"
								id="noEsbn"
								name="noEsbn"
								value="noEsbn"
								onChange={(e) => toggleShowESBN(e.target.value)}
								checked={showESBN === "noEsbn"}
							/>
							<label for="noEsbn">Pay without ESBN</label>
						</div>
					</div>

					<hr className={style.divider} />
					{showESBN === "esbn" && (
						<>
							<div className={styles.wrapper}>
								<div
									className={[
										style.payments__double__inputs
									].join(" ")}
								>
									<div>
										<label
											className={styles.input__label}
											htmlFor="ownersesbn"
										>
											ESBN{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											onChange={(e) =>
												setownersesbn(e.target.value)
											}
											id="ownersesbn"
											placeholder="enter your ESBN"
											required
											value={ownersesbn}
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="BillNumber"
										>
											Bill Number{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											onChange={(e) =>
												setBillNumber(e.target.value)
											}
											id="BillNumber"
											placeholder="Enter bill number"
											required
											value={BillNumber}
										/>
									</div>
								</div>
								<hr className={style.divider} />

								<div
									className={[
										style.payments__double__inputs
									].join(" ")}
								>
									<div>
										<label
											className={styles.input__label}
											htmlFor="name"
										>
											Name{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={name}
											onChange={(e) =>
												setName(e.target.value)
											}
											id="amount"
											placeholder="Enter Name"
											required
											disabled
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="amount"
										>
											Amount{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={amount}
											onChange={(e) =>
												setAmount(e.target.value)
											}
											id="amount"
											placeholder="Enter amount"
											required
										/>
									</div>
								</div>
								<br />
								<div
									className={[
										style.payments__double__inputs
									].join(" ")}
								>
									<RevenueHeads
										setValue={(val) => setRevenue(val)}
										value={revenue?.Description}
									/>
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										margin: "23px 30px 0px 0px"
									}}
								>
									{ownersesbn &&
										BillNumber &&
										name &&
										amount && (
											<button
												onClick={() => ""}
												className={[style.btn]}
											>
												Pay now
											</button>
										)}
								</div>
							</div>
						</>
					)}
					{showESBN === "noEsbn" && (
						<>
							<div className={styles.wrapper}>
								<div
									className={[
										style.payments__double__inputs
									].join(" ")}
								>
									<div>
										<label
											className={styles.input__label}
											htmlFor="name"
										>
											Name{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={name}
											onChange={(e) =>
												setName(e.target.value)
											}
											id="name"
											placeholder="Enter Name"
											required
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="amount"
										>
											Amount{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={amount}
											onChange={(e) =>
												setAmount(e.target.value)
											}
											id="amount"
											placeholder="Enter amount"
											required
										/>
									</div>
								</div>
								<br />

								<div
									className={[
										style.payments__double__inputs
									].join(" ")}
								>
									<div>
										<label
											className={styles.input__label}
											htmlFor="email"
										>
											Email{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={email}
											onChange={(e) =>
												setEmail(e.target.value)
											}
											id="name"
											placeholder="Enter Email"
											required
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="phone"
										>
											Phone Number{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={phone}
											onChange={(e) =>
												setPhone(e.target.value)
											}
											id="amount"
											placeholder="Enter phone number"
											required
										/>
									</div>
								</div>

								<br />

								<div
									className={[
										style.payments__double__inputs
									].join(" ")}
								>
									<RevenueHeads
										setValue={(val) => setRevenue(val)}
										value={revenue}
									/>
								</div>

								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										margin: "23px 30px 0px 0px"
									}}
								>
									{name &&
										amount &&
										email &&
										phone &&
										revenue && (
											<button
												onClick={() => handlePostData()}
												className={[style.btn]}
											>
												Pay now
											</button>
										)}
								</div>
							</div>
						</>
					)}
				</div>
			</main>
		</>
	);
};

export default Unifiedpayment;
