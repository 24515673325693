import React, { createRef, useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import styles from "../MDAs/style.module.css";
import styles1 from "../../Dashboard/MDAs/style.module.css";

const headers = [
	["Payee Agent", "StateTinName"],
	["ESBN", "StateTin"],
	["Filed Year", "FilingYear"],
	["Filed Status", "FilingStatus"]
	// ["Amount Due (₦)", "amountDue"],
	// ["Amount Paid (₦)", "amountPaid"],
	// ["Variance (₦)", "variance"]
];

export const EmployeeCompliance = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [filters, setFilters] = useState({
		ESBN: "",
		filedYear: "",
		filingStatus: "",
		startDate: "",
		endDate: ""
	});
	const filterRef = createRef();
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const history = useHistory();

	const fetchH1 =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (!filterRef?.current?.classList?.contains(styles.no__display)) {
				filterRef?.current?.classList?.toggle(styles.no__display);
			}

			let query = `page=${currentPage}&pageSize=20&`;
			if (filters?.filedYear) {
				query += `filingYear=${filters?.filedYear}&`;
			}

			if (filters?.agentName) {
				query += `stateTinName=${filters?.agentName}&`;
			}

			if (filters?.ESBN) {
				query += `stateTin=${filters?.ESBN}&`;
			}

			if (filters?.startDate) {
				query += `start=${filters?.startDate}&`;
			}

			if (filters?.endDate) {
				query += `end=${filters?.endDate}&`;
			}

			try {
				const accessToken = localStorage.getItem("access_token");
				let data = await axios
					.get(`adminlandpage/annual-returns/h1filing?${query}`, {
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					})
					.then((res) => res?.data || {});

				if (!data || !data?.data) {
					return null;
				}
				setData(data?.data);
				setTotal(data?.total);
			} catch (error) {
				setAlert({
					showing: true,
					type: "error",
					message:
						error?.response?.data?.message ??
						"Annual Returns Fetching Unsuccessful!"
				});
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		fetchH1()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);
	return (
		<div className={styles1.MDA__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles.actions__div}>
				<button></button>
				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							disabled={true}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="owneresbn">Esbn</label>
								<input
									name="owneresbn"
									id="owneresbn"
									type="text"
									value={filters.ESBN}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											ESBN: e.target.value
										}))
									}
									placeholder="Esbn"
								/>
							</div>
						</form>
						<p>By Filing Details</p>
						<form>
							<div>
								<label htmlFor="filedYear">Filed Year</label>
								<input
									name="filedYear"
									id="filedYear"
									type="text"
									value={filters.filedYear}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											filedYear: e.target.value
										}))
									}
									placeholder="Eg 22/12/1990"
								/>
							</div>
							<div>
								<label htmlFor="filingStatus">
									Filing Status
								</label>
								<input
									name="filingStatus"
									id="filingStatus"
									type="text"
									value={filters.filingStatus}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											filingStatus: e.target.value
										}))
									}
								/>
							</div>
							<div>
								<label htmlFor="agentName">Agent Name</label>
								<input
									name="agentName"
									id="agentName"
									type="text"
									value={filters.agentName}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											agentName: e.target.value
										}))
									}
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={filters?.startDate}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											startDate: e.target.value
										}))
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={filters?.endDate}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											endDate: e.target.value
										}))
									}
								/>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={fetchH1()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<div className={styles.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={fetchH1}
						onView={(data) => {
							history.push(`/annual-returns/view-employees`, {
								coy: data?.id,
								corporateCompanyID: data?.CorporateCompanyInfoId
							});
						}}
					/>
				)}
			</div>
		</div>
	);
};
