import style from "./style.module.css";
import useIntersectionObserver from "../IntersectionObserver";
import observerStyle from "../IntersectionObserver/style.module.css";
const FlexLeft = ({
	heading,
	secondHeading,
	content1,
	content2,
	content3,
	content4,
	image
}) => {
	useIntersectionObserver();
	return (
		<main className={style.personalTaxIncome}>
			<img
				src={image}
				alt="taxTypeImg"
				className={`${observerStyle.animateHomePage} ${observerStyle.slideInLeft}`}
			/>
			<div
				className={`${style.paragraphs} ${observerStyle.animateHomePage} ${observerStyle.slideInRight}`}
			>
				<h2>{heading}</h2>
				<p className={style.standAlone}>{content1}</p>
				<p>{content2}</p>
				<h2>{secondHeading}</h2>
				<p>{content3}</p>
				<p>{content4}</p>
			</div>
		</main>
	);
};

export default FlexLeft;
