import Table from "../../components/Table";
import styles from "../MDAs/style.module.css";
import { useEffect, useState } from "react";
import { isValid } from "date-fns";
import axios from "axios";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";

import Modal from "../../components/Modal";
import { exportExcel, formatAmount, remapObj } from "../../utils";

const MDAOptionsAnalytics = () => {
	const [showPeriodModal, togglePeriodModal] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const [order] = useState("TOP");
	const [limit] = useState(50);

	const [period, setPeriod] = useState([
		{
			key: "selection"
		}
	]);

	const headers = [
		["MDA Code", "MDACode"],
		["MDA Description", "Description"],
		["Revenue Code", "RevenueCode"],
		["Revenue Description", "RevenueDescription"],
		["Amount (NGN)", "Amount", formatAmount]
	];

	const _getAnalytics = () => {
		let query = "";
		if (order) {
			query += `order=${order}&`;
		}
		if (limit) {
			query += `limit=${limit}&`;
		}
		if (isValid(period[0].startDate)) {
			query += `startDate=${new Date(period[0].startDate).getTime()}&`;
		}
		if (isValid(period[0].endDate)) {
			query += `endDate=${new Date(period[0].endDate).getTime()}&`;
		}

		return axios
			.get(`/admin/orderedmdacollections?${query}`)
			.then((res) => (res.data || {}).data);
	};

	const exportAnalytics = async () => {
		const info = await _getAnalytics();
		if (!info) return;
		exportExcel(
			remapObj(info, headers),
			headers,
			`${order} ${limit} MDA Report - ${new Date().toLocaleString()}.xlsx`
		);
	};

	const getAnalytics = async (e) => {
		e?.preventDefault?.();

		setLoading(true);
		try {
			const info = await _getAnalytics();

			if (info) {
				setData(info);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getAnalytics();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.MDA__page}>
			<div className={styles.actions__div}>
				<button
					className={["primary__btn", styles.download__button].join(
						" "
					)}
					onClick={(e) => {
						exportAnalytics();
					}}
				>
					Download Report
				</button>
				{/* <form className={styles.options__form} onSubmit={getAnalytics}>
					<div className={styles.form__control}>
						<label htmlFor="position">Position</label>
						<select
							id="position"
							name="position"
							onChange={(e) => setOrder(e.target.value)}
							value={order}
							required
						>
							<option>TOP</option>
							<option>BOTTOM</option>
						</select>
					</div>
					<div className={styles.form__control}>
						<label htmlFor="number">Number Of Items</label>
						<input
							type="number"
							id="number"
							placeholder="eg 50"
							min={1}
							required
							onChange={(e) => setLimit(e.target.value)}
							value={limit}
						/>
					</div>
					<footer style={{ display: "flex", alignItems: "flex-end" }}>
						<button className={styles.submit__button} type="submit">
							Search
						</button>
					</footer>
				</form> */}
			</div>
			<div className={styles.actions__div}>
				{/* <button onClick={() => { togglePeriodModal(!showPeriodModal) }}>
          Select Period
				</button> */}
				{showPeriodModal && (
					<Modal
						showing={showPeriodModal}
						toggle={togglePeriodModal}
						small
						style={{
							width: "60%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center"
						}}
					>
						<DateRangePicker
							showDate={new Date()}
							onChange={(item) => setPeriod([item.selection])}
							showSelectionPreview={true}
							moveRangeOnFirstSelection={false}
							months={2}
							ranges={period}
							direction="horizontal"
						/>
						<footer
							style={{
								display: "flex",
								width: "80%",
								alignItems: "flex-end"
							}}
						>
							<button
								className={styles.submit__button}
								type="submit"
								onClick={(e) => {
									getAnalytics(e);
									togglePeriodModal(false);
								}}
							>
								Search
							</button>
						</footer>
					</Modal>
				)}
			</div>
			<div className={styles.table__div}>
				<Table headers={headers} data={data} />
			</div>
		</div>
	);
};

export default MDAOptionsAnalytics;
