import { useState, useEffect } from "react";
import axios from "axios";
import Alert from "../../components/Alert";
import styles from "./style.module.css";

const CompanyDetails = () => {
	const userId = JSON.parse(localStorage.getItem("user_info")).id;
	const [details, setDetails] = useState(null);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	useEffect(() => {
		const getDetails = async () => {
			setLoading(true);
			try {
				const details = await axios
					.get(
						`/admin/corporate-taxpayer/getcoyselfservicesinglepagetin?userId=${userId}`
					)
					.then((res) => res.data.data);

				setDetails(details);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setLoading(false);
			}
		};

		getDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section className={styles.wrapper}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{loading ? (
				<p style={{ textAlign: "center" }}>Loading...</p>
			) : (
				<>
					<div className={styles.details}>
						<div className={styles.detail}>
							<span className={styles.detail__name}>TIN</span>
							<span className={styles.detail__value}>
								{details?.result?.TIN || "N/A"}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								Company Name
							</span>
							<span className={styles.detail__value}>
								{details?.result?.OrgName}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>Address</span>
							<span className={styles.detail__value}>
								{details?.result?.Office_Address}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>Email</span>
							<span className={styles.detail__value}>
								{details?.result?.Email_Address}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								Contact Number
							</span>
							<span className={styles.detail__value}>
								{details?.result?.Phone_Number}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								Owner's ESBN
							</span>
							<span className={styles.detail__value}>
								{details?.result?.Business_Owner_Anssid ||
									"N/A"}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								rC Number
							</span>
							<span className={styles.detail__value}>
								{details?.result?.RC_Number || "N/A"}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								Type of Business
							</span>
							<span className={styles.detail__value}>
								{details?.result?.Economic_Sector}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>
								Business Category
							</span>
							<span className={styles.detail__value}>
								{details?.result?.Business_Category}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>State</span>
							<span className={styles.detail__value}>
								{details?.result?.State}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>LGA</span>
							<span className={styles.detail__value}>
								{details?.result?.Lga}
							</span>
						</div>
						<div className={styles.detail}>
							<span className={styles.detail__name}>Zone</span>
							<span className={styles.detail__value}>
								{details?.result?.Zone}
							</span>
						</div>
					</div>
				</>
			)}
		</section>
	);
};

export default CompanyDetails;
