import React, { createRef, useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import styles from "../MDAs/style.module.css";
import styles1 from "../../Dashboard/MDAs/style.module.css";

const headers = [
	["Employee Name", "fullName"],
	["Employee ESBN", "eSBN"],
	["Months in Service", "monthsInService"],
	["Total Gross Salary (₦)", "annualGrossSalary"],
	["Total Deductions (₦)", "annualCalcTotalRelief"],
	["Annual Tax Due (₦)", "annualCalcTaxDue"]
];

export const ViewEmployees = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [filingYear, setfilingYear] = useState("");
	const [total, setTotal] = useState(0);
	const [filters, setFilters] = useState({
		ESBN: "",
		fullname: "",
		servicemth: "",
		grosssal: "",
		totrelief: "",
		annualtax: "",
		startDate: "",
		endDate: ""
	});
	const filterRef = createRef();
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const { state } = useLocation();

	const { coy, corporateCompanyID } = state;

	const history = useHistory();

	const fetchAnnualReturns =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (!filterRef?.current?.classList?.contains(styles.no__display)) {
				filterRef?.current?.classList?.toggle(styles.no__display);
			}
			let query = `page=${currentPage}&pageSize=20&`;
			if (filters?.fullname) {
				query += `fullname=${filters?.fullname}&`;
			}

			if (filters?.servicemth) {
				query += `servicemth=${filters?.servicemth}&`;
			}

			if (filters?.ESBN) {
				query += `esbn=${filters?.ESBN}&`;
			}

			if (filters?.grosssal) {
				query += `grosssal=${filters?.grosssal}&`;
			}

			if (filters?.totrelief) {
				query += `totrelief=${filters?.totrelief}&`;
			}

			if (filters?.annualtax) {
				query += `annualtax=${filters?.annualtax}&`;
			}

			if (filters?.startDate) {
				query += `start=${filters?.startDate}&`;
			}

			if (filters?.endDate) {
				query += `end=${filters?.endDate}&`;
			}

			try {
				const accessToken = localStorage.getItem("access_token");
				let data = await axios
					.get(
						`coy/annual-returns/stafflist/${coy}/${corporateCompanyID}?${query}`,
						{
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						}
					)
					.then((res) => res?.data || {});

				if (!data || !data?.data) {
					return null;
				}
				setData(data?.data?.EmployeeDetails || []);
				setTotal(data?.data?.EmployeeDetails?.length);
				setfilingYear(data?.data?.annualReturnInfo?.filingYear);
			} catch (error) {
				setAlert({
					showing: true,
					type: "error",
					message:
						error?.response?.data?.message ??
						"Annual Returns Fetching Unsuccessful!"
				});
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		fetchAnnualReturns()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);
	return (
		<div className={styles1.MDA__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles.actions__div}>
				<button></button>
				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							disabled={true}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="owneresbn">Esbn</label>
								<input
									name="owneresbn"
									id="owneresbn"
									type="text"
									value={filters.ESBN}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											ESBN: e.target.value
										}))
									}
									placeholder="Esbn"
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form>
							<div>
								<label htmlFor="fullname">Filed Year</label>
								<input
									name="fullname"
									id="fullname"
									type="text"
									value={filters.fullname}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											fullname: e.target.value
										}))
									}
									placeholder="Full name"
								/>
							</div>
							<div>
								<label htmlFor="servicemth">
									Months In Service
								</label>
								<input
									name="servicemth"
									id="servicemth"
									type="text"
									value={filters.servicemth}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											servicemth: e.target.value
										}))
									}
									placeholder="Status"
								/>
							</div>
							<div>
								<label htmlFor="grosssal">
									Total Gross Salary
								</label>
								<input
									name="grosssal"
									id="grosssal"
									type="text"
									value={filters.grosssal}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											grosssal: e.target.value
										}))
									}
								/>
							</div>
							<div>
								<label htmlFor="totrelief">
									Total Annual Relief
								</label>
								<input
									name="totrelief"
									id="totrelief"
									type="text"
									value={filters.totrelief}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											totrelief: e.target.value
										}))
									}
								/>
							</div>
							<div>
								<label htmlFor="annualtax">
									Annual Tax Due
								</label>
								<input
									name="annualtax"
									id="annualtax"
									type="text"
									value={filters.annualtax}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											annualtax: e.target.value
										}))
									}
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={filters?.startDate}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											startDate: e.target.value
										}))
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={filters?.endDate}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											endDate: e.target.value
										}))
									}
								/>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={fetchAnnualReturns()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<div className={styles.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={fetchAnnualReturns}
						onEdit={(employeeData) => {
							history.push(`/annual-returns/employee-details`, {
								employeeStateData: {
									...employeeData,
									corporateCompanyID: corporateCompanyID,
									filingYear: filingYear
								}
							});
						}}
					/>
				)}
			</div>
		</div>
	);
};
