import taxCalImg from "../../assets/Home/taxCalImg.svg";
import styles from "../calculator/style.module.css";
import useIntersectionObserver from "../IntersectionObserver";
import observerStyle from "../IntersectionObserver/style.module.css";
const Calculator = () => {
	useIntersectionObserver();
	return (
		<main>
			<section className={styles.calculatorSection}>
				<img
					src={taxCalImg}
					alt="Tax Calculator"
					className={`${observerStyle.slideInRight} ${observerStyle.animateHomePage}`}
				/>
				<div
					className={` ${styles.calcSearchSection} ${observerStyle.slideInLeft} ${observerStyle.animateHomePage}`}
				>
					<h2>
						Tax{" "}
						<span className={styles.underlineGreen}>
							Calculator
						</span>{" "}
					</h2>
					<p>
						This presents you the mechanism for an accurate
						computation of the tax due per a period usually a year.
						This calculator has been configured taking into
						consideration all the necessary input for correctness.
					</p>
					<button
						type="button"
						data-toggle="modal"
						data-target="#myModal2"
					>
						Calculator Your Tax
					</button>
				</div>
			</section>
		</main>
	);
};

export default Calculator;
