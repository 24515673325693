import styles from "../../Identity-management-individual/style.module.css";
import style1 from "../UserDetails/style.module.css";

const Support = () => {
	return (
		<section className={style1.wrapper}>
			<form className={styles.columned__form}>
				<h2 className={style1.pronounced__text}>Email us on</h2>
				<a
					href="mailto:eirssupport@norlics.com.ng"
					className={style1.special__link}
				>
					eirssupport@norlics.com.ng
				</a>
			</form>
		</section>
	);
};

export default Support;
