import React, { useState } from "react";
import styles from "./style.module.css";
import { ReactComponent as TabIcon } from "../../assets/tabIcon.svg";
import { StepFour } from "./corporateTabs/stepFour";
import { StepThree } from "./corporateTabs/stepThree";
import { StepTwo } from "./corporateTabs/stepTwo";
import { StepOne } from "./corporateTabs/stepOne";
import { StepFive } from "./corporateTabs/stepFive";

export const FileAnnualReturns = () => {
	const [tabState, setTabState] = useState(1);
	const [filingData, setFilingData] = useState(null);

	const tabViews =
		tabState === 5 ? (
			<StepFive
				updateTab={(tabNumber) => setTabState(tabNumber)}
				updateFilingData={(data) => setFilingData(data)}
				filingData={filingData}
			/>
		) : tabState === 4 ? (
			<StepFour
				updateTab={(tabNumber) => setTabState(tabNumber)}
				updateFilingData={(data) => setFilingData(data)}
				filingData={filingData}
			/>
		) : tabState === 3 ? (
			<StepThree
				updateTab={(tabNumber) => setTabState(tabNumber)}
				updateFilingData={(data) => setFilingData(data)}
				filingData={filingData}
			/>
		) : tabState === 2 ? (
			<StepTwo
				updateTab={(tabNumber) => setTabState(tabNumber)}
				updateFilingData={(data) => setFilingData(data)}
				filingData={filingData}
			/>
		) : (
			<StepOne
				updateTab={(tabNumber) => setTabState(tabNumber)}
				updateFilingData={(data) => setFilingData(data)}
				filingData={filingData}
			/>
		);
	return (
		<div>
			<ul className={`nav nav-pills ${styles.tabs__container}`}>
				<li>
					<TabIcon />
				</li>
				<li
					className={`${styles.tabs} ${
						tabState === 1 ? styles.active__tab : ""
					}`}
					onClick={() => setTabState(1)}
				>
					Step 1
				</li>
				<li
					className={`${styles.tabs} ${
						tabState === 2 ? styles.active__tab : ""
					}`}
					onClick={() => setTabState(2)}
				>
					Step 2
				</li>
				<li
					className={`${styles.tabs} ${
						tabState === 3 ? styles.active__tab : ""
					}`}
					onClick={() => setTabState(3)}
				>
					Step 3
				</li>
				<li
					className={`${styles.tabs} ${
						tabState === 4 ? styles.active__tab : ""
					}`}
					onClick={() => setTabState(4)}
				>
					Step 4
				</li>
				<li
					className={`${styles.tabs} ${
						tabState === 5 ? styles.active__tab : ""
					}`}
					onClick={() => setTabState(5)}
				>
					Step 5
				</li>
			</ul>
			<div>{tabViews}</div>
		</div>
	);
};
