import { useState, useEffect, createRef } from "react";
import axios from "axios";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import Modal from "../../components/Modal/regular";
import approvedCheck from "../../assets/Certificate/approvedCheck.svg";
import failureCheck from "../../assets/Certificate/failureCheck.svg";
import styles from "./style.module.css";
import styles1 from "../../Dashboard/MDAs/style.module.css";

const AuthoriseDirectAssessmentCertificate = () => {
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [ESBN, setESBN] = useState("");
	const [name, setName] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [viewModalShowing, setViewModalShowing] = useState(false);
	const [loadingCert, setLoadingCert] = useState(false);
	const [certificate, setCertificate] = useState(null);
	const [reviewedCertDetails, setReviewedCertDetails] = useState(null);
	const [approveModalShowing, setApproveModalShowing] = useState(false);
	const [approving, setApproving] = useState(false);
	const [approvalSuccessModalShowing, setApprovalSuccessModalShowing] =
		useState(false);
	const [rejectModalShowing, setRejectModalShowing] = useState(false);
	const [rejecting, setRejecting] = useState(false);
	const [rejectionSuccessModalShowing, setRejectionSuccessModalShowing] =
		useState(false);
	const [rejectReason, setRejectReason] = useState("");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const filterRef = createRef();

	const headers = [
		["ESBN", "ESBN"],
		["Name", "TaxPayerName"],
		["Address", "TaxPayerAddr"],
		["Year", "CertCurYear"],
		["Certificate Type", "Type_of_Cert"],
		["Amount Paid", "Total_Amt_Paid"],
		["Generated By", "Inputter"],
		["Request Date", "InputterTime", (data) => data?.split(" ")[0]],
		["Status", "ApproveFlg", getStatusText],
		[
			"Denial Reason",
			"RejectReason",
			(data) => (!data?.trim() ? null : data)
		],
		[
			"Approved By",
			"Approval1_Name1",
			(data) => (!data?.trim() ? null : data)
		],
		[
			"Approval Date",
			"AuthoriserTime",
			(data) => (data ? data?.split(" ")[0] : null)
		]
	];

	function getStatusText(approveRejectFlag) {
		if (approveRejectFlag === "0") {
			return "Pending";
		} else if (approveRejectFlag === "3") {
			return "Approved";
		} else {
			return "Denied";
		}
	}

	const getCertificates =
		({ currentPage = 1, pageSize = 20, download = false } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (!filterRef.current?.classList.contains(styles1.no__display)) {
				filterRef.current?.classList.toggle(styles1.no__display);
			}

			let query = `page=${currentPage}&pageSize=${pageSize}&`;

			if (ESBN) {
				query += `esbn=${ESBN}&`;
			}

			if (startDate) {
				query += `startdate=${startDate}&`;
			}

			if (endDate) {
				query += `enddate=${endDate}&`;
			}

			if (name) {
				query += `Name=${name}&`;
			}

			try {
				let { data, total } = await axios
					.get(`/getallmanualcerts?${query}`)
					.then((res) => res.data.data);
				setData(data);
				setTotal(total);

				return data;
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setLoading(false);
			}
		};

	const viewCertificate = async (ESBN, year, viewMode) => {
		setLoadingCert(true);
		setViewModalShowing(true);
		try {
			const data = await axios
				.get(`getcerts/${ESBN}/${year}/${viewMode}`)
				.then((res) => res.data);

			setCertificate(data.html);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setLoadingCert(false);
		}
	};

	const approve = async () => {
		setApproving(true);
		setApproveModalShowing(false);

		try {
			await axios
				.post(`approvecert`, {
					ESBN: reviewedCertDetails?.ESBN,
					startYear: reviewedCertDetails?.startYear,
					inputter: userInfo?.email,
					verifierId: userInfo?.email
				})
				.then((res) => res.data);

			setViewModalShowing(false);
			setApprovalSuccessModalShowing(true);
			getCertificates()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setApproving(false);
		}
	};

	const reject = async (e) => {
		e?.preventDefault();
		setRejecting(true);

		try {
			const res = await axios
				.post(`rejectcert`, {
					ESBN: reviewedCertDetails?.ESBN,
					startYear: reviewedCertDetails?.startYear,
					inputter: userInfo?.email,
					verifierId: userInfo?.email,
					reason4Reject: rejectReason
				})
				.then((res) => res.data);

			setRejectionSuccessModalShowing(false);
			setReviewedCertDetails(null);
			setRejectReason("");
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.msg
			});
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRejecting(false);
		}
	};

	useEffect(() => {
		getCertificates()();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clearFilters = () => {
		setESBN("");
		setName("");
		setStartDate("");
		setEndDate("");

		filterRef.current.classList.toggle(styles1.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getCertificates()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		if (!viewModalShowing) {
			setApproveModalShowing(false);
			setRejectModalShowing(false);

			// This means that it is important that at the point
			// of clicking "yes" on the idiot-proof rejection modal,
			// setting rejectionSuccessModalShowing to true must come before
			// setting viewModalShowing to false.
			if (!rejectionSuccessModalShowing && !approvalSuccessModalShowing)
				setReviewedCertDetails(null);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewModalShowing]);

	useEffect(() => {
		if (!rejectionSuccessModalShowing) setRejectReason("");
	}, [rejectionSuccessModalShowing]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{viewModalShowing && (
				<Modal toggle={setViewModalShowing} showing={viewModalShowing}>
					{loadingCert ? (
						<p style={{ textAlign: "center" }}>Loading...</p>
					) : (
						<>
							{reviewedCertDetails.status === "0" && (
								<p className={styles.review__instructions}>
									Please review the form details below. Once
									you have completed your review, select
									either "Approve" or "Reject" to proceed with
									your decision.
								</p>
							)}
							<div
								dangerouslySetInnerHTML={{
									__html: certificate
								}}
							></div>
							{reviewedCertDetails.status === "0" && (
								<div className={styles.review__btns}>
									<button
										className="tertiary__btn"
										onClick={() =>
											setRejectModalShowing(true)
										}
										disabled={approveModalShowing}
									>
										{rejecting ? "Rejecting..." : "Reject"}
									</button>
									<button
										className="primary__btn"
										onClick={() =>
											setApproveModalShowing(true)
										}
										disabled={rejectModalShowing}
									>
										{approving ? "Approving..." : "Approve"}
									</button>
									{/* Idiot proof modal for approval */}
									{approveModalShowing && (
										<div
											className={`${styles.idiotProof__modal} ${styles.approve__modal}`}
										>
											<p>
												Are you sure you want to{" "}
												<span>Approve</span> this TCC
												with{" "}
												<b>
													{reviewedCertDetails.ESBN}
												</b>{" "}
												ESBN?
											</p>
											<div
												className={
													styles.idiotProof__btns
												}
											>
												<button
													onClick={() => {
														setApproveModalShowing(
															false
														);
													}}
												>
													No
												</button>
												<button
													onClick={() => approve()}
													disabled={approving}
												>
													Yes
												</button>
											</div>
										</div>
									)}
									{/* Idiot proof modal for rejection */}
									{rejectModalShowing && (
										<div
											className={`${styles.idiotProof__modal} ${styles.reject__modal}`}
										>
											<p>
												Are you sure you want to{" "}
												<span>Reject</span> this TCC
												with{" "}
												<b>
													{reviewedCertDetails.ESBN}
												</b>{" "}
												ESBN?
											</p>
											<div
												className={
													styles.idiotProof__btns
												}
											>
												<button
													onClick={() => {
														setRejectModalShowing(
															false
														);
													}}
												>
													No
												</button>
												<button
													onClick={() => {
														setRejectionSuccessModalShowing(
															true
														);
														setRejectModalShowing(
															false
														);
														setViewModalShowing(
															false
														);
													}}
												>
													Yes
												</button>
											</div>
										</div>
									)}
								</div>
							)}
						</>
					)}
				</Modal>
			)}
			{/* approval success modal */}
			{approvalSuccessModalShowing && (
				<Modal>
					<div className={styles.approvalSuccess__badge}>
						<img
							src={approvedCheck}
							alt="circle with a tick check inside"
						/>
						<span>APPROVED</span>
					</div>
					<p className={styles.approve__success_message}>
						You have successfully approved{" "}
						<b>{reviewedCertDetails?.Name}</b>'s Tax Clearance
						Certificate, an email would be sent to notify them
						accordingly.
					</p>
					<button
						className={styles.continue__btn}
						onClick={() => {
							setReviewedCertDetails(null);
							setApprovalSuccessModalShowing(false);
						}}
					>
						Continue
					</button>
				</Modal>
			)}
			{/* rejection success modal */}
			{rejectionSuccessModalShowing && (
				<Modal
					showing={rejectionSuccessModalShowing}
					toggle={setRejectionSuccessModalShowing}
				>
					<div className={styles.rejection__badge}>
						<img src={failureCheck} alt="circle with an X inside" />
						<span>REJECTION</span>
					</div>
					<p className={styles.reject__reason_instruction}>
						You are about to reject{" "}
						<b>{reviewedCertDetails?.Name}</b>'s Tax Clearance
						Certificate. Please, state your reason.
					</p>
					<form
						onSubmit={(e) => reject(e)}
						className={styles.reject__reason_form}
					>
						<label htmlFor="rejectReason">
							Reason for Rejecting{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<textarea
							id="rejectReason"
							placeholder="write the reason for rejecting this TCC"
							value={rejectReason}
							onChange={(e) => setRejectReason(e.target.value)}
							rows={2}
						/>
						<button
							type="submit"
							className="primary__btn"
							disabled={!rejectReason || rejecting}
						>
							{rejecting ? "Continuing..." : "Continue"}
						</button>
					</form>
				</Modal>
			)}
			<div
				className={styles1.actions__div}
				style={{ display: "flex", justifyContent: "flex-end" }}
			>
				<div className={styles1.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles1.no__display
							);
						}}
						className={styles1.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles1.filter__div,
							styles1.no__display
						].join(" ")}
					>
						<div className={styles1.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles1.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles1.clear__filter_fields}
							onClick={clearFilters}
							disabled={!ESBN && !startDate && !endDate && !name}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="ESBN">ESBN</label>
								<input
									name="ESBN"
									id="ESBN"
									type="text"
									value={ESBN}
									onChange={(e) => setESBN(e.target.value)}
									placeholder="Enter ESBN"
								/>
							</div>
						</form>
						<p>By Taxpayer</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="name">Name</label>
								<input
									name="name"
									id="name"
									type="text"
									value={name}
									onChange={(e) => setName(e.target.value)}
									placeholder="Eg Ada Agu"
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								styles1.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getCertificates()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			{loading && !data ? (
				<p style={{ textAlign: "center" }}>Loading...</p>
			) : (
				<div className={styles.table__wrapper}>
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getCertificates}
						onView={(data) => {
							viewCertificate(data.ESBN, data.CertCurYear, 1);
							setReviewedCertDetails({
								ESBN: data.ESBN,
								startYear: data.CertCurYear,
								Name: data.TaxPayerName,
								status: data.ApproveFlg
							});
						}}
					/>
				</div>
			)}
		</section>
	);
};

export default AuthoriseDirectAssessmentCertificate;
