import React, { useState, useEffect, createRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Table from "../../components/Table";
import styles from "../MDAs/style.module.css";
const coy_ANSSID = process.env.REACT_APP_ANSSID;

const Audit = () => {
	const history = useHistory();
	const filterRef = createRef();

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [anssid, setAnssid] = useState("");
	const [accountType, setAccountType] = useState("");
	const [startdate, setStartDate] = useState("");
	const [enddate, setEndDate] = useState("");
	const [AgentPhone, setAgentPhone] = useState("");
	const [AgentEmail, setAgentEmail] = useState("");
	const [AnssidPhone, setAnssidPhone] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);

	const headers = [
		[`${coy_ANSSID}`, "Anssid"],
		["Name", "NAME"],
		["Created By", "Inputter_Name"],
		["Modified By", "Modifier_Name"],
		[
			"Registration Date",
			"createdAt",
			(val) => new Date(val).toLocaleString()
		],
		["Account Type", "Account_Type"]
	];

	const getAudit =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (!filterRef.current.classList.contains(styles.no__display)) {
				filterRef.current.classList.toggle(styles.no__display);
			}

			let query = `page=${currentPage}&pageSize=20&`;
			if (anssid) {
				query += `anssid=${anssid}&`;
			}
			if (accountType) {
				query += `accountType=${accountType}&`;
			}
			if (startdate) {
				query += `startdate=${new Date(startdate).toLocaleString(
					"en-US",
					{
						timeZone: "Africa/Lagos",
						year: "numeric",
						month: "2-digit",
						day: "2-digit"
					}
				)}&`;
			}

			if (enddate) {
				query += `enddate=${new Date(enddate).toLocaleString("en-US", {
					timeZone: "Africa/Lagos",
					year: "numeric",
					month: "2-digit",
					day: "2-digit"
				})}&`;
			}

			if (AgentPhone) {
				query += `AgentPhone=${AgentPhone}&`;
			}

			if (AgentEmail) {
				query += `AgentEmail=${AgentEmail}&`;
			}

			if (AnssidPhone) {
				query += `AnssidPhone=${AnssidPhone}&`;
			}

			try {
				const accessToken = localStorage.getItem("access_token");
				const info = await axios
					.get(`/anssidsearch?${query}`, {
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					})
					.then((res) => res.data);

				if (info) {
					setData(info?.data);
					setTotal(info?.total);
				}
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		};

	const clearFilters = () => {
		setAnssid("");
		setStartDate("");
		setEndDate("");
		setAccountType("");
		setAgentPhone("");
		setAgentEmail("");
		setAnssidPhone("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getAudit()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getAudit()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.MDA__page}>
			<div className={styles.actions__div}>
				<form></form>
				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!anssid &&
								!accountType &&
								!startdate &&
								!enddate &&
								!AgentPhone &&
								!AgentEmail &&
								!AnssidPhone
							}
						>
							Clear Filter fields
						</button>
						<p>By ANSSID</p>
						<form>
							<div>
								<label htmlFor="anssid">{coy_ANSSID}</label>
								<input
									name="anssid"
									id="anssid"
									type="text"
									value={anssid}
									onChange={(e) => setAnssid(e.target.value)}
									placeholder={coy_ANSSID}
								/>
							</div>
							<div>
								<label htmlFor="AnssidPhone">
									{coy_ANSSID} Phone
								</label>
								<input
									name="AnssidPhone"
									id="AnssidPhone"
									type="text"
									value={AnssidPhone}
									onChange={(e) =>
										setAnssidPhone(e.target.value)
									}
									placeholder="Phone Number"
								/>
							</div>
						</form>
						<p>By Account Type</p>
						<form>
							<div>
								<label htmlFor="accountType">
									Account Type
								</label>
								<select
									name="accountType"
									id="accountType"
									value={accountType}
									onChange={(e) =>
										setAccountType(e.target.value)
									}
								>
									<option value="">
										Select Account Type
									</option>
									<option value={1}>Individual</option>
									<option value={2}>Corporate</option>
								</select>
							</div>
						</form>
						<p>By Agent</p>
						<form>
							<div>
								<label htmlFor="AgentPhone">Agent Phone</label>
								<input
									name="AgentPhone"
									id="AgentPhone"
									type="text"
									value={AgentPhone}
									onChange={(e) =>
										setAgentPhone(e.target.value)
									}
									placeholder="Eg. +2348012345678"
								/>
							</div>
							<div>
								<label htmlFor="AgentEmail">Agent Email</label>
								<input
									name="AgentEmail"
									id="AgentEmail"
									type="text"
									value={AgentEmail}
									onChange={(e) =>
										setAgentEmail(e.target.value)
									}
									placeholder="Eg. chukwu@gmail.com"
								/>
							</div>
						</form>
						<p>By Registration Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startdate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="end_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={enddate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getAudit()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<div className={styles.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getAudit}
						onView={(data) => {
							history.push(
								`/audit-anssid/${data.Account_Type}/`,
								{
									anssid: data.Anssid
								}
							);
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default Audit;
