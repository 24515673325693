import { useState, useEffect, createRef, useRef } from "react";
import axios from "axios";

import Alert from "../../components/Alert";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import { formatAmount, exportExcel, remapObj } from "../../utils";

import styles from "../MDAs/style.module.css";

const coy_ANSSID = process.env.REACT_APP_ANSSID;

const TotalCollections = () => {
	const componentRef = useRef();
	const [fullTableShowing, toggleFullTable] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const filterRef = createRef();

	const [anssid, setAnssid] = useState("");
	const [paymentReference, setPaymentReference] = useState("");
	const [mdaValue, setMDAValue] = useState("");
	const [mdaZone, setMDAZone] = useState("");
	const [revHead, setRevHead] = useState("");
	const [startdate, setStartDate] = useState("");
	const [enddate, setEndDate] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState("");
	const [revSource, setRevSource] = useState("");
	const [receipt, setReceipt] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [taxpayerName, setTaxpayerName] = useState("");
	const [total, setTotal] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	const [revenueHeads, setRevenueHeads] = useState([]);
	const [revenueSources, setRevenueSources] = useState([]);
	const [mdas, setMdas] = useState([]);
	const [mdazones, setMdaZones] = useState([]);
	const [paymentGateway, setPaymentGateway] = useState("");
	const [payGates, setPayGates] = useState([]);
	const [billNumber, setBillNumber] = useState("");

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const headers = [
		["Payment Date", "DatePaid", (val) => new Date(val).toLocaleString()],
		["Payment Reference", "PaymentReference"],
		["Bill Number", "BillNo"],
		[`${coy_ANSSID}`, "ANSSID"],
		["Taxpayer name", "TaxpayerName"],
		["Amount (NGN)", "Amount", formatAmount],
		["Receipt Number", "ReceiptNo"],
		["Revenue Source", "RevenueCode"],
		["MDA", "AgencyCode"],
		["MDA Zones", "MDA_Zone"],
		["Initiator", "InitiatedBy"]
	];

	const getTotalCollections =
		({ currentPage = 1, pageSize = 20, download = false } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (!filterRef.current.classList.contains(styles.no__display)) {
				filterRef.current.classList.toggle(styles.no__display);
			}

			let query = `page=${currentPage}&pageSize=${pageSize}&`;
			if (anssid) {
				query += `anssid=${anssid}&`;
			}
			if (taxpayerName) {
				query += `taxpayerName=${taxpayerName}&`;
			}
			if (paymentReference) {
				query += `reference=${paymentReference}&`;
			}

			if (startdate) {
				query += `startdate=${new Date(startdate).toLocaleString(
					"en-US",
					{
						timeZone: "Africa/Lagos",
						year: "numeric",
						month: "2-digit",
						day: "2-digit"
					}
				)}&`;
			}

			if (enddate) {
				query += `enddate=${new Date(enddate).toLocaleString("en-US", {
					timeZone: "Africa/Lagos",
					year: "numeric",
					month: "2-digit",
					day: "2-digit"
				})}&`;
			}

			if (minAmount) {
				query += `minAmount=${minAmount}&`;
			}

			if (maxAmount) {
				query += `maxAmount=${maxAmount}&`;
			}

			if (revSource) {
				query += `revSource=${revSource}&`;
			}

			if (receipt) {
				query += `receipt=${receipt}&`;
			}

			if (revHead) {
				query += `revCode=${revHead}&`;
			}

			if (mdaValue) {
				query += `mda=${mdaValue}&`;
			}

			if (mdaZone) {
				query += `mdaZone=${mdaZone}&`;
			}

			if (paymentGateway) {
				query += `initiatedby=${paymentGateway}&`;
			}

			if (billNumber) {
				query += `billno=${billNumber}&`;
			}
			try {
				const accessToken = localStorage.getItem("access_token");
				const info = await axios
					.get(`/txn/reports/invoices?${query}`, {
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					})
					.then((res) => res.data);

				if (info) {
					if (!download) setData(info.data);
					setTotal(info.total);
					setTotalAmount(info.totalAmt);
				}

				return info;
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		async function getOptions() {
			try {
				const getRevenueHeads = axios.get(`/settings/revenuehead`);

				const getRevenueSources = axios.get(`/settings/revenuesource`);
				const getMdas = axios.get(`/settings/mdasetup`);

				const getPayGate = axios.get(`/reports/paymentverticals`);
				const getMdaWaterCorpZones = axios.get(`/settings/zones/04`);

				const results = await Promise.all([
					getRevenueHeads,
					getRevenueSources,
					getMdas,
					getPayGate,
					getMdaWaterCorpZones
				]);

				results.forEach((each, i) => {
					if (i === 0) {
						setRevenueHeads(each.data.data);
					}
					if (i === 1) {
						setRevenueSources(each.data.data);
					}
					if (i === 2) {
						setMdas(each.data.data);
					}
					if (i === 3) {
						setPayGates(each.data.data.data);
					}
					if (i === 4) {
						setMdaZones(each.data.data);
					}
				});
			} catch (e) {
				if (e.response) {
					setAlert((alert) => ({
						...alert,
						showing: true,
						type: "error",
						message: e.response.data.message
					}));
				} else {
					setAlert((alert) => ({
						...alert,
						showing: true,
						type: "error",
						message: e.message
					}));
				}
			}
		}

		getOptions();
	}, []);

	const clearFilters = () => {
		setAnssid("");
		setPaymentReference("");
		setStartDate("");
		setEndDate("");
		setMinAmount("");
		setMaxAmount("");
		setTaxpayerName("");
		setRevSource("");
		setRevHead("");
		setMDAValue("");
		setMDAZone("");
		setPaymentGateway("");
		setBillNumber("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getTotalCollections()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getTotalCollections()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const exportAnalytics = async () => {
		const info = await getTotalCollections({
			currentPage: 1,
			pageSize: total,
			download: true
		})();
		if (!info.data) console.log("What da hell!");
		exportExcel(
			remapObj(info.data, headers),
			headers,
			`Total Collections Report.xlsx`
		);
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div className={styles.MDA__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles.actions__div}>
				<button onClick={() => toggleFullTable(!fullTableShowing)}>
					View Entire Table
				</button>
				<button
					className={["primary__btn", styles.download__button].join(
						" "
					)}
					onClick={() => exportAnalytics()}
					disabled={loading}
				>
					Download Report
				</button>
				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!anssid &&
								!paymentReference &&
								!startdate &&
								!enddate &&
								!minAmount &&
								!maxAmount &&
								!taxpayerName &&
								!revSource &&
								!revHead &&
								!mdaValue &&
								!mdaZone &&
								!paymentGateway &&
								!billNumber
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="anssid">{coy_ANSSID}</label>
								<input
									name="anssid"
									id="anssid"
									type="text"
									value={anssid}
									onChange={(e) => setAnssid(e.target.value)}
									placeholder={coy_ANSSID}
								/>
							</div>
							<div>
								<label htmlFor="payment_reference">
									Payment Reference
								</label>
								<input
									name="payment_reference"
									id="payment_reference"
									type="text"
									value={paymentReference}
									onChange={(e) =>
										setPaymentReference(e.target.value)
									}
									placeholder="Payment Reference"
								/>
							</div>
							<div>
								<label htmlFor="receipt">Receipt Number</label>
								<input
									name="receipt"
									id="receipt"
									type="text"
									value={receipt}
									onChange={(e) => setReceipt(e.target.value)}
									placeholder="Receipt Number"
								/>
							</div>
							<div>
								<label htmlFor="billNumber">Bill Number</label>
								<input
									name="billNumber"
									id="billNumber"
									type="text"
									value={billNumber}
									onChange={(e) =>
										setBillNumber(e.target.value)
									}
									placeholder="Bill Number"
								/>
							</div>
						</form>
						<p>By Taxpayer Name</p>
						<form>
							<div>
								<label htmlFor="taxpayer_name">
									Taxpayer Name
								</label>
								<input
									name="taxpayer_name"
									id="taxpayer_name"
									type="text"
									value={taxpayerName}
									onChange={(e) =>
										setTaxpayerName(e.target.value)
									}
									placeholder="Eg. Chinenye Ikwe"
								/>
							</div>
						</form>
						<p>By MDA</p>
						<form>
							<div>
								<label htmlFor="mda">MDA</label>
								<select
									id="mda"
									name="MDA"
									value={mdaValue}
									onChange={(e) =>
										setMDAValue(e.target.value)
									}
								>
									<option value="">--Select MDA--</option>
									{mdas.length &&
										mdas.map((option) => (
											<option
												key={option.Code}
												value={option.Code}
											>
												{option.Description}
											</option>
										))}
								</select>
							</div>
							<div>
								<label htmlFor="mda">MDA Zone(s)</label>
								<select
									id="mdazone"
									name="MDAzone"
									value={mdaZone}
									onChange={(e) => setMDAZone(e.target.value)}
								>
									<option value="">
										--Select MDA Zone--
									</option>
									{mdazones.length &&
										mdazones.map((option) => (
											<option
												key={option.Description}
												value={option.Description}
											>
												{option.Description}
											</option>
										))}
								</select>
							</div>
						</form>
						<p>By Revenue Source</p>
						<form>
							<div>
								<label htmlFor="revSource">
									Revenue Source
								</label>
								<select
									id="revSource"
									name="revSource"
									value={revSource}
									onChange={(e) =>
										setRevSource(e.target.value)
									}
								>
									<option value="">
										--Select Revenue Source--
									</option>
									{revenueSources.length &&
										revenueSources.map((option) => (
											<option
												key={option.Code}
												value={option.Code}
											>
												{option.Description}
											</option>
										))}
								</select>
							</div>
							<div>
								<label htmlFor="revenueHead">
									Revenue Heads
								</label>
								<select
									id="revenueHead"
									name="RevenueHead"
									value={revHead}
									onChange={(e) => setRevHead(e.target.value)}
								>
									<option value="">
										--Select Revenue Head--
									</option>
									{revenueHeads.length &&
										revenueHeads.map((option) => (
											<option
												key={option.Code}
												value={option.Code}
											>
												{option.Description}
											</option>
										))}
								</select>
							</div>
						</form>
						{/* ///////////////////// */}
						<p>By Payment Gateway</p>
						<form>
							<div>
								<label htmlFor="paymentGateway">
									Payment Gateway
								</label>
								<select
									id="paymentGateway"
									name="paymentGateway"
									value={paymentGateway}
									onChange={(e) =>
										setPaymentGateway(e.target.value)
									}
								>
									<option value="">
										--Select Payment Gateway--
									</option>
									{payGates.length &&
										payGates.map((option) => (
											<option
												key={option.Code}
												value={option.Code}
											>
												{option.enum_value}
											</option>
										))}
								</select>
							</div>
						</form>
						{/* ///////////////////// */}
						<p>By Payment Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startdate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={enddate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<p>By Amount</p>
						<form>
							<div>
								<label htmlFor="min_amount">Min Amount</label>
								<input
									name="min_amount"
									id="min_amount"
									type="number"
									value={minAmount}
									onChange={(e) =>
										setMinAmount(e.target.value)
									}
									placeholder="100,000"
								/>
							</div>
							<div>
								<label htmlFor="max_amount">Max Amount</label>
								<input
									name="max_amount"
									id="max_amount"
									type="number"
									value={maxAmount}
									onChange={(e) =>
										setMaxAmount(e.target.value)
									}
									placeholder="2,000,000"
								/>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getTotalCollections()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			{data && (
				<p style={{ textAlign: "end", marginBottom: "15px" }}>
					<b>Total Number of Transactions:</b> {total} &nbsp;&nbsp; |
					&nbsp;&nbsp;
					<b>Total Amount:</b> &#8358; {formatAmount(totalAmount)}
				</p>
			)}
			<div className={styles.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getTotalCollections}
						ref={componentRef}
					/>
				)}
			</div>
			{fullTableShowing && (
				<Modal showing={fullTableShowing} toggle={toggleFullTable}>
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getTotalCollections}
					/>
				</Modal>
			)}
		</div>
	);
};

export default TotalCollections;
