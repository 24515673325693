import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from "../../Identity-management-individual/style.module.css";
import styles1 from "./style.module.css";
import { OutlineButton } from './components/buttons';
import Table from '../../components/Table';
import { useFetchIncome, useSetupGETRequests, useSetupPOSTRequests } from './components/hooks';
import { useHistory } from "react-router";
import { formatAmount } from '../../utils';
import { EditModal } from './components/editModal';
import axios from 'axios';
import Alert from '../../components/Alert';
import { useLocation } from "react-router-dom";
import GoBackButton from '../../components/goBackButton';


const user = JSON.parse(localStorage.getItem("user_info"));

const headers = [
    ["Type of Income", "IncomeType"],
    ["Source of Income", "IncomeSource"],
    ["Ammount (₦)", "Amount", formatAmount]
];
const prepaymentTableHeader = [
    ["Type of Prepayments", "IncomeType"],
    ["Income Description", "IncomeSource"],
    ["Ammount (₦)", "Amount", formatAmount]
];


export const EditHNI = () => {
    let { state } = useLocation();

    const [loading, setLoading] = useState(false);
    const [taxIsCalculated, setTaxIsCalculated] = useState(false);
    const [billNumber, setBillNumber] = useState(null);
    const history = useHistory();

    const [showModal, setShowModal] = useState({
        modalState: false,
        modalType: "",
        data: null
    });

    const [alert, setAlert] = useState({
        showing: false,
        type: null,
        message: ""
    });

    const [taxpayerDetails, setTaxpayerDetails] = useState({
        zone: '',
        esbn: '',
        lga: '',
        email: '',
        gender: '',
        address: '',
        name: '',
        fiscalYear: '',
        phoneNumber: '',
        taxAreaOffice: ''
    });
    const [totalCharges, setTotalCharges] = useState(0);
    // const [generatedBillData, setGeneratedBillData] = useState(null);

    const [taxData, setTaxData] = useState({
        tax7percentage: 0,
        tax11percentage: 0,
        tax15percentage: 0,
        tax19percentage: 0,
        tax21percentage: 0,
        tax24percentage: 0,
        tax24bpercentage: 0
    })

    const zones = useFetchIncome({
        route: `settings/zones/${user?.mdaModuleCoy}`
    })

    const lgas = useFetchIncome({
        route: `settings/lgass/${user?.mdaModuleCoy}`
    })

    const taxoffices = useFetchIncome({
        route: `settings/assesstaxoffice`
    })

    const allIncome = useFetchIncome({
        route: `hniallincome`
    })

    const allPrepayment = useFetchIncome({
        route: `hniallprepayment`
    })

    const HINData = useFetchIncome({
        route: `hni/${state?.BillNumber}`
    })

    const {
        loading: savingHNI,
        status,
        setHookData: saveHNIHook
    } = useSetupPOSTRequests();


    const { data: esbnData, setHookData: getESBNData } = useSetupGETRequests();

    const [income, setIncome] = useState({
        incometype: '',
        incomesource: '',
        amount: ''
    })

    const [payment, setPayment] = useState({
        incometype: '',
        amount: ''
    })

    const [incomeTableData, setIncomeTableData] = useState([]);
    const [paymentTableData, setPaymentTableData] = useState([]);

    const updateFields = useCallback(() => {
        if (HINData[0]?.data) {
            setTaxpayerDetails((prevState) => ({
                ...prevState,
                esbn: HINData[0]?.data?.ESBN,
                zone: HINData[0]?.data?.Zone,
                gender: HINData[0]?.data?.Gender,
                email: HINData[0]?.data?.Email,
                name: HINData[0]?.data?.Name,
                lga: HINData[0]?.data?.LGA,
                firstName: HINData[0]?.data?.firstname,
                fiscalYear: HINData[0]?.data?.FiscalYear,
                phoneNumber: HINData[0]?.data?.PhoneNo,
                address: HINData[0]?.data?.Address,
                taxAreaOffice: HINData[0]?.data?.TaxOffice
            }))
            setBillNumber(HINData[0]?.data?.BillNumber);
            setIncomeTableData(HINData[0]?.data?.HNIDetailsIncomes)
            setPaymentTableData(HINData[0]?.data?.HNIDetailsPrepayments)
            setTotalCharges(HINData[0]?.data?.TaxableIncome)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [HINData[0]?.data])

    const updateTables = (data, tableType) => {
        if (Object.values(data).includes('')) {
            return
        } else {
            if (tableType === 'income') {
                setIncomeTableData((prevState) => ([
                    ...prevState,
                    {
                        ...{
                            IncomeType: data?.incometype ?? '',
                            IncomeSource: data?.incomesource ?? '',
                            Amount: data?.amount ?? ''
                        },
                        index: prevState?.length + 1
                    }
                ]))
                setIncome({
                    incometype: '',
                    incomesource: '',
                    amount: ''
                })
            } else {
                setPaymentTableData((prevState) => ([
                    ...prevState,
                    {
                        ...{
                            IncomeType: data?.incometype ?? '',
                            Amount: data?.amount ?? ''
                        },
                        index: prevState?.length + 1
                    }
                ]))
                setPayment({
                    incometype: '',
                    amount: ''
                })
            }
        }

    }

    //Get Tranch Charges
    const getTranchCharges = async (incomeTotal = allIncomeTotal) => {
        setLoading(true);
        setTaxIsCalculated(true);

        try {
            const res = await axios
                .get(`/assessdatranchcharges/${incomeTotal}`)
                .then((res) => (res.data || {}).data);

            // setTranches(res);
            if (res) {
                res.forEach((tranch) => {
                    if (tranch.incTranchNo === "1")
                        setTaxData((prevState) => ({
                            ...prevState,
                            tax7percentage: Number(tranch.tranchTax || 0)
                        }));
                    if (tranch.incTranchNo === "2")
                        setTaxData((prevState) => ({
                            ...prevState,
                            tax11percentage: Number(tranch.tranchTax || 0)
                        }));
                    if (tranch.incTranchNo === "3")
                        setTaxData((prevState) => ({
                            ...prevState,
                            tax15percentage: Number(tranch.tranchTax || 0)
                        }));
                    if (tranch.incTranchNo === "4")
                        setTaxData((prevState) => ({
                            ...prevState,
                            tax19percentage: Number(tranch.tranchTax || 0)
                        }));
                    if (tranch.incTranchNo === "5")
                        setTaxData((prevState) => ({
                            ...prevState,
                            tax21percentage: Number(tranch.tranchTax || 0)
                        }));
                    if (tranch.incTranchNo === "6")
                        setTaxData((prevState) => ({
                            ...prevState,
                            tax24percentage: Number(tranch.tranchTax || 0)
                        }));
                });

                setTotalCharges(res[res.length - 2]?.totTaxCalc);
                setLoading(false);
                setTaxIsCalculated(false);
                setAlert({
                    ...alert,
                    showing: true,
                    type: "success",
                    message: "ChargeTaxSpread found"
                });
            }
        } catch (error) {
            if (error.response) {
                setAlert({
                    ...alert,
                    showing: true,
                    type: "error",
                    message:
                        error.response.data?.errors?.[0] ||
                        error.response.data?.msg
                });
            }
        }
    };

    const allIncomeTotal = useMemo(() => {
        const totalAmount = incomeTableData.reduce(
            (accumulator, currentValue) =>
                accumulator + Number(currentValue.Amount || currentValue.amount),
            0
        );
        getTranchCharges(totalAmount);
        return totalAmount;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incomeTableData])

    const allPrepaymentTotal = useMemo(() => {
        const totalAmount = paymentTableData.reduce(
            (accumulator, currentValue) =>
                accumulator + Number(currentValue.Amount || currentValue.amount),
            0
        );
        return totalAmount;
    }, [paymentTableData])

    const editTableData = (record, tableType) => {

        if (tableType === 'income') {
            const filteredTableData = incomeTableData?.map(
                current => {
                    return current?.id === record?.index ||
                        current?.index === record?.index
                        ? record : current
                }
            );
            setIncomeTableData(filteredTableData);
        } else {
            const filteredTableData = paymentTableData?.map(
                current => {
                    return current?.id === record?.index ||
                        current?.index === record?.index
                        ? record : current
                }
            );
            setPaymentTableData(filteredTableData);
        }
    }

    const deleteTableData = (record, tableType) => {
        if (tableType === 'income') {
            const filteredTableData = incomeTableData?.filter(
                current => current?.index !== record?.index
            );
            const reshuffledTableData = filteredTableData?.map((data, index) => ({
                ...data,
                index
            }))
            setIncomeTableData(reshuffledTableData)
        } else {
            const filteredTableData = paymentTableData?.filter(
                current => current?.index !== record?.index
            );

            const reshuffledTableData = filteredTableData?.map((data, index) => ({
                ...data,
                index
            }))
            setPaymentTableData(reshuffledTableData)
        }
    }

    useEffect(() => {
        updateFields()
    }, [updateFields])

    //Get Tranch Charges

    const handleSaveHNI = async () => {

        if (billNumber) {
            const payload = {
                billno: billNumber,
                esbn: taxpayerDetails?.esbn,
                name: `${taxpayerDetails?.name}`?.trim(),
                sex: taxpayerDetails?.gender,
                addr: taxpayerDetails?.address,
                phoneno: taxpayerDetails?.phoneNumber,
                email: taxpayerDetails?.email,
                lga: taxpayerDetails?.lga,
                zone: taxpayerDetails?.zone,
                taxoffice: taxpayerDetails?.taxAreaOffice,
                fiscalyear: taxpayerDetails?.fiscalYear,
                duedate: `${taxpayerDetails?.fiscalYear}/12/31`,
                totalamtbilled: allIncomeTotal,
                discount: 0,
                discountreason: " ",
                grandtot: allIncomeTotal,
                totalrelief: 0,
                totaloutstanding: 0,
                taxableincome: allIncomeTotal,
                totaltaxdue: totalCharges,
                procstatus: "Pending",
                ...taxData,
                approve: "0",
                whyreject: "",
                inputter: user?.email
                    ? `${user?.email}`.trim()
                    : "",
                authoriser: user?.email
                    ? `${user?.email}`.trim()
                    : "",
                incomes: incomeTableData?.map((income) => ({
                    incometype: income?.incomeType ?? income?.IncomeType ?? ' ',
                    amt: income?.amount ?? income?.Amount ?? 0,
                    incomesource: income?.incomesource ?? income?.IncomeSource ?? ' ',
                    outstandingamt: 0,
                    outstandingreason: " ",
                    revenuecode: " ", //direct Assessment (Current)
                    revenuename: " "
                })),
                prepayments: paymentTableData?.map((payment) => ({
                    incometype: payment?.incomeType ?? payment?.IncomeType ?? ' ',
                    incomesource: payment?.incomesource ?? ' ',
                    amount: payment?.amount ?? payment?.Amount ?? 0,
                    // outstandingamt: 0,
                    // outstandingreason: " ",
                    // revenuecode: " ", //direct Assessment (Current)
                    // revenuename: " "
                })),
            }
            saveHNIHook({
                method: 'PUT',
                route: `hni`,
                payload
            })
        }
    }

    useEffect(() => {
        if (status.state !== null) {
            setAlert({
                showing: true,
                type: status.state,
                message: status.message
            });
            if (status.state === "success") {
                setTimeout(() => {
                    history.push('/hni/tax');
                }, 500);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    useEffect(() => {
        if (taxpayerDetails?.esbn) {
            getESBNData({
                route: `users/anssid?anssid=${taxpayerDetails?.esbn}`,
                service: 'esbn'
            })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taxpayerDetails?.esbn])

    useEffect(() => {
        if (esbnData) {
            setTaxpayerDetails({
                ...taxpayerDetails,
                // zone: '',
                gender: esbnData?.sex,
                email: esbnData?.email,
                name: `${esbnData?.firstname} ${esbnData?.surname}`,
                phoneNumber: esbnData?.phonenumber,
                address: esbnData?.residentialaddress
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [esbnData])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTaxpayerDetails({ ...taxpayerDetails, [name]: value })
    }

    const handleIncomeChange = (e) => {
        const { name, value } = e.target;
        setIncome({ ...income, [name]: value })
    }
    const handlePaymentChange = (e) => {
        const { name, value } = e.target;
        setPayment({ ...payment, [name]: value })
    }

    const submit = (e) => {
        e?.preventDefault();
        if (taxIsCalculated || totalCharges > 0) {
            handleSaveHNI()
        } else {
            getTranchCharges();
        }
    }

    useEffect(() => {
        const delay = setTimeout(
            () =>
                setAlert({
                    showing: false,
                    type: null,
                    message: ""
                }),
            3000
        );

        return () => clearTimeout(delay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!alert.showing]);

    return (
        <section>
            {showModal.modalState &&
                <EditModal
                    data={showModal.data}
                    incomeTypes={
                        showModal.modalType === 'income' ?
                            allIncome[0]?.data :
                            allPrepayment[0]?.data
                    }
                    showing={showModal.modalState}
                    closeModal={(value) =>
                        setShowModal({
                            modalState: value,
                            modalType: "",
                            data: null
                        })
                    }
                    modalAction={(reason) => {
                        editTableData(reason, showModal.modalType)
                    }}
                    modalType={showModal.modalType}
                />
            }
            <GoBackButton />
            <form className={styles.columned__form} onSubmit={submit}>
                {alert.showing && (
                    <Alert text={alert.message} type={alert.type} />
                )}
                <h3>Taxpayer Details</h3>
                <br />

                <div className={styles.double__inputs}>
                    <div>
                        <label className={styles.input__label} htmlFor="esbn">
                            ESBN
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="text"
                            name="esbn"
                            onChange={handleInputChange}
                            id="esbn"
                            placeholder="Enter ESBN"
                            value={taxpayerDetails?.esbn}
                            required
                        />
                    </div>
                    <div>
                        <label className={styles.input__label} htmlFor="name">
                            Name
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="text"
                            name="name"
                            onChange={handleInputChange}
                            id="name"
                            placeholder="Enter first name"
                            value={taxpayerDetails?.name}
                            required
                        />
                    </div>
                </div>
                <div className={styles.double__inputs}>
                    <div>
                        <label className={styles.input__label} htmlFor="phoneNumber">
                            Phone Number
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="text"
                            name="phoneNumber"
                            onChange={handleInputChange}
                            id="phoneNumber"
                            placeholder="Enter phone number"
                            value={taxpayerDetails?.phoneNumber}
                            required
                        />
                    </div>
                    <div>
                        <label
                            className={styles.input__label}
                            htmlFor="gender"
                        >
                            Gender
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                            id="gender"
                            name="gender"
                            onChange={handleInputChange}
                            value={taxpayerDetails?.gender}
                            required
                        >
                            <option value="">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                </div>

                <div className={styles.double__inputs}>
                    <div>
                        <label
                            className={styles.input__label}
                            htmlFor="email"
                        >
                            Email
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="email"
                            name="email"
                            onChange={handleInputChange}
                            id="email"
                            placeholder="Enter email"
                            value={taxpayerDetails?.email}
                            required
                        />
                    </div>
                    <div>
                        <label className={styles.input__label} htmlFor="lga">
                            Local Govt. Area
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                            id="lga"
                            name="lga"
                            onChange={handleInputChange}
                            value={taxpayerDetails?.lga}
                            required
                        >
                            <option value="">Select</option>
                            {lgas[0]?.data?.map((tzone, index) => (
                                <React.Fragment key={tzone.Code + index}>
                                    <option
                                        value={tzone.Description}
                                        zonecode={tzone.Code}
                                        index={index}
                                    >
                                        {tzone.Description}
                                    </option>
                                </React.Fragment>
                            ))}
                        </select>
                    </div>
                </div>

                <div className={styles.double__inputs}>
                    <div>
                        <label
                            className={styles.input__label}
                            htmlFor="zone"
                        >
                            Zone
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                            id="zone"
                            name="zone"
                            onChange={handleInputChange}
                            value={taxpayerDetails?.zone}
                            required
                        >
                            <option value="">Select</option>
                            {zones[0]?.data?.map((tzone, index) => (
                                <React.Fragment key={tzone.Code + index}>
                                    <option
                                        value={tzone.Description}
                                        zonecode={tzone.Code}
                                        index={index}
                                    >
                                        {tzone.Description}
                                    </option>
                                </React.Fragment>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className={styles.input__label} htmlFor="taxAreaOffice">
                            Tax Area Offices
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                            id="taxAreaOffice"
                            name="taxAreaOffice"
                            onChange={handleInputChange}
                            value={taxpayerDetails?.taxAreaOffice}
                            required
                        >
                            <option value="">Select</option>
                            {taxoffices[0]?.data?.map((tzone, index) => (
                                <React.Fragment key={tzone.Code + index}>
                                    <option
                                        value={tzone.Description}
                                        zonecode={tzone.Code}
                                        index={index}
                                    >
                                        {tzone.Description}
                                    </option>
                                </React.Fragment>
                            ))}
                        </select>
                    </div>
                </div>

                <div className={styles.double__inputs}>
                    <div>
                        <label className={styles.input__label} htmlFor="address">
                            Address
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="text"
                            name="address"
                            onChange={handleInputChange}
                            id="address"
                            placeholder="Enter your address"
                            value={taxpayerDetails?.address}
                            required
                        />
                    </div>
                    <div>
                        <label
                            className={styles.input__label}
                            htmlFor="fiscalYear"
                        >
                            Fiscal Year
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                            id="fiscalYear"
                            name="fiscalYear"
                            onChange={handleInputChange}
                            value={taxpayerDetails?.fiscalYear}
                            required
                        >
                            <option value="">Select</option>
                            <option>2013</option>
                            {new Array(new Date().getFullYear() - 2013)
                                .fill(0)
                                .map((_, i) => (
                                    <option key={i}>{2013 + i + 1}</option>
                                ))}
                        </select>
                    </div>
                </div>
                <div className={styles.double__inputs}>
                    <div>
                        <label className={styles.input__label} htmlFor="fiscalDueDate">
                            Fiscal Due Date
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="text"
                            name="fiscalDueDate"
                            id="fiscalDueDate"
                            value={
                                taxpayerDetails?.fiscalYear ?
                                    `31/12/${taxpayerDetails?.fiscalYear}` : ''
                            }
                            placeholder=""
                            disabled
                            required
                        />
                    </div>
                </div>


                <hr className={styles.divider} />

                <h3>Details of Incomes</h3>
                <p>After entering the information, click on "Add Income" to include it.
                    <br />
                    Repeat this process to add additional income sources.</p>
                <br />

                <div className={styles.double__inputs}>
                    <div>
                        <label
                            className={styles.input__label}
                            htmlFor="incometype"
                        >
                            Type of Income
                        </label>
                        <select
                            id="incometype"
                            name="incometype"
                            onChange={handleIncomeChange}
                            value={income?.incometype}
                        >
                            <option value="">Select</option>
                            {allIncome[0]?.data?.map((income, index) => (
                                <React.Fragment key={income.Code + index}>
                                    <option
                                        value={income.Description}
                                    >
                                        {income.Description}
                                    </option>
                                </React.Fragment>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className={styles.input__label} htmlFor="incomesource">
                            Source of Income
                        </label>
                        <input
                            type="text"
                            name="incomesource"
                            id="incomesource"
                            onChange={handleIncomeChange}
                            value={income?.incomesource}
                            placeholder="Enter source of income"
                        />
                    </div>
                </div>
                <div className={styles.double__inputs}
                >
                    <div>
                        <label className={styles.input__label} htmlFor="amount">
                            Amount
                        </label>
                        <div className={styles1.amount__input}>
                            <input
                                type="text"
                                name="amount"
                                id="amount"
                                onChange={handleIncomeChange}
                                value={income?.amount}
                                placeholder="Enter amount"
                            />
                            <span className='amount__symb'>₦</span>
                        </div>
                    </div>
                </div>

                <div className={styles1.add_button__wrapper}>
                    <OutlineButton
                        text='Add Income'
                        onclick={() => updateTables(income, 'income')}
                        loading={false}
                        loadingStyle={styles.loading__button}
                    />
                </div>
                <br />
                <br />

                <Table
                    headers={headers}
                    data={incomeTableData}
                    // full
                    pageSize={20}
                    total={incomeTableData?.length}
                    onRowClick={() => null}
                    billNoOfAmountPaidShowing={null}
                    onEdit={(record) =>
                        setShowModal({
                            modalState: true,
                            modalType: "income",
                            data: record
                        })
                    }
                    onDelete={(record) => deleteTableData(record, 'income')}
                />

                <div className={styles1.totals__wrapper}>
                    <div className={[
                        styles1.totals,
                        styles1.income__totals,
                    ].join(" ")}
                    >
                        <h5>Total Income</h5>
                        <h5>₦{Number(allIncomeTotal).toLocaleString()}</h5>
                    </div>
                </div>
                <hr className={styles.divider} />

                <h3>Prepayments</h3>

                <div className={styles.double__inputs}>
                    <div>
                        <label
                            className={styles.input__label}
                            htmlFor="incometype"
                        >
                            Type of Income
                        </label>
                        <select
                            id="incometype"
                            name="incometype"
                            onChange={handlePaymentChange}
                            value={payment?.incometype}
                        // required
                        >
                            <option value="">Select</option>
                            {allPrepayment[0]?.data?.map((prepayment, index) => (
                                <React.Fragment key={prepayment.Code + index}>
                                    <option
                                        value={prepayment.Description}
                                    >
                                        {prepayment.Description}
                                    </option>
                                </React.Fragment>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label className={styles.input__label} htmlFor="incomesource">
                            Income Description
                        </label>
                        <input
                            type="text"
                            name="incomesource"
                            id="incomesource"
                            onChange={handlePaymentChange}
                            value={payment?.incomesource}
                            placeholder="Enter source of income"
                        />
                    </div>
                </div>

                <div className={styles.double__inputs}>
                    <div>
                        <label className={styles.input__label} htmlFor="amount">
                            Amount
                        </label>
                        <div className={styles1.amount__input__side}>
                            <input
                                type="text"
                                name="amount"
                                id="amount"
                                placeholder="Enter amount"
                                onChange={handlePaymentChange}
                                value={payment?.amount}
                            />
                            <span>₦</span>
                        </div>
                    </div>
                </div>

                <div className={styles1.add_button__wrapper}>
                    <OutlineButton
                        text='Add Prepayments'
                        onclick={() => updateTables(payment, 'prepayments')}
                        loading={false}
                        loadingStyle={styles.loading__button}
                    />
                </div>
                <br />
                <br />
                <br />

                <Table
                    headers={prepaymentTableHeader}
                    data={paymentTableData}
                    // full
                    total={paymentTableData?.length}
                    onRowClick={() => null}
                    billNoOfAmountPaidShowing={null}
                    onEdit={(record) =>
                        setShowModal({
                            modalState: true,
                            modalType: "prepayments",
                            data: record
                        })
                    }
                    onDelete={(record) => deleteTableData(record, 'prepayments')}

                />
                <div className={styles1.totals__wrapper}>
                    <div className={[
                        styles1.totals,
                        styles1.prepayment__totals,
                    ].join(" ")}
                    >
                        <h5>Total Prepayments</h5>
                        <h5>₦{Number(allPrepaymentTotal).toLocaleString()}</h5>
                    </div>
                </div>

                <hr className={styles.divider} />

                <h3>Total Tax: ₦{Number(totalCharges).toLocaleString()}</h3>

                <footer
                    style={{
                        marginLeft: 'auto'
                    }}
                    className={styles.form__footer}
                >
                    <button
                        style={{
                            padding: '0px 25px'
                        }}
                        disabled={
                            Object.values(taxpayerDetails).includes('') ||
                            incomeTableData.length === 0 ||
                            loading ||
                            taxIsCalculated ||
                            savingHNI
                        }
                        type="submit"
                        className={[
                            "primary__btn",
                            styles.primary__button,
                            loading || taxIsCalculated ||
                                savingHNI ? styles.loading__button : ""
                        ].join(" ")}
                    >
                        {
                            totalCharges > 0 ?
                                "Update Bill" : savingHNI ?
                                    "Updating HNI..." : "Calculate Tax"
                        }

                        {/* {(loading || taxIsCalculated || savingHNI) && <Spinner />} */}
                    </button>
                    <div className={styles1.margin__buttom} />
                </footer>
            </form>
        </section>
    )
}
