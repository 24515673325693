import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import style from "../style.module.css";
import style1 from "../../../Identity-management-individual/style.module.css";
import arrowRightIcon from "../../../assets/arrowRightIcon.svg";
import arrowLeftIcon from "../../../assets/arrowLeftIcon.svg";
import trashIcon from "../../../assets/Filing/trashIcon.svg";
import Spinner from "../../../components/spinner";

export const StepThree = ({
	updateTab = () => {},
	updateFilingData = () => {},
	filingData = null
}) => {
	// const [loading, setLoading] = useState(false);
	const [loading] = useState(false);
	const [supportingDocs, setSupportingDocs] = useState({
		pension: filingData?.supportingDocs?.pension ?? null,
		nhf: filingData?.supportingDocs?.nhf ?? null,
		nhis: filingData?.supportingDocs?.nhis ?? null,
		lifeAssurance: filingData?.supportingDocs?.lifeAssurance ?? null
	});
	const pensionRef = useRef(null);
	const nhfRef = useRef(null);
	const nhisRef = useRef(null);
	const lifeAssuranceRef = useRef(null);

	useEffect(() => {
		updateFilingData({
			...filingData,
			supportingDocs: {
				...(filingData?.supportingDocs || {}),
				pension: supportingDocs?.pension,
				nhf: supportingDocs?.nhf,
				nhis: supportingDocs?.nhis,
				lifeAssurance: supportingDocs?.lifeAssurance
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [supportingDocs]);

	return (
		<div className={styles.wrapper}>
			<p>Step 3: Upload Other Supporting Documents</p>

			<div className={`bg-warning ${styles.notice__left}`}>
				<p>
					<strong>Instructions</strong>
				</p>
				<ul>
					<li>You have entered values for your annual tax reliefs</li>
					<li>Kindly upload the supporting documents</li>
					<li>Please upload the relevant documents</li>
					<li>If you have any challenge, contact:</li>
					<li>EMAIL: email@email.com</li>
					<li>
						Mrs Amadi (HOD of Assessment Department) - 08037874266;
						Mrs. Opa - 08066830190; Alex - 07032139423; Chidi -
						08162842990
					</li>
				</ul>
			</div>
			<br />

			<div className={`row ${styles.uploads}`}>
				<div className={`col-md-6`}>
					<p>
						<strong>
							Payment Receipt: Annual Pension (Max: 1mb)
						</strong>
					</p>
					<span className={style.flex__left}>
						<input
							type="file"
							id="pension"
							accept="image/png, image/jpeg, image/webp"
							ref={pensionRef}
							onChange={(e) => {
								setSupportingDocs((prev) => ({
									...prev,
									pension: e.target.files[0]
								}));
							}}
							style={{ display: "none" }}
						/>

						<label
							htmlFor="pension"
							className={`btn btn-success ${styles.downloads__button}`}
						>
							<p>Choose File</p>
						</label>
						<span className={styles.ml_1}>
							<p>{supportingDocs?.pension?.name}</p>
						</span>
						{supportingDocs?.pension?.name && (
							<span className={styles.ml_1}>
								<img
									src={trashIcon}
									alt="delete"
									className={styles.delete_icon}
									onClick={() => {
										setSupportingDocs((prevVal) => ({
											...prevVal,
											pension: null
										}));
										pensionRef.current.value = null;
									}}
								/>
							</span>
						)}
					</span>
				</div>

				<div className={`col-md-6`}>
					<p>
						<strong>Payment Receipt: Annual NHF (Max: 1mb)</strong>
					</p>
					<span className={style.flex__left}>
						<input
							type="file"
							id="nhf"
							accept="image/png, image/jpeg, image/webp"
							ref={nhfRef}
							onChange={(e) => {
								setSupportingDocs((prev) => ({
									...prev,
									nhf: e.target.files[0]
								}));
							}}
							style={{ display: "none" }}
						/>
						<label
							htmlFor="nhf"
							className={`btn btn-success ${styles.downloads__button}`}
						>
							<p>Choose File</p>
						</label>
						<span className={styles.ml_1}>
							<p>{supportingDocs?.nhf?.name}</p>
						</span>
						{supportingDocs?.nhf?.name && (
							<span className={styles.ml_1}>
								<img
									src={trashIcon}
									alt="delete"
									className={styles.delete_icon}
									onClick={() => {
										setSupportingDocs((prevVal) => ({
											...prevVal,
											nhf: null
										}));
										nhfRef.current.value = null;
									}}
								/>
							</span>
						)}
					</span>
				</div>

				<div className={`col-md-6`}>
					<p>
						<strong>Payment Receipt: Annual NHIS (Max: 1mb)</strong>
					</p>
					<span className={style.flex__left}>
						<input
							type="file"
							id="nhis"
							accept="image/png, image/jpeg, image/webp"
							ref={nhisRef}
							onChange={(e) => {
								setSupportingDocs((prev) => ({
									...prev,
									nhis: e.target.files[0]
								}));
							}}
							style={{ display: "none" }}
						/>
						<label
							htmlFor="nhis"
							className={`btn btn-success ${styles.downloads__button}`}
						>
							<p>Choose File</p>
						</label>
						<span className={styles.ml_1}>
							<p>{supportingDocs?.nhis?.name}</p>
						</span>
						{supportingDocs?.nhis?.name && (
							<span className={styles.ml_1}>
								<img
									src={trashIcon}
									alt="delete"
									className={styles.delete_icon}
									onClick={() => {
										setSupportingDocs((prevVal) => ({
											...prevVal,
											nhis: null
										}));
										nhisRef.current.value = null;
									}}
								/>
							</span>
						)}
					</span>
				</div>

				<div className={`col-md-6`}>
					<p>
						<strong>
							Payment Receipt: Life assurance (Max: 1mb)
						</strong>
					</p>
					<span className={style.flex__left}>
						<input
							type="file"
							id="lifeAssurance"
							accept="image/png, image/jpeg, image/webp"
							ref={lifeAssuranceRef}
							onChange={(e) => {
								setSupportingDocs((prev) => ({
									...prev,
									lifeAssurance: e.target.files[0]
								}));
							}}
							style={{ display: "none" }}
						/>
						<label
							htmlFor="lifeAssurance"
							className={`btn btn-success ${styles.downloads__button}`}
						>
							<p>Choose File</p>
						</label>
						<span className={styles.ml_1}>
							<p>{supportingDocs?.lifeAssurance?.name}</p>
						</span>
						{supportingDocs?.lifeAssurance?.name && (
							<span className={styles.ml_1}>
								<img
									src={trashIcon}
									alt="delete"
									className={styles.delete_icon}
									onClick={() => {
										setSupportingDocs((prevVal) => ({
											...prevVal,
											lifeAssurance: null
										}));
										lifeAssuranceRef.current.value = null;
									}}
								/>
							</span>
						)}
					</span>
				</div>
			</div>
			<br />
			<br />
			<br />

			<footer className={style1.form__footer}>
				<button
					disabled={loading}
					type="submit"
					className={`btn text-success ${styles.border__button__green}`}
					onClick={() => updateTab(2)}
				>
					<img src={arrowLeftIcon} alt="download" />
					Go Back
				</button>
				<button
					// disabled={Object.values(supportingDocs).includes(null)}
					type="submit"
					className={[
						"primary__btn",
						style1.primary__button,
						loading ? style1.loading__button : ""
					].join(" ")}
					onClick={() => updateTab(4)}
				>
					Next
					<img src={arrowRightIcon} alt="download" />
					{loading && <Spinner />}
				</button>
			</footer>
		</div>
	);
};
