import { useEffect, useState } from "react";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import Alert from "../../components/Alert";
import AddIcon from "../../assets/UserManagement/add.svg";
import styles from "../user/style.module.css";

const Group = () => {
	const history = useHistory();

	const [roles, setRoles] = useState(null);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const accessToken = localStorage.getItem("access_token");

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	useEffect(() => {
		async function fetchRoles() {
			setLoading(true);

			try {
				const request = await axios.get(`/auth/role`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				});
				setLoading(false);

				console.log(request.data.data);
				setRoles(request?.data?.data?.roles);
			} catch (error) {
				setLoading(false);
				if (error.response) {
					console.log("response", error.response.data.message);
					setAlert((alert) => ({
						...alert,
						showing: true,
						type: "error",
						message: error.response.data.message
					}));
				} else {
					console.log("Error", error.message);
					setAlert((alert) => ({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					}));
				}
			}
		}

		fetchRoles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const edit = (role) => {
		history.push("/create-role", role);
	};

	const deactivate = async (id) => {
		try {
			await axios.put(`/admin/usermanagement/deactivateaccount/${id}`, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: "Role Deactivated!"
			});
		} catch (error) {
			if (error.response) {
				console.log("response", error.response.data.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data.message
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		}
	};

	return (
		<main className={styles.wrapper}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<header className={styles.header}>
				<div className={styles.top}>
					<p className={styles.heading__text}>Roles</p>
					<span className={styles.drop__arrow}></span>
				</div>
				<div className={styles.bottom}>
					<p className={styles.heading__text}>
						{roles?.length}{" "}
						{loading ? "" : roles?.length === 1 ? "Role" : "Roles"}
					</p>
					<NavLink to="/create-role" className={styles.nav__link}>
						<img src={AddIcon} alt="add icon" />
						Create Role
					</NavLink>
				</div>
			</header>
			<section>
				<table className={styles.table}>
					{loading && (
						<tbody>
							<tr>
								<td>
									<p style={{ textAlign: "center" }}>
										Loading...
									</p>
								</td>
							</tr>
						</tbody>
					)}
					{!roles?.length && !loading ? (
						<tbody>
							<tr>
								<td>
									<p style={{ textAlign: "center" }}>
										No Roles.
									</p>
								</td>
							</tr>
						</tbody>
					) : (
						<tbody>
							{roles?.map((role) => (
								<tr key={role.Name}>
									<td>
										<p>Name</p>
										<p>{role.Name}</p>
									</td>
									<td>
										<button
											className={styles.deactive__btn}
											onClick={() => edit(role)}
										>
											Edit
										</button>
										<button
											className={styles.delete__btn}
											onClick={() => deactivate(role.id)}
										>
											Deactivate
										</button>
									</td>
								</tr>
							))}
						</tbody>
					)}
				</table>
			</section>
		</main>
	);
};

export default Group;
