import React, { createRef, useEffect, useState } from 'react'
import axios from 'axios';
import { useHistory } from "react-router";
import Table from '../../components/Table';
import styles from "../MDAs/style.module.css";
import styles3 from "../../Identity-management-individual/style.module.css";
import Alert from '../../components/Alert';

const headers = [
    ["Code", "Code"],
    ["Description/Name", "Description"]
];

const accessToken = localStorage.getItem("access_token");

const HNISetupHome = () => {
    const history = useHistory();

    const [data, setData] = useState(null);
    const [total, setTotal] = useState(0);
    const [setupType, setSetupType] = useState('income');
    const [loading, setLoading] = useState(true);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const filterRef = createRef();

    const [alert, setAlert] = useState({
        showing: false,
        type: null,
        message: ""
    });

    const upateHNITable = async ({
        query = `page=${1}&pageSize=${20}&`,
        download,
        printing
    }) => {
        try {
            let data = await axios
                .get(
                    `${setupType === "income" ?
                        `hniallincome?${query}` :
                        `hniallprepayment?${query}`
                    }`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    }
                )
                .then((res) => res.data || { data: {} });
            if (!data || !data?.data) {
                return null;
            }

            if (!download && !printing) setData(data.data);
            setTotal(data?.total);

            return data.data;
        } catch (error) {
            if (error.response) {
                setAlert({
                    ...alert,
                    showing: true,
                    type: "error",
                    message:
                        error.response.data?.msg ||
                        error.response.data?.errors?.[0] ||
                        error.response.data?.errors?.[0]?.message ||
                        error.response.data?.errors?.details[0]?.message ||
                        error.response.message ||
                        error.response.data.message
                });
            } else {
                setAlert({
                    ...alert,
                    showing: true,
                    type: "error",
                    message: error.response?.data?.msg
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const getHNI =
        ({
            currentPage = 1,
            pageSize = 20,
            download = false,
            printing = false
        } = {}) =>
            async (e) => {
                e?.preventDefault?.();
                setLoading(true);
                if (!filterRef?.current?.classList?.contains(styles.no__display)) {
                    filterRef?.current?.classList?.toggle(styles.no__display);
                }
                let query = `page=${currentPage}&pageSize=${pageSize}&`;
                upateHNITable({
                    query: query,
                    download,
                    printing
                })
            };

    const deactivateBill = async (record) => {
        const payload = {
            Code: record?.Code,
            Description: record?.Description,
            Deactivated: !record?.Deactivated
        }

        let data = await axios
            .put(
                `${setupType === "income" ?
                    `hniincome/${record?.Code}` :
                    `hniprepayment/${record?.Code}`
                }`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then((res) => res.data || { data: {} });

        if (data) {
            upateHNITable({
                query: `page=${1}&pageSize=${20}&`
            });
        }
        setAlert({
            ...alert,
            showing: true,
            type: "success",
            message: data?.msg
        });
        if (!data || !data?.data) {
            // handle errors
            return null;
        }
    }

    useEffect(() => {
        getHNI()();
        setFiltersCleared(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersCleared === true, setupType]);

    useEffect(() => {
        const delay = setTimeout(
            () =>
                setAlert({
                    showing: false,
                    type: null,
                    message: ""
                }),
            3000
        );

        return () => clearTimeout(delay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!alert.showing]);

    return (
        <div className={styles3.columned__table}>
            {alert.showing && (
                <Alert text={alert.message} type={alert.type} />
            )}
            <div className={styles.actions__div}>
                <button
                    className="primary__btn"
                    style={{
                        padding: "0 25px"
                    }}
                    onClick={() => {
                        history.push("/hni/create-setup", {
                            setupType: setupType
                        });
                    }}
                >
                    Create New Setup
                </button>
            </div>
            <div
                className={styles.radio__button_div}
            >
                <div>
                    <div>
                        <input
                            id="incomeSetup"
                            type="radio"
                            value="income"
                            checked={setupType === "income"}
                            onChange={(e) => {
                                setSetupType(e.target.value);
                            }}
                        />
                        <label
                            className={styles3.input__label}
                            htmlFor="incomeSetup"
                        >
                            Income
                        </label>
                    </div>
                    <div>
                        <input
                            id="paymentSetup"
                            type="radio"
                            value="payment"
                            checked={setupType === "payment"}
                            onChange={(e) => {
                                setSetupType(e.target.value);
                            }}
                        />
                        <label
                            className={styles3.input__label}
                            htmlFor="paymentSetup"
                        >
                            Prepayment
                        </label>
                    </div>
                </div>
            </div>
            <div className={styles.table__div}>
                {loading ? (
                    <p style={{ textAlign: "center" }}>Loading...</p>
                ) : (
                    <Table
                        headers={headers}
                        data={data || []}
                        full
                        pageSize={20}
                        total={total}
                        onRowClick={() => null}
                        billNoOfAmountPaidShowing={null}
                        onEdit={(data) =>
                            history.push(`/hni/edit-setup`, {
                                setupType,
                                ...data
                            })
                        }
                        onToggleActivation={(record) => deactivateBill(record)}
                    />
                )}
            </div>
        </div>
    )
}

export default HNISetupHome